import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return(
        <>
        <div className="sidenav-overlay"></div>
        <div className="drag-target"></div>

        <footer className="footer footer-static footer-light navbar-border navbar-shadow">
            <p className="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2"><span className="float-md-left d-block d-md-inline-block">Copyright &copy; 2022 <span className="text-bold-800 grey darken-2">takeiteasy srl</span></span><span className="float-md-right d-none d-lg-block">P.IVA 08759080727 | Privacy Policy | Termini e Condizioni<span id="scroll-top"></span></span></p>
        </footer>
        </>
    )
}

export default Footer;