import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet";
import * as config from '../config';
import axios from 'axios';
import { Skeleton, Empty, Avatar, Card, Button } from 'antd';
import { EditOutlined, CheckOutlined } from '@ant-design/icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Errors from '../components/Errors';

function Billing() {
    const navigate = useNavigate()
    const [haveError, setHaveError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [subscriptions, setSubscriptions] = useState([])

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        checkErrors(userInfo)
        getAllPlans(userInfo)
    }, [])

    const checkErrors = (userInfo) => {
        if (userInfo !== null) {
            if (userInfo.is_code_verify == "0" || userInfo.is_code_verify == 0 || userInfo.is_completed == "0" || userInfo.is_completed == 0) {
                setHaveError(true)
            } else {
                setHaveError(false)
            }
        } else {
            setHaveError(false)
        }
    }

    const getAllPlans = (userInfo) => {
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-user-subscriptions`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_admin_user: userInfo.id
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                setSubscriptions(response.data.sub_plans)
                setLoading(false)
            } else {
                setSubscriptions([])
                setLoading(false)
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            setLoading(false)
            console.error(error);
        });
    }

    const openSubBilling = (customer_id) => {
        toast.info("Caricamento...", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-pharmacy-billing`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                customer_id: customer_id
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                window.location.href = response.data.message;
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    return(
        <>
        <ToastContainer />
        <Helmet>
            <title>Fatturazione | Takeiteasy Partners</title>
        </Helmet>
        <Navbar />
        <div className="app-content content">
            <div className="content-overlay"></div>
            <div className="content-wrapper">
                <Errors />
                <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2">
                        <h3 className="content-header-title">Fatturazione</h3>
                    </div>
                </div>
                <div className="content-body">
                    <section className="row">
                        <div className="col-12">
                            <div className="card">
                                {(haveError)
                                    ?
                                    <Empty
                                        description={
                                            <span>
                                                Risolvi gli errori per poter continuare.
                                            </span>
                                        }
                                    />
                                    :
                                    <>
                                    <div className="card-content">
                                        <div className="card-body">
                                            <div className='row px-1'>
                                                <div className='col-lg-4 col-md-6 col-12'>
                                                    {(loading)
                                                        ?
                                                        <>
                                                        <Skeleton avatar={true} active={true} />
                                                        <Skeleton className='mt-1' avatar={true} active={true} />
                                                        </>
                                                        :
                                                        (subscriptions.length > 0)
                                                            ?
                                                            subscriptions.map((item, index) => {
                                                                return(
                                                                    <Button key={index} onClick={(item.is_expired == false) ? () => openSubBilling(item.stripe_customer_id) : () => navigate(`/ripristino/farmacia/${item.id_pharmacy}/${item.stripe_customer_id}`)} className={`${(subscriptions.length == index + 1) ? 'mb-0' : 'mb-1'} d-flex align-items-center justify-content-between px-2 py-3`} size='large' block>
                                                                        <div className='d-flex align-items-center'>
                                                                            <Avatar size='large' src={item.logo} className="mr-1" />
                                                                            {item.name_pharmacy}
                                                                        </div>
                                                                        <i className='ft-chevron-right'></i>
                                                                    </Button>
                                                                )
                                                            })
                                                            :
                                                            <>
                                                            <Skeleton avatar={true} active={true} />
                                                            <Skeleton className='mt-1' avatar={true} active={true} />
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default Billing;