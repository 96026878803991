import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet";
import * as config from '../config';
import axios from 'axios';
import { Skeleton, Empty, Avatar, Card, Button, Breadcrumb, Button as ButtonAntd, Switch, Modal, Input, Collapse, Divider, DatePicker, Table, Dropdown, Tooltip } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Errors from '../components/Errors';
import { ThreeDots } from 'react-loader-spinner';
import { PlusOutlined, CloseOutlined, CalendarFilled, CopyOutlined } from '@ant-design/icons';
import InputMask from 'react-input-mask';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import locale from 'antd/es/date-picker/locale/it_IT';
import moment, { months } from 'moment';
import 'moment/locale/it';
import { DownOutlined, EditOutlined, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';

const { TextArea } = Input;
const { confirm } = Modal;

function EditVisit() {
    const navigate = useNavigate()
    const { id_visit } = useParams()
    const [allVisits, setAllVisits] = useState([])
    const [loadVisits, setLoadVisits] = useState(true)
    const [haveError, setHaveError] = useState(false)
    const [loadPage, setLoadPage] = useState(true)
    const [showBook, setShowBook] = useState(false)
    const [loadingBook, setLoadingBook] = useState(false)
    const [descriptionBook, setDescriptionBook] = useState("")
    const [dataBook, setDataBook] = useState(null)
    const [timeSlot, setTimeSlot] = useState([])
    const [loadSlot, setLoadSlot] = useState(false)
    const [slotSelect, setSlotSelect] = useState("")
    const [listTicketDirect, setListTicketDirect] = useState([])
    const [showEditModal, setShowEditModal] = useState(false)
    const [idTicket, setIdTicket] = useState(null)
    const [editDescription, setEditDescription] = useState("")
    const [loadingEditTicket, setLoadingEditTicket] = useState(false)
    const [visit, setVisit] = useState({
        name_visit: "",
        description: "",
        price: "",
        discount_price: "",
        start_date: "",
        end_date: "",
        duration: ""
    })
    const [valueLunedi, setValueLunedi] = useState({
        id_day: null,
        day_name: 'Lunedì',
        open_hour: "",
        closed_hour: "",
        second_open_hour: "",
        second_closed_hour: "",
        is_open: 1,
        is_second_hour: false
    });
    const [valueMartedi, setValueMartedi] = useState({
        id_day: null,
        day_name: 'Martedì',
        open_hour: "",
        closed_hour: "",
        second_open_hour: "",
        second_closed_hour: "",
        is_open: 1,
        is_second_hour: false
    });
    const [valueMercoledi, setValueMercoledi] = useState({
        id_day: null,
        day_name: 'Mercoledì',
        open_hour: "",
        closed_hour: "",
        second_open_hour: "",
        second_closed_hour: "",
        is_open: 1,
        is_second_hour: false
    });
    const [valueGiovedi, setValueGiovedi] = useState({
        id_day: null,
        day_name: 'Giovedì',
        open_hour: "",
        closed_hour: "",
        second_open_hour: "",
        second_closed_hour: "",
        is_open: 1,
        is_second_hour: false
    });
    const [valueVenerdi, setValueVenerdi] = useState({
        id_day: null,
        day_name: 'Venerdì',
        open_hour: "",
        closed_hour: "",
        second_open_hour: "",
        second_closed_hour: "",
        is_open: 1,
        is_second_hour: false
    });
    const [valueSabato, setValueSabato] = useState({
        id_day: null,
        day_name: 'Sabato',
        open_hour: "",
        closed_hour: "",
        second_open_hour: "",
        second_closed_hour: "",
        is_open: 1,
        is_second_hour: false
    });
    const [valueDomenica, setValueDomenica] = useState({
        id_day: null,
        day_name: 'Domenica',
        open_hour: "",
        closed_hour: "",
        second_open_hour: "",
        second_closed_hour: "",
        is_open: 1,
        is_second_hour: false
    });
    const [loading, setLoading] = useState(false)
    const [selectedVisit, setSelectedVisit] = useState(null)

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => `#${text}`
        },
        {
            title: 'Descrizione',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Data ticket',
            dataIndex: 'date_slot',
            key: 'date_slot',
            render: (text, record, index) => moment(text).format('ddd DD MMM YYYY')
        },
        {
            title: 'Slot ticket',
            dataIndex: 'time_slot',
            key: 'time_slot',
            render: (text, record, index) => `${text.split(':')[0]}:${text.split(':')[1]}`
        },
        {
            title: 'Azioni',
            dataIndex: 'azioni',
            key: 'azioni',
            render: (text, record, index) => <Dropdown menu={{ items: [{ label: "Modifica", icon: <EditOutlined />, onClick: () => openEditModal(record.id, record.description), key: '1' },{ label: "Elimina", icon: <DeleteOutlined />, onClick: () => deleteTicketVisitRequest(record.id), danger: true, key: '2' }] }}>
                <ButtonAntd>
                    <div className="d-flex align-items-center">
                        Azioni
                        <DownOutlined style={{ marginLeft: 5 }} />
                    </div>
                </ButtonAntd>
            </Dropdown>
        }
    ];

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        checkErrors(userInfo)
        checkPharmacyBilling()
        getAllVisits()
        getListDirectTicket()
    }, [])

    const checkErrors = (userInfo) => {
        if (userInfo !== null) {
            if (userInfo.is_code_verify == "0" || userInfo.is_code_verify == 0 || userInfo.is_completed == "0" || userInfo.is_completed == 0) {
                setHaveError(true)
            } else {
                setHaveError(false)
            }
        } else {
            setHaveError(false)
        }
    }

    const checkPharmacyBilling = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/check-pharmacy-has-visits`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                if (response.data.has_visits !== true) {
                    navigate('/')
                }
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const handleSecondOrarioLunedi = () => setValueLunedi({ ...valueLunedi, is_second_hour: true });
    const handleNotSecondOrarioLunedi = () => setValueLunedi({ ...valueLunedi, is_second_hour: false });

    const handleSecondOrarioMartedi = () => setValueMartedi({ ...valueMartedi, is_second_hour: true });
    const handleNotSecondOrarioMartedi = () => setValueMartedi({ ...valueMartedi, is_second_hour: false });

    const handleSecondOrarioMercoledi = () => setValueMercoledi({ ...valueMercoledi, is_second_hour: true });
    const handleNotSecondOrarioMercoledi = () => setValueMercoledi({ ...valueMercoledi, is_second_hour: false });

    const handleSecondOrarioGiovedi = () => setValueGiovedi({ ...valueGiovedi, is_second_hour: true });
    const handleNotSecondOrarioGiovedi = () => setValueGiovedi({ ...valueGiovedi, is_second_hour: false });

    const handleSecondOrarioVenerdi = () => setValueVenerdi({ ...valueVenerdi, is_second_hour: true });
    const handleNotSecondOrarioVenerdi = () => setValueVenerdi({ ...valueVenerdi, is_second_hour: false });
    
    const handleSecondOrarioSabato = () => setValueSabato({ ...valueSabato, is_second_hour: true });
    const handleNotSecondOrarioSabato = () => setValueSabato({ ...valueSabato, is_second_hour: false });
    
    const handleSecondOrarioDomenica = () => setValueDomenica({ ...valueDomenica, is_second_hour: true });
    const handleNotSecondOrarioDomenica = () => setValueDomenica({ ...valueDomenica, is_second_hour: false });

    const handleChange = (e) => {
        if (e.target.name == "name_visit") {
            setVisit({ ...visit, [e.target.name]: capitalizeFirstLowercaseRest(e.target.value) })
        } else if (e.target.name == "price" || e.target.name == "discount_price") {
            setVisit({ ...visit, [e.target.name]: setFirstLetterCurrency(e.target.value.replace(/[^0-9€.,\b]+/ig, "")) })
        } else if (e.target.name == "duration") {
            setVisit({ ...visit, [e.target.name]: e.target.value.replace(/[^0-9\b]+/ig, "") })
        } else {
            setVisit({ ...visit, [e.target.name]: e.target.value })
        }
    }

    const capitalizeFirstLowercaseRest = str => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const setFirstLetterCurrency = str => {
        if (str.length == 1 && str !== '€') {
            return `€${str}`;
        } else {
            return str;
        }
    }

    const openEditModal = (id, description) => {
        setEditDescription(description)
        setIdTicket(id)
        setShowEditModal(true)
    }

    const getPharmacyVisitData = (allVisitsJson) => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-pharmacy-visit`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_visit: id_visit,
                id_pharmacy: pharmacyInfo.id_pharmacy
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                if (response.data.result == true) {
                    setVisit({ 
                        name_visit: response.data.items.name_visit,
                        price: response.data.items.price,
                        discount_price: response.data.items.discount_price,
                        description: response.data.items.description,
                        start_date: (response.data.items.is_date == "1" || response.data.items.is_date == 1) ? response.data.items.start_date : "",
                        end_date: (response.data.items.is_date == "1" || response.data.items.is_date == 1) ? response.data.items.end_date : "",
                        duration: response.data.items.duration,
                        description: response.data.items.description
                    })
                    const elementIndex = allVisitsJson.findIndex(e => e.name_visit == response.data.items.name_visit)
                    if (elementIndex !== -1) {
                        setSelectedVisit(allVisitsJson[elementIndex].id)
                    }
                    if (response.data.days_work.length > 0) {
                        let count = 0;
                        response.data.days_work.forEach(element => {
                            count++;
                            switch (element.day_name) {
                                case 'Lunedì':
                                    setValueLunedi({
                                        ...valueLunedi,
                                        id_day: element.id_day,
                                        open_hour: element.open_hour,
                                        second_open_hour: element.second_open_hour,
                                        closed_hour: element.closed_hour,
                                        second_closed_hour: element.second_closed_hour,
                                        is_open: parseInt(element.is_open),
                                        is_second_hour: (element.second_open_hour !== "" && element.second_closed_hour !== "" && element.second_open_hour !== null && element.second_closed_hour !== null) ? true : false
                                    })
                                    break;
                                case 'Martedì':
                                    setValueMartedi({
                                        ...valueMartedi,
                                        id_day: element.id_day,
                                        open_hour: element.open_hour,
                                        second_open_hour: element.second_open_hour,
                                        closed_hour: element.closed_hour,
                                        second_closed_hour: element.second_closed_hour,
                                        is_open: parseInt(element.is_open),
                                        is_second_hour: (element.second_open_hour !== "" && element.second_closed_hour !== "" && element.second_open_hour !== null && element.second_closed_hour !== null) ? true : false
                                    })
                                    break;
                                case 'Mercoledì':
                                    setValueMercoledi({
                                        ...valueMercoledi,
                                        id_day: element.id_day,
                                        open_hour: element.open_hour,
                                        second_open_hour: element.second_open_hour,
                                        closed_hour: element.closed_hour,
                                        second_closed_hour: element.second_closed_hour,
                                        is_open: parseInt(element.is_open),
                                        is_second_hour: (element.second_open_hour !== "" && element.second_closed_hour !== "" && element.second_open_hour !== null && element.second_closed_hour !== null) ? true : false
                                    })
                                    break;
                                case 'Giovedì':
                                    setValueGiovedi({
                                        ...valueGiovedi,
                                        id_day: element.id_day,
                                        open_hour: element.open_hour,
                                        second_open_hour: element.second_open_hour,
                                        closed_hour: element.closed_hour,
                                        second_closed_hour: element.second_closed_hour,
                                        is_open: parseInt(element.is_open),
                                        is_second_hour: (element.second_open_hour !== "" && element.second_closed_hour !== "" && element.second_open_hour !== null && element.second_closed_hour !== null) ? true : false
                                    })
                                    break;
                                case 'Venerdì':
                                    setValueVenerdi({
                                        ...valueVenerdi,
                                        id_day: element.id_day,
                                        open_hour: element.open_hour,
                                        second_open_hour: element.second_open_hour,
                                        closed_hour: element.closed_hour,
                                        second_closed_hour: element.second_closed_hour,
                                        is_open: parseInt(element.is_open),
                                        is_second_hour: (element.second_open_hour !== "" && element.second_closed_hour !== "" && element.second_open_hour !== null && element.second_closed_hour !== null) ? true : false
                                    })
                                    break;
                                case 'Sabato':
                                    setValueSabato({
                                        ...valueSabato,
                                        id_day: element.id_day,
                                        open_hour: element.open_hour,
                                        second_open_hour: element.second_open_hour,
                                        closed_hour: element.closed_hour,
                                        second_closed_hour: element.second_closed_hour,
                                        is_open: parseInt(element.is_open),
                                        is_second_hour: (element.second_open_hour !== "" && element.second_closed_hour !== "" && element.second_open_hour !== null && element.second_closed_hour !== null) ? true : false
                                    })
                                    break;
                                case 'Domenica':
                                    setValueDomenica({
                                        ...valueDomenica,
                                        id_day: element.id_day,
                                        open_hour: element.open_hour,
                                        second_open_hour: element.second_open_hour,
                                        closed_hour: element.closed_hour,
                                        second_closed_hour: element.second_closed_hour,
                                        is_open: parseInt(element.is_open),
                                        is_second_hour: (element.second_open_hour !== "" && element.second_closed_hour !== "" && element.second_open_hour !== null && element.second_closed_hour !== null) ? true : false
                                    })
                                    break;
                                default:
                                    setValueLunedi({
                                        ...valueLunedi,
                                        id_day: element.id_day,
                                        open_hour: element.open_hour,
                                        second_open_hour: element.second_open_hour,
                                        closed_hour: element.closed_hour,
                                        second_closed_hour: element.second_closed_hour,
                                        is_open: parseInt(element.is_open),
                                        is_second_hour: (element.second_open_hour !== "" && element.second_closed_hour !== "" && element.second_open_hour !== null && element.second_closed_hour !== null) ? true : false
                                    })
                                    break;
                            }

                            if (count == response.data.days_work.length) {
                                setLoadPage(false)
                            }
                        });
                    } else {
                        setLoadPage(false)
                    }
                } else {
                    toast.error(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setHaveError(true)
                }
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const getAllVisits = () => {
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-all-visits`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN
            })
        })
        .then(function(response) {
            if (response.data.length > 0) {
                setAllVisits(response.data)
                getPharmacyVisitData(response.data)
                setLoadVisits(false)
            } else {
                setLoadVisits(false)
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const selectVisit = (id, name) => {
        if (id == selectedVisit) {
            setSelectedVisit(null)
            setVisit({ ...visit, name_visit: '' })
        } else {
            setSelectedVisit(id)
            setVisit({ ...visit, name_visit: name })
        }
    }

    const updatePharmacyVisit = () => {
        if (visit.name_visit !== '' && visit.price !== '' && visit.description !== '' && visit.duration !== '') {
            let isError = false;

            if (valueLunedi.is_open == 1) {
                if (valueLunedi.is_second_hour == true) {
                    if (valueLunedi.open_hour == "" || valueLunedi.closed_hour == "" || valueLunedi.second_open_hour == "" || valueLunedi.second_closed_hour == "") {
                        isError = true;
                    }
                } else {
                    if (valueLunedi.open_hour == "" || valueLunedi.closed_hour == "") {
                        isError = true;
                    }
                }
            }

            if (valueMartedi.is_open == 1) {
                if (valueMartedi.is_second_hour == true) {
                    if (valueMartedi.open_hour == "" || valueMartedi.closed_hour == "" || valueMartedi.second_open_hour == "" || valueMartedi.second_closed_hour == "") {
                        isError = true;
                    }
                } else {
                    if (valueMartedi.open_hour == "" || valueMartedi.closed_hour == "") {
                        isError = true;
                    }
                }
            }

            if (valueMercoledi.is_open == 1) {
                if (valueMercoledi.is_second_hour == true) {
                    if (valueMercoledi.open_hour == "" || valueMercoledi.closed_hour == "" || valueMercoledi.second_open_hour == "" || valueMercoledi.second_closed_hour == "") {
                        isError = true;
                    }
                } else {
                    if (valueMercoledi.open_hour == "" || valueMercoledi.closed_hour == "") {
                        isError = true;
                    }
                }
            }

            if (valueGiovedi.is_open == 1) {
                if (valueGiovedi.is_second_hour == true) {
                    if (valueGiovedi.open_hour == "" || valueGiovedi.closed_hour == "" || valueGiovedi.second_open_hour == "" || valueGiovedi.second_closed_hour == "") {
                        isError = true;
                    }
                } else {
                    if (valueGiovedi.open_hour == "" || valueGiovedi.closed_hour == "") {
                        isError = true;
                    }
                }
            }

            if (valueVenerdi.is_open == 1) {
                if (valueVenerdi.is_second_hour == true) {
                    if (valueVenerdi.open_hour == "" || valueVenerdi.closed_hour == "" || valueVenerdi.second_open_hour == "" || valueVenerdi.second_closed_hour == "") {
                        isError = true;
                    }
                } else {
                    if (valueVenerdi.open_hour == "" || valueVenerdi.closed_hour == "") {
                        isError = true;
                    }
                }
            }

            if (valueSabato.is_open == 1) {
                if (valueSabato.is_second_hour == true) {
                    if (valueSabato.open_hour == "" || valueSabato.closed_hour == "" || valueSabato.second_open_hour == "" || valueSabato.second_closed_hour == "") {
                        isError = true;
                    }
                } else {
                    if (valueSabato.open_hour == "" || valueSabato.closed_hour == "") {
                        isError = true;
                    }
                }
            }

            if (valueDomenica.is_open == 1) {
                if (valueDomenica.is_second_hour == true) {
                    if (valueDomenica.open_hour == "" || valueDomenica.closed_hour == "" || valueDomenica.second_open_hour == "" || valueDomenica.second_closed_hour == "") {
                        isError = true;
                    }
                } else {
                    if (valueDomenica.open_hour == "" || valueDomenica.closed_hour == "") {
                        isError = true;
                    }
                }
            }

            if (isError == true) {
                toast.error("Inserisci gli orari di lavoro per la visita", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                setLoading(true)
                let is_date = 0;
                let start_date = moment(new Date()).format('YYYY-MM-DD');
                let end_date = moment(new Date()).format('YYYY-MM-DD');
                if (visit.discount_price !== "" && visit.discount_price !== null && visit.discount_price !== "€") {
                    if (visit.start_date !== "" && visit.start_date !== null) {
                        start_date = moment(visit.start_date).format('YYYY-MM-DD');
                    }
                    if (visit.end_date !== "" && visit.end_date !== null) {
                        end_date = moment(visit.end_date).format('YYYY-MM-DD');
                    }
                    if (visit.start_date !== "" && visit.start_date !== null && visit.end_date !== "" && visit.end_date !== null) {
                        is_date = 1;
                    }
                }
                let days_work = [];
                days_work.push(valueLunedi);
                days_work.push(valueMartedi);
                days_work.push(valueMercoledi);
                days_work.push(valueGiovedi);
                days_work.push(valueVenerdi);
                days_work.push(valueSabato);
                days_work.push(valueDomenica);
                axios({
                    method: 'post',
                    url: `${config.WEBSITE_URL}/partners/edit-pharmacy-visit`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    auth: {
                        username: config.USERNAME_TOKEN,
                        password: config.PASSWORD_TOKEN
                    },
                    data: JSON.stringify({
                        token_access: config.WEB_TOKEN,
                        id_visit: id_visit,
                        name_visit: visit.name_visit,
                        price: visit.price,
                        duration: visit.duration,
                        description: visit.description,
                        discount_price: visit.discount_price,
                        start_date: start_date,
                        end_date: end_date,
                        is_date: is_date,
                        array_days_work: days_work
                    })
                })
                .then(function(response) {
                    if (response.data.code == true) {
                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setLoading(false)
                        setTimeout(() => {
                            navigate('/visite')
                        }, 1000);
                    } else {
                        toast.error(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setLoading(false)
                    }
                })
                .catch(function(error) {
                    console.error(error);
                    setLoading(false)
                });
            }
        } else {
            toast.error("Inserisci i campi obbligatori", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const getSlotsVisit = (dateVisit) => {
        setSlotSelect("")
        setLoadSlot(true)
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-visit-time-slot`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                day_name: capitalizeFirstLetter(moment(dateVisit).format('dddd')),
                id_visit: id_visit,
                duration: visit.duration,
                today_date: dateVisit
            })
        })
        .then(function(response) {
            if (response.data.length > 0) {
                setTimeSlot(response.data)
                setLoadSlot(false)
            } else {
                setTimeSlot([])
                setLoadSlot(false)
            }
        })
        .catch(function(error) {
            setTimeSlot([])
            setLoadSlot(false)
            console.error(error);
        });
    }

    const openBookModal = () => {
        setShowBook(true)
    }

    const handleCloseBook = () => {
        setShowBook(false)
    }

    const handleCloseEditTicket = () => {
        setShowEditModal(false)
    }

    const changeDateBook = (e) => {
        if (e !== null) {
            setDataBook(moment(e.$d).format('YYYY-MM-DD'))
            getSlotsVisit(moment(e.$d).format('YYYY-MM-DD'))
        } else {
            setDataBook(null)
        }
    }

    const getListDirectTicket = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-direct-ticket`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_visit: id_visit,
                id_pharmacy: pharmacyInfo.id_pharmacy
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                if (response.data.result == true) {
                    setListTicketDirect(response.data.tickets)
                }
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            toast.error("Non è stato possibile recuperare le prenotazioni dirette. Prova a ricaricare la pagina", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    const saveBookTikcet = () => {
        setLoadingBook(true)
        if (descriptionBook!== "" && descriptionBook !== null && dataBook !== null && dataBook !== "" && slotSelect !== null && slotSelect !== "") {
            const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
            axios({
                method: 'post',
                url: `${config.WEBSITE_URL}/partners/create-ticket-visit`,
                headers: {
                    'Content-Type': 'application/json'
                },
                auth: {
                    username: config.USERNAME_TOKEN,
                    password: config.PASSWORD_TOKEN
                },
                data: JSON.stringify({
                    token_access: config.WEB_TOKEN,
                    id_visit: id_visit,
                    id_pharmacy: pharmacyInfo.id_pharmacy,
                    date_slot: dataBook,
                    time_slot: slotSelect,
                    description: descriptionBook
                })
            })
            .then(function(response) {
                if (response.data.code == true) {
                    if (response.data.result == true) {
                        getListDirectTicket()
                        setLoadingBook(false)
                        setShowBook(false)
                        setDescriptionBook("")
                        setDataBook(null)
                        setSlotSelect("")
                        setTimeSlot([])
                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        setLoadingBook(false)
                        toast.error(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                } else {
                    setLoadingBook(false)
                    toast.error(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch(function(error) {
                setLoadingBook(false)
                toast.error("Non è statp possibile inserire il ticket. Riprova più tardi", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        } else {
            setLoadingBook(false)
            toast.error("Inserisci i campi obbligatori (*)", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const editVisitTikcet = () => {
        setLoadingEditTicket(true)
        if (editDescription !== "" && editDescription !== null && idTicket !== null) {
            const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
            axios({
                method: 'post',
                url: `${config.WEBSITE_URL}/partners/edit-ticket-visit`,
                headers: {
                    'Content-Type': 'application/json'
                },
                auth: {
                    username: config.USERNAME_TOKEN,
                    password: config.PASSWORD_TOKEN
                },
                data: JSON.stringify({
                    token_access: config.WEB_TOKEN,
                    id_visit: id_visit,
                    id_pharmacy: pharmacyInfo.id_pharmacy,
                    id_ticket: idTicket,
                    description: editDescription
                })
            })
            .then(function(response) {
                if (response.data.code == true) {
                    if (response.data.result == true) {
                        getListDirectTicket()
                        setLoadingEditTicket(false)
                        setShowEditModal(false)
                        setEditDescription("")
                        setIdTicket(null)
                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        setLoadingEditTicket(false)
                        toast.error(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                } else {
                    setLoadingEditTicket(false)
                    toast.error(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch(function(error) {
                setLoadingEditTicket(false)
                toast.error("Non è statp possibile modificare il ticket. Riprova più tardi", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        } else {
            setLoadingEditTicket(false)
            toast.error("Inserisci i campi obbligatori (*)", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const deleteTicketVisitRequest = (uid) => {
        confirm({
            title: 'ATTENZIONE',
            icon: <ExclamationCircleFilled />,
            content: 'Sei sicuro di voler eliminare la prenotazione?',
            onOk() {
                deleteTicketVisit(uid)
            },
            okButtonProps: { danger: true },
            okText: "Elimina",
            cancelText: "Annulla"
        });
    }

    const deleteTicketVisit = (uid) => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/delete-ticket-visit`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_visit: id_visit,
                id_pharmacy: pharmacyInfo.id_pharmacy,
                id_ticket: uid
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                if (response.data.result == true) {
                    getListDirectTicket()
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.error(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            toast.error("Non è statp possibile modificare il ticket. Riprova più tardi", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const copyAllHours = (open, second_open, close, second_close, is_second_hour) => {
        setValueLunedi({ ...valueLunedi, open_hour: open, second_open_hour: second_open, closed_hour: close, second_closed_hour: second_close, is_second_hour: is_second_hour })
        setValueMartedi({ ...valueMartedi, open_hour: open, second_open_hour: second_open, closed_hour: close, second_closed_hour: second_close, is_second_hour: is_second_hour })
        setValueMercoledi({ ...valueMercoledi, open_hour: open, second_open_hour: second_open, closed_hour: close, second_closed_hour: second_close, is_second_hour: is_second_hour })
        setValueGiovedi({ ...valueGiovedi, open_hour: open, second_open_hour: second_open, closed_hour: close, second_closed_hour: second_close, is_second_hour: is_second_hour })
        setValueVenerdi({ ...valueVenerdi, open_hour: open, second_open_hour: second_open, closed_hour: close, second_closed_hour: second_close, is_second_hour: is_second_hour })
        setValueSabato({ ...valueSabato, open_hour: open, second_open_hour: second_open, closed_hour: close, second_closed_hour: second_close, is_second_hour: is_second_hour })
        setValueDomenica({ ...valueDomenica, open_hour: open, second_open_hour: second_open, closed_hour: close, second_closed_hour: second_close, is_second_hour: is_second_hour })
    }

    const GestioneOrari = () => {
        return (
            <div className="row d-flex justify-content-center">
                <div className="col-lg-10 col-md-10 col-12 px-2 py-1" style={{ backgroundColor: '#f7f7f7', borderRadius: 20 }}>
                    <label style={{ color: '#2b335e' }}>Orari di lavoro visita</label>
                    <div className="row mb-2">
                        <div className="col-xl-4 col-md-12 col-12 mb-lg-0 mb-md-1 mb-1">
                            <h4 style={{ fontWeight: '500' }}>Lunedì</h4>
                            <Switch defaultChecked={(valueLunedi.is_open == 1) ? true : false} unCheckedChildren="Chiuso" checkedChildren="Aperto" onChange={() => setValueLunedi((valueLunedi.is_open == 0) ? { ...valueLunedi, is_open: 1 } : { ...valueLunedi, is_open: 0 })} />
                        </div>
                        {(valueLunedi.is_open == 1)
                            &&
                            <div className="col-xl-8 col-md-12 col-12">
                                <div className="d-flex align-items-center">
                                    <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueLunedi.open_hour} onChange={(value) => setValueLunedi({ ...valueLunedi, open_hour: value.target.value })} />
                                    <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueLunedi.closed_hour} onChange={(value) => setValueLunedi({ ...valueLunedi, closed_hour: value.target.value })} />
                                    <div className='ml-2'>
                                        {(!valueLunedi.is_second_hour)
                                            ?
                                            <ButtonAntd className='d-flex align-items-center justify-content-center' type='primary' icon={<PlusOutlined />} shape='circle' size='large' onClick={handleSecondOrarioLunedi} />
                                            :
                                            <ButtonAntd className='d-flex align-items-center justify-content-center' danger type='primary' icon={<CloseOutlined />} shape='circle' size='large' onClick={handleNotSecondOrarioLunedi} />
                                        }
                                        <Tooltip title="Clicca sul pulsante per copiare gli orari su tutti i giorni della settimana" placement='right'>
                                            <ButtonAntd style={{ backgroundColor: 'green' }} className='d-flex align-items-center justify-content-center mt-1' type='primary' icon={<CopyOutlined />} shape='circle' size='large' onClick={() => copyAllHours(valueLunedi.open_hour, valueLunedi.second_open_hour, valueLunedi.closed_hour, valueLunedi.second_closed_hour, valueLunedi.is_second_hour)} />
                                        </Tooltip>
                                    </div>
                                </div>
                                {(valueLunedi.is_second_hour)
                                    &&
                                    <div className="d-flex align-items-center mt-1">
                                        <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueLunedi.second_open_hour} onChange={(value) => setValueLunedi({ ...valueLunedi, second_open_hour: value.target.value })} />
                                        <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueLunedi.second_closed_hour} onChange={(value) => setValueLunedi({ ...valueLunedi, second_closed_hour: value.target.value })} />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <hr />
                    <div className="row my-2">
                        <div className="col-xl-4 col-md-12 col-12 mb-lg-0 mb-md-1 mb-1">
                            <h4 style={{ fontWeight: '500' }}>Martedì</h4>
                            <Switch defaultChecked={(valueMartedi.is_open == 1) ? true : false } unCheckedChildren="Chiuso" checkedChildren="Aperto" onChange={() => setValueMartedi((valueMartedi.is_open == 0) ? { ...valueMartedi, is_open: 1 } : { ...valueMartedi, is_open: 0 })} />
                        </div>
                        {(valueMartedi.is_open == 1)
                            &&
                            <div className="col-xl-8 col-md-12 col-12">
                                <div className="d-flex align-items-center">
                                    <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueMartedi.open_hour} onChange={(value) => setValueMartedi({ ...valueMartedi, open_hour: value.target.value })} />
                                    <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueMartedi.closed_hour} onChange={(value) => setValueMartedi({ ...valueMartedi, closed_hour: value.target.value })} />
                                    <div className='ml-2'>
                                        {(!valueMartedi.is_second_hour)
                                            ?
                                            <ButtonAntd className='d-flex align-items-center justify-content-center' type='primary' icon={<PlusOutlined />} shape='circle' size='large' onClick={handleSecondOrarioMartedi} />
                                            :
                                            <ButtonAntd className='d-flex align-items-center justify-content-center' danger type='primary' icon={<CloseOutlined />} shape='circle' size='large' onClick={handleNotSecondOrarioMartedi} />
                                        }
                                        <Tooltip title="Clicca sul pulsante per copiare gli orari su tutti i giorni della settimana" placement='right'>
                                            <ButtonAntd style={{ backgroundColor: 'green' }} className='d-flex align-items-center justify-content-center mt-1' type='primary' icon={<CopyOutlined />} shape='circle' size='large' onClick={() => copyAllHours(valueMartedi.open_hour, valueMartedi.second_open_hour, valueMartedi.closed_hour, valueMartedi.second_closed_hour, valueMartedi.is_second_hour)} />
                                        </Tooltip>
                                    </div>
                                </div>
                                {(valueMartedi.is_second_hour)
                                    &&
                                    <div className="d-flex align-items-center mt-1">
                                        <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueMartedi.second_open_hour} onChange={(value) => setValueMartedi({ ...valueMartedi, second_open_hour: value.target.value })} />
                                        <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueMartedi.second_closed_hour} onChange={(value) => setValueMartedi({ ...valueMartedi, second_closed_hour: value.target.value })} />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <hr />
                    <div className="row my-2">
                        <div className="col-xl-4 col-md-12 col-12 mb-lg-0 mb-md-1 mb-1">
                            <h4 style={{ fontWeight: '500' }}>Mercoledì</h4>
                            <Switch defaultChecked={(valueMercoledi.is_open == 1) ? true : false } unCheckedChildren="Chiuso" checkedChildren="Aperto" onChange={() => setValueMercoledi((valueMercoledi.is_open == 0) ? { ...valueMercoledi, is_open: 1 } : { ...valueMercoledi, is_open: 0 })} />
                        </div>
                        {(valueMercoledi.is_open == 1)
                            &&
                            <div className="col-xl-8 col-md-12 col-12">
                                <div className="d-flex align-items-center">
                                    <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueMercoledi.open_hour} onChange={(value) => setValueMercoledi({ ...valueMercoledi, open_hour: value.target.value })} />
                                    <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueMercoledi.closed_hour} onChange={(value) => setValueMercoledi({ ...valueMercoledi, closed_hour: value.target.value })} />
                                    <div className='ml-2'>
                                        {(!valueMercoledi.is_second_hour)
                                            ?
                                            <ButtonAntd className='d-flex align-items-center justify-content-center' type='primary' icon={<PlusOutlined />} shape='circle' size='large' onClick={handleSecondOrarioMercoledi} />
                                            :
                                            <ButtonAntd className='d-flex align-items-center justify-content-center' danger type='primary' icon={<CloseOutlined />} shape='circle' size='large' onClick={handleNotSecondOrarioMercoledi} />
                                        }
                                        <Tooltip title="Clicca sul pulsante per copiare gli orari su tutti i giorni della settimana" placement='right'>
                                            <ButtonAntd style={{ backgroundColor: 'green' }} className='d-flex align-items-center justify-content-center mt-1' type='primary' icon={<CopyOutlined />} shape='circle' size='large' onClick={() => copyAllHours(valueMercoledi.open_hour, valueMercoledi.second_open_hour, valueMercoledi.closed_hour, valueMercoledi.second_closed_hour, valueMercoledi.is_second_hour)} />
                                        </Tooltip>
                                    </div>
                                </div>
                                {(valueMercoledi.is_second_hour)
                                    &&
                                    <div className="d-flex align-items-center mt-1">
                                        <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueMercoledi.second_open_hour} onChange={(value) => setValueMercoledi({ ...valueMercoledi, second_open_hour: value.target.value })} />
                                        <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueMercoledi.second_closed_hour} onChange={(value) => setValueMercoledi({ ...valueMercoledi, second_closed_hour: value.target.value })} />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <hr />
                    <div className="row my-2">
                        <div className="col-xl-4 col-md-12 col-12 mb-lg-0 mb-md-1 mb-1">
                            <h4 style={{ fontWeight: '500' }}>Giovedì</h4>
                            <Switch defaultChecked={(valueGiovedi.is_open == 1) ? true : false } unCheckedChildren="Chiuso" checkedChildren="Aperto" onChange={() => setValueGiovedi((valueGiovedi.is_open == 0) ? { ...valueGiovedi, is_open: 1 } : { ...valueGiovedi, is_open: 0 })} />
                        </div>
                        {(valueGiovedi.is_open == 1)
                            &&
                            <div className="col-xl-8 col-md-12 col-12">
                                <div className="d-flex align-items-center">
                                    <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueGiovedi.open_hour} onChange={(value) => setValueGiovedi({ ...valueGiovedi, open_hour: value.target.value })} />
                                    <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueGiovedi.closed_hour} onChange={(value) => setValueGiovedi({ ...valueGiovedi, closed_hour: value.target.value })} />
                                    <div className='ml-2'>
                                        {(!valueGiovedi.is_second_hour)
                                            ?
                                            <ButtonAntd className='d-flex align-items-center justify-content-center' type='primary' icon={<PlusOutlined />} shape='circle' size='large' onClick={handleSecondOrarioGiovedi} />
                                            :
                                            <ButtonAntd className='d-flex align-items-center justify-content-center' danger type='primary' icon={<CloseOutlined />} shape='circle' size='large' onClick={handleNotSecondOrarioGiovedi} />
                                        }
                                        <Tooltip title="Clicca sul pulsante per copiare gli orari su tutti i giorni della settimana" placement='right'>
                                            <ButtonAntd style={{ backgroundColor: 'green' }} className='d-flex align-items-center justify-content-center mt-1' type='primary' icon={<CopyOutlined />} shape='circle' size='large' onClick={() => copyAllHours(valueGiovedi.open_hour, valueGiovedi.second_open_hour, valueGiovedi.closed_hour, valueGiovedi.second_closed_hour, valueGiovedi.is_second_hour)} />
                                        </Tooltip>
                                    </div>
                                </div>
                                {(valueGiovedi.is_second_hour)
                                    &&
                                    <div className="d-flex align-items-center mt-1">
                                        <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueGiovedi.second_open_hour} onChange={(value) => setValueGiovedi({ ...valueGiovedi, second_open_hour: value.target.value })} />
                                        <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueGiovedi.second_closed_hour} onChange={(value) => setValueGiovedi({ ...valueGiovedi, second_closed_hour: value.target.value })} />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <hr />
                    <div className="row my-2">
                        <div className="col-xl-4 col-md-12 col-12 mb-lg-0 mb-md-1 mb-1">
                            <h4 style={{ fontWeight: '500' }}>Venerdì</h4>
                            <Switch defaultChecked={(valueVenerdi.is_open == 1) ? true : false } unCheckedChildren="Chiuso" checkedChildren="Aperto" onChange={() => setValueVenerdi((valueVenerdi.is_open == 0) ? { ...valueVenerdi, is_open: 1 } : { ...valueVenerdi, is_open: 0 })} />
                        </div>
                        {(valueVenerdi.is_open == 1)
                            &&
                            <div className="col-xl-8 col-md-12 col-12">
                                <div className="d-flex align-items-center">
                                    <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueVenerdi.open_hour} onChange={(value) => setValueVenerdi({ ...valueVenerdi, open_hour: value.target.value })} />
                                    <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueVenerdi.closed_hour} onChange={(value) => setValueVenerdi({ ...valueVenerdi, closed_hour: value.target.value })} />
                                    <div className='ml-2'>
                                        {(!valueVenerdi.is_second_hour)
                                            ?
                                            <ButtonAntd className='d-flex align-items-center justify-content-center' type='primary' icon={<PlusOutlined />} shape='circle' size='large' onClick={handleSecondOrarioVenerdi} />
                                            :
                                            <ButtonAntd className='d-flex align-items-center justify-content-center' danger type='primary' icon={<CloseOutlined />} shape='circle' size='large' onClick={handleNotSecondOrarioVenerdi} />
                                        }
                                        <Tooltip title="Clicca sul pulsante per copiare gli orari su tutti i giorni della settimana" placement='right'>
                                            <ButtonAntd style={{ backgroundColor: 'green' }} className='d-flex align-items-center justify-content-center mt-1' type='primary' icon={<CopyOutlined />} shape='circle' size='large' onClick={() => copyAllHours(valueVenerdi.open_hour, valueVenerdi.second_open_hour, valueVenerdi.closed_hour, valueVenerdi.second_closed_hour, valueVenerdi.is_second_hour)} />
                                        </Tooltip>
                                    </div>
                                </div>
                                {(valueVenerdi.is_second_hour)
                                    &&
                                    <div className="d-flex align-items-center mt-1">
                                        <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueVenerdi.second_open_hour} onChange={(value) => setValueVenerdi({ ...valueVenerdi, second_open_hour: value.target.value })} />
                                        <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueVenerdi.second_closed_hour} onChange={(value) => setValueVenerdi({ ...valueVenerdi, second_closed_hour: value.target.value })} />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <hr />
                    <div className="row my-2">
                        <div className="col-xl-4 col-md-12 col-12 mb-lg-0 mb-md-1 mb-1">
                            <h4 style={{ fontWeight: '500' }}>Sabato</h4>
                            <Switch defaultChecked={(valueSabato.is_open == 1) ? true : false } unCheckedChildren="Chiuso" checkedChildren="Aperto" onChange={() => setValueSabato((valueSabato.is_open == 0) ? { ...valueSabato, is_open: 1 } : { ...valueSabato, is_open: 0 })} />
                        </div>
                        {(valueSabato.is_open == 1)
                            &&
                            <div className="col-xl-8 col-md-12 col-12">
                                <div className="d-flex align-items-center">
                                    <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueSabato.open_hour} onChange={(value) => setValueSabato({ ...valueSabato, open_hour: value.target.value })} />
                                    <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueSabato.closed_hour} onChange={(value) => setValueSabato({ ...valueSabato, closed_hour: value.target.value })} />
                                    <div className='ml-2'>
                                        {(!valueSabato.is_second_hour)
                                            ?
                                            <ButtonAntd className='d-flex align-items-center justify-content-center' type='primary' icon={<PlusOutlined />} shape='circle' size='large' onClick={handleSecondOrarioSabato} />
                                            :
                                            <ButtonAntd className='d-flex align-items-center justify-content-center' danger type='primary' icon={<CloseOutlined />} shape='circle' size='large' onClick={handleNotSecondOrarioSabato} />
                                        }
                                        <Tooltip title="Clicca sul pulsante per copiare gli orari su tutti i giorni della settimana" placement='right'>
                                            <ButtonAntd style={{ backgroundColor: 'green' }} className='d-flex align-items-center justify-content-center mt-1' type='primary' icon={<CopyOutlined />} shape='circle' size='large' onClick={() => copyAllHours(valueSabato.open_hour, valueSabato.second_open_hour, valueSabato.closed_hour, valueSabato.second_closed_hour, valueSabato.is_second_hour)} />
                                        </Tooltip>
                                    </div>
                                </div>
                                {(valueSabato.is_second_hour)
                                    &&
                                    <div className="d-flex align-items-center mt-1">
                                        <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueSabato.second_open_hour} onChange={(value) => setValueSabato({ ...valueSabato, second_open_hour: value.target.value })} />
                                        <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueSabato.second_closed_hour} onChange={(value) => setValueSabato({ ...valueSabato, second_closed_hour: value.target.value })} />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <hr />
                    <div className="row mt-2">
                        <div className="col-xl-4 col-md-12 col-12 mb-lg-0 mb-md-1 mb-1">
                            <h4 style={{ fontWeight: '500' }}>Domenica</h4>
                            <Switch defaultChecked={(valueDomenica.is_open == 1) ? true : false } unCheckedChildren="Chiuso" checkedChildren="Aperto" onChange={() => setValueDomenica((valueDomenica.is_open == 0) ? { ...valueDomenica, is_open: 1 } : { ...valueDomenica, is_open: 0 })} />
                        </div>
                        {(valueDomenica.is_open == 1)
                            &&
                            <div className="col-xl-8 col-md-12 col-12">
                                <div className="d-flex align-items-center">
                                    <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueDomenica.open_hour} onChange={(value) => setValueDomenica({ ...valueDomenica, open_hour: value.target.value })} />
                                    <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueDomenica.closed_hour} onChange={(value) => setValueDomenica({ ...valueDomenica, closed_hour: value.target.value })} />
                                    <div className='ml-2'>
                                        {(!valueDomenica.is_second_hour)
                                            ?
                                            <ButtonAntd className='d-flex align-items-center justify-content-center' type='primary' icon={<PlusOutlined />} shape='circle' size='large' onClick={handleSecondOrarioDomenica} />
                                            :
                                            <ButtonAntd className='d-flex align-items-center justify-content-center' danger type='primary' icon={<CloseOutlined />} shape='circle' size='large' onClick={handleNotSecondOrarioDomenica} />
                                        }
                                        <Tooltip title="Clicca sul pulsante per copiare gli orari su tutti i giorni della settimana" placement='right'>
                                            <ButtonAntd style={{ backgroundColor: 'green' }} className='d-flex align-items-center justify-content-center mt-1' type='primary' icon={<CopyOutlined />} shape='circle' size='large' onClick={() => copyAllHours(valueDomenica.open_hour, valueDomenica.second_open_hour, valueDomenica.closed_hour, valueDomenica.second_closed_hour, valueDomenica.is_second_hour)} />
                                        </Tooltip>
                                    </div>
                                </div>
                                {(valueDomenica.is_second_hour)
                                    &&
                                    <div className="d-flex align-items-center mt-1">
                                        <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueDomenica.second_open_hour} onChange={(value) => setValueDomenica({ ...valueDomenica, second_open_hour: value.target.value })} />
                                        <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueDomenica.second_closed_hour} onChange={(value) => setValueDomenica({ ...valueDomenica, second_closed_hour: value.target.value })} />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

    return(
        <>
        <ToastContainer />
        <Helmet>
            <title>Modifica Visita | Takeiteasy Partners</title>
        </Helmet>
        <Navbar />
        <div className="app-content content">
            <div className="content-overlay"></div>
            <div className="content-wrapper">
                <Errors />
                <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2">
                        <h3 className="content-header-title">Modifica visita</h3>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/visite">Visite</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Modifica visita</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                <div className="content-body">
                    <section className="row">
                        <div className="col-12">
                            <div className="card">
                                {(haveError)
                                    ?
                                    <Empty
                                        description={
                                            <span>
                                                Risolvi gli errori per poter continuare.
                                            </span>
                                        }
                                    />
                                    :
                                    <>
                                    <div className="card-content">
                                        <div className="card-body">
                                            <div className='row mb-1'>
                                                <div className='col-12 d-flex align-items-center justify-content-end'>
                                                    <ButtonAntd className='d-flex align-items-center' size='large' type='primary' icon={<CalendarFilled />} onClick={openBookModal}>
                                                        Prenota ticket
                                                    </ButtonAntd>
                                                </div>
                                            </div>
                                            <label style={{ color: '#2b335e' }}>Seleziona una visita tra quelle deisponibili</label>
                                            {(loadVisits)
                                                ?
                                                <div className='px-2 py-1'>
                                                    <Skeleton active={true} />
                                                </div>
                                                :
                                                <div className='pb-2'>
                                                    {allVisits.map((item, index) => {
                                                        return(
                                                            <Button type={(item.id == selectedVisit) ? 'primary' : 'dashed'} onClick={() => selectVisit(item.id, item.name_visit)} style={{ marginRight: 10, marginBottom: 10 }}>{item.name_visit}</Button>
                                                        )
                                                    })}
                                                </div>
                                            }
                                            {(loadPage)
                                                ?
                                                <div className='py-1'>
                                                    <Skeleton active={true} />
                                                </div>
                                                :
                                                <>
                                                <form>
                                                    <fieldset>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label htmlFor="name_visit">Nome visita*</label>
                                                                    <input type="text" class="form-control" maxLength={300} disabled={(selectedVisit !== null) ? true : false} value={visit.name_visit} onChange={handleChange} name="name_visit" id="name_visit" placeholder='Inserisci il Nome del servizio offerto se non presente nella lista' required />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label htmlFor="price">Prezzo*</label>
                                                                    <input type="text" class="form-control" maxLength={50} value={visit.price} onChange={handleChange} name="price" id="price" placeholder='Inserisci il Prezzo della visita' required />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label htmlFor="discount_price">Prezzo scontato (Se presente)</label>
                                                                    <input type="text" class="form-control" maxLength={50} value={visit.discount_price} onChange={handleChange} name="discount_price" id="discount_price" placeholder='Inserisci il Prezzo scontato della visita' />
                                                                </div>
                                                            </div>
                                                            {(visit.discount_price !== "" && visit.discount_price !== null)
                                                                &&
                                                                <>
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <label htmlFor="start_date">Data inizio promozione (Se presente)</label>
                                                                        <input type="date" class="form-control" value={visit.start_date} onChange={handleChange} name="start_date" id="start_date" placeholder='Inserisci la Data di inizio della promozione' />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <label htmlFor="end_date">Data fine promozione (Se presente)</label>
                                                                        <input type="date" class="form-control" value={visit.end_date} onChange={handleChange} name="end_date" id="end_date" placeholder='Inserisci la Data di fine della promozione' />
                                                                    </div>
                                                                </div>
                                                                </>
                                                            }
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label htmlFor="duration">Durata* (in minuti)</label>
                                                                    <input type="number" class="form-control" maxLength={50} value={visit.duration} onChange={handleChange} name="duration" id="duration" placeholder='Inserisci la Durata della visita' required />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label htmlFor="description">Descrizione*</label>
                                                                    <textarea class="form-control" maxLength={350} value={visit.description} onChange={handleChange} name="description" id="description" placeholder='Inserisci la Descirzione per la visita' required></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </form>
                                                <Divider orientation="left">Prenotazioni dirette</Divider>
                                                <Table columns={columns} dataSource={listTicketDirect} />
                                                <Divider orientation="left">Orari di lavoro</Divider>
                                                <GestioneOrari />
                                                </>
                                            }
                                            <fieldset>
                                                <div style={{ marginTop: 24 }} className="float-right">
                                                    <Button type="primary" onClick={(loading) ? null : updatePharmacyVisit}>
                                                        {(loading)
                                                            ?
                                                            <ThreeDots 
                                                                height="11" 
                                                                width="23" 
                                                                radius="4.5"
                                                                color="#fff" 
                                                                ariaLabel="three-dots-loading"
                                                                wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
                                                                visible={true}
                                                            />
                                                            :
                                                            'Salva'
                                                        }
                                                    </Button>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                    </>
                                }
                            </div>
                        </div>
                        <Modal title="Prenota un ticket per la visita" open={showBook} okText={(loadingBook) ? <ThreeDots height="11" width="23" radius="4.5" color="#fff" ariaLabel="three-dots-loading" wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }} visible={true} /> : "Salva"} cancelText="Chiudi" onOk={(loadingBook) ? null : saveBookTikcet} onCancel={handleCloseBook}>
                            <div className='row mt-1'>
                                <div className='col-12'>
                                    <label htmlFor='description'>Nome e cognome per la prenotazione*</label>
                                    <TextArea showCount maxLength={100} style={{ height: 50 }} name="description" placeholder="Inserisci il nome e cognome per la prenotazione" value={descriptionBook} onChange={(e) => setDescriptionBook(e.target.value)} />
                                </div>
                                <div className='col-12 mt-2'>
                                    <label htmlFor='data'>Data prenotazione*</label>
                                    <DatePicker style={{ width: '100%' }} allowClear={true} defaultValue={(dataBook !== null) ? dayjs(moment(dataBook).format('DD-MM-YYYY'), 'DD-MM-YYYY') : null} size='large' locale={locale} format='DD-MM-YYYY' placeholder='Seleziona la data' onChange={(e) => changeDateBook(e)} name='data_book' />
                                </div>
                                <div className='col-12 mt-2'>
                                    <label htmlFor='tickets' style={{ width: '100%' }}>Slot orari*</label>
                                    {(loadSlot)
                                        ?
                                        <Skeleton active={true} />
                                        :
                                        (timeSlot.length > 0)
                                            ?
                                            timeSlot.map((item, index) => {
                                                if (item.enabled == true) {
                                                    return(
                                                        <Button onClick={() => setSlotSelect(item.slot_start_time)} type={(slotSelect == item.slot_start_time) ? 'primary' : 'default'} size='large' className='mr-1 mb-1'>{item.slot_start_time}</Button>
                                                    )
                                                } else {
                                                    return(
                                                        <Button size='large' disabled={true} className='mr-1 mb-1' type="dashed">{item.slot_start_time}</Button>
                                                    )
                                                }
                                            })
                                            :
                                            <h5 className='text-danger'>Slot non disponobili</h5>
                                    }
                                </div>
                            </div>
                        </Modal>
                        <Modal title="Modifica il ticket della visita" open={showEditModal} okText={(loadingEditTicket) ? <ThreeDots height="11" width="23" radius="4.5" color="#fff" ariaLabel="three-dots-loading" wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }} visible={true} /> : "Salva"} cancelText="Chiudi" onOk={(loadingEditTicket) ? null : editVisitTikcet} onCancel={handleCloseEditTicket}>
                            <div className='row mt-1'>
                                <div className='col-12 mb-2'>
                                    <label htmlFor='description'>Nome e cognome per la prenotazione*</label>
                                    <TextArea showCount maxLength={100} style={{ height: 50 }} name="description" placeholder="Inserisci il nome e cognome per la prenotazione" value={editDescription} onChange={(e) => setEditDescription(e.target.value)} />
                                </div>
                            </div>
                        </Modal>
                    </section>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default EditVisit;