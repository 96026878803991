import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet";
import * as config from '../config';
import axios from 'axios';
import { Skeleton, Empty, Avatar, Card, Button, Breadcrumb, Button as ButtonAntd, Switch, Tooltip } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Errors from '../components/Errors';
import { ThreeDots } from 'react-loader-spinner';
import { PlusOutlined, CloseOutlined, CopyOutlined } from '@ant-design/icons';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/it';

function AddVisit() {
    const navigate = useNavigate()
    const [allVisits, setAllVisits] = useState([])
    const [loadVisits, setLoadVisits] = useState(true)
    const [haveError, setHaveError] = useState(false)
    const [visit, setVisit] = useState({
        name_visit: "",
        description: "",
        price: "",
        discount_price: "",
        start_date: "",
        end_date: "",
        duration: ""
    })
    const [valueLunedi, setValueLunedi] = useState({
        id_day: null,
        day_name: 'Lunedì',
        open_hour: "",
        closed_hour: "",
        second_open_hour: "",
        second_closed_hour: "",
        is_open: 1,
        is_second_hour: false
    });
    const [valueMartedi, setValueMartedi] = useState({
        id_day: null,
        day_name: 'Martedì',
        open_hour: "",
        closed_hour: "",
        second_open_hour: "",
        second_closed_hour: "",
        is_open: 1,
        is_second_hour: false
    });
    const [valueMercoledi, setValueMercoledi] = useState({
        id_day: null,
        day_name: 'Mercoledì',
        open_hour: "",
        closed_hour: "",
        second_open_hour: "",
        second_closed_hour: "",
        is_open: 1,
        is_second_hour: false
    });
    const [valueGiovedi, setValueGiovedi] = useState({
        id_day: null,
        day_name: 'Giovedì',
        open_hour: "",
        closed_hour: "",
        second_open_hour: "",
        second_closed_hour: "",
        is_open: 1,
        is_second_hour: false
    });
    const [valueVenerdi, setValueVenerdi] = useState({
        id_day: null,
        day_name: 'Venerdì',
        open_hour: "",
        closed_hour: "",
        second_open_hour: "",
        second_closed_hour: "",
        is_open: 1,
        is_second_hour: false
    });
    const [valueSabato, setValueSabato] = useState({
        id_day: null,
        day_name: 'Sabato',
        open_hour: "",
        closed_hour: "",
        second_open_hour: "",
        second_closed_hour: "",
        is_open: 1,
        is_second_hour: false
    });
    const [valueDomenica, setValueDomenica] = useState({
        id_day: null,
        day_name: 'Domenica',
        open_hour: "",
        closed_hour: "",
        second_open_hour: "",
        second_closed_hour: "",
        is_open: 1,
        is_second_hour: false
    });
    const [loading, setLoading] = useState(false)
    const [selectedVisit, setSelectedVisit] = useState(null)

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        checkErrors(userInfo)
        checkPharmacyBilling()
        getAllVisits()
    }, [])

    const checkErrors = (userInfo) => {
        if (userInfo !== null) {
            if (userInfo.is_code_verify == "0" || userInfo.is_code_verify == 0 || userInfo.is_completed == "0" || userInfo.is_completed == 0) {
                setHaveError(true)
            } else {
                setHaveError(false)
            }
        } else {
            setHaveError(false)
        }
    }

    const checkPharmacyBilling = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/check-pharmacy-has-visits`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                if (response.data.has_visits !== true) {
                    navigate('/')
                }
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const handleSecondOrarioLunedi = () => setValueLunedi({ ...valueLunedi, is_second_hour: true });
    const handleNotSecondOrarioLunedi = () => setValueLunedi({ ...valueLunedi, is_second_hour: false });

    const handleSecondOrarioMartedi = () => setValueMartedi({ ...valueMartedi, is_second_hour: true });
    const handleNotSecondOrarioMartedi = () => setValueMartedi({ ...valueMartedi, is_second_hour: false });

    const handleSecondOrarioMercoledi = () => setValueMercoledi({ ...valueMercoledi, is_second_hour: true });
    const handleNotSecondOrarioMercoledi = () => setValueMercoledi({ ...valueMercoledi, is_second_hour: false });

    const handleSecondOrarioGiovedi = () => setValueGiovedi({ ...valueGiovedi, is_second_hour: true });
    const handleNotSecondOrarioGiovedi = () => setValueGiovedi({ ...valueGiovedi, is_second_hour: false });

    const handleSecondOrarioVenerdi = () => setValueVenerdi({ ...valueVenerdi, is_second_hour: true });
    const handleNotSecondOrarioVenerdi = () => setValueVenerdi({ ...valueVenerdi, is_second_hour: false });
    
    const handleSecondOrarioSabato = () => setValueSabato({ ...valueSabato, is_second_hour: true });
    const handleNotSecondOrarioSabato = () => setValueSabato({ ...valueSabato, is_second_hour: false });
    
    const handleSecondOrarioDomenica = () => setValueDomenica({ ...valueDomenica, is_second_hour: true });
    const handleNotSecondOrarioDomenica = () => setValueDomenica({ ...valueDomenica, is_second_hour: false });

    const handleChange = (e) => {
        if (e.target.name == "name_visit") {
            setVisit({ ...visit, [e.target.name]: capitalizeFirstLowercaseRest(e.target.value) })
        } else if (e.target.name == "price" || e.target.name == "discount_price") {
            setVisit({ ...visit, [e.target.name]: setFirstLetterCurrency(e.target.value.replace(/[^0-9€.,\b]+/ig, "")) })
        } else if (e.target.name == "duration") {
            setVisit({ ...visit, [e.target.name]: e.target.value.replace(/[^0-9\b]+/ig, "") })
        } else {
            setVisit({ ...visit, [e.target.name]: e.target.value })
        }
    }

    const capitalizeFirstLowercaseRest = str => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const setFirstLetterCurrency = str => {
        if (str.length == 1 && str !== '€') {
            return `€${str}`;
        } else {
            return str;
        }
    }

    const getAllVisits = () => {
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-all-visits`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN
            })
        })
        .then(function(response) {
            if (response.data.length > 0) {
                setAllVisits(response.data)
                setLoadVisits(false)
            } else {
                setLoadVisits(false)
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const selectVisit = (id, name) => {
        if (id == selectedVisit) {
            setSelectedVisit(null)
            setVisit({ ...visit, name_visit: '' })
        } else {
            setSelectedVisit(id)
            setVisit({ ...visit, name_visit: name })
        }
    }

    const insertPharmacyVisit = () => {
        if (visit.name_visit !== '' && visit.price !== '' && visit.description !== '' && visit.duration !== '') {
            let isError = false;

            if (valueLunedi.is_open == 1) {
                if (valueLunedi.is_second_hour == true) {
                    if (valueLunedi.open_hour == "" || valueLunedi.closed_hour == "" || valueLunedi.second_open_hour == "" || valueLunedi.second_closed_hour == "") {
                        isError = true;
                    }
                } else {
                    if (valueLunedi.open_hour == "" || valueLunedi.closed_hour == "") {
                        isError = true;
                    }
                }
            }

            if (valueMartedi.is_open == 1) {
                if (valueMartedi.is_second_hour == true) {
                    if (valueMartedi.open_hour == "" || valueMartedi.closed_hour == "" || valueMartedi.second_open_hour == "" || valueMartedi.second_closed_hour == "") {
                        isError = true;
                    }
                } else {
                    if (valueMartedi.open_hour == "" || valueMartedi.closed_hour == "") {
                        isError = true;
                    }
                }
            }

            if (valueMercoledi.is_open == 1) {
                if (valueMercoledi.is_second_hour == true) {
                    if (valueMercoledi.open_hour == "" || valueMercoledi.closed_hour == "" || valueMercoledi.second_open_hour == "" || valueMercoledi.second_closed_hour == "") {
                        isError = true;
                    }
                } else {
                    if (valueMercoledi.open_hour == "" || valueMercoledi.closed_hour == "") {
                        isError = true;
                    }
                }
            }

            if (valueGiovedi.is_open == 1) {
                if (valueGiovedi.is_second_hour == true) {
                    if (valueGiovedi.open_hour == "" || valueGiovedi.closed_hour == "" || valueGiovedi.second_open_hour == "" || valueGiovedi.second_closed_hour == "") {
                        isError = true;
                    }
                } else {
                    if (valueGiovedi.open_hour == "" || valueGiovedi.closed_hour == "") {
                        isError = true;
                    }
                }
            }

            if (valueVenerdi.is_open == 1) {
                if (valueVenerdi.is_second_hour == true) {
                    if (valueVenerdi.open_hour == "" || valueVenerdi.closed_hour == "" || valueVenerdi.second_open_hour == "" || valueVenerdi.second_closed_hour == "") {
                        isError = true;
                    }
                } else {
                    if (valueVenerdi.open_hour == "" || valueVenerdi.closed_hour == "") {
                        isError = true;
                    }
                }
            }

            if (valueSabato.is_open == 1) {
                if (valueSabato.is_second_hour == true) {
                    if (valueSabato.open_hour == "" || valueSabato.closed_hour == "" || valueSabato.second_open_hour == "" || valueSabato.second_closed_hour == "") {
                        isError = true;
                    }
                } else {
                    if (valueSabato.open_hour == "" || valueSabato.closed_hour == "") {
                        isError = true;
                    }
                }
            }

            if (valueDomenica.is_open == 1) {
                if (valueDomenica.is_second_hour == true) {
                    if (valueDomenica.open_hour == "" || valueDomenica.closed_hour == "" || valueDomenica.second_open_hour == "" || valueDomenica.second_closed_hour == "") {
                        isError = true;
                    }
                } else {
                    if (valueDomenica.open_hour == "" || valueDomenica.closed_hour == "") {
                        isError = true;
                    }
                }
            }

            if (isError == true) {
                toast.error("Inserisci gli orari di lavoro per la visita", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                setLoading(true)
                const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
                let is_date = 0;
                let start_date = moment(new Date()).format('YYYY-MM-DD');
                let end_date = moment(new Date()).format('YYYY-MM-DD');
                if (visit.discount_price !== "" && visit.discount_price !== null && visit.discount_price !== "€") {
                    if (visit.start_date !== "" && visit.start_date !== null) {
                        start_date = moment(visit.start_date).format('YYYY-MM-DD');
                    }
                    if (visit.end_date !== "" && visit.end_date !== null) {
                        end_date = moment(visit.end_date).format('YYYY-MM-DD');
                    }
                    if (visit.start_date !== "" && visit.start_date !== null && visit.end_date !== "" && visit.end_date !== null) {
                        is_date = 1;
                    }
                }
                let days_work = [];
                days_work.push(valueLunedi);
                days_work.push(valueMartedi);
                days_work.push(valueMercoledi);
                days_work.push(valueGiovedi);
                days_work.push(valueVenerdi);
                days_work.push(valueSabato);
                days_work.push(valueDomenica);
                axios({
                    method: 'post',
                    url: `${config.WEBSITE_URL}/partners/add-pharmacy-visit`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    auth: {
                        username: config.USERNAME_TOKEN,
                        password: config.PASSWORD_TOKEN
                    },
                    data: JSON.stringify({
                        token_access: config.WEB_TOKEN,
                        id_pharmacy: pharmacyInfo.id_pharmacy,
                        name_visit: visit.name_visit,
                        price: visit.price,
                        duration: visit.duration,
                        description: visit.description,
                        discount_price: visit.discount_price,
                        start_date: start_date,
                        end_date: end_date,
                        is_date: is_date,
                        array_days_work: days_work
                    })
                })
                .then(function(response) {
                    if (response.data.code == true) {
                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setLoading(false)
                        setTimeout(() => {
                            navigate('/visite')
                        }, 1000);
                    } else {
                        toast.error(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setLoading(false)
                    }
                })
                .catch(function(error) {
                    console.error(error);
                    setLoading(false)
                });
            }
        } else {
            toast.error("Inserisci i campi obbligatori", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const copyAllHours = (open, second_open, close, second_close, is_second_hour) => {
        setValueLunedi({ ...valueLunedi, open_hour: open, second_open_hour: second_open, closed_hour: close, second_closed_hour: second_close, is_second_hour: is_second_hour })
        setValueMartedi({ ...valueMartedi, open_hour: open, second_open_hour: second_open, closed_hour: close, second_closed_hour: second_close, is_second_hour: is_second_hour })
        setValueMercoledi({ ...valueMercoledi, open_hour: open, second_open_hour: second_open, closed_hour: close, second_closed_hour: second_close, is_second_hour: is_second_hour })
        setValueGiovedi({ ...valueGiovedi, open_hour: open, second_open_hour: second_open, closed_hour: close, second_closed_hour: second_close, is_second_hour: is_second_hour })
        setValueVenerdi({ ...valueVenerdi, open_hour: open, second_open_hour: second_open, closed_hour: close, second_closed_hour: second_close, is_second_hour: is_second_hour })
        setValueSabato({ ...valueSabato, open_hour: open, second_open_hour: second_open, closed_hour: close, second_closed_hour: second_close, is_second_hour: is_second_hour })
        setValueDomenica({ ...valueDomenica, open_hour: open, second_open_hour: second_open, closed_hour: close, second_closed_hour: second_close, is_second_hour: is_second_hour })
    }

    return(
        <>
        <ToastContainer />
        <Helmet>
            <title>Aggiungi Visita | Takeiteasy Partners</title>
        </Helmet>
        <Navbar />
        <div className="app-content content">
            <div className="content-overlay"></div>
            <div className="content-wrapper">
                <Errors />
                <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2">
                        <h3 className="content-header-title">Aggiungi visita</h3>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/visite">Visite</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Aggiungi visita</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                <div className="content-body">
                    <section className="row">
                        <div className="col-12">
                            <div className="card">
                                {(haveError)
                                    ?
                                    <Empty
                                        description={
                                            <span>
                                                Risolvi gli errori per poter continuare.
                                            </span>
                                        }
                                    />
                                    :
                                    <>
                                    <div className="card-content">
                                        <div className="card-body">
                                            <label style={{ color: '#2b335e' }}>Seleziona una visita tra quelle deisponibili</label>
                                            {(loadVisits)
                                                ?
                                                <div className='px-2 py-1'>
                                                    <Skeleton active={true} />
                                                </div>
                                                :
                                                <div className='pb-2'>
                                                    {allVisits.map((item, index) => {
                                                        return(
                                                            <Button key={index} type={(item.id == selectedVisit) ? 'primary' : 'dashed'} onClick={() => selectVisit(item.id, item.name_visit)} style={{ marginRight: 10, marginBottom: 10 }}>{item.name_visit}</Button>
                                                        )
                                                    })}
                                                </div>
                                            }
                                            <form>
                                                <fieldset>
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label htmlFor="name_visit">Nome visita*</label>
                                                                <input type="text" class="form-control" maxLength={300} disabled={(selectedVisit !== null) ? true : false} value={visit.name_visit} onChange={handleChange} name="name_visit" id="name_visit" placeholder='Inserisci il Nome del servizio offerto se non presente nella lista' required />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label htmlFor="price">Prezzo*</label>
                                                                <input type="text" class="form-control" maxLength={50} value={visit.price} onChange={handleChange} name="price" id="price" placeholder='Inserisci il Prezzo della visita' required />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label htmlFor="discount_price">Prezzo scontato (Se presente)</label>
                                                                <input type="text" class="form-control" maxLength={50} value={visit.discount_price} onChange={handleChange} name="discount_price" id="discount_price" placeholder='Inserisci il Prezzo scontato della visita' />
                                                            </div>
                                                        </div>
                                                        {(visit.discount_price !== "" && visit.discount_price !== null)
                                                            &&
                                                            <>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label htmlFor="start_date">Data inizio promozione (Se presente)</label>
                                                                    <input type="date" class="form-control" value={visit.start_date} onChange={handleChange} name="start_date" id="start_date" placeholder='Inserisci la Data di inizio della promozione' />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label htmlFor="end_date">Data fine promozione (Se presente)</label>
                                                                    <input type="date" class="form-control" value={visit.end_date} onChange={handleChange} name="end_date" id="end_date" placeholder='Inserisci la Data di fine della promozione' />
                                                                </div>
                                                            </div>
                                                            </>
                                                        }
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label htmlFor="duration">Durata* (in minuti)</label>
                                                                <input type="number" class="form-control" maxLength={50} value={visit.duration} onChange={handleChange} name="duration" id="duration" placeholder='Inserisci la Durata della visita' required />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label htmlFor="description">Descrizione*</label>
                                                                <textarea class="form-control" value={visit.description} maxLength={350} onChange={handleChange} name="description" id="description" placeholder='Inserisci la Descirzione per la visita' required></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </form>
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-lg-10 col-md-10 col-12 px-2 py-1" style={{ backgroundColor: '#f7f7f7', borderRadius: 20 }}>
                                                    <label style={{ color: '#2b335e' }}>Orari di lavoro visita</label>
                                                    <div className="row mb-2">
                                                        <div className="col-xl-4 col-md-12 col-12 mb-lg-0 mb-md-1 mb-1">
                                                            <h4 style={{ fontWeight: '500' }}>Lunedì</h4>
                                                            <Switch defaultChecked={(valueLunedi.is_open == 1) ? true : false } unCheckedChildren="Chiuso" checkedChildren="Aperto" onChange={() => setValueLunedi((valueLunedi.is_open == 0) ? { ...valueLunedi, is_open: 1 } : { ...valueLunedi, is_open: 0 })} />
                                                        </div>
                                                        {(valueLunedi.is_open == 1)
                                                            &&
                                                            <div className="col-xl-8 col-md-12 col-12">
                                                                <div className="d-flex align-items-center">
                                                                    <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueLunedi.open_hour} onChange={(value) => setValueLunedi({ ...valueLunedi, open_hour: value.target.value })} />
                                                                    <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueLunedi.closed_hour} onChange={(value) => setValueLunedi({ ...valueLunedi, closed_hour: value.target.value })} />
                                                                    <div className='ml-2'>
                                                                        {(!valueLunedi.is_second_hour)
                                                                            ?
                                                                            <ButtonAntd className='d-flex align-items-center justify-content-center' type='primary' icon={<PlusOutlined />} shape='circle' size='large' onClick={handleSecondOrarioLunedi} />
                                                                            :
                                                                            <ButtonAntd className='d-flex align-items-center justify-content-center' danger type='primary' icon={<CloseOutlined />} shape='circle' size='large' onClick={handleNotSecondOrarioLunedi} />
                                                                        }
                                                                        <Tooltip title="Clicca sul pulsante per copiare gli orari su tutti i giorni della settimana" placement='right'>
                                                                            <ButtonAntd style={{ backgroundColor: 'green' }} className='d-flex align-items-center justify-content-center mt-1' type='primary' icon={<CopyOutlined />} shape='circle' size='large' onClick={() => copyAllHours(valueLunedi.open_hour, valueLunedi.second_open_hour, valueLunedi.closed_hour, valueLunedi.second_closed_hour, valueLunedi.is_second_hour)} />
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                                {(valueLunedi.is_second_hour)
                                                                    &&
                                                                    <div className="d-flex align-items-center mt-1">
                                                                        <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueLunedi.second_open_hour} onChange={(value) => setValueLunedi({ ...valueLunedi, second_open_hour: value.target.value })} />
                                                                        <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueLunedi.second_closed_hour} onChange={(value) => setValueLunedi({ ...valueLunedi, second_closed_hour: value.target.value })} />
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    <hr />
                                                    <div className="row my-2">
                                                        <div className="col-xl-4 col-md-12 col-12 mb-lg-0 mb-md-1 mb-1">
                                                            <h4 style={{ fontWeight: '500' }}>Martedì</h4>
                                                            <Switch defaultChecked={(valueMartedi.is_open == 1) ? true : false } unCheckedChildren="Chiuso" checkedChildren="Aperto" onChange={() => setValueMartedi((valueMartedi.is_open == 0) ? { ...valueMartedi, is_open: 1 } : { ...valueMartedi, is_open: 0 })} />
                                                        </div>
                                                        {(valueMartedi.is_open == 1)
                                                            &&
                                                            <div className="col-xl-8 col-md-12 col-12">
                                                                <div className="d-flex align-items-center">
                                                                    <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueMartedi.open_hour} onChange={(value) => setValueMartedi({ ...valueMartedi, open_hour: value.target.value })} />
                                                                    <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueMartedi.closed_hour} onChange={(value) => setValueMartedi({ ...valueMartedi, closed_hour: value.target.value })} />
                                                                    <div className='ml-2'>
                                                                        {(!valueMartedi.is_second_hour)
                                                                            ?
                                                                            <ButtonAntd className='d-flex align-items-center justify-content-center' type='primary' icon={<PlusOutlined />} shape='circle' size='large' onClick={handleSecondOrarioMartedi} />
                                                                            :
                                                                            <ButtonAntd className='d-flex align-items-center justify-content-center' danger type='primary' icon={<CloseOutlined />} shape='circle' size='large' onClick={handleNotSecondOrarioMartedi} />
                                                                        }
                                                                        <Tooltip title="Clicca sul pulsante per copiare gli orari su tutti i giorni della settimana" placement='right'>
                                                                            <ButtonAntd style={{ backgroundColor: 'green' }} className='d-flex align-items-center justify-content-center mt-1' type='primary' icon={<CopyOutlined />} shape='circle' size='large' onClick={() => copyAllHours(valueMartedi.open_hour, valueMartedi.second_open_hour, valueMartedi.closed_hour, valueMartedi.second_closed_hour, valueMartedi.is_second_hour)} />
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                                {(valueMartedi.is_second_hour)
                                                                    &&
                                                                    <div className="d-flex align-items-center mt-1">
                                                                        <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueMartedi.second_open_hour} onChange={(value) => setValueMartedi({ ...valueMartedi, second_open_hour: value.target.value })} />
                                                                        <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueMartedi.second_closed_hour} onChange={(value) => setValueMartedi({ ...valueMartedi, second_closed_hour: value.target.value })} />
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    <hr />
                                                    <div className="row my-2">
                                                        <div className="col-xl-4 col-md-12 col-12 mb-lg-0 mb-md-1 mb-1">
                                                            <h4 style={{ fontWeight: '500' }}>Mercoledì</h4>
                                                            <Switch defaultChecked={(valueMercoledi.is_open == 1) ? true : false } unCheckedChildren="Chiuso" checkedChildren="Aperto" onChange={() => setValueMercoledi((valueMercoledi.is_open == 0) ? { ...valueMercoledi, is_open: 1 } : { ...valueMercoledi, is_open: 0 })} />
                                                        </div>
                                                        {(valueMercoledi.is_open == 1)
                                                            &&
                                                            <div className="col-xl-8 col-md-12 col-12">
                                                                <div className="d-flex align-items-center">
                                                                    <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueMercoledi.open_hour} onChange={(value) => setValueMercoledi({ ...valueMercoledi, open_hour: value.target.value })} />
                                                                    <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueMercoledi.closed_hour} onChange={(value) => setValueMercoledi({ ...valueMercoledi, closed_hour: value.target.value })} />
                                                                    <div className='ml-2'>
                                                                        {(!valueMercoledi.is_second_hour)
                                                                            ?
                                                                            <ButtonAntd className='d-flex align-items-center justify-content-center' type='primary' icon={<PlusOutlined />} shape='circle' size='large' onClick={handleSecondOrarioMercoledi} />
                                                                            :
                                                                            <ButtonAntd className='d-flex align-items-center justify-content-center' danger type='primary' icon={<CloseOutlined />} shape='circle' size='large' onClick={handleNotSecondOrarioMercoledi} />
                                                                        }
                                                                        <Tooltip title="Clicca sul pulsante per copiare gli orari su tutti i giorni della settimana" placement='right'>
                                                                            <ButtonAntd style={{ backgroundColor: 'green' }} className='d-flex align-items-center justify-content-center mt-1' type='primary' icon={<CopyOutlined />} shape='circle' size='large' onClick={() => copyAllHours(valueMercoledi.open_hour, valueMercoledi.second_open_hour, valueMercoledi.closed_hour, valueMercoledi.second_closed_hour, valueMercoledi.is_second_hour)} />
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                                {(valueMercoledi.is_second_hour)
                                                                    &&
                                                                    <div className="d-flex align-items-center mt-1">
                                                                        <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueMercoledi.second_open_hour} onChange={(value) => setValueMercoledi({ ...valueMercoledi, second_open_hour: value.target.value })} />
                                                                        <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueMercoledi.second_closed_hour} onChange={(value) => setValueMercoledi({ ...valueMercoledi, second_closed_hour: value.target.value })} />
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    <hr />
                                                    <div className="row my-2">
                                                        <div className="col-xl-4 col-md-12 col-12 mb-lg-0 mb-md-1 mb-1">
                                                            <h4 style={{ fontWeight: '500' }}>Giovedì</h4>
                                                            <Switch defaultChecked={(valueGiovedi.is_open == 1) ? true : false } unCheckedChildren="Chiuso" checkedChildren="Aperto" onChange={() => setValueGiovedi((valueGiovedi.is_open == 0) ? { ...valueGiovedi, is_open: 1 } : { ...valueGiovedi, is_open: 0 })} />
                                                        </div>
                                                        {(valueGiovedi.is_open == 1)
                                                            &&
                                                            <div className="col-xl-8 col-md-12 col-12">
                                                                <div className="d-flex align-items-center">
                                                                    <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueGiovedi.open_hour} onChange={(value) => setValueGiovedi({ ...valueGiovedi, open_hour: value.target.value })} />
                                                                    <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueGiovedi.closed_hour} onChange={(value) => setValueGiovedi({ ...valueGiovedi, closed_hour: value.target.value })} />
                                                                    <div className='ml-2'>
                                                                        {(!valueGiovedi.is_second_hour)
                                                                            ?
                                                                            <ButtonAntd className='d-flex align-items-center justify-content-center' type='primary' icon={<PlusOutlined />} shape='circle' size='large' onClick={handleSecondOrarioGiovedi} />
                                                                            :
                                                                            <ButtonAntd className='d-flex align-items-center justify-content-center' danger type='primary' icon={<CloseOutlined />} shape='circle' size='large' onClick={handleNotSecondOrarioGiovedi} />
                                                                        }
                                                                        <Tooltip title="Clicca sul pulsante per copiare gli orari su tutti i giorni della settimana" placement='right'>
                                                                            <ButtonAntd style={{ backgroundColor: 'green' }} className='d-flex align-items-center justify-content-center mt-1' type='primary' icon={<CopyOutlined />} shape='circle' size='large' onClick={() => copyAllHours(valueGiovedi.open_hour, valueGiovedi.second_open_hour, valueGiovedi.closed_hour, valueGiovedi.second_closed_hour, valueGiovedi.is_second_hour)} />
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                                {(valueGiovedi.is_second_hour)
                                                                    &&
                                                                    <div className="d-flex align-items-center mt-1">
                                                                        <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueGiovedi.second_open_hour} onChange={(value) => setValueGiovedi({ ...valueGiovedi, second_open_hour: value.target.value })} />
                                                                        <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueGiovedi.second_closed_hour} onChange={(value) => setValueGiovedi({ ...valueGiovedi, second_closed_hour: value.target.value })} />
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    <hr />
                                                    <div className="row my-2">
                                                        <div className="col-xl-4 col-md-12 col-12 mb-lg-0 mb-md-1 mb-1">
                                                            <h4 style={{ fontWeight: '500' }}>Venerdì</h4>
                                                            <Switch defaultChecked={(valueVenerdi.is_open == 1) ? true : false } unCheckedChildren="Chiuso" checkedChildren="Aperto" onChange={() => setValueVenerdi((valueVenerdi.is_open == 0) ? { ...valueVenerdi, is_open: 1 } : { ...valueVenerdi, is_open: 0 })} />
                                                        </div>
                                                        {(valueVenerdi.is_open == 1)
                                                            &&
                                                            <div className="col-xl-8 col-md-12 col-12">
                                                                <div className="d-flex align-items-center">
                                                                    <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueVenerdi.open_hour} onChange={(value) => setValueVenerdi({ ...valueVenerdi, open_hour: value.target.value })} />
                                                                    <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueVenerdi.closed_hour} onChange={(value) => setValueVenerdi({ ...valueVenerdi, closed_hour: value.target.value })} />
                                                                    <div className='ml-2'>
                                                                        {(!valueVenerdi.is_second_hour)
                                                                            ?
                                                                            <ButtonAntd className='d-flex align-items-center justify-content-center' type='primary' icon={<PlusOutlined />} shape='circle' size='large' onClick={handleSecondOrarioVenerdi} />
                                                                            :
                                                                            <ButtonAntd className='d-flex align-items-center justify-content-center' danger type='primary' icon={<CloseOutlined />} shape='circle' size='large' onClick={handleNotSecondOrarioVenerdi} />
                                                                        }
                                                                        <Tooltip title="Clicca sul pulsante per copiare gli orari su tutti i giorni della settimana" placement='right'>
                                                                            <ButtonAntd style={{ backgroundColor: 'green' }} className='d-flex align-items-center justify-content-center mt-1' type='primary' icon={<CopyOutlined />} shape='circle' size='large' onClick={() => copyAllHours(valueVenerdi.open_hour, valueVenerdi.second_open_hour, valueVenerdi.closed_hour, valueVenerdi.second_closed_hour, valueVenerdi.is_second_hour)} />
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                                {(valueVenerdi.is_second_hour)
                                                                    &&
                                                                    <div className="d-flex align-items-center mt-1">
                                                                        <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueVenerdi.second_open_hour} onChange={(value) => setValueVenerdi({ ...valueVenerdi, second_open_hour: value.target.value })} />
                                                                        <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueVenerdi.second_closed_hour} onChange={(value) => setValueVenerdi({ ...valueVenerdi, second_closed_hour: value.target.value })} />
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    <hr />
                                                    <div className="row my-2">
                                                        <div className="col-xl-4 col-md-12 col-12 mb-lg-0 mb-md-1 mb-1">
                                                            <h4 style={{ fontWeight: '500' }}>Sabato</h4>
                                                            <Switch defaultChecked={(valueSabato.is_open == 1) ? true : false } unCheckedChildren="Chiuso" checkedChildren="Aperto" onChange={() => setValueSabato((valueSabato.is_open == 0) ? { ...valueSabato, is_open: 1 } : { ...valueSabato, is_open: 0 })} />
                                                        </div>
                                                        {(valueSabato.is_open == 1)
                                                            &&
                                                            <div className="col-xl-8 col-md-12 col-12">
                                                                <div className="d-flex align-items-center">
                                                                    <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueSabato.open_hour} onChange={(value) => setValueSabato({ ...valueSabato, open_hour: value.target.value })} />
                                                                    <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueSabato.closed_hour} onChange={(value) => setValueSabato({ ...valueSabato, closed_hour: value.target.value })} />
                                                                    <div className='ml-2'>
                                                                        {(!valueSabato.is_second_hour)
                                                                            ?
                                                                            <ButtonAntd className='d-flex align-items-center justify-content-center' type='primary' icon={<PlusOutlined />} shape='circle' size='large' onClick={handleSecondOrarioSabato} />
                                                                            :
                                                                            <ButtonAntd className='d-flex align-items-center justify-content-center' danger type='primary' icon={<CloseOutlined />} shape='circle' size='large' onClick={handleNotSecondOrarioSabato} />
                                                                        }
                                                                        <Tooltip title="Clicca sul pulsante per copiare gli orari su tutti i giorni della settimana" placement='right'>
                                                                            <ButtonAntd style={{ backgroundColor: 'green' }} className='d-flex align-items-center justify-content-center mt-1' type='primary' icon={<CopyOutlined />} shape='circle' size='large' onClick={() => copyAllHours(valueSabato.open_hour, valueSabato.second_open_hour, valueSabato.closed_hour, valueSabato.second_closed_hour, valueSabato.is_second_hour)} />
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                                {(valueSabato.is_second_hour)
                                                                    &&
                                                                    <div className="d-flex align-items-center mt-1">
                                                                        <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueSabato.second_open_hour} onChange={(value) => setValueSabato({ ...valueSabato, second_open_hour: value.target.value })} />
                                                                        <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueSabato.second_closed_hour} onChange={(value) => setValueSabato({ ...valueSabato, second_closed_hour: value.target.value })} />
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    <hr />
                                                    <div className="row mt-2">
                                                        <div className="col-xl-4 col-md-12 col-12 mb-lg-0 mb-md-1 mb-1">
                                                            <h4 style={{ fontWeight: '500' }}>Domenica</h4>
                                                            <Switch defaultChecked={(valueDomenica.is_open == 1) ? true : false } unCheckedChildren="Chiuso" checkedChildren="Aperto" onChange={() => setValueDomenica((valueDomenica.is_open == 0) ? { ...valueDomenica, is_open: 1 } : { ...valueDomenica, is_open: 0 })} />
                                                        </div>
                                                        {(valueDomenica.is_open == 1)
                                                            &&
                                                            <div className="col-xl-8 col-md-12 col-12">
                                                                <div className="d-flex align-items-center">
                                                                    <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueDomenica.open_hour} onChange={(value) => setValueDomenica({ ...valueDomenica, open_hour: value.target.value })} />
                                                                    <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueDomenica.closed_hour} onChange={(value) => setValueDomenica({ ...valueDomenica, closed_hour: value.target.value })} />
                                                                    <div className='ml-2'>
                                                                        {(!valueDomenica.is_second_hour)
                                                                            ?
                                                                            <ButtonAntd className='d-flex align-items-center justify-content-center' type='primary' icon={<PlusOutlined />} shape='circle' size='large' onClick={handleSecondOrarioDomenica} />
                                                                            :
                                                                            <ButtonAntd className='d-flex align-items-center justify-content-center' danger type='primary' icon={<CloseOutlined />} shape='circle' size='large' onClick={handleNotSecondOrarioDomenica} />
                                                                        }
                                                                        <Tooltip title="Clicca sul pulsante per copiare gli orari su tutti i giorni della settimana" placement='right'>
                                                                            <ButtonAntd style={{ backgroundColor: 'green' }} className='d-flex align-items-center justify-content-center mt-1' type='primary' icon={<CopyOutlined />} shape='circle' size='large' onClick={() => copyAllHours(valueDomenica.open_hour, valueDomenica.second_open_hour, valueDomenica.closed_hour, valueDomenica.second_closed_hour, valueDomenica.is_second_hour)} />
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                                {(valueDomenica.is_second_hour)
                                                                    &&
                                                                    <div className="d-flex align-items-center mt-1">
                                                                        <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueDomenica.second_open_hour} onChange={(value) => setValueDomenica({ ...valueDomenica, second_open_hour: value.target.value })} />
                                                                        <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueDomenica.second_closed_hour} onChange={(value) => setValueDomenica({ ...valueDomenica, second_closed_hour: value.target.value })} />
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <fieldset>
                                                <div style={{ marginTop: 24 }} className="float-right">
                                                    <Button type="primary" onClick={(loading) ? null : insertPharmacyVisit}>
                                                        {(loading)
                                                            ?
                                                            <ThreeDots 
                                                                height="11" 
                                                                width="23" 
                                                                radius="4.5"
                                                                color="#fff" 
                                                                ariaLabel="three-dots-loading"
                                                                wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
                                                                visible={true}
                                                            />
                                                            :
                                                            'Salva'
                                                        }
                                                    </Button>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                    </>
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default AddVisit;