import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { ThreeDots } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../assets/images/logo-header-takeiteasy.png';
import { PinInput } from 'react-input-pin-code';
import md5 from 'md5';
import * as config from '../config';
import axios from 'axios';
import Countdown from 'react-countdown';

function VerifyCode() {
    const navigate = useNavigate()
    const countDownRef = useRef()
    const [loading, setLoading] = useState(false)
    const [code, setCode] = useState(['','','','','',''])
    const [isSend, setIsSend] = useState(false)

    useEffect(() => {
        document.body.setAttribute("class","vertical-layout vertical-menu bg-cyan bg-lighten-2 1-column blank-page");
        document.body.setAttribute("data-col","1-column");

        return () => {
            document.body.setAttribute("class","vertical-layout vertical-menu 2-columns fixed-navbar");
            document.body.setAttribute("data-col","2-columns");
        };
    }, [])

    const verifyPinCode = () => {
        setLoading(true)
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        if (code[0] !== "" && code[1] !== "" && code[2] !== "" && code[3] !== "" && code[4] !== "" && code[5] != "") {
            axios({
                method: 'post',
                url: `${config.WEBSITE_URL}/partners/verify-code`,
                headers: {
                    'Content-Type': 'application/json'
                },
                auth: {
                    username: config.USERNAME_TOKEN,
                    password: config.PASSWORD_TOKEN
                },
                data: JSON.stringify({
                    token_access: config.WEB_TOKEN,
                    id: userInfo.id,
                    code: `${code[0]}${code[1]}${code[2]}${code[3]}${code[4]}${code[5]}`
                })
            })
            .then(function(response) {
                if (response.data.code == true) {
                    const newUserInfo = { ...userInfo, is_code_verify: "1" };
                    localStorage.setItem('user_info', JSON.stringify(newUserInfo))
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (userInfo.is_completed == "0" || userInfo.is_completed == 0 || userInfo.is_completed == null) {
                        setTimeout(() => {
                            navigate('/completa/profilo')
                        }, 1000);
                    } else {
                        setTimeout(() => {
                            navigate('/')
                        }, 1000);
                    }
                    setLoading(false)
                } else {
                    toast.error(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setLoading(false)
                }
            })
            .catch(function(error) {
                console.error(error);
                setLoading(false)
            });
        } else {
            toast.error("Inserisci il codice completo", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false)
        }
    }

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (!completed) {
            return <span>0{minutes}:{(seconds.toString().length == 1) ? `0${seconds}` : seconds}</span>;
        }
    }

    const resendCode = () => {
        setIsSend(true)
        setTimeout(() => {
            countDownRef.current.start()
        }, 500);
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/resend-verify-code`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id: userInfo.id
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                toast.success(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const onCompleteCount = () => {
        setIsSend(false)
    }

    return(
        <>
        <ToastContainer />
        <Helmet>
            <title>Verifica Email | Takeiteasy Partners</title>
        </Helmet>
        <div className="app-content content">
            <div className="content-overlay"></div>
            <div className="content-wrapper">
                <div className="content-header row">
                </div>
                <div className="content-body">
                    <section className="row flexbox-container">
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            <div className="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
                                <div className="card border-grey border-lighten-3 m-0">
                                    <div className="card-header border-0">
                                        <div className="card-title text-center">
                                            <div className="p-1"><img src={logo} style={{ width: '65%' }} /></div>
                                        </div>
                                    </div>
                                    <div className="card-content">
                                        <p className="card-subtitle line-on-side text-muted text-center font-small-3 mx-2">
                                            <span>Verifica il tuo indirizzo email</span>
                                        </p>
                                        <p className="mx-2 font-small-3">Ti abbiamo inviato un codice di verifica al tuo indirizzo email. Inserisci il codice e verifica il tuo account.</p>
                                        <div className="card-body pt-0">
                                            <form className="form-horizontal">
                                                <div className="d-flex justify-content-center">
                                                    <PinInput
                                                        values={code}
                                                        onChange={(value, index, values) => setCode(values)}
                                                        autoFocus={true}
                                                        required={true}
                                                        placeholder="0"
                                                        size='lg'
                                                        //onComplete={verifyPinCode}
                                                    />
                                                </div>
                                                <button type="button" disabled={loading} onClick={verifyPinCode} className="btn btn-info btn-lg btn-block mt-2">
                                                    {(loading)
                                                        ?
                                                        <ThreeDots 
                                                            height="22" 
                                                            width="46" 
                                                            radius="9"
                                                            color="#fff" 
                                                            ariaLabel="three-dots-loading"
                                                            wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
                                                            visible={true}
                                                        />
                                                        :
                                                        <>
                                                        <i className="ft-unlock"></i> Verifica email
                                                        </>
                                                    }
                                                </button>
                                                <div className='float-sm-right mt-1'>
                                                    {(isSend)
                                                        ?
                                                        <Countdown
                                                            ref={countDownRef}
                                                            date={Date.now() + 60000}
                                                            renderer={renderer}
                                                            autoStart={false}
                                                            onComplete={onCompleteCount}
                                                        />
                                                        :
                                                        <a style={{ color: '#1e9ff2' }} className="card-link" onClick={resendCode}>Invia un nuovo codice</a>
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                        <div className="card-footer border-0 mt-1">
                                            <p className="float-sm-left text-center">
                                                <Link to="/login" className="card-link">
                                                    Login
                                                </Link>
                                            </p>
                                            <p className="float-sm-right text-center">
                                                Nuovo su Takeiteasy ? <Link to="/signup" className="card-link">Crea un account</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        </>
    )
}

export default VerifyCode;