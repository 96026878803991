import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet";
import Rating from 'react-rating';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler, ArcElement } from 'chart.js';
import { Line, Pie } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import * as config from '../config.js';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment, { months } from 'moment';
import 'moment/locale/it';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const optionsTicket = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Guadagno ticket',
        },
    },
};

const labels = ['Gennaio','Febbraio','Marzo','Aprile','Maggio','Giugno','Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre'];

function DashboardVisits() {
    const navigate = useNavigate()
    const [countTickets, setCountTickets] = useState(0)
    const [countYearTickets, setCountYearTickets] = useState(0)
    const [priceMonth, setPriceMonth] = useState(0)
    const [priceYear, setPriceYear] = useState(0)
    const [priceLastMonth, setPriceLastMonth] = useState(0)
    const [percentageMonth, setPercentageMonth] = useState(null)
    const [priceLastYear, setPriceLastYear] = useState(0)
    const [percentageYear, setPercentageYear] = useState(null)
    const [allProfits, setAllProfits] = useState([])
    const [listVisits, setListVisits] = useState([])
    const [listPriceVisits, setListPriceVisits] = useState([])

    useEffect(() => {
        checkPharmacyBilling()
        getProfitsYear()
        getVisitsSingleProfits()
        getTicketsMonthCount()
        getTicketsYearCount()
        getPriceMonth()
        getPriceYear()
    }, [])

    const checkPharmacyBilling = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/check-pharmacy-has-visits`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                if (response.data.has_visits !== true) {
                    navigate('/')
                }
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const getProfitsYear = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-year-visits-profits`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                setAllProfits(response.data.all_profits)
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const getVisitsSingleProfits = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        const startOfMonth = moment().startOf('year').format('YYYY-MM-DD');
        const endOfMonth   = moment().endOf('year').format('YYYY-MM-DD');
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-visits-single-profits`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy,
                first_day_year: startOfMonth,
                last_day_year: endOfMonth
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                if (response.data.all_visits.length > 0) {
                    const arrayNames = [];
                    const arrayPrices = [];
                    let superCount = 0;
                    response.data.all_visits.forEach(element => {
                        if (!arrayNames.includes(element.name_visit)) {
                            arrayNames.push(element.name_visit)
                            const listPrices = response.data.all_visits.filter(e => e.name_visit == element.name_visit)
                            let count = 0;
                            let calculatePrice = 0;
                            listPrices.forEach(data => {
                                const newPrice = data.price.replace("€","")
                                calculatePrice = parseFloat(newPrice) + calculatePrice;
                                count++;

                                if (count == listPrices.length) {
                                    arrayPrices.push(calculatePrice.toFixed(2))
                                }
                            });
                        }
                        superCount++;

                        if (superCount == response.data.all_visits.length) {
                            setListVisits(arrayNames)
                            setListPriceVisits(arrayPrices)
                        }
                    });
                }
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const getTicketsMonthCount = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
        const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD');
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-visits-tickets-month-count`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy,
                first_day_month: startOfMonth,
                last_day_month: endOfMonth
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                setCountTickets(response.data.number_tickets)
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const getTicketsYearCount = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        const startOfMonth = moment().startOf('year').format('YYYY-MM-DD');
        const endOfMonth   = moment().endOf('year').format('YYYY-MM-DD');
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-visits-tickets-month-count`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy,
                first_day_month: startOfMonth,
                last_day_month: endOfMonth
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                setCountYearTickets(response.data.number_tickets)
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const getPriceMonth = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
        const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD');
        const prevStartOfMonth = moment().startOf('month').subtract(1, 'month').format('YYYY-MM-DD');
        const prevEndOfMonth   = moment().endOf('month').subtract(1, 'month').format('YYYY-MM-DD');
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-visits-price`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy,
                first_day_month: startOfMonth,
                last_day_month: endOfMonth,
                prev_first_day_month: prevStartOfMonth,
                prev_last_day_month: prevEndOfMonth
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                if (response.data.prev_price !== 0) {
                    const thisMonth = parseFloat(response.data.price)
                    const lastMonth = parseFloat(response.data.prev_price)
                    const percentage = ((thisMonth - lastMonth) / lastMonth) * 100
                    setPercentageMonth(percentage.toFixed(0).toString())
                }
                setPriceLastMonth(parseFloat(response.data.prev_price).toFixed(2))
                setPriceMonth(parseFloat(response.data.price).toFixed(2))
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const getPriceYear = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        const startOfMonth = moment().startOf('year').format('YYYY-MM-DD');
        const endOfMonth   = moment().endOf('year').format('YYYY-MM-DD');
        const prevStartOfMonth = moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DD');
        const prevEndOfMonth   = moment().endOf('year').subtract(1, 'year').format('YYYY-MM-DD');
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-visits-price`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy,
                first_day_month: startOfMonth,
                last_day_month: endOfMonth,
                prev_first_day_month: prevStartOfMonth,
                prev_last_day_month: prevEndOfMonth
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                if (response.data.prev_price !== 0) {
                    const thisMonth = parseFloat(response.data.price)
                    const lastMonth = parseFloat(response.data.prev_price)
                    const percentage = ((thisMonth - lastMonth) / lastMonth) * 100
                    setPercentageYear(percentage.toFixed(0).toString())
                }
                setPriceLastYear(parseFloat(response.data.prev_price).toFixed(2))
                setPriceYear(parseFloat(response.data.price).toFixed(2))
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    return(
        <>
        <Helmet>
            <title>Dashboard Visite | Takeiteasy Partners</title>
        </Helmet>
        <ToastContainer />
        <Navbar />
        <div className="app-content content">
            <div className="content-overlay"></div>
            <div className="content-wrapper">
                <div className="content-header row">
                </div>
                <div className="content-body">
                    <div className="row" style={{ paddingBottom: '0.275rem' }}>
                        <div className="col-lg-6 col-12">
                            <div className="card pull-up" style={{ minHeight: '90%' }}>
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="media d-flex">
                                            <div className="media-body text-left">
                                                <h6 className="text-muted">Ticket visite ultimo mese</h6>
                                                <h2 className="font-large-2">{countTickets}</h2>
                                            </div>
                                            <div className="align-self-center">
                                                <i className="la la-ticket success font-large-2 float-right"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="card pull-up" style={{ minHeight: '90%' }}>
                                <div className="card-content">
                                    <div className="card-body">
                                        <div className="media d-flex">
                                            <div className="media-body text-left">
                                                <h6 className="text-muted">Ticket visite ultimo anno</h6>
                                                <h2 className="font-large-2">{countYearTickets}</h2>
                                            </div>
                                            <div className="align-self-center">
                                                <i className="la la-ticket info font-large-2 float-right"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title text-center">Guadagno visite</h4>
                                </div>
                                <div className="card-content collapse show">
                                    <div className="card-body pt-0">
                                        <div className="row">
                                            <div className="col-md-6 col-12 border-right-blue-grey border-right-lighten-5 text-center">
                                                {(percentageMonth !== null)
                                                    ?
                                                    (percentageMonth.includes('-'))
                                                        ?
                                                        <h6 className="danger text-bold-600">{percentageMonth}%</h6>
                                                        :
                                                        <h6 className="success text-bold-600">+{percentageMonth}%</h6>
                                                    :
                                                    <h6 className="success text-bold-600">+0%</h6>
                                                }
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <div className="mr-2">
                                                        <h6 className="font-large-1 text-bold-400">€{priceLastMonth}</h6>
                                                        <p style={{ fontSize: '0.9rem' }} className="blue-grey lighten-2 mb-0">Mese scorso</p>
                                                    </div>
                                                    <div>
                                                        <h4 className="font-large-2 text-bold-400">€{priceMonth}</h4>
                                                        <p className="blue-grey lighten-2 mb-0">Questo mese</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 text-center">
                                                {(percentageYear !== null)
                                                    ?
                                                    (percentageYear.includes('-'))
                                                        ?
                                                        <h6 className="danger text-bold-600">{percentageYear}%</h6>
                                                        :
                                                        <h6 className="success text-bold-600">+{percentageYear}%</h6>
                                                    :
                                                    <h6 className="success text-bold-600">+0%</h6>
                                                }
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <div className="mr-2">
                                                        <h6 className="font-large-1 text-bold-400">€{priceLastYear}</h6>
                                                        <p style={{ fontSize: '0.9rem' }} className="blue-grey lighten-2 mb-0">Anno scorso</p>
                                                    </div>
                                                    <div>
                                                        <h4 className="font-large-2 text-bold-400">€{priceYear}</h4>
                                                        <p className="blue-grey lighten-2 mb-0">Questo anno</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ paddingBottom: '0.575rem' }}>
                        <div className="col-xl-4 col-12">
                            <div className="card" style={{ minHeight: '97%' }}>
                                <div className="card-header">
                                    <h4 className="card-title mr-1">Andamento visite</h4>
                                </div>
                                <div className="card-content collapse show">
                                    <div className="card-body pt-0">
                                        <Pie data={{
                                            labels: listVisits,
                                            datasets: [
                                                {
                                                    label: 'Fatturato annuale visita in €',
                                                    data: listPriceVisits,
                                                    backgroundColor: [
                                                        'rgba(255, 99, 132, 0.2)',
                                                        'rgba(54, 162, 235, 0.2)',
                                                        'rgba(255, 206, 86, 0.2)',
                                                        'rgba(75, 192, 192, 0.2)',
                                                        'rgba(153, 102, 255, 0.2)'
                                                    ],
                                                    borderColor: [
                                                        'rgba(255, 99, 132, 1)',
                                                        'rgba(54, 162, 235, 1)',
                                                        'rgba(255, 206, 86, 1)',
                                                        'rgba(75, 192, 192, 1)',
                                                        'rgba(153, 102, 255, 1)'
                                                    ],
                                                    borderWidth: 1,
                                                },
                                            ],
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 col-12">
                            <div className="card" style={{ minHeight: '97%' }}>
                                <div className="card-header">
                                    <h4 className="card-title">Andamento fatturato visite</h4>
                                </div>
                                <div className="card-content collapse show">
                                    <div className="card-body pt-0">
                                        <Line options={optionsTicket} data={{
                                            labels,
                                            datasets: [
                                                {
                                                    fill: true,
                                                    label: 'Fatturato in €',
                                                    data: allProfits,
                                                    borderColor: 'rgba(255, 159, 64, 1)',
                                                    backgroundColor: 'rgba(255, 159, 64, 0.2)',
                                                },
                                            ],
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default DashboardVisits;