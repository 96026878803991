import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from '../utils/ProtectedRoute';
import Dashboard from '../screens/Dashboard';
import Login from '../screens/Login';
import Signup from '../screens/Signup';
import ForgotPassword from '../screens/ForgotPassword';
import Loading from '../screens/Loading';
import Contacts from '../screens/Contacts';
import Tickets from '../screens/Tickets';
import Calendar from '../screens/Calendar';
import Visits from '../screens/Visits';
import Flyer from '../screens/Flyer';
import DashboardVisits from '../screens/DashboardVisits';
import VerifyCode from '../screens/VerifyCode';
import E404Page from '../screens/E404Page';
import CompleteProfile from '../screens/CompleteProfile';
import Pharmacies from '../screens/Pharmacies';
import VerifyPharmacy from '../screens/VerifyPharmacy';
import EditPharmacy from '../screens/EditPharmacy';
import AddPharmacy from '../screens/AddPharmacy';
import Profile from '../screens/Profile';
import EditProfile from '../screens/EditProfile';
import EditBilling from '../screens/EditBilling';
import EditPassword from '../screens/EditPassword';
import AddVisit from '../screens/AddVisit';
import EditVisit from '../screens/EditVisit';
import ActivatePharmacy from '../screens/ActivatePharmacy';
import PaymentComplete from '../screens/PaymentComplete';
import Billing from '../screens/Billing';
import ReactivatePharmacy from '../screens/ReactivePharmacy';
import RestoreComplete from '../screens/RestoreComplete';
import AddProducts from '../screens/AddProducts';
import EditProduct from '../screens/EditProduct';
import DisplayTickets from '../screens/DisplayTickets';

const MyRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path='/' index={true} element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                <Route path='/login' element={<Login />} />
                <Route path='/signup' element={<Signup />} />
                <Route path='/password/forgot' element={<ForgotPassword />} />
                <Route path='/account/verifica' element={<ProtectedRoute><VerifyCode /></ProtectedRoute>} />
                <Route path='/contatti' element={<ProtectedRoute><Contacts /></ProtectedRoute>} />
                <Route path='/ticket' element={<ProtectedRoute><Tickets /></ProtectedRoute>} />
                <Route path='/calendario' element={<ProtectedRoute><Calendar /></ProtectedRoute>} />
                <Route path='/visite' element={<ProtectedRoute><Visits /></ProtectedRoute>} />
                <Route path='/promozioni' element={<ProtectedRoute><Flyer /></ProtectedRoute>} />
                <Route path='/promozioni/:result/:id_pharmacy/:id_plan/:id_session' element={<ProtectedRoute><Flyer /></ProtectedRoute>} />
                <Route path='/dashboard/visite' element={<ProtectedRoute><DashboardVisits /></ProtectedRoute>} />
                <Route path='/completa/profilo' element={<ProtectedRoute><CompleteProfile /></ProtectedRoute>} />
                <Route path='/farmacie' element={<ProtectedRoute><Pharmacies /></ProtectedRoute>} />
                <Route path='/verifica/farmacia/:id_pharmacy' element={<ProtectedRoute><VerifyPharmacy /></ProtectedRoute>} />
                <Route path='/modifica/farmacia/:id_pharmacy' element={<ProtectedRoute><EditPharmacy /></ProtectedRoute>} />
                <Route path='/aggiungi/farmacia' element={<ProtectedRoute><AddPharmacy /></ProtectedRoute>} />
                <Route path='/profilo' element={<ProtectedRoute><Profile /></ProtectedRoute>} />
                <Route path='/modifica/profilo' element={<ProtectedRoute><EditProfile /></ProtectedRoute>} />
                <Route path='/modifica/fatturazione' element={<ProtectedRoute><EditBilling /></ProtectedRoute>} />
                <Route path='/modifica/password' element={<ProtectedRoute><EditPassword /></ProtectedRoute>} />
                <Route path='/nuova/visita' element={<ProtectedRoute><AddVisit /></ProtectedRoute>} />
                <Route path='/visita/:id_visit' element={<ProtectedRoute><EditVisit /></ProtectedRoute>} />
                <Route path='/attiva/farmacia/:id_pharmacy' element={<ProtectedRoute><ActivatePharmacy /></ProtectedRoute>} />
                <Route path='/pagamento/completato/:id_plan/:id_pharmacy/:id_session' element={<ProtectedRoute><PaymentComplete /></ProtectedRoute>} />
                <Route path='/fatturazione' element={<ProtectedRoute><Billing /></ProtectedRoute>} />
                <Route path='/ripristino/farmacia/:id_pharmacy/:id_customer' element={<ProtectedRoute><ReactivatePharmacy /></ProtectedRoute>} />
                <Route path='/ripristino/completato' element={<ProtectedRoute><RestoreComplete /></ProtectedRoute>} />
                <Route path='/aggiungi/prodotto' element={<ProtectedRoute><AddProducts /></ProtectedRoute>} />
                <Route path='/prodotto/:id_product' element={<ProtectedRoute><EditProduct /></ProtectedRoute>} />
                <Route path='/display/tickets' element={<ProtectedRoute><DisplayTickets /></ProtectedRoute>} />
                <Route path='*' element={<E404Page />} />
            </Routes>
        </Router>
    );
}

export default MyRoutes;