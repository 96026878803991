import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet";
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Empty, Skeleton, Alert, Breadcrumb } from 'antd';
import * as config from '../config';
import axios from 'axios';
import Errors from '../components/Errors';
import { PinInput } from 'react-input-pin-code';
import { ThreeDots } from 'react-loader-spinner';

function VerifyPharmacy() {
    const { id_pharmacy } = useParams()
    const [code, setCode] = useState(['','','','','',''])
    const [isLoading, setIsLoading] = useState(true)
    const [loadResponse, setLoadResponse] = useState(false)
    const [haveError, setHaveError] = useState(false)
    const [pharmacy, setPharmacy] = useState(null)
    const [message, setMessage] = useState("")

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        checkErrors(userInfo)
        getPharmacy(userInfo)
    }, [])

    const checkErrors = (userInfo) => {
        if (userInfo !== null) {
            if (userInfo.is_code_verify == "0" || userInfo.is_code_verify == 0 || userInfo.is_completed == "0" || userInfo.is_completed == 0) {
                setHaveError(true)
            } else {
                setHaveError(false)
            }
        } else {
            setHaveError(false)
        }
    }

    const getPharmacy = (userInfo) => {
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-user-pharmacy`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_admin_user: userInfo.id,
                id_pharmacy: id_pharmacy
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                setPharmacy({ name_pharmacy: response.data.name_pharmacy, address: response.data.address, city: response.data.city, cap: response.data.cap, province: response.data.province })
                setIsLoading(false)
            } else {
                setIsLoading(false)
                setMessage(response.data.message)
            }
        })
        .catch(function(error) {
            setIsLoading(false)
            console.error(error);
        });
    }

    const verifyPharmacy = () => {
        setLoadResponse(true)
        if (code[0] !== "" && code[1] !== "" && code[2] !== "" && code[3] !== "" && code[4] !== "" && code[5] != "") {
            axios({
                method: 'post',
                url: `${config.WEBSITE_URL}/partners/verify-pharmacy`,
                headers: {
                    'Content-Type': 'application/json'
                },
                auth: {
                    username: config.USERNAME_TOKEN,
                    password: config.PASSWORD_TOKEN
                },
                data: JSON.stringify({
                    token_access: config.WEB_TOKEN,
                    id_pharmacy: id_pharmacy,
                    verify_code: `${code[0]}${code[1]}${code[2]}${code[3]}${code[4]}${code[5]}`
                })
            })
            .then(function(response) {
                if (response.data.code == true) {
                    const pharmacy = JSON.parse(localStorage.getItem('pharmacy'))
                    if (pharmacy.id_pharmacy === id_pharmacy) {
                        const newPharmacy = { ...pharmacy, is_verificated: "1" }
                        localStorage.setItem('pharmacy', JSON.stringify(newPharmacy))
                    }
                    const pharmaciesInfo = JSON.parse(localStorage.getItem('pharmacies_info'))
                    const pharmacyIndex = pharmaciesInfo.findIndex(element => element.id_pharmacy == id_pharmacy)
                    if (pharmacyIndex != -1) {
                        pharmaciesInfo[pharmacyIndex] = { ...pharmaciesInfo[pharmacyIndex], is_verificated: "1" }
                        localStorage.setItem('pharmacies_info', JSON.stringify(pharmaciesInfo))
                    }
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setLoadResponse(false)
                } else {
                    toast.error(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setLoadResponse(false)
                }
            })
            .catch(function(error) {
                console.error(error);
                setLoadResponse(false)
            });
        } else {
            toast.error("Inserisci il codice di verifica", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoadResponse(false)
        }
    }

    return(
        <>
        <ToastContainer />
        <Helmet>
            <title>Verifica Farmacia | Takeiteasy Partners</title>
        </Helmet>
        <Navbar />
        <div class="app-content content">
            <div class="content-overlay"></div>
            <div class="content-wrapper">
                <Errors />
                <div class="content-header row">
                    <div class="content-header-left col-md-6 col-12 mb-2">
                        <h3 class="content-header-title">Verifica farmacia</h3>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/farmacie">Farmacie</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Verifica farmacia</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                <div class="content-body">
                    <section class="input-groups" id="input-groups">
                        <div class="row match-height">
                            <div class="col-12">
                                <div class="card">
                                    {(haveError)
                                        ?
                                        <Empty
                                            description={
                                                <span>
                                                    Risolvi gli errori per poter continuare.
                                                </span>
                                            }
                                        />
                                        :
                                        <>
                                        <div class="card-header">
                                            <h4 class="card-title">Inserisci il codice della Farmacia ricevuto per posta</h4>
                                        </div>
                                        <div class="card-body">
                                            {(isLoading)
                                                ?
                                                <Skeleton active />
                                                :
                                                (pharmacy == null)
                                                    ?
                                                    <Empty
                                                        description={
                                                            <span>
                                                                {message}
                                                            </span>
                                                        }
                                                    />
                                                    :
                                                    <>
                                                    <div className='row mb-3'>
                                                        <div className='col-lg-4 col-md-6 col-12'>
                                                        <Alert
                                                            message={pharmacy.name_pharmacy}
                                                            description={`${pharmacy.address}, ${pharmacy.city}, ${pharmacy.cap}, ${pharmacy.province}`}
                                                            type="info"
                                                            showIcon
                                                        />
                                                        </div>
                                                    </div>
                                                    <form className="form-horizontal">
                                                        <div className="d-flex justify-content-center">
                                                            <PinInput
                                                                values={code}
                                                                onChange={(value, index, values) => setCode(values)}
                                                                autoFocus={false}
                                                                required={true}
                                                                placeholder="0"
                                                                size='lg'
                                                                //onComplete={verifyPinCode}
                                                            />
                                                        </div>
                                                        <div className="text-right mt-1">
                                                            <Button variant='primary' onClick={(loadResponse) ? null : verifyPharmacy}>
                                                                {(loadResponse)
                                                                    ?
                                                                    <ThreeDots 
                                                                        height="14.6" 
                                                                        width="30.6" 
                                                                        radius="4.5"
                                                                        color="#fff" 
                                                                        ariaLabel="three-dots-loading"
                                                                        wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
                                                                        visible={true}
                                                                    />
                                                                    :
                                                                    'Verifica Farmacia'
                                                                }
                                                            </Button>
                                                        </div>
                                                    </form>
                                                    </>
                                            }
                                        </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default VerifyPharmacy;