import React, { useEffect, useState } from 'react';
import MyRoutes from "./routers/index";
import { UserContext } from './routers/UserContext';

function App() {
  const [userAuth,setUserAuth] = React.useState((localStorage.getItem('isAuth') !== undefined && localStorage.getItem('isAuth') !== null && localStorage.getItem('isAuth') !== "") ? localStorage.getItem('isAuth') : false)

  return (
    <UserContext.Provider value={{userAuth, setUserAuth}}>
      <MyRoutes />
    </UserContext.Provider>
  );
}

export default App;
