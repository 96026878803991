import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet";
import * as config from '../config';
import axios from 'axios';
import { Skeleton, Empty, Avatar, Card, Button, Breadcrumb, Button as ButtonAntd, Switch, AutoComplete } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Errors from '../components/Errors';
import { ThreeDots } from 'react-loader-spinner';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/it';

function EditProduct() {
    const navigate = useNavigate()
    const { id_product } = useParams()
    const [allDrugs, setAllDrugs] = useState([])
    const [loadDrugs, setLoadDrugs] = useState(true)
    const [loadProducts, setLoadProducts] = useState(true)
    const [message, setMessage] = useState("Risolvi gli errori per poter continuare.")
    const [filterDrugs, setFilterDrugs] = useState([])
    const [haveError, setHaveError] = useState(false)
    const [product, setProduct] = useState({
        name_product: "",
        description: "",
        price: "",
        discount_price: "",
        start_date: "",
        end_date: ""
    })
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        checkErrors(userInfo)
        getPharmacyProductData()
        getAllDrugs()
    }, [])

    const checkErrors = (userInfo) => {
        if (userInfo !== null) {
            if (userInfo.is_code_verify == "0" || userInfo.is_code_verify == 0 || userInfo.is_completed == "0" || userInfo.is_completed == 0) {
                setHaveError(true)
            } else {
                setHaveError(false)
            }
        } else {
            setHaveError(false)
        }
    }

    const getPharmacyProductData = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-pharmacy-product`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_product: id_product,
                id_pharmacy: pharmacyInfo.id_pharmacy
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                if (response.data.result == true) {
                    setProduct({
                        name_product: response.data.items.name_product,
                        price: response.data.items.price,
                        discount_price: response.data.items.discount_price,
                        description: response.data.items.description,
                        start_date: (response.data.items.is_date == "1" || response.data.items.is_date == 1) ? response.data.items.start_date : "",
                        end_date: (response.data.items.is_date == "1" || response.data.items.is_date == 1) ? response.data.items.end_date : ""
                    })
                } else {
                    toast.error(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setHaveError(true)
                }
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const onSelect = (data) => {
        setProduct({ ...product, name_product: data })
    }

    const handleChange = (e) => {
        if (e.target.name == "name_product") {
            setProduct({ ...product, [e.target.name]: capitalizeFirstLowercaseRest(e.target.value) })
        } else if (e.target.name == "price" || e.target.name == "discount_price") {
            setProduct({ ...product, [e.target.name]: setFirstLetterCurrency(e.target.value.replace(/[^0-9€.,\b]+/ig, "")) })
        } else {
            setProduct({ ...product, [e.target.name]: e.target.value })
        }
    }

    const capitalizeFirstLowercaseRest = str => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const setFirstLetterCurrency = str => {
        if (str.length == 1 && str !== '€') {
            return `€${str}`;
        } else {
            return str;
        }
    }

    const getAllDrugs = () => {
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-all-drugs`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN
            })
        })
        .then(function(response) {
            if (response.data.length > 0) {
                setAllDrugs(response.data)
                setFilterDrugs(response.data)
                setLoadDrugs(false)
            } else {
                setLoadDrugs(false)
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const searchDrugs = (drug_string) => {
        const filterDrugs = allDrugs.filter(e => e.label.toUpperCase().match(drug_string.toUpperCase()))
        setFilterDrugs(filterDrugs)
    }

    const editPharmacyProduct = () => {
        if (product.name_product !== "" && product.price !== "" && product.price !== "€") {
            if (product.discount_price !== "" && product.discount_price !== "€") {
                if (product.start_date !== "" && product.start_date !== null && product.end_date == "" || product.end_date == null) {
                    toast.error("Per continuare inserisci la data di fine promozione", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if (product.end_date !== "" && product.end_date !== null && product.start_date == "" || product.start_date == null) {
                    toast.error("Per continuare inserisci la data di inizio promozione", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    setLoading(true)
                    sendPharmacyProduct()
                }
            } else {
                setLoading(true)
                sendPharmacyProduct()
            }
        } else {
            toast.error("Inserisci i campi obbligatori", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const sendPharmacyProduct = () => {
        let is_date = 0;
        let start_date = moment(new Date()).format('YYYY-MM-DD');
        let end_date = moment(new Date()).format('YYYY-MM-DD');
        if (product.discount_price !== "" && product.discount_price !== "€") {
            if (product.start_date !== "" && product.start_date !== null) {
                start_date = moment(product.start_date).format('YYYY-MM-DD');
            }
            if (product.end_date !== "" && product.end_date !== null) {
                end_date = moment(product.end_date).format('YYYY-MM-DD');
            }
            if (product.start_date !== "" && product.start_date !== null && product.end_date !== "" && product.end_date !== null) {
                is_date = 1;
            }
        }
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/edit-pharmacy-product`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_product: id_product,
                name_product: product.name_product,
                description: product.description,
                price: product.price,
                discount_price: product.discount_price,
                start_date: start_date,
                end_date: end_date,
                is_date: is_date
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                toast.success(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setLoading(false)
                setTimeout(() => {
                    navigate('/promozioni')
                }, 1000);
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setLoading(false)
            }
        })
        .catch(function(error) {
            console.error(error);
            setLoading(false)
        });
    }

    return(
        <>
        <ToastContainer />
        <Helmet>
            <title>Modifica Prodotto | Takeiteasy Partners</title>
        </Helmet>
        <Navbar />
        <div className="app-content content">
            <div className="content-overlay"></div>
            <div className="content-wrapper">
                <Errors />
                <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2">
                        <h3 className="content-header-title">Modifica prodotto</h3>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/promozioni">Promozioni</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Modifica prodotto</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                <div className="content-body">
                    <section className="row">
                        <div className="col-12">
                            <div className="card">
                                {(haveError)
                                    ?
                                    <Empty
                                        description={
                                            <span>
                                                {message}
                                            </span>
                                        }
                                        className="py-3"
                                    />
                                    :
                                    <>
                                    <div className="card-content">
                                        <div className="card-body">
                                            <label style={{ color: '#2b335e' }}>Seleziona un farmaco tra quelli deisponibili</label>
                                            {(loadDrugs)
                                                ?
                                                <div className='px-2 py-1'>
                                                    <Skeleton active={true} />
                                                </div>
                                                :
                                                <div className='pb-2'>
                                                    <AutoComplete
                                                        options={filterDrugs}
                                                        style={{
                                                            width: 400,
                                                        }}
                                                        onSelect={onSelect}
                                                        onSearch={(text) => searchDrugs(text)}
                                                        placeholder="Cerca il nome del farmaco"
                                                    />
                                                </div>
                                            }
                                            <form>
                                                <fieldset>
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label htmlFor="name_visit">Nome prodotto*</label>
                                                                <input type="text" class="form-control" maxLength={100} value={product.name_product} onChange={handleChange} name="name_product" id="name_product" placeholder='Inserisci il Nome del prodotto se non presente nella lista' required />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label htmlFor="price">Prezzo*</label>
                                                                <input type="text" class="form-control" maxLength={50} value={product.price} onChange={handleChange} name="price" id="price" placeholder='Inserisci il Prezzo del prodotto' required />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label htmlFor="discount_price">Prezzo scontato (Se presente)</label>
                                                                <input type="text" class="form-control" maxLength={50} value={product.discount_price} onChange={handleChange} name="discount_price" id="discount_price" placeholder='Inserisci il Prezzo scontato del prodotto' />
                                                            </div>
                                                        </div>
                                                        {(product.discount_price !== "")
                                                            &&
                                                            <>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label htmlFor="start_date">Data inizio promozione (Se presente)</label>
                                                                    <input type="date" class="form-control" value={product.start_date} onChange={handleChange} name="start_date" id="start_date" placeholder='Inserisci la Data di inizio della promozione' />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label htmlFor="end_date">Data fine promozione (Se presente)</label>
                                                                    <input type="date" class="form-control" value={product.end_date} onChange={handleChange} name="end_date" id="end_date" placeholder='Inserisci la Data di fine della promozione' />
                                                                </div>
                                                            </div>
                                                            </>
                                                        }
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label htmlFor="description">Descrizione</label>
                                                                <textarea class="form-control" maxLength={350} value={product.description} onChange={handleChange} name="description" id="description" placeholder='Inserisci la Descirzione per il prodotto'></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </form>
                                            <fieldset>
                                                <div style={{ marginTop: 24 }} className="float-right">
                                                    <Button type="primary" onClick={(loading) ? null : editPharmacyProduct}>
                                                        {(loading)
                                                            ?
                                                            <ThreeDots 
                                                                height="11" 
                                                                width="23" 
                                                                radius="4.5"
                                                                color="#fff" 
                                                                ariaLabel="three-dots-loading"
                                                                wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
                                                                visible={true}
                                                            />
                                                            :
                                                            'Salva'
                                                        }
                                                    </Button>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                    </>
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default EditProduct;