import React, { useContext } from 'react'
import { Navigate, useLocation } from "react-router-dom";
import { UserContext } from '../routers/UserContext';

const ProtectedRoute = ({children}) => {
    const {userAuth,setUserAuth} = useContext(UserContext)
    let location = useLocation();

    if(!userAuth) {
        return <Navigate to="/login" state={{ from: location }} replace />
    }
    return children

};

export default ProtectedRoute;