import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet";
import * as config from '../config';
import axios from 'axios';
import { Skeleton, Empty, Avatar, Card, Button, Breadcrumb } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Errors from '../components/Errors';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import it from 'react-phone-input-2/lang/it.json';
import { ThreeDots } from 'react-loader-spinner';
import md5 from 'md5';

function EditPassword() {
    const navigate = useNavigate()
    const [haveError, setHaveError] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [userProfile, setUserProfile] = useState({
        old_password: "",
        new_password: ""
    })
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        checkErrors(userInfo)
    }, [])

    const checkErrors = (userInfo) => {
        if (userInfo !== null) {
            if (userInfo.is_code_verify == "0" || userInfo.is_code_verify == 0 || userInfo.is_completed == "0" || userInfo.is_completed == 0) {
                setHaveError(true)
            } else {
                setHaveError(false)
            }
        } else {
            setHaveError(false)
        }
    }

    const handleChange = (e) => {
        setUserProfile({ ...userProfile, [e.target.name]: e.target.value })
    }

    const handleEditPassword = () => {
        setLoading(true)
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        if (userProfile.old_password !== "" && userProfile.new_password !== "") {
            axios({
                method: 'post',
                url: `${config.WEBSITE_URL}/partners/update-user-password`,
                headers: {
                    'Content-Type': 'application/json'
                },
                auth: {
                    username: config.USERNAME_TOKEN,
                    password: config.PASSWORD_TOKEN
                },
                data: JSON.stringify({
                    token_access: config.WEB_TOKEN,
                    id_admin_user: userInfo.id,
                    old_password: md5(userProfile.old_password.trim()),
                    new_password: md5(userProfile.new_password.trim())
                })
            })
            .then(function(response) {
                if (response.data.code == true) {
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(() => {
                        navigate('/profilo')
                    }, 1000);
                    setLoading(false)
                } else {
                    setLoading(false)
                    toast.error(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch(function(error) {
                setLoading(false)
                console.error(error);
            });
        } else {
            setLoading(false)
            toast.error("Compila tutti i campi obbligatori", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    return(
        <>
        <ToastContainer />
        <Helmet>
            <title>Modifica Password | Takeiteasy Partners</title>
        </Helmet>
        <Navbar />
        <div className="app-content content">
            <div className="content-overlay"></div>
            <div className="content-wrapper">
                <Errors />
                <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2">
                        <h3 className="content-header-title">Modifica password</h3>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/profilo">Profilo</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Modifica password</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                <div className="content-body">
                    <section className="row">
                        <div className="col-12">
                            <div className="card">
                                {(haveError)
                                    ?
                                    <Empty
                                        description={
                                            <span>
                                                Risolvi gli errori per poter continuare.
                                            </span>
                                        }
                                    />
                                    :
                                    <>
                                    <div className="card-content">
                                        <div className="card-body">
                                            <form>
                                                <fieldset>
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <label htmlFor="old_password">Vecchia password*</label>
                                                            <div className="form-group position-relative has-icon-left">
                                                                <input type={(showPassword) ? "text" : "password"} class="form-control" value={userProfile.old_password} onChange={handleChange} name="old_password" id="old_password" placeholder='Inserisci la tua Vecchia password' required />
                                                                <div className="form-control-position">
                                                                    <i style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} className={(showPassword) ? "la la-eye-slash" : "la la-eye"}></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label htmlFor="new_password">Nuova password*</label>
                                                            <div className="form-group position-relative has-icon-left">
                                                                <input type={(showNewPassword) ? "text" : "password"} class="form-control" value={userProfile.new_password} onChange={handleChange} name="new_password" id="new_password" placeholder='Inserisci la tua Nuova password' required />
                                                                <div className="form-control-position">
                                                                    <i style={{ cursor: 'pointer' }} onClick={() => setShowNewPassword(!showNewPassword)} className={(showNewPassword) ? "la la-eye-slash" : "la la-eye"}></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                                <fieldset>
                                                    <div style={{ marginTop: 24 }} className="float-right">
                                                        <Button type="primary" onClick={(loading) ? null : handleEditPassword}>
                                                            {(loading)
                                                                ?
                                                                <ThreeDots 
                                                                    height="11" 
                                                                    width="23" 
                                                                    radius="4.5"
                                                                    color="#fff" 
                                                                    ariaLabel="three-dots-loading"
                                                                    wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
                                                                    visible={true}
                                                                />
                                                                :
                                                                'Salva'
                                                            }
                                                        </Button>
                                                    </div>
                                                </fieldset>
                                            </form>
                                        </div>
                                    </div>
                                    </>
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default EditPassword;