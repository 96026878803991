import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet";
import * as config from '../config';
import axios from 'axios';
import { Skeleton, Empty, Avatar, Card, Button, Breadcrumb } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Errors from '../components/Errors';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import it from 'react-phone-input-2/lang/it.json';
import { ThreeDots } from 'react-loader-spinner';

function EditProfile() {
    const navigate = useNavigate()
    const [haveError, setHaveError] = useState(false)
    const [userProfile, setUserProfile] = useState({
        name: "",
        surname: "",
        email: "",
        phone: ""
    })
    const [isLoading, setIsLoading] = useState(true)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        checkErrors(userInfo)
        getUserData(userInfo)
    }, [])

    const checkErrors = (userInfo) => {
        if (userInfo !== null) {
            if (userInfo.is_code_verify == "0" || userInfo.is_code_verify == 0 || userInfo.is_completed == "0" || userInfo.is_completed == 0) {
                setHaveError(true)
            } else {
                setHaveError(false)
            }
        } else {
            setHaveError(false)
        }
    }

    const getUserData = (userInfo) => {
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-user-data`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_admin_user: userInfo.id
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                setUserProfile({
                    name: response.data.name,
                    surname: response.data.surname,
                    email: response.data.email,
                    phone: response.data.phone
                })
                setIsLoading(false)
            } else {
                setIsLoading(false)
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            setIsLoading(false)
            console.error(error);
        });
    }

    const handleChange = (e) => {
        if (e.target.name == "name" || e.target.name == "surname") {
            setUserProfile({ ...userProfile, [e.target.name]: capitalizeFirstLowercaseRest(e.target.value) })
        } else if (e.target.name == "email") {
            setUserProfile({ ...userProfile, [e.target.name]: e.target.value.toLowerCase() })
        } else {
            setUserProfile({ ...userProfile, [e.target.name]: e.target.value })
        }
    }

    const capitalizeFirstLowercaseRest = str => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const handleEditProfile = () => {
        setLoading(true)
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        if (userProfile.name !== "" && userProfile.surname !== "" && userProfile.email !== "" && userProfile.phone !== "") {
            axios({
                method: 'post',
                url: `${config.WEBSITE_URL}/partners/update-user-profile`,
                headers: {
                    'Content-Type': 'application/json'
                },
                auth: {
                    username: config.USERNAME_TOKEN,
                    password: config.PASSWORD_TOKEN
                },
                data: JSON.stringify({
                    token_access: config.WEB_TOKEN,
                    id_admin_user: userInfo.id,
                    name: userProfile.name.trim(),
                    surname: userProfile.surname.trim(),
                    email: userProfile.email.trim(),
                    phone: userProfile.phone
                })
            })
            .then(function(response) {
                if (response.data.code == true) {
                    const newUserInfo = { ...userInfo, name: userProfile.name.trim(), surname: userProfile.surname.trim(), email: userProfile.email.trim() }
                    localStorage.setItem('user_info', JSON.stringify(newUserInfo))
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(() => {
                        navigate('/profilo')
                    }, 1000);
                    setLoading(false)
                } else {
                    setLoading(false)
                    toast.error(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch(function(error) {
                setLoading(false)
                console.error(error);
            });
        } else {
            setLoading(false)
            toast.error("Compila tutti i campi obbligatori", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    return(
        <>
        <ToastContainer />
        <Helmet>
            <title>Modifica Profilo | Takeiteasy Partners</title>
        </Helmet>
        <Navbar />
        <div className="app-content content">
            <div className="content-overlay"></div>
            <div className="content-wrapper">
                <Errors />
                <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2">
                        <h3 className="content-header-title">Modifica profilo</h3>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/profilo">Profilo</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Modifica profilo</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                <div className="content-body">
                    <section className="row">
                        <div className="col-12">
                            <div className="card">
                                {(haveError)
                                    ?
                                    <Empty
                                        description={
                                            <span>
                                                Risolvi gli errori per poter continuare.
                                            </span>
                                        }
                                    />
                                    :
                                    <>
                                    <div className="card-content">
                                        <div className="card-body">
                                            {(isLoading)
                                                ?
                                                <Skeleton active />
                                                :
                                                <form>
                                                    <fieldset>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label htmlFor="name">Nome*</label>
                                                                    <input type="text" class="form-control" value={userProfile.name} onChange={handleChange} name="name" id="name" placeholder='Inserisci il tuo Nome' required />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label htmlFor="surname">Cognome*</label>
                                                                    <input type="text" class="form-control" value={userProfile.surname} onChange={handleChange} name="surname" id="surname" placeholder='Inserisci il tuo Cognome' required />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label htmlFor="email">Indirizzo e-mail*</label>
                                                                    <input type="email" class="form-control" value={userProfile.email} onChange={handleChange} name="email" id="email" placeholder='Inserisci il tuo Indirizzo e-mail' required />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label htmlFor='phone'>Numero di telefono*</label>
                                                                    <PhoneInput
                                                                        placeholder='Insrisci il tuo Numero di telefono'
                                                                        country={'it'}
                                                                        localization={it}
                                                                        regions={'europe'}
                                                                        inputClass="form-control"
                                                                        inputStyle={{ width: '100%' }}
                                                                        disableSearchIcon={true}
                                                                        searchPlaceholder="Cerca"
                                                                        inputProps={{
                                                                            name: 'phone',
                                                                            required: true,
                                                                            id: 'phone'
                                                                        }}
                                                                        countryCodeEditable={false}
                                                                        enableSearch={true}
                                                                        value={userProfile.phone}
                                                                        onChange={(phone) => setUserProfile({ ...userProfile, phone: phone })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    <fieldset>
                                                        <div style={{ marginTop: 24 }} className="float-right">
                                                            <Button type="primary" onClick={(loading) ? null : handleEditProfile}>
                                                                {(loading)
                                                                    ?
                                                                    <ThreeDots 
                                                                        height="11" 
                                                                        width="23" 
                                                                        radius="4.5"
                                                                        color="#fff" 
                                                                        ariaLabel="three-dots-loading"
                                                                        wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
                                                                        visible={true}
                                                                    />
                                                                    :
                                                                    'Salva'
                                                                }
                                                            </Button>
                                                        </div>
                                                    </fieldset>
                                                </form>
                                            }
                                        </div>
                                    </div>
                                    </>
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default EditProfile;