import React, { useEffect, useState } from 'react';
import { Alert, Button, Space } from 'antd';
import { useNavigate } from 'react-router-dom';

function Errors() {
    const navigate = useNavigate()
    const [typeError, setTypeError] = useState(null)
    const [typeErrorPharmacy, setTypeErrorPharmacy] = useState(null)
    const [idPharmacy, setIdPharmacy] = useState(null)

    useEffect(() => {
        const pharmacy = JSON.parse(localStorage.getItem('pharmacy'))
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        if (userInfo !== null) {
            if (userInfo.is_code_verify == "0" || userInfo.is_code_verify == 0) {
                setTypeError(1)
            } else if (userInfo.is_completed == "0" || userInfo.is_completed == 0) {
                setTypeError(2)
            } else {
                setTypeError(null)
            }
        } else {
            setTypeError(null)
        }
        if (pharmacy !== null) {
            setIdPharmacy(pharmacy.id_pharmacy)
            if (pharmacy.is_verificated == "0" || pharmacy.is_verificated == 0) {
                setTypeErrorPharmacy(1)
            } else {
                setTypeErrorPharmacy(null)
            }
        } else {
            setTypeErrorPharmacy(null)
        }
    }, [])

    return(
        (typeError !== null || typeErrorPharmacy !== null)
            ?
            <Space
                direction="vertical"
                style={{
                    width: '100%',
                }}
                className="mb-2"
            >
                {(typeError == 1)
                    ?
                    <Alert
                        message="Non hai verificato la tua e-mail"
                        showIcon
                        description="Verifica subito il tuo indirizzo di posta elettronica per continuare ad utilizzare l'account"
                        type="error"
                        action={
                            <Button size="middle" danger onClick={() => navigate('/account/verifica')}>
                                Verifica
                            </Button>
                        }
                    />
                    :
                    (typeError == 2)
                        ?
                        <Alert
                            message="Non hai completato il tuo profilo"
                            showIcon
                            description="Completa il tuo profilo per iniziare ad utilizzare Takeiteasy nella tua farmacia"
                            type="error"
                            action={
                                <Button size="middle" danger onClick={() => navigate('/completa/profilo')}>
                                    Completa profilo
                                </Button>
                            }
                        />
                        :
                        null
                }
                {(typeErrorPharmacy == 1)
                    ?
                    <Alert
                        message="Non hai verificato la tua Farmacia"
                        showIcon
                        description="Verifica subito la tua farmacia per renderla visibili agli utenti Takeiteasy"
                        type="error"
                        action={
                            <Button size="middle" danger onClick={() => navigate(`/verifica/farmacia/${idPharmacy}`)}>
                                Verifica
                            </Button>
                        }
                    />
                    :
                    null
                }
            </Space>
            :
            null
    )
}

export default Errors;