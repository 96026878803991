import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet";
import { Result, Button as ButtonAntd, Avatar, Card, Badge } from 'antd';
import * as config from '../config.js';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThreeDots } from 'react-loader-spinner';
import { LoadingOutlined, CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';

function PaymentComplete() {
    const { id_plan, id_pharmacy, id_session } = useParams()
    const navigate = useNavigate()
    const [loadResult, setLoadResult] = useState(true)
    const [messageTitle, setMessageTitle] = useState("Stiamo attivando la tua farmacia")
    const [messageDesc, setMessageDesc] = useState("Non chiudere questa pagina")
    const [hasError, setHasError] = useState(false)
    const [pageError, setPageError] = useState(false)

    useEffect(() => {
        checkParamaters()
    }, [])

    const checkParamaters = () => {
        if (id_plan !== null && id_plan !== undefined && id_pharmacy !== null && id_pharmacy !== undefined && id_session !== null && id_session !== undefined) {
            completePaymentSubscription()
        } else {
            setMessageTitle("Dati mancanti")
            setMessageDesc("La pagina che stai tentando di caricare ha dei dati mancanti")
            setHasError(true)
            setPageError(true)
            setLoadResult(false)
        }
    }

    const reloadFunction = () => {
        setTimeout(() => {
            setLoadResult(true)
            setTimeout(() => {
                completePaymentSubscription()
            }, 1000);
        }, 1500);
    }

    const completePaymentSubscription = () => {
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/add-pharmacy-subscription`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_plan: id_plan,
                id_pharmacy: id_pharmacy,
                id_session: id_session
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                setMessageTitle(response.data.title)
                setMessageDesc(response.data.message)
                setHasError(false)
                setLoadResult(false)
            } else {
                setMessageTitle(response.data.title)
                setMessageDesc(response.data.message)
                setHasError(true)
                setLoadResult(false)
            }
        })
        .catch(function(error) {
            setMessageTitle("Qualcosa è andato storto")
            setMessageDesc("Stiamo recuperando le informazioni. Attendi e non ricaricare la pagina")
            setHasError(true)
            setLoadResult(false)
            reloadFunction()
            console.error(error);
        });
    }

    return(
        <>
        <Helmet>
            <title>Pagamento Completato | Takeiteasy Partners</title>
        </Helmet>
        <ToastContainer />
        <Navbar />
        <div className="app-content content">
            <div className="content-overlay"></div>
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2">
                        <h3 className="content-header-title">Pagamento completato</h3>
                    </div>
                </div>
                <div className="content-body">
                    <section className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <Result
                                            icon={(loadResult) ? <LoadingOutlined /> : (hasError) ? <CloseCircleFilled /> : <CheckCircleFilled />}
                                            status={(loadResult) ? "info" : (hasError) ? "error" : "success"}
                                            title={messageTitle}
                                            subTitle={messageDesc}
                                            extra={
                                                (!loadResult)
                                                    ?
                                                    (hasError)
                                                        ?
                                                        (pageError)
                                                            ?
                                                            <ButtonAntd type="primary" onClick={() => navigate('/')}>
                                                                Vai alla Dashboard
                                                            </ButtonAntd>
                                                            :
                                                            <ButtonAntd type="primary">
                                                                Contatta l'Assistenza
                                                            </ButtonAntd>
                                                        :
                                                        <ButtonAntd type="primary" onClick={() => navigate('/')}>
                                                            Vai alla Dashboard
                                                        </ButtonAntd>
                                                    :
                                                    null
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default PaymentComplete;