import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet";
import * as config from '../config';
import axios from 'axios';
import { Skeleton, Empty, Avatar, Card, Button } from 'antd';
import { EditOutlined, CheckOutlined } from '@ant-design/icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Errors from '../components/Errors';

function Profile() {
    const navigate = useNavigate()
    const [haveError, setHaveError] = useState(false)

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        checkErrors(userInfo)
    }, [])

    const checkErrors = (userInfo) => {
        if (userInfo !== null) {
            if (userInfo.is_code_verify == "0" || userInfo.is_code_verify == 0 || userInfo.is_completed == "0" || userInfo.is_completed == 0) {
                setHaveError(true)
            } else {
                setHaveError(false)
            }
        } else {
            setHaveError(false)
        }
    }

    return(
        <>
        <ToastContainer />
        <Helmet>
            <title>Profilo | Takeiteasy Partners</title>
        </Helmet>
        <Navbar />
        <div className="app-content content">
            <div className="content-overlay"></div>
            <div className="content-wrapper">
                <Errors />
                <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2">
                        <h3 className="content-header-title">Gestisci profilo</h3>
                    </div>
                </div>
                <div className="content-body">
                    <section className="row">
                        <div className="col-12">
                            <div className="card">
                                {(haveError)
                                    ?
                                    <Empty
                                        description={
                                            <span>
                                                Risolvi gli errori per poter continuare.
                                            </span>
                                        }
                                    />
                                    :
                                    <>
                                    <div className="card-content">
                                        <div className="card-body">
                                            <div className='row px-1'>
                                                <div className='col-lg-4 col-md-6 col-12'>
                                                    <Button onClick={() => navigate('/modifica/profilo')} className='mb-1 d-flex align-items-center justify-content-between px-2 py-3' size='large' block>
                                                        <div className='d-flex align-items-center'>
                                                            <i className='ft-user mr-1'></i>
                                                            Modifica Profilo
                                                        </div>
                                                        <i className='ft-chevron-right'></i>
                                                    </Button>
                                                    <Button onClick={() => navigate('/modifica/fatturazione')} className='mb-1 d-flex align-items-center justify-content-between px-2 py-3' size='large' block>
                                                        <div className='d-flex align-items-center'>
                                                            <i className='ft-paperclip mr-1'></i>
                                                            Modifica Fatturazione
                                                        </div>
                                                        <i className='ft-chevron-right'></i>
                                                    </Button>
                                                    <Button onClick={() => navigate('/modifica/password')} className='d-flex align-items-center justify-content-between px-2 py-3' size='large' block>
                                                        <div className='d-flex align-items-center'>
                                                            <i className='ft-lock mr-1'></i>
                                                            Modifica Password
                                                        </div>
                                                        <i className='ft-chevron-right'></i>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default Profile;