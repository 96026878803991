import React, { useEffect, useState, useContext } from 'react';
import { json, Link, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { ThreeDots } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../assets/images/logo-header-takeiteasy.png';
import md5 from 'md5';
import * as config from '../config';
import axios from 'axios';
import { UserContext } from '../routers/UserContext';

function Login() {
    const {userAuth,setUserAuth} = useContext(UserContext)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [user, setUser] = useState({
        email: "",
        password: ""
    })

    useEffect(() => {
        document.body.setAttribute("class","vertical-layout vertical-menu bg-cyan bg-lighten-2 1-column blank-page");
        document.body.setAttribute("data-col","1-column");

        return () => {
            document.body.setAttribute("class","vertical-layout vertical-menu 2-columns fixed-navbar");
            document.body.setAttribute("data-col","2-columns");
        };
    }, [])

    const handleChange = (e) => {
        if (e.target.name == "email") {
            setUser({ ...user, [e.target.name]: e.target.value.toLowerCase() })
        } else {
            setUser({ ...user, [e.target.name]: e.target.value })
        }
    }

    const loginAccount = (event) => {
        event.preventDefault();
        setLoading(true)
        if (user.email !== "" && user.password !== "") {
            const password = md5(user.password);
            axios({
                method: 'post',
                url: `${config.WEBSITE_URL}/partners/login`,
                headers: {
                    'Content-Type': 'application/json'
                },
                auth: {
                    username: config.USERNAME_TOKEN,
                    password: config.PASSWORD_TOKEN
                },
                data: JSON.stringify({
                    token_access: config.WEB_TOKEN,
                    email: user.email.trim(),
                    password: password
                })
            })
            .then(function(response) {
                if (response.data.code == true) {
                    localStorage.setItem('user_info', JSON.stringify({
                        id: response.data.id,
                        name: response.data.name,
                        surname: response.data.surname,
                        email: response.data.email,
                        is_employee: response.data.is_employee,
                        is_code_verify: response.data.is_code_verify,
                        is_completed: response.data.is_completed
                    }))
                    setUserAuth(true)
                    localStorage.setItem('isAuth', true)
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (response.data.pharmacies.length > 0) {
                        localStorage.setItem('pharmacies_info', JSON.stringify(response.data.pharmacies))
                        localStorage.setItem('pharmacy', JSON.stringify(response.data.pharmacies[0]))
                    }
                    if (response.data.is_code_verify == "0" || response.data.is_code_verify == 0) {
                        setTimeout(() => {
                            navigate('/account/verifica')
                        }, 1000);
                    } else if (response.data.is_completed == "0" || response.data.is_completed == 0 || response.data.is_completed == null) {
                        setTimeout(() => {
                            navigate('/completa/profilo')
                        }, 1000);
                    } else {
                        setTimeout(() => {
                            navigate('/')
                        }, 1000);
                    }
                    setLoading(false)
                } else {
                    toast.error(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setLoading(false)
                }
            })
            .catch(function(error) {
                console.error(error);
                setLoading(false)
            });
        } else {
            toast.error("Compila tutti i campi obbligatori", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false)
        }
    }

    return(
        <>
        <ToastContainer />
        <Helmet>
            <title>Accedi | Takeiteasy Partners</title>
        </Helmet>
        <div className="app-content content">
            <div className="content-overlay"></div>
            <div className="content-wrapper">
                <div className="content-header row">
                </div>
                <div className="content-body">
                    <section className="row flexbox-container">
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            <div className="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
                                <div className="card border-grey border-lighten-3 m-0">
                                    <div className="card-header border-0">
                                        <div className="card-title text-center">
                                            <div className="p-1"><img src={logo} style={{ width: '65%' }} /></div>
                                        </div>
                                    </div>
                                    <div className="card-content">
                                        <p className="card-subtitle line-on-side text-muted text-center font-small-3 mx-2">
                                            <span>Accedi per gestire la tua farmacia</span>
                                        </p>
                                        <div className="card-body pt-0">
                                            <form className="form-horizontal" onSubmit={loginAccount}>
                                                <fieldset className="form-group position-relative has-icon-left">
                                                    <input type="email" className="form-control" onChange={handleChange} value={user.email} name="email" id="email" placeholder="Email" required />
                                                    <div className="form-control-position">
                                                        <i className="la la-envelope"></i>
                                                    </div>
                                                </fieldset>
                                                <fieldset className="form-group position-relative has-icon-left">
                                                    <input type={(showPassword) ? "text" : "password"} className="form-control" onChange={handleChange} value={user.password} name="password" id="password" placeholder="Password" required />
                                                    <div className="form-control-position">
                                                        <i style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} className={(showPassword) ? "la la-eye-slash" : "la la-eye"}></i>
                                                    </div>
                                                </fieldset>
                                                <div className="form-group row">
                                                    <div className="col-sm-6 col-12 text-center text-sm-left pr-0">
                                                    </div>
                                                    <div className="col-sm-6 col-12 float-sm-left text-center text-sm-right">
                                                        <Link className="card-link" to="/password/forgot">
                                                            Hai dimenticato la Password?
                                                        </Link>
                                                    </div>
                                                </div>
                                                <button type="submit" disabled={loading} className="btn btn-info btn-lg btn-block">
                                                    {(loading)
                                                        ?
                                                        <ThreeDots 
                                                            height="22" 
                                                            width="46" 
                                                            radius="9"
                                                            color="#fff" 
                                                            ariaLabel="three-dots-loading"
                                                            wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
                                                            visible={true}
                                                        />
                                                        :
                                                        <>
                                                        <i className="ft-unlock"></i> Accedi 
                                                        </>
                                                    }
                                                </button>
                                            </form>
                                        </div>
                                        <p className="card-subtitle line-on-side text-muted text-center font-small-3 mx-2 my-1">
                                            <span>Nuovo su Takeiteasy?</span>
                                        </p>
                                        <div className="card-body">
                                            <Link className="btn btn-success btn-block" to="/signup">
                                                <i className="la la-user"></i>
                                                Registrati
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
        </div>
        </>
    )
}

export default Login;