import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo-header-takeiteasy.png';
import icon from '../assets/images/icona-takeiteasy.png';
import avatar from '../assets/images/avatar-sito-takeiteasy.png';
import { UserContext } from '../routers/UserContext';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Select, Modal } from 'antd';
import * as config from '../config';
import axios from 'axios';

const { confirm } = Modal;

function Navbar() {
    const {userAuth,setUserAuth} = useContext(UserContext)
    const location = useLocation()
    const navigate = useNavigate()
    const [url, setUrl] = useState('')
    const [openMenu, setOpenMenu] = useState(false)
    const [user, setUser] = useState({})
    const [pharmacies, setPharmacies] = useState([])
    const [pharmacy, setPharmacy] = useState(null)
    const [isActiveVisite, setIsActiveVisite] = useState(false)
    const [showMenu, setShowMenu] = useState(false)

    useEffect(() => {
        setUrl(location.pathname);
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        if (userInfo !== null) {
            setUser(userInfo)
        }
        const pharmaciesInfo = JSON.parse(localStorage.getItem('pharmacies_info'))
        const pharmacy = JSON.parse(localStorage.getItem('pharmacy'))
        if (pharmaciesInfo !== null && pharmacy !== null) {
            setShowMenu(true)
            setPharmacies(pharmaciesInfo)
            setPharmacy(pharmacy.id_pharmacy)
        }
        checkPharmacyBilling()
    }, [location])

    const checkPharmacyBilling = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        if (pharmacyInfo !== null) {
            axios({
                method: 'post',
                url: `${config.WEBSITE_URL}/partners/check-pharmacy-has-visits`,
                headers: {
                    'Content-Type': 'application/json'
                },
                auth: {
                    username: config.USERNAME_TOKEN,
                    password: config.PASSWORD_TOKEN
                },
                data: JSON.stringify({
                    token_access: config.WEB_TOKEN,
                    id_pharmacy: pharmacyInfo.id_pharmacy
                })
            })
            .then(function(response) {
                if (response.data.code == true) {
                    setIsActiveVisite(response.data.has_visits)
                }
            })
            .catch(function(error) {
                console.error(error);
            });
        }
    }

    const clickMenu = () => {
        setOpenMenu(!openMenu)
    }

    const closeMenuMobile = () => {
        document.getElementById("menuToggleIcon").click();
    }

    const logoutAccount = () => {
        confirm({
            title: 'ATTENZIONE',
            icon: <ExclamationCircleFilled />,
            content: 'Sei sicuro di voler uscire dal tuo account?',
            onOk() {
                logoutConfirm()
            },
            okText: "Esci",
            cancelText: "Annulla"
        });
    }

    const logoutConfirm = () => {
        setUserAuth(false)
        localStorage.removeItem('isAuth')
        localStorage.removeItem('user_info')
        localStorage.removeItem('pharmacies_info')
        localStorage.removeItem('pharmacy')
    }

    const handleChange = (value) => {
        const pharmaciesInfo = JSON.parse(localStorage.getItem('pharmacies_info'))
        const pharmacyIndex = pharmaciesInfo.findIndex(element => element.id_pharmacy == value)
        if (pharmacyIndex != -1) {
            setPharmacy(value)
            localStorage.setItem('pharmacy', JSON.stringify({ id_pharmacy: pharmaciesInfo[pharmacyIndex].id_pharmacy, name_pharmacy: pharmaciesInfo[pharmacyIndex].name_pharmacy, is_active: pharmaciesInfo[pharmacyIndex].is_active, is_verificated: pharmaciesInfo[pharmacyIndex].is_verificated }))
            window.location.reload()
        }
    }

    return(
        <>
        <nav className="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-semi-light bg-info navbar-shadow">
            <div className="navbar-wrapper">
                <div className="navbar-header">
                    <ul className="nav navbar-nav flex-row">
                        <li className="nav-item mobile-menu d-md-none mr-auto"><a id='menuToggleIcon' className="nav-link nav-menu-main menu-toggle hidden-xs" href="#"><i className="ft-menu font-large-1"></i></a></li>
                        <li className="nav-item">
                            <Link to="/" className="navbar-brand">
                                {(openMenu)
                                    ?
                                    <img className="brand-logo-icon" src={icon} />
                                    :
                                    <img className="brand-logo" src={logo} />
                                }
                            </Link>
                        </li>
                        <li className="nav-item d-md-none"><a className="nav-link open-navbar-container" data-toggle="collapse" data-target="#navbar-mobile"><i className="la la-ellipsis-v"></i></a></li>
                    </ul>
                </div>
                <div className="navbar-container content">
                    <div className="collapse navbar-collapse" id="navbar-mobile">
                        <ul className="nav navbar-nav mr-auto float-left">
                            <li className="nav-item d-none d-md-block">
                                <a onClick={clickMenu} className="nav-link nav-menu-main menu-toggle hidden-xs" href="#">
                                    <i className="ft-menu"></i>
                                </a>
                            </li>
                            {(user.is_completed == "1" || user.is_completed == 1)
                                ?
                                (pharmacies.length > 0)
                                    ?
                                    <li className="nav-item d-flex align-items-center">
                                        <Select
                                            value={pharmacy}
                                            style={{
                                                width: 220,
                                            }}
                                            onChange={handleChange}
                                            options={pharmacies.map((item, index) => {
                                                return { value: item.id_pharmacy, label: item.name_pharmacy }
                                            })}
                                        />
                                    </li>
                                    :
                                    null
                                :
                                null
                            }
                        </ul>
                        <ul className="nav navbar-nav float-right">
                            {/*<li className="dropdown dropdown-notification nav-item"><a className="nav-link nav-link-label" href="#" data-toggle="dropdown"><i className="ficon ft-bell"></i><span className="badge badge-pill badge-danger badge-up badge-glow">5</span></a>
                                <ul className="dropdown-menu dropdown-menu-media dropdown-menu-right">
                                    <li className="dropdown-menu-header">
                                        <h6 className="dropdown-header m-0"><span className="grey darken-2">Notifications</span></h6><span className="notification-tag badge badge-danger float-right m-0">5 New</span>
                                    </li>
                                    <li className="scrollable-container media-list w-100"><a href="javascript:void(0)">
                                            <div className="media">
                                                <div className="media-left align-self-center"><i className="ft-plus-square icon-bg-circle bg-cyan mr-0"></i></div>
                                                <div className="media-body">
                                                    <h6 className="media-heading">You have new order!</h6>
                                                    <p className="notification-text font-small-3 text-muted">Lorem ipsum dolor sit amet, consectetuer elit.</p><small>
                                                        <time className="media-meta text-muted" dateTime="2015-06-11T18:29:20+08:00">30 minutes ago</time></small>
                                                </div>
                                            </div>
                                        </a><a href="javascript:void(0)">
                                            <div className="media">
                                                <div className="media-left align-self-center"><i className="ft-download-cloud icon-bg-circle bg-red bg-darken-1 mr-0"></i></div>
                                                <div className="media-body">
                                                    <h6 className="media-heading red darken-1">99% Server load</h6>
                                                    <p className="notification-text font-small-3 text-muted">Aliquam tincidunt mauris eu risus.</p><small>
                                                        <time className="media-meta text-muted" dateTime="2015-06-11T18:29:20+08:00">Five hour ago</time></small>
                                                </div>
                                            </div>
                                        </a><a href="javascript:void(0)">
                                            <div className="media">
                                                <div className="media-left align-self-center"><i className="ft-alert-triangle icon-bg-circle bg-yellow bg-darken-3 mr-0"></i></div>
                                                <div className="media-body">
                                                    <h6 className="media-heading yellow darken-3">Warning notifixation</h6>
                                                    <p className="notification-text font-small-3 text-muted">Vestibulum auctor dapibus neque.</p><small>
                                                        <time className="media-meta text-muted" dateTime="2015-06-11T18:29:20+08:00">Today</time></small>
                                                </div>
                                            </div>
                                        </a><a href="javascript:void(0)">
                                            <div className="media">
                                                <div className="media-left align-self-center"><i className="ft-check-circle icon-bg-circle bg-cyan mr-0"></i></div>
                                                <div className="media-body">
                                                    <h6 className="media-heading">Complete the task</h6><small>
                                                        <time className="media-meta text-muted" dateTime="2015-06-11T18:29:20+08:00">Last week</time></small>
                                                </div>
                                            </div>
                                        </a><a href="javascript:void(0)">
                                            <div className="media">
                                                <div className="media-left align-self-center"><i className="ft-file icon-bg-circle bg-teal mr-0"></i></div>
                                                <div className="media-body">
                                                    <h6 className="media-heading">Generate monthly report</h6><small>
                                                        <time className="media-meta text-muted" dateTime="2015-06-11T18:29:20+08:00">Last month</time></small>
                                                </div>
                                            </div>
                                        </a></li>
                                    <li className="dropdown-menu-footer"><a className="dropdown-item text-muted text-center" href="javascript:void(0)">Read all notifications</a></li>
                                </ul>
                            </li>*/}
                            {(user !== null)
                                ?
                                (user.is_code_verify == "0" || user.is_code_verify == 0)
                                    ?
                                    <li className="align-items-center d-flex nav-item">
                                        <Alert variant='danger' className='mb-0'>
                                            Il tuo indirizzo email non è stato verificato.<br/>
                                            <Link className='alert-link' to="/account/verifica">Verifica ora</Link>
                                        </Alert>
                                    </li>
                                    :
                                    null
                                :
                                null
                            }
                            <li className="dropdown dropdown-user nav-item">
                                <a className="dropdown-toggle nav-link dropdown-user-link" href="#" data-toggle="dropdown">
                                    <span className="mr-1 user-name text-bold-700">{user.name} {user.surname}</span>
                                    <span className="avatar avatar-online">
                                        <img src={avatar} alt="avatar" />
                                    </span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <Link to="/farmacie" className="dropdown-item">
                                        <i className="ft-paperclip"></i> Farmacie
                                    </Link>
                                    <Link to="/profilo" className="dropdown-item">
                                        <i className="ft-user"></i> Modifica profilo
                                    </Link>
                                    <Link to="/fatturazione" className="dropdown-item">
                                        <i className="ft-settings"></i> Fatturazione
                                    </Link>
                                    <a href="https://www.takeiteasy-app.com/privacy-policy-takeiteasy-partners/" target='_blank' className='dropdown-item'>
                                        <i className="ft-lock"></i> Privacy Policy
                                    </a>
                                    <a href="https://www.takeiteasy-app.com/termini-e-condizioni-takeiteasy-partners/" target='_blank' className='dropdown-item'>
                                        <i className="ft-book"></i> Termini e Condizioni
                                    </a>
                                    {/*<Link to="/" className="dropdown-item">
                                        <i className="ft-headphones"></i> Assistenza
                                    </Link>*/}
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item" onClick={logoutAccount}>
                                        <i className="ft-power"></i> Logout
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>

        <div className="main-menu menu-fixed menu-light menu-accordion    menu-shadow " data-scroll-to-active="true">
            <div className="main-menu-content">
                <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
                    {(showMenu)
                        &&
                        <>
                            <li className={(url === '/') ? "active nav-item" : "nav-item"}>
                                <Link to="/">
                                    <i className="la la-home" style={{ marginRight: '12px' }}></i>
                                    <span className="menu-title" data-i18n="Dashboard">Dashboard</span>
                                </Link>
                            </li>
                            {(isActiveVisite)
                                &&
                                <li className={(url === '/dashboard/visite') ? "active nav-item" : "nav-item"}>
                                    <Link to="/dashboard/visite">
                                        <i className="la la-bar-chart" style={{ marginRight: '12px' }}></i>
                                        <span className="menu-title" data-i18n="Dashboard Visite">Dashboard Visite</span>
                                    </Link>
                                </li>
                            }
                            <li className={(url === '/ticket') ? "active nav-item" : "nav-item"}>
                                <Link to="/ticket">
                                    <i className="la la-ticket" style={{ marginRight: '12px' }}></i>
                                    <span className="menu-title" data-i18n="Ticket">Ticket</span>
                                </Link>
                            </li>
                            <li className={(url === '/calendario') ? "active nav-item" : "nav-item"}>
                                <Link to="/calendario">
                                    <i className="la la-calendar" style={{ marginRight: '12px' }}></i>
                                    <span className="menu-title" data-i18n="Calendario">Calendario</span>
                                </Link>
                            </li>
                            <li className={(url === '/contatti') ? "active nav-item" : "nav-item"}>
                                <Link to="/contatti">
                                    <i className="la la-users" style={{ marginRight: '12px' }}></i>
                                    <span className="menu-title" data-i18n="Contatti">Contatti</span>
                                </Link>
                            </li>
                            {(isActiveVisite)
                                &&
                                <li className={(url === '/visite') ? "active nav-item" : "nav-item"}>
                                    <Link to="/visite">
                                        <i className="la la-stethoscope" style={{ marginRight: '12px' }}></i>
                                        <span className="menu-title" data-i18n="Visite">Visite</span>
                                    </Link>
                                </li>
                            }
                        </>
                    }
                    {/*<li className={(url.startsWith('/promozioni')) ? "active nav-item" : "nav-item"}>
                        <Link to="/promozioni">
                            <i className="la la-gift" style={{ marginRight: '12px' }}></i>
                            <span className="menu-title" data-i18n="Promozioni">Promozioni</span>
                        </Link>
                    </li>
                    <li className=" nav-item"><a href="index.html"><i className="la la-home"></i><span className="menu-title" data-i18n="Dashboard">Dashboard</span><span className="badge badge badge-info badge-pill float-right mr-2">3</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="dashboard-ecommerce.html"><i></i><span data-i18n="eCommerce">eCommerce</span></a>
                            </li>
                            <li className="active"><a className="menu-item" href="dashboard-crypto.html"><i></i><span data-i18n="Crypto">Crypto</span></a>
                            </li>
                            <li><a className="menu-item" href="dashboard-sales.html"><i></i><span data-i18n="Sales">Sales</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-television"></i><span className="menu-title" data-i18n="Templates">Templates</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="#"><i></i><span data-i18n="Vertical">Vertical</span></a>
                                <ul className="menu-content">
                                    <li><a className="menu-item" href="../vertical-menu-template"><i></i><span data-i18n="classNameic Menu">classNameic Menu</span></a>
                                    </li>
                                    <li><a className="menu-item" href="../vertical-modern-menu-template"><i></i><span data-i18n="Modern Menu">Modern Menu</span></a>
                                    </li>
                                    <li><a className="menu-item" href="../vertical-collapsed-menu-template"><i></i><span data-i18n="Collapsed Menu">Collapsed Menu</span></a>
                                    </li>
                                    <li><a className="menu-item" href="../vertical-compact-menu-template"><i></i><span data-i18n="Compact Menu">Compact Menu</span></a>
                                    </li>
                                    <li><a className="menu-item" href="../vertical-content-menu-template"><i></i><span data-i18n="Content Menu">Content Menu</span></a>
                                    </li>
                                    <li><a className="menu-item" href="../vertical-overlay-menu-template"><i></i><span data-i18n="Overlay Menu">Overlay Menu</span></a>
                                    </li>
                                </ul>
                            </li>
                            <li><a className="menu-item" href="#"><i></i><span data-i18n="Horizontal">Horizontal</span></a>
                                <ul className="menu-content">
                                    <li><a className="menu-item" href="../horizontal-menu-template"><i></i><span data-i18n="classNameic">classNameic</span></a>
                                    </li>
                                    <li><a className="menu-item" href="../horizontal-menu-template-nav"><i></i><span data-i18n="Full Width">Full Width</span></a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className=" navigation-header"><span>Admin Panels</span><i className="la la-ellipsis-h" data-toggle="tooltip" data-placement="right" data-original-title="Admin Panels"></i>
                    </li>
                    <li className=" nav-item"><a href="../ecommerce-menu-template" target="_blank"><i className="la la-shopping-cart"></i><span className="menu-title" data-i18n="eCommerce">eCommerce</span></a>
                    </li>
                    <li className=" nav-item"><a href="../travel-menu-template" target="_blank"><i className="la la-plane"></i><span className="menu-title" data-i18n="Travel &amp; Booking">Travel &amp; Booking</span></a>
                    </li>
                    <li className=" nav-item"><a href="../hospital-menu-template" target="_blank"><i className="la la-stethoscope"></i><span className="menu-title" data-i18n="Hospital">Hospital</span></a>
                    </li>
                    <li className=" nav-item"><a href="../crypto-menu-template" target="_blank"><i className="la la-bitcoin"></i><span className="menu-title" data-i18n="Crypto">Crypto</span></a>
                    </li>
                    <li className=" nav-item"><a href="../support-menu-template" target="_blank"><i className="la la-tag"></i><span className="menu-title" data-i18n="Support Ticket">Support Ticket</span></a>
                    </li>
                    <li className=" nav-item"><a href="../bank-menu-template" target="_blank"><i className="la la-bank"></i><span className="menu-title" data-i18n="Bank">Bank</span></a>
                    </li>
                    <li className=" navigation-header"><span>Apps</span><i className="la la-ellipsis-h" data-toggle="tooltip" data-placement="right" data-original-title="Apps"></i>
                    </li>
                    <li className=" nav-item"><a href="app-todo.html"><i className="la la-check-square"></i><span className="menu-title" data-i18n="ToDo">ToDo</span></a>
                    </li>
                    <li className=" nav-item"><a href="app-contacts.html"><i className="la la-users"></i><span className="menu-title" data-i18n="Contacts">Contacts</span></a>
                    </li>
                    <li className=" nav-item"><a href="app-email.html"><i className="la la-envelope"></i><span className="menu-title" data-i18n="Email">Email</span></a>
                    </li>
                    <li className=" nav-item"><a href="app-chat.html"><i className="la la-comments"></i><span className="menu-title" data-i18n="Chat">Chat</span></a>
                    </li>
                    <li className=" nav-item"><a href="app-kanban.html"><i className="la la-file-text"></i><span className="menu-title" data-i18n="Kanban">Kanban</span></a>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-briefcase"></i><span className="menu-title" data-i18n="Project">Project</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="project-summary.html"><i></i><span data-i18n="Project Summary">Project Summary</span></a>
                            </li>
                            <li><a className="menu-item" href="project-tasks.html"><i></i><span data-i18n="Project Task">Project Task</span></a>
                            </li>
                            <li><a className="menu-item" href="project-bugs.html"><i></i><span data-i18n="Project Bugs">Project Bugs</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-calendar"></i><span className="menu-title" data-i18n="Calendars">Calendars</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="#"><i></i><span data-i18n="Full Calendar">Full Calendar</span></a>
                                <ul className="menu-content">
                                    <li><a className="menu-item" href="full-calender-basic.html"><i></i><span data-i18n="Basic">Basic</span></a>
                                    </li>
                                    <li><a className="menu-item" href="full-calender-events.html"><i></i><span data-i18n="Events">Events</span></a>
                                    </li>
                                    <li><a className="menu-item" href="full-calender-advance.html"><i></i><span data-i18n="Advance">Advance</span></a>
                                    </li>
                                    <li><a className="menu-item" href="full-calender-extra.html"><i></i><span data-i18n="Extra">Extra</span></a>
                                    </li>
                                </ul>
                            </li>
                            <li><a className="menu-item" href="calendars-clndr.html"><i></i><span data-i18n="CLNDR">CLNDR</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" navigation-header"><span>Pages</span><i className="la la-ellipsis-h" data-toggle="tooltip" data-placement="right" data-original-title="Pages"></i>
                    </li>
                    <li className=" nav-item"><a href="news-feed.html"><i className="la la-newspaper-o"></i><span className="menu-title" data-i18n="News Feed">News Feed</span></a>
                    </li>
                    <li className=" nav-item"><a href="social-feed.html"><i className="la la-share-alt"></i><span className="menu-title" data-i18n="Social Feed">Social Feed</span></a>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-clipboard"></i><span className="menu-title" data-i18n="Invoice">Invoice</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="invoice-summary.html"><i></i><span data-i18n="Invoice Summary">Invoice Summary</span></a>
                            </li>
                            <li><a className="menu-item" href="invoice-template.html"><i></i><span data-i18n="Invoice Template">Invoice Template</span></a>
                            </li>
                            <li><a className="menu-item" href="invoice-list.html"><i></i><span data-i18n="Invoice List">Invoice List</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-film"></i><span className="menu-title" data-i18n="Timelines">Timelines</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="timeline-center.html"><i></i><span data-i18n="Timelines Center">Timelines Center</span></a>
                            </li>
                            <li><a className="menu-item" href="timeline-left.html"><i></i><span data-i18n="Timelines Left">Timelines Left</span></a>
                            </li>
                            <li><a className="menu-item" href="timeline-right.html"><i></i><span data-i18n="Timelines Right">Timelines Right</span></a>
                            </li>
                            <li><a className="menu-item" href="timeline-horizontal.html"><i></i><span data-i18n="Timelines Horizontal">Timelines Horizontal</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="account-setting.html"><i className="la la-user-plus"></i><span className="menu-title" data-i18n="Account Setting">Account Setting</span></a>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-user"></i><span className="menu-title" data-i18n="Users">Users</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="page-users-list.html"><i></i><span data-i18n="Users List">Users List</span></a>
                            </li>
                            <li><a className="menu-item" href="page-users-view.html"><i></i><span data-i18n="Users View">Users View</span></a>
                            </li>
                            <li><a className="menu-item" href="page-users-edit.html"><i></i><span data-i18n="Users Edit">Users Edit</span></a>
                            </li>
                            <li><a className="menu-item" href="user-profile.html"><i></i><span data-i18n="Users Profile">Users Profile</span></a>
                            </li>
                            <li><a className="menu-item" href="user-cards.html"><i></i><span data-i18n="Users Cards">Users Cards</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-image"></i><span className="menu-title" data-i18n="Gallery">Gallery</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="gallery-grid.html"><i></i><span data-i18n="Gallery Grid">Gallery Grid</span></a>
                            </li>
                            <li><a className="menu-item" href="gallery-grid-with-desc.html"><i></i><span data-i18n="Gallery Grid with Desc">Gallery Grid with Desc</span></a>
                            </li>
                            <li><a className="menu-item" href="gallery-masonry.html"><i></i><span data-i18n="Masonry Gallery">Masonry Gallery</span></a>
                            </li>
                            <li><a className="menu-item" href="gallery-masonry-with-desc.html"><i></i><span data-i18n="Masonry Gallery with Desc">Masonry Gallery with Desc</span></a>
                            </li>
                            <li><a className="menu-item" href="gallery-hover-effects.html"><i></i><span data-i18n="Hover Effects">Hover Effects</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-search"></i><span className="menu-title" data-i18n="Search">Search</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="search-page.html"><i></i><span data-i18n="Search Page">Search Page</span></a>
                            </li>
                            <li><a className="menu-item" href="search-website.html"><i></i><span data-i18n="Search Website">Search Website</span></a>
                            </li>
                            <li><a className="menu-item" href="search-images.html"><i></i><span data-i18n="Search Images">Search Images</span></a>
                            </li>
                            <li><a className="menu-item" href="search-videos.html"><i></i><span data-i18n="Search Videos">Search Videos</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-unlock"></i><span className="menu-title" data-i18n="Authentication">Authentication</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="login-simple.html" target="_blank"><i></i><span data-i18n="Login Simple">Login Simple</span></a>
                            </li>
                            <li><a className="menu-item" href="login-with-bg.html" target="_blank"><i></i><span data-i18n="Login with Bg">Login with Bg</span></a>
                            </li>
                            <li><a className="menu-item" href="login-with-bg-image.html" target="_blank"><i></i><span data-i18n="Login with Bg Image">Login with Bg Image</span></a>
                            </li>
                            <li><a className="menu-item" href="login-with-navbar.html" target="_blank"><i></i><span data-i18n="Login with Navbar">Login with Navbar</span></a>
                            </li>
                            <li><a className="menu-item" href="login-advanced.html" target="_blank"><i></i><span data-i18n="Login Advanced">Login Advanced</span></a>
                            </li>
                            <li><a className="menu-item" href="register-simple.html" target="_blank"><i></i><span data-i18n="Register Simple">Register Simple</span></a>
                            </li>
                            <li><a className="menu-item" href="register-with-bg.html" target="_blank"><i></i><span data-i18n="Register with Bg">Register with Bg</span></a>
                            </li>
                            <li><a className="menu-item" href="register-with-bg-image.html" target="_blank"><i></i><span data-i18n="Register with Bg Image">Register with Bg Image</span></a>
                            </li>
                            <li><a className="menu-item" href="register-with-navbar.html" target="_blank"><i></i><span data-i18n="Register with Navbar">Register with Navbar</span></a>
                            </li>
                            <li><a className="menu-item" href="register-advanced.html" target="_blank"><i></i><span data-i18n="Register Advanced">Register Advanced</span></a>
                            </li>
                            <li><a className="menu-item" href="unlock-user.html" target="_blank"><i></i><span data-i18n="Unlock User">Unlock User</span></a>
                            </li>
                            <li><a className="menu-item" href="recover-password.html" target="_blank"><i></i><span data-i18n="recover-password">recover-password</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-warning"></i><span className="menu-title" data-i18n="Error">Error</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="error-400.html"><i></i><span data-i18n="Error 400">Error 400</span></a>
                            </li>
                            <li><a className="menu-item" href="error-400-with-navbar.html"><i></i><span data-i18n="Error 400 with Navbar">Error 400 with Navbar</span></a>
                            </li>
                            <li><a className="menu-item" href="error-401.html"><i></i><span data-i18n="Error 401">Error 401</span></a>
                            </li>
                            <li><a className="menu-item" href="error-401-with-navbar.html"><i></i><span data-i18n="Error 401 with Navbar">Error 401 with Navbar</span></a>
                            </li>
                            <li><a className="menu-item" href="error-403.html"><i></i><span data-i18n="Error 403">Error 403</span></a>
                            </li>
                            <li><a className="menu-item" href="error-403-with-navbar.html"><i></i><span data-i18n="Error 403 with Navbar">Error 403 with Navbar</span></a>
                            </li>
                            <li><a className="menu-item" href="error-404.html"><i></i><span data-i18n="Error 404">Error 404</span></a>
                            </li>
                            <li><a className="menu-item" href="error-404-with-navbar.html"><i></i><span data-i18n="Error 404 with Navbar">Error 404 with Navbar</span></a>
                            </li>
                            <li><a className="menu-item" href="error-500.html"><i></i><span data-i18n="Error 500">Error 500</span></a>
                            </li>
                            <li><a className="menu-item" href="error-500-with-navbar.html"><i></i><span data-i18n="Error 500 with Navbar">Error 500 with Navbar</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-file-text"></i><span className="menu-title" data-i18n="Others">Others</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="#"><i></i><span data-i18n="Coming Soon">Coming Soon</span></a>
                                <ul className="menu-content">
                                    <li><a className="menu-item" href="coming-soon-flat.html"><i></i><span data-i18n="Flat">Flat</span></a>
                                    </li>
                                    <li><a className="menu-item" href="coming-soon-bg-image.html"><i></i><span data-i18n="Bg image">Bg image</span></a>
                                    </li>
                                    <li><a className="menu-item" href="coming-soon-bg-video.html"><i></i><span data-i18n="Bg video">Bg video</span></a>
                                    </li>
                                </ul>
                            </li>
                            <li><a className="menu-item" href="under-maintenance.html"><i></i><span data-i18n="Maintenance">Maintenance</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="pricing.html"><i className="la la-money"></i><span className="menu-title" data-i18n="Pricing">Pricing</span></a>
                    </li>
                    <li className=" nav-item"><a href="checkout-form.html"><i className="la la-credit-card"></i><span className="menu-title" data-i18n="Checkout">Checkout</span></a>
                    </li>
                    <li className=" nav-item"><a href="faq.html"><i className="la la-question"></i><span className="menu-title" data-i18n="FAQ">FAQ</span></a>
                    </li>
                    <li className=" nav-item"><a href="knowledge-base.html"><i className="la la-database"></i><span className="menu-title" data-i18n="Knowledge Base">Knowledge Base</span></a>
                    </li>
                    <li className=" navigation-header"><span>Layouts</span><i className="la la-ellipsis-h" data-toggle="tooltip" data-placement="right" data-original-title="Layouts"></i>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-columns"></i><span className="menu-title" data-i18n="Page layouts">Page layouts</span><span className="badge badge badge-pill badge-danger float-right mr-2">New</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="layout-1-column.html"><i></i><span data-i18n="1 column">1 column</span></a>
                            </li>
                            <li><a className="menu-item" href="layout-2-columns.html"><i></i><span data-i18n="2 columns">2 columns</span></a>
                            </li>
                            <li><a className="menu-item" href="#"><i></i><span data-i18n="Content Sidebar">Content Sidebar</span></a>
                                <ul className="menu-content">
                                    <li><a className="menu-item" href="layout-content-left-sidebar.html"><i></i><span data-i18n="Left sidebar">Left sidebar</span></a>
                                    </li>
                                    <li><a className="menu-item" href="layout-content-left-sticky-sidebar.html"><i></i><span data-i18n="Left sticky sidebar">Left sticky sidebar</span></a>
                                    </li>
                                    <li><a className="menu-item" href="layout-content-right-sidebar.html"><i></i><span data-i18n="Right sidebar">Right sidebar</span></a>
                                    </li>
                                    <li><a className="menu-item" href="layout-content-right-sticky-sidebar.html"><i></i><span data-i18n="Right sticky sidebar">Right sticky sidebar</span></a>
                                    </li>
                                </ul>
                            </li>
                            <li><a className="menu-item" href="#"><i></i><span data-i18n="Content Det Sidebar">Content Det Sidebar</span></a>
                                <ul className="menu-content">
                                    <li><a className="menu-item" href="layout-content-detached-left-sidebar.html"><i></i><span data-i18n="Detached left sidebar">Detached left sidebar</span></a>
                                    </li>
                                    <li><a className="menu-item" href="layout-content-detached-left-sticky-sidebar.html"><i></i><span data-i18n="Detached sticky left sidebar">Detached sticky left sidebar</span></a>
                                    </li>
                                    <li><a className="menu-item" href="layout-content-detached-right-sidebar.html"><i></i><span data-i18n="Detached right sidebar">Detached right sidebar</span></a>
                                    </li>
                                    <li><a className="menu-item" href="layout-content-detached-right-sticky-sidebar.html"><i></i><span data-i18n="Detached sticky right sidebar">Detached sticky right sidebar</span></a>
                                    </li>
                                </ul>
                            </li>
                            <li className="navigation-divider"></li>
                            <li><a className="menu-item" href="layout-fixed-navbar.html"><i></i><span data-i18n="Fixed navbar">Fixed navbar</span></a>
                            </li>
                            <li><a className="menu-item" href="layout-fixed-navigation.html"><i></i><span data-i18n="Fixed navigation">Fixed navigation</span></a>
                            </li>
                            <li><a className="menu-item" href="layout-fixed-navbar-navigation.html"><i></i><span data-i18n="Fixed navbar &amp; navigation">Fixed navbar &amp; navigation</span></a>
                            </li>
                            <li><a className="menu-item" href="layout-fixed-navbar-footer.html"><i></i><span data-i18n="Fixed navbar &amp; footer">Fixed navbar &amp; footer</span></a>
                            </li>
                            <li className="navigation-divider"></li>
                            <li><a className="menu-item" href="layout-fixed.html"><i></i><span data-i18n="Fixed layout">Fixed layout</span></a>
                            </li>
                            <li><a className="menu-item" href="layout-boxed.html"><i></i><span data-i18n="Boxed layout">Boxed layout</span></a>
                            </li>
                            <li><a className="menu-item" href="layout-static.html"><i></i><span data-i18n="Static layout">Static layout</span></a>
                            </li>
                            <li className="navigation-divider"></li>
                            <li><a className="menu-item" href="layout-light.html"><i></i><span data-i18n="Light layout">Light layout</span></a>
                            </li>
                            <li><a className="menu-item" href="layout-dark.html"><i></i><span data-i18n="Dark layout">Dark layout</span></a>
                            </li>
                            <li><a className="menu-item" href="layout-semi-dark.html"><i></i><span data-i18n="Semi dark layout">Semi dark layout</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-navicon"></i><span className="menu-title" data-i18n="Navbars">Navbars</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="navbar-light.html"><i></i><span data-i18n="Navbar Light">Navbar Light</span></a>
                            </li>
                            <li><a className="menu-item" href="navbar-dark.html"><i></i><span data-i18n="Navbar Dark">Navbar Dark</span></a>
                            </li>
                            <li><a className="menu-item" href="navbar-semi-dark.html"><i></i><span data-i18n="Navbar Semi Dark">Navbar Semi Dark</span></a>
                            </li>
                            <li><a className="menu-item" href="navbar-brand-center.html"><i></i><span data-i18n="Brand Center">Brand Center</span></a>
                            </li>
                            <li><a className="menu-item" href="navbar-fixed-top.html"><i></i><span data-i18n="Fixed Top">Fixed Top</span></a>
                            </li>
                            <li><a className="menu-item" href="#"><i></i><span data-i18n="Hide on Scroll">Hide on Scroll</span></a>
                                <ul className="menu-content">
                                    <li><a className="menu-item" href="navbar-hide-on-scroll-top.html"><i></i><span data-i18n="Hide on Scroll Top">Hide on Scroll Top</span></a>
                                    </li>
                                    <li><a className="menu-item" href="navbar-hide-on-scroll-bottom.html"><i></i><span data-i18n="Hide on Scroll Bottom">Hide on Scroll Bottom</span></a>
                                    </li>
                                </ul>
                            </li>
                            <li><a className="menu-item" href="navbar-components.html"><i></i><span data-i18n="Navbar Components">Navbar Components</span></a>
                            </li>
                            <li><a className="menu-item" href="navbar-styling.html"><i></i><span data-i18n="Navbar Styling">Navbar Styling</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-arrows-v"></i><span className="menu-title" data-i18n="Vertical Nav">Vertical Nav</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="#"><i></i><span data-i18n="Navigation Types">Navigation Types</span></a>
                                <ul className="menu-content">
                                    <li><a className="menu-item" href="../vertical-menu-template"><i></i><span data-i18n="Vertical Menu">Vertical Menu</span></a>
                                    </li>
                                    <li><a className="menu-item" href="../vertical-modern-menu-template"><i></i><span data-i18n="Vertical Modern Menu">Vertical Modern Menu</span></a>
                                    </li>
                                    <li><a className="menu-item" href="../vertical-overlay-menu-template"><i></i><span data-i18n="Vertical Overlay">Vertical Overlay</span></a>
                                    </li>
                                    <li><a className="menu-item" href="../vertical-compact-menu-template"><i></i><span data-i18n="Vertical Compact">Vertical Compact</span></a>
                                    </li>
                                    <li><a className="menu-item" href="../vertical-content-menu-template"><i></i><span data-i18n="Vertical Content">Vertical Content</span></a>
                                    </li>
                                </ul>
                            </li>
                            <li><a className="menu-item" href="vertical-nav-fixed.html"><i></i><span data-i18n="Fixed Navigation">Fixed Navigation</span></a>
                            </li>
                            <li><a className="menu-item" href="vertical-nav-static.html"><i></i><span data-i18n="Static Navigation">Static Navigation</span></a>
                            </li>
                            <li><a className="menu-item" href="vertical-nav-light.html"><i></i><span data-i18n="Navigation Light">Navigation Light</span></a>
                            </li>
                            <li><a className="menu-item" href="vertical-nav-dark.html"><i></i><span data-i18n="Navigation Dark">Navigation Dark</span></a>
                            </li>
                            <li><a className="menu-item" href="vertical-nav-accordion.html"><i></i><span data-i18n="Accordion Navigation">Accordion Navigation</span></a>
                            </li>
                            <li><a className="menu-item" href="vertical-nav-collapsible.html"><i></i><span data-i18n="Collapsible Navigation">Collapsible Navigation</span></a>
                            </li>
                            <li><a className="menu-item" href="vertical-nav-flipped.html"><i></i><span data-i18n="Flipped Navigation">Flipped Navigation</span></a>
                            </li>
                            <li><a className="menu-item" href="vertical-nav-native-scroll.html"><i></i><span data-i18n="Native scroll">Native scroll</span></a>
                            </li>
                            <li><a className="menu-item" href="vertical-nav-right-side-icon.html"><i></i><span data-i18n="Right side icons">Right side icons</span></a>
                            </li>
                            <li><a className="menu-item" href="vertical-nav-bordered.html"><i></i><span data-i18n="Bordered Navigation">Bordered Navigation</span></a>
                            </li>
                            <li><a className="menu-item" href="vertical-nav-disabled-link.html"><i></i><span data-i18n="Disabled Navigation">Disabled Navigation</span></a>
                            </li>
                            <li><a className="menu-item" href="vertical-nav-styling.html"><i></i><span data-i18n="Navigation Styling">Navigation Styling</span></a>
                            </li>
                            <li><a className="menu-item" href="vertical-nav-tags-pills.html"><i></i><span data-i18n="Tags &amp; Pills">Tags &amp; Pills</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-arrows-h"></i><span className="menu-title" data-i18n="Horizontal Nav">Horizontal Nav</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="#"><i></i><span data-i18n="Navigation Types">Navigation Types</span></a>
                                <ul className="menu-content">
                                    <li><a className="menu-item" href="../horizontal-menu-template"><i></i><span data-i18n="Left Icon Navigation">Left Icon Navigation</span></a>
                                    </li>
                                    <li><a className="menu-item" href="../horizontal-menu-template-nav"><i></i><span data-i18n="Top Icon Navigation">Top Icon Navigation</span></a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-header"></i><span className="menu-title" data-i18n="Page Headers">Page Headers</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="headers-breadcrumbs-basic.html"><i></i><span data-i18n="Breadcrumbs basic">Breadcrumbs basic</span></a>
                            </li>
                            <li><a className="menu-item" href="headers-breadcrumbs-top.html"><i></i><span data-i18n="Breadcrumbs top">Breadcrumbs top</span></a>
                            </li>
                            <li><a className="menu-item" href="headers-breadcrumbs-bottom.html"><i></i><span data-i18n="Breadcrumbs bottom">Breadcrumbs bottom</span></a>
                            </li>
                            <li><a className="menu-item" href="headers-breadcrumbs-top-with-description.html"><i></i><span data-i18n="Breadcrumbs top with desc">Breadcrumbs top with desc</span></a>
                            </li>
                            <li><a className="menu-item" href="headers-breadcrumbs-with-button.html"><i></i><span data-i18n="Breadcrumbs with button">Breadcrumbs with button</span></a>
                            </li>
                            <li><a className="menu-item" href="headers-breadcrumbs-with-round-button.html"><i></i><span data-i18n="Breadcrumbs with button 2">Breadcrumbs with button 2</span></a>
                            </li>
                            <li><a className="menu-item" href="headers-breadcrumbs-with-button-group.html"><i></i><span data-i18n="Breadcrumbs with buttons">Breadcrumbs with buttons</span></a>
                            </li>
                            <li><a className="menu-item" href="headers-breadcrumbs-with-description.html"><i></i><span data-i18n="Breadcrumbs with desc">Breadcrumbs with desc</span></a>
                            </li>
                            <li><a className="menu-item" href="headers-breadcrumbs-with-search.html"><i></i><span data-i18n="Breadcrumbs with search">Breadcrumbs with search</span></a>
                            </li>
                            <li><a className="menu-item" href="headers-breadcrumbs-with-stats.html"><i></i><span data-i18n="Breadcrumbs with stats">Breadcrumbs with stats</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-download"></i><span className="menu-title" data-i18n="Footers">Footers</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="footer-light.html"><i></i><span data-i18n="Footer Light">Footer Light</span></a>
                            </li>
                            <li><a className="menu-item" href="footer-dark.html"><i></i><span data-i18n="Footer Dark">Footer Dark</span></a>
                            </li>
                            <li><a className="menu-item" href="footer-transparent.html"><i></i><span data-i18n="Footer Transparent">Footer Transparent</span></a>
                            </li>
                            <li><a className="menu-item" href="footer-fixed.html"><i></i><span data-i18n="Footer Fixed">Footer Fixed</span></a>
                            </li>
                            <li><a className="menu-item" href="footer-components.html"><i></i><span data-i18n="Footer Components">Footer Components</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" navigation-header"><span>General</span><i className="la la-ellipsis-h" data-toggle="tooltip" data-placement="right" data-original-title="General"></i>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-paint-brush"></i><span className="menu-title" data-i18n="Color Palette">Color Palette</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="color-palette-primary.html"><i></i><span data-i18n="Primary palette">Primary palette</span></a>
                            </li>
                            <li><a className="menu-item" href="color-palette-danger.html"><i></i><span data-i18n="Danger palette">Danger palette</span></a>
                            </li>
                            <li><a className="menu-item" href="color-palette-success.html"><i></i><span data-i18n="Success palette">Success palette</span></a>
                            </li>
                            <li><a className="menu-item" href="color-palette-warning.html"><i></i><span data-i18n="Warning palette">Warning palette</span></a>
                            </li>
                            <li><a className="menu-item" href="color-palette-info.html"><i></i><span data-i18n="Info palette">Info palette</span></a>
                            </li>
                            <li className="navigation-divider"></li>
                            <li><a className="menu-item" href="color-palette-red.html"><i></i><span data-i18n="Red palette">Red palette</span></a>
                            </li>
                            <li><a className="menu-item" href="color-palette-pink.html"><i></i><span data-i18n="Pink palette">Pink palette</span></a>
                            </li>
                            <li><a className="menu-item" href="color-palette-purple.html"><i></i><span data-i18n="Purple palette">Purple palette</span></a>
                            </li>
                            <li><a className="menu-item" href="color-palette-blue.html"><i></i><span data-i18n="Blue palette">Blue palette</span></a>
                            </li>
                            <li><a className="menu-item" href="color-palette-cyan.html"><i></i><span data-i18n="Cyan palette">Cyan palette</span></a>
                            </li>
                            <li><a className="menu-item" href="color-palette-teal.html"><i></i><span data-i18n="Teal palette">Teal palette</span></a>
                            </li>
                            <li><a className="menu-item" href="color-palette-yellow.html"><i></i><span data-i18n="Yellow palette">Yellow palette</span></a>
                            </li>
                            <li><a className="menu-item" href="color-palette-amber.html"><i></i><span data-i18n="Amber palette">Amber palette</span></a>
                            </li>
                            <li><a className="menu-item" href="color-palette-blue-grey.html"><i></i><span data-i18n="Blue Grey palette">Blue Grey palette</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-puzzle-piece"></i><span className="menu-title" data-i18n="Starter kit">Starter kit</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="../../../starter-kit/ltr/vertical-menu-template/layout-1-column.html"><i></i><span data-i18n="1 column">1 column</span></a>
                            </li>
                            <li><a className="menu-item" href="../../../starter-kit/ltr/vertical-menu-template/layout-2-columns.html"><i></i><span data-i18n="2 columns">2 columns</span></a>
                            </li>
                            <li><a className="menu-item" href="#"><i></i><span data-i18n="Content Det Sidebar">Content Det Sidebar</span></a>
                                <ul className="menu-content">
                                    <li><a className="menu-item" href="../../../starter-kit/ltr/vertical-menu-template/layout-content-detached-left-sidebar.html"><i></i><span data-i18n="Detached left sidebar">Detached left sidebar</span></a>
                                    </li>
                                    <li><a className="menu-item" href="../../../starter-kit/ltr/vertical-menu-template/layout-content-detached-left-sticky-sidebar.html"><i></i><span data-i18n="Detached sticky left sidebar">Detached sticky left sidebar</span></a>
                                    </li>
                                    <li><a className="menu-item" href="../../../starter-kit/ltr/vertical-menu-template/layout-content-detached-right-sidebar.html"><i></i><span data-i18n="Detached right sidebar">Detached right sidebar</span></a>
                                    </li>
                                    <li><a className="menu-item" href="../../../starter-kit/ltr/vertical-menu-template/layout-content-detached-right-sticky-sidebar.html"><i></i><span data-i18n="Detached sticky right sidebar">Detached sticky right sidebar</span></a>
                                    </li>
                                </ul>
                            </li>
                            <li className="navigation-divider"></li>
                            <li><a className="menu-item" href="../../../starter-kit/ltr/vertical-menu-template/layout-fixed-navbar.html"><i></i><span data-i18n="Fixed navbar">Fixed navbar</span></a>
                            </li>
                            <li><a className="menu-item" href="../../../starter-kit/ltr/vertical-menu-template/layout-fixed-navigation.html"><i></i><span data-i18n="Fixed navigation">Fixed navigation</span></a>
                            </li>
                            <li><a className="menu-item" href="../../../starter-kit/ltr/vertical-menu-template/layout-fixed-navbar-navigation.html"><i></i><span data-i18n="Fixed navbar &amp; navigation">Fixed navbar &amp; navigation</span></a>
                            </li>
                            <li><a className="menu-item" href="../../../starter-kit/ltr/vertical-menu-template/layout-fixed-navbar-footer.html"><i></i><span data-i18n="Fixed navbar &amp; footer">Fixed navbar &amp; footer</span></a>
                            </li>
                            <li className="navigation-divider"></li>
                            <li><a className="menu-item" href="../../../starter-kit/ltr/vertical-menu-template/layout-fixed.html"><i></i><span data-i18n="Fixed layout">Fixed layout</span></a>
                            </li>
                            <li><a className="menu-item" href="../../../starter-kit/ltr/vertical-menu-template/layout-boxed.html"><i></i><span data-i18n="Boxed layout">Boxed layout</span></a>
                            </li>
                            <li><a className="menu-item" href="../../../starter-kit/ltr/vertical-menu-template/layout-static.html"><i></i><span data-i18n="Static layout">Static layout</span></a>
                            </li>
                            <li className="navigation-divider"></li>
                            <li><a className="menu-item" href="../../../starter-kit/ltr/vertical-menu-template/layout-light.html"><i></i><span data-i18n="Light layout">Light layout</span></a>
                            </li>
                            <li><a className="menu-item" href="../../../starter-kit/ltr/vertical-menu-template/layout-dark.html"><i></i><span data-i18n="Dark layout">Dark layout</span></a>
                            </li>
                            <li><a className="menu-item" href="../../../starter-kit/ltr/vertical-menu-template/layout-semi-dark.html"><i></i><span data-i18n="Semi dark layout">Semi dark layout</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className="disabled nav-item"><a href="#"><i className="la la-eye-slash"></i><span className="menu-title" data-i18n="Disabled Menu">Disabled Menu</span></a>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-android"></i><span className="menu-title" data-i18n="Menu levels">Menu levels</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="#"><i></i><span data-i18n="Second level">Second level</span></a>
                            </li>
                            <li><a className="menu-item" href="#"><i></i><span data-i18n="Second level child">Second level child</span></a>
                                <ul className="menu-content">
                                    <li><a className="menu-item" href="#"><i></i><span data-i18n="Third level">Third level</span></a>
                                    </li>
                                    <li><a className="menu-item" href="#"><i></i><span data-i18n="Third level child">Third level child</span></a>
                                        <ul className="menu-content">
                                            <li><a className="menu-item" href="#"><i></i><span data-i18n="Fourth level">Fourth level</span></a>
                                            </li>
                                            <li><a className="menu-item" href="#"><i></i><span data-i18n="Fourth level">Fourth level</span></a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className=" navigation-header"><span>User Interface</span><i className="la la-ellipsis-h" data-toggle="tooltip" data-placement="right" data-original-title="User Interface"></i>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-tablet"></i><span className="menu-title" data-i18n="Cards">Cards</span><span className="badge badge badge-success float-right mr-2">New</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="card-bootstrap.html"><i></i><span data-i18n="Bootstrap">Bootstrap</span></a>
                            </li>
                            <li><a className="menu-item" href="card-headings.html"><i></i><span data-i18n="Headings">Headings</span></a>
                            </li>
                            <li><a className="menu-item" href="card-options.html"><i></i><span data-i18n="Options">Options</span></a>
                            </li>
                            <li><a className="menu-item" href="card-actions.html"><i></i><span data-i18n="Action">Action</span></a>
                            </li>
                            <li><a className="menu-item" href="card-draggable.html"><i></i><span data-i18n="Draggable">Draggable</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-fire"></i><span className="menu-title" data-i18n="Advance Cards">Advance Cards</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="card-statistics.html"><i></i><span data-i18n="Statistics">Statistics</span></a>
                            </li>
                            <li><a className="menu-item" href="card-weather.html"><i></i><span data-i18n="Weather">Weather</span></a>
                            </li>
                            <li><a className="menu-item" href="card-charts.html"><i></i><span data-i18n="Charts">Charts</span></a>
                            </li>
                            <li><a className="menu-item" href="card-interactive.html"><i></i><span data-i18n="Interactive">Interactive</span></a>
                            </li>
                            <li><a className="menu-item" href="card-maps.html"><i></i><span data-i18n="Maps">Maps</span></a>
                            </li>
                            <li><a className="menu-item" href="card-social.html"><i></i><span data-i18n="Social">Social</span></a>
                            </li>
                            <li><a className="menu-item" href="card-ecommerce.html"><i></i><span data-i18n="E-Commerce">E-Commerce</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-compass"></i><span className="menu-title" data-i18n="Content">Content</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="content-grid.html"><i></i><span data-i18n="Grid">Grid</span></a>
                            </li>
                            <li><a className="menu-item" href="content-typography.html"><i></i><span data-i18n="Typography">Typography</span></a>
                            </li>
                            <li><a className="menu-item" href="content-text-utilities.html"><i></i><span data-i18n="Text utilities">Text utilities</span></a>
                            </li>
                            <li><a className="menu-item" href="content-syntax-highlighter.html"><i></i><span data-i18n="Syntax highlighter">Syntax highlighter</span></a>
                            </li>
                            <li><a className="menu-item" href="content-helper-classNamees.html"><i></i><span data-i18n="Helper classNamees">Helper classNamees</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-server"></i><span className="menu-title" data-i18n="Components">Components</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="component-alerts.html"><i></i><span data-i18n="Alerts">Alerts</span></a>
                            </li>
                            <li><a className="menu-item" href="component-callout.html"><i></i><span data-i18n="Callout">Callout</span></a>
                            </li>
                            <li><a className="menu-item" href="#"><i></i><span data-i18n="Buttons">Buttons</span></a>
                                <ul className="menu-content">
                                    <li><a className="menu-item" href="component-buttons-basic.html"><i></i><span data-i18n="Basic Buttons">Basic Buttons</span></a>
                                    </li>
                                    <li><a className="menu-item" href="component-buttons-extended.html"><i></i><span data-i18n="Extended Buttons">Extended Buttons</span></a>
                                    </li>
                                </ul>
                            </li>
                            <li><a className="menu-item" href="component-carousel.html"><i></i><span data-i18n="Carousel">Carousel</span></a>
                            </li>
                            <li><a className="menu-item" href="component-collapse.html"><i></i><span data-i18n="Collapse">Collapse</span></a>
                            </li>
                            <li><a className="menu-item" href="component-dropdowns.html"><i></i><span data-i18n="Dropdowns">Dropdowns</span></a>
                            </li>
                            <li><a className="menu-item" href="component-list-group.html"><i></i><span data-i18n="List Group">List Group</span></a>
                            </li>
                            <li><a className="menu-item" href="component-modals.html"><i></i><span data-i18n="Modals">Modals</span></a>
                            </li>
                            <li><a className="menu-item" href="component-pagination.html"><i></i><span data-i18n="Pagination">Pagination</span></a>
                            </li>
                            <li><a className="menu-item" href="component-navs-component.html"><i></i><span data-i18n="Navs Component">Navs Component</span></a>
                            </li>
                            <li><a className="menu-item" href="component-tabs-component.html"><i></i><span data-i18n="Tabs Component">Tabs Component</span></a>
                            </li>
                            <li><a className="menu-item" href="component-pills-component.html"><i></i><span data-i18n="Pills Component">Pills Component</span></a>
                            </li>
                            <li><a className="menu-item" href="component-tooltips.html"><i></i><span data-i18n="Tooltips">Tooltips</span></a>
                            </li>
                            <li><a className="menu-item" href="component-popovers.html"><i></i><span data-i18n="Popovers">Popovers</span></a>
                            </li>
                            <li><a className="menu-item" href="component-badges.html"><i></i><span data-i18n="Badges">Badges</span></a>
                            </li>
                            <li><a className="menu-item" href="component-pill-badges.html"><i></i><span data-i18n="Pill Badges">Pill Badges</span></a>
                            </li>
                            <li><a className="menu-item" href="component-progress.html"><i></i><span data-i18n="Progress">Progress</span></a>
                            </li>
                            <li><a className="menu-item" href="component-media-objects.html"><i></i><span data-i18n="Media Objects">Media Objects</span></a>
                            </li>
                            <li><a className="menu-item" href="component-scrollable.html"><i></i><span data-i18n="Scrollable">Scrollable</span></a>
                            </li>
                            <li><a className="menu-item" href="component-spinners.html"><i></i><span data-i18n="Spinners">Spinners</span></a>
                            </li>
                            <li><a className="menu-item" href="components-toast.html"><i></i><span data-i18n="Toast &amp; Custom Switch">Toast &amp; Custom Switch</span></a>
                            </li>
                            <li><a className="menu-item" href="components-bootstrap-spinner.html"><i></i><span data-i18n="Bootstrap Spinner">Bootstrap Spinner</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-diamond"></i><span className="menu-title" data-i18n="Extra Components">Extra Components</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="ex-component-sweet-alerts.html"><i></i><span data-i18n="Sweet Alerts">Sweet Alerts</span></a>
                            </li>
                            <li><a className="menu-item" href="ex-component-tree-views.html"><i></i><span data-i18n="Tree Views">Tree Views</span></a>
                            </li>
                            <li><a className="menu-item" href="ex-component-toastr.html"><i></i><span data-i18n="Toastr">Toastr</span></a>
                            </li>
                            <li><a className="menu-item" href="ex-component-ratings.html"><i></i><span data-i18n="Ratings">Ratings</span></a>
                            </li>
                            <li><a className="menu-item" href="ex-component-noui-slider.html"><i></i><span data-i18n="NoUI Slider">NoUI Slider</span></a>
                            </li>
                            <li><a className="menu-item" href="ex-component-date-time-dropper.html"><i></i><span data-i18n="Date Time Dropper">Date Time Dropper</span></a>
                            </li>
                            <li><a className="menu-item" href="ex-component-lists.html"><i></i><span data-i18n="Lists">Lists</span></a>
                            </li>
                            <li><a className="menu-item" href="ex-component-toolbar.html"><i></i><span data-i18n="Toolbar">Toolbar</span></a>
                            </li>
                            <li><a className="menu-item" href="ex-component-knob.html"><i></i><span data-i18n="Knob">Knob</span></a>
                            </li>
                            <li><a className="menu-item" href="ex-component-long-press.html"><i></i><span data-i18n="Long Press">Long Press</span></a>
                            </li>
                            <li><a className="menu-item" href="ex-component-offline.html"><i></i><span data-i18n="Offline">Offline</span></a>
                            </li>
                            <li><a className="menu-item" href="ex-component-zoom.html"><i></i><span data-i18n="Zoom">Zoom</span></a>
                            </li>
                            <li><a className="menu-item" href="ex-component-media-player.html"><i></i><span data-i18n="media Player">media Player</span></a>
                            </li>
                            <li><a className="menu-item" href="ex-component-tour.html"><i></i><span data-i18n="Tour">Tour</span></a>
                            </li>
                            <li><a className="menu-item" href="ex-component-swiper.html"><i></i><span data-i18n="Swiper">Swiper</span></a>
                            </li>
                            <li><a className="menu-item" href="ex-component-avatar.html"><i></i><span data-i18n="Avatar">Avatar</span></a>
                            </li>
                            <li><a className="menu-item" href="miscellaneous.html"><i></i><span data-i18n="Miscellaneous">Miscellaneous</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-eye"></i><span className="menu-title" data-i18n="Icons">Icons</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="icons-feather.html"><i></i><span data-i18n="Feather">Feather</span></a>
                            </li>
                            <li><a className="menu-item" href="icons-line-awesome.html"><i></i><span data-i18n="Line Awesome">Line Awesome</span></a>
                            </li>
                            <li><a className="menu-item" href="icons-meteocons.html"><i></i><span data-i18n="Meteocons">Meteocons</span></a>
                            </li>
                            <li><a className="menu-item" href="icons-simple-line-icons.html"><i></i><span data-i18n="Simple Line Icons">Simple Line Icons</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="animation.html"><i className="la la-spinner spinner"></i><span className="menu-title" data-i18n="Animation">Animation</span></a>
                    </li>
                    <li className=" navigation-header"><span>Forms</span><i className="la la-ellipsis-h" data-toggle="tooltip" data-placement="right" data-original-title="Forms"></i>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-terminal"></i><span className="menu-title" data-i18n="Form Elements">Form Elements</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="form-inputs.html"><i></i><span data-i18n="Form Inputs">Form Inputs</span></a>
                            </li>
                            <li><a className="menu-item" href="form-input-groups.html"><i></i><span data-i18n="Input Groups">Input Groups</span></a>
                            </li>
                            <li><a className="menu-item" href="form-input-grid.html"><i></i><span data-i18n="Input Grid">Input Grid</span></a>
                            </li>
                            <li><a className="menu-item" href="form-extended-inputs.html"><i></i><span data-i18n="Extended Inputs">Extended Inputs</span></a>
                            </li>
                            <li><a className="menu-item" href="form-checkboxes-radios.html"><i></i><span data-i18n="Checkboxes &amp; Radios">Checkboxes &amp; Radios</span></a>
                            </li>
                            <li><a className="menu-item" href="form-switch.html"><i></i><span data-i18n="Switch">Switch</span></a>
                            </li>
                            <li><a className="menu-item" href="#"><i></i><span data-i18n="Select">Select</span></a>
                                <ul className="menu-content">
                                    <li><a className="menu-item" href="form-select2.html"><i></i><span data-i18n="Select2">Select2</span></a>
                                    </li>
                                    <li><a className="menu-item" href="form-selectize.html"><i></i><span data-i18n="Selectize">Selectize</span></a>
                                    </li>
                                    <li><a className="menu-item" href="form-selectivity.html"><i></i><span data-i18n="Selectivity">Selectivity</span></a>
                                    </li>
                                    <li><a className="menu-item" href="form-select-box-it.html"><i></i><span data-i18n="Select Box It">Select Box It</span></a>
                                    </li>
                                </ul>
                            </li>
                            <li><a className="menu-item" href="form-dual-listbox.html"><i></i><span data-i18n="Dual Listbox">Dual Listbox</span></a>
                            </li>
                            <li><a className="menu-item" href="form-tags-input.html"><i></i><span data-i18n="Tags Input">Tags Input</span></a>
                            </li>
                            <li><a className="menu-item" href="form-validation.html"><i></i><span data-i18n="Validation">Validation</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-file-text"></i><span className="menu-title" data-i18n="Form Layouts">Form Layouts</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="form-layout-basic.html"><i></i><span data-i18n="Basic Forms">Basic Forms</span></a>
                            </li>
                            <li><a className="menu-item" href="form-layout-horizontal.html"><i></i><span data-i18n="Horizontal Forms">Horizontal Forms</span></a>
                            </li>
                            <li><a className="menu-item" href="form-layout-hidden-labels.html"><i></i><span data-i18n="Hidden Labels">Hidden Labels</span></a>
                            </li>
                            <li><a className="menu-item" href="form-layout-form-actions.html"><i></i><span data-i18n="Form Actions">Form Actions</span></a>
                            </li>
                            <li><a className="menu-item" href="form-layout-row-separator.html"><i></i><span data-i18n="Row Separator">Row Separator</span></a>
                            </li>
                            <li><a className="menu-item" href="form-layout-bordered.html"><i></i><span data-i18n="Bordered">Bordered</span></a>
                            </li>
                            <li><a className="menu-item" href="form-layout-striped-rows.html"><i></i><span data-i18n="Striped Rows">Striped Rows</span></a>
                            </li>
                            <li><a className="menu-item" href="form-layout-striped-labels.html"><i></i><span data-i18n="Striped Labels">Striped Labels</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-paste"></i><span className="menu-title" data-i18n="Form Wizard">Form Wizard</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="form-wizard-circle-style.html"><i></i><span data-i18n="Circle Style">Circle Style</span></a>
                            </li>
                            <li><a className="menu-item" href="form-wizard-notification-style.html"><i></i><span data-i18n="Notification Style">Notification Style</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="form-repeater.html"><i className="la la-repeat"></i><span className="menu-title" data-i18n="Form Repeater">Form Repeater</span></a>
                    </li>
                    <li className=" navigation-header"><span>Tables</span><i className="la la-ellipsis-h" data-toggle="tooltip" data-placement="right" data-original-title="Tables"></i>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-table"></i><span className="menu-title" data-i18n="Bootstrap Tables">Bootstrap Tables</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="table-basic.html"><i></i><span data-i18n="Basic Tables">Basic Tables</span></a>
                            </li>
                            <li><a className="menu-item" href="table-border.html"><i></i><span data-i18n="Table Border">Table Border</span></a>
                            </li>
                            <li><a className="menu-item" href="table-sizing.html"><i></i><span data-i18n="Table Sizing">Table Sizing</span></a>
                            </li>
                            <li><a className="menu-item" href="table-styling.html"><i></i><span data-i18n="Table Styling">Table Styling</span></a>
                            </li>
                            <li><a className="menu-item" href="table-components.html"><i></i><span data-i18n="Table Components">Table Components</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-th"></i><span className="menu-title" data-i18n="DataTables">DataTables</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="dt-basic-initialization.html"><i></i><span data-i18n="Basic Initialisation">Basic Initialisation</span></a>
                            </li>
                            <li><a className="menu-item" href="dt-advanced-initialization.html"><i></i><span data-i18n="Advanced initialisation">Advanced initialisation</span></a>
                            </li>
                            <li><a className="menu-item" href="dt-styling.html"><i></i><span data-i18n="Styling">Styling</span></a>
                            </li>
                            <li><a className="menu-item" href="dt-data-sources.html"><i></i><span data-i18n="Data Sources">Data Sources</span></a>
                            </li>
                            <li><a className="menu-item" href="dt-api.html"><i></i><span data-i18n="API">API</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-th-large"></i><span className="menu-title" data-i18n="DataTables Ext">DataTables Ext</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="dt-extensions-autofill.html"><i></i><span data-i18n="AutoFill">AutoFill</span></a>
                            </li>
                            <li><a className="menu-item" href="#"><i></i><span data-i18n="Buttons">Buttons</span></a>
                                <ul className="menu-content">
                                    <li><a className="menu-item" href="dt-extensions-buttons-basic.html"><i></i><span data-i18n="Basic Buttons">Basic Buttons</span></a>
                                    </li>
                                    <li><a className="menu-item" href="dt-extensions-buttons-html-5-data-export.html"><i></i><span data-i18n="HTML 5 Data Export">HTML 5 Data Export</span></a>
                                    </li>
                                    <li><a className="menu-item" href="dt-extensions-buttons-flash-data-export.html"><i></i><span data-i18n="Flash Data Export">Flash Data Export</span></a>
                                    </li>
                                    <li><a className="menu-item" href="dt-extensions-buttons-column-visibility.html"><i></i><span data-i18n="Column Visibility">Column Visibility</span></a>
                                    </li>
                                    <li><a className="menu-item" href="dt-extensions-buttons-print.html"><i></i><span data-i18n="Print">Print</span></a>
                                    </li>
                                    <li><a className="menu-item" href="dt-extensions-buttons-api.html"><i></i><span data-i18n="API">API</span></a>
                                    </li>
                                </ul>
                            </li>
                            <li><a className="menu-item" href="dt-extensions-column-reorder.html"><i></i><span data-i18n="Column Reorder">Column Reorder</span></a>
                            </li>
                            <li><a className="menu-item" href="dt-extensions-fixed-columns.html"><i></i><span data-i18n="Fixed Columns">Fixed Columns</span></a>
                            </li>
                            <li><a className="menu-item" href="dt-extensions-key-table.html"><i></i><span data-i18n="Key Table">Key Table</span></a>
                            </li>
                            <li><a className="menu-item" href="dt-extensions-row-reorder.html"><i></i><span data-i18n="Row Reorder">Row Reorder</span></a>
                            </li>
                            <li><a className="menu-item" href="dt-extensions-select.html"><i></i><span data-i18n="Select">Select</span></a>
                            </li>
                            <li><a className="menu-item" href="dt-extensions-fix-header.html"><i></i><span data-i18n="Fix Header">Fix Header</span></a>
                            </li>
                            <li><a className="menu-item" href="dt-extensions-responsive.html"><i></i><span data-i18n="Responsive">Responsive</span></a>
                            </li>
                            <li><a className="menu-item" href="dt-extensions-column-visibility.html"><i></i><span data-i18n="Column Visibility">Column Visibility</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" navigation-header"><span>Add Ons</span><i className="la la-ellipsis-h" data-toggle="tooltip" data-placement="right" data-original-title="Add Ons"></i>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-edit"></i><span className="menu-title" data-i18n="Editors">Editors</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="editor-quill.html"><i></i><span data-i18n="Quill">Quill</span></a>
                            </li>
                            <li><a className="menu-item" href="editor-ckeditor.html"><i></i><span data-i18n="CKEditor">CKEditor</span></a>
                            </li>
                            <li><a className="menu-item" href="editor-summernote.html"><i></i><span data-i18n="Summernote">Summernote</span></a>
                            </li>
                            <li><a className="menu-item" href="editor-tinymce.html"><i></i><span data-i18n="TinyMCE">TinyMCE</span></a>
                            </li>
                            <li><a className="menu-item" href="#"><i></i><span data-i18n="Code Editor">Code Editor</span></a>
                                <ul className="menu-content">
                                    <li><a className="menu-item" href="code-editor-codemirror.html"><i></i><span data-i18n="CodeMirror">CodeMirror</span></a>
                                    </li>
                                    <li><a className="menu-item" href="code-editor-ace.html"><i></i><span data-i18n="Ace">Ace</span></a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-calendar"></i><span className="menu-title" data-i18n="Pickers">Pickers</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="pickers-date-&amp;-time-picker.html"><i></i><span data-i18n="Date &amp; Time Picker">Date &amp; Time Picker</span></a>
                            </li>
                            <li><a className="menu-item" href="pickers-color-picker.html"><i></i><span data-i18n="Color Picker">Color Picker</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-code-fork"></i><span className="menu-title" data-i18n="jQuery UI">jQuery UI</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="jquery-ui-interactions.html"><i></i><span data-i18n="Interactions">Interactions</span></a>
                            </li>
                            <li><a className="menu-item" href="jquery-ui-navigations.html"><i></i><span data-i18n="Navigations">Navigations</span></a>
                            </li>
                            <li><a className="menu-item" href="jquery-ui-date-pickers.html"><i></i><span data-i18n="Date Pickers">Date Pickers</span></a>
                            </li>
                            <li><a className="menu-item" href="jquery-ui-autocomplete.html"><i></i><span data-i18n="Autocomplete">Autocomplete</span></a>
                            </li>
                            <li><a className="menu-item" href="jquery-ui-buttons-select.html"><i></i><span data-i18n="Buttons &amp; Select">Buttons &amp; Select</span></a>
                            </li>
                            <li><a className="menu-item" href="jquery-ui-slider-spinner.html"><i></i><span data-i18n="Slider &amp; Spinner">Slider &amp; Spinner</span></a>
                            </li>
                            <li><a className="menu-item" href="jquery-ui-dialog-tooltip.html"><i></i><span data-i18n="Dialog &amp; Tooltip">Dialog &amp; Tooltip</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="add-on-block-ui.html"><i className="la la-terminal"></i><span className="menu-title" data-i18n="Block UI">Block UI</span></a>
                    </li>
                    <li className=" nav-item"><a href="add-on-image-cropper.html"><i className="la la-crop"></i><span className="menu-title" data-i18n="Image Cropper">Image Cropper</span></a>
                    </li>
                    <li className=" nav-item"><a href="add-on-drag-drop.html"><i className="la la-mouse-pointer"></i><span className="menu-title" data-i18n="Drag &amp; Drop">Drag &amp; Drop</span></a>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-cloud-upload"></i><span className="menu-title" data-i18n="File Uploader">File Uploader</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="file-uploader-dropzone.html"><i></i><span data-i18n="Dropzone">Dropzone</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="internationalization.html"><i className="la la-flag-o"></i><span className="menu-title" data-i18n="Internationalization">Internationalization</span></a>
                    </li>
                    <li className=" navigation-header"><span>Charts &amp; Maps</span><i className="la la-ellipsis-h" data-toggle="tooltip" data-placement="right" data-original-title="Charts &amp; Maps"></i>
                    </li>
                    <li className=" nav-item"><a href="charts-apexcharts.html"><i className="la la-pie-chart"></i><span className="menu-title" data-i18n="Apex Charts">Apex Charts</span></a>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-area-chart"></i><span className="menu-title" data-i18n="Chartjs">Chartjs</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="chartjs-line-charts.html"><i></i><span data-i18n="Line charts">Line charts</span></a>
                            </li>
                            <li><a className="menu-item" href="chartjs-bar-charts.html"><i></i><span data-i18n="Bar charts">Bar charts</span></a>
                            </li>
                            <li><a className="menu-item" href="chartjs-pie-doughnut-charts.html"><i></i><span data-i18n="Pie &amp; Doughnut charts">Pie &amp; Doughnut charts</span></a>
                            </li>
                            <li><a className="menu-item" href="chartjs-scatter-charts.html"><i></i><span data-i18n="Scatter charts">Scatter charts</span></a>
                            </li>
                            <li><a className="menu-item" href="chartjs-polar-radar-charts.html"><i></i><span data-i18n="Polar &amp; Radar charts">Polar &amp; Radar charts</span></a>
                            </li>
                            <li><a className="menu-item" href="chartjs-advance-charts.html"><i></i><span data-i18n="Advance charts">Advance charts</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-line-chart"></i><span className="menu-title" data-i18n="D3 Charts">D3 Charts</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="d3-line-chart.html"><i></i><span data-i18n="Line Chart">Line Chart</span></a>
                            </li>
                            <li><a className="menu-item" href="d3-bar-chart.html"><i></i><span data-i18n="Bar Chart">Bar Chart</span></a>
                            </li>
                            <li><a className="menu-item" href="d3-pie-chart.html"><i></i><span data-i18n="Pie Chart">Pie Chart</span></a>
                            </li>
                            <li><a className="menu-item" href="d3-circle-diagrams.html"><i></i><span data-i18n="Circle Diagrams">Circle Diagrams</span></a>
                            </li>
                            <li><a className="menu-item" href="d3-tree-chart.html"><i></i><span data-i18n="Tree Chart">Tree Chart</span></a>
                            </li>
                            <li><a className="menu-item" href="d3-other-charts.html"><i></i><span data-i18n="Other Charts">Other Charts</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-pie-chart"></i><span className="menu-title" data-i18n="Chartist">Chartist</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="chartist-line-charts.html"><i></i><span data-i18n="Line charts">Line charts</span></a>
                            </li>
                            <li><a className="menu-item" href="chartist-bar-charts.html"><i></i><span data-i18n="Bar charts">Bar charts</span></a>
                            </li>
                            <li><a className="menu-item" href="chartist-pie-charts.html"><i></i><span data-i18n="Pie charts">Pie charts</span></a>
                            </li>
                        </ul>
                    </li>
                    <li className=" nav-item"><a href="morris-charts.html"><i className="la la-share-alt"></i><span className="menu-title" data-i18n="Morris Charts">Morris Charts</span></a>
                    </li>
                    <li className=" nav-item"><a href="rickshaw-charts.html"><i className="la la-bullseye"></i><span className="menu-title" data-i18n="Rickshaw Charts">Rickshaw Charts</span></a>
                    </li>
                    <li className=" nav-item"><a href="#"><i className="la la-map"></i><span className="menu-title" data-i18n="Maps">Maps</span></a>
                        <ul className="menu-content">
                            <li><a className="menu-item" href="maps-leaflet.html"><i></i><span data-i18n="Leaflet Maps">Leaflet Maps</span></a>
                            </li>
                            <li><a className="menu-item" href="#"><i></i><span data-i18n="Vector Maps">Vector Maps</span></a>
                                <ul className="menu-content">
                                    <li><a className="menu-item" href="vector-maps-jvector.html"><i></i><span data-i18n="jVector Map">jVector Map</span></a>
                                    </li>
                                    <li><a className="menu-item" href="vector-maps-jvq.html"><i></i><span data-i18n="JQV Map">JQV Map</span></a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className=" navigation-header"><span>Support</span><i className="la la-ellipsis-h" data-toggle="tooltip" data-placement="right" data-original-title="Support"></i>
                    </li>
                    <li className=" nav-item"><a href="https://pixinvent.ticksy.com/" target="_blank"><i className="la la-support"></i><span className="menu-title" data-i18n="Raise Support">Raise Support</span></a>
                    </li>
                    <li className=" nav-item"><a href="https://pixinvent.com/modern-admin-clean-bootstrap-4-dashboard-html-template/documentation" target="_blank"><i className="la la-text-height"></i><span className="menu-title" data-i18n="Documentation">Documentation</span></a>
                    </li>*/}
                </ul>
            </div>
        </div>
        </>
    )
}

export default Navbar;