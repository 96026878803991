import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { ThreeDots } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../assets/images/logo-header-takeiteasy.png';
import md5 from 'md5';
import * as config from '../config';
import axios from 'axios';
import { UserContext } from '../routers/UserContext';

function Signup() {
    const {userAuth,setUserAuth} = useContext(UserContext)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [user, setUser] = useState({
        name: "",
        surname: "",
        email: "",
        password: ""
    })

    useEffect(() => {
        document.body.setAttribute("class","vertical-layout vertical-menu bg-cyan bg-lighten-2 1-column blank-page");
        document.body.setAttribute("data-col","1-column");

        return () => {
            document.body.setAttribute("class","vertical-layout vertical-menu 2-columns fixed-navbar");
            document.body.setAttribute("data-col","2-columns");
        };
    }, [])

    const capitalizeFirstLowercaseRest = str => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const handleChange = (e) => {
        if (e.target.name == "name" || e.target.name == "surname") {
            setUser({ ...user, [e.target.name]: capitalizeFirstLowercaseRest(e.target.value) })
        } else if (e.target.name == "email") {
            setUser({ ...user, [e.target.name]: e.target.value.toLowerCase() })
        } else {
            setUser({ ...user, [e.target.name]: e.target.value })
        }
    }

    const signupAccount = (event) => {
        event.preventDefault();
        setLoading(true)
        if (user.name !== "" && user.surname !== "" && user.email !== "" && user.password !== "") {
            const password = md5(user.password);
            axios({
                method: 'post',
                url: `${config.WEBSITE_URL}/partners/signup`,
                headers: {
                    'Content-Type': 'application/json'
                },
                auth: {
                    username: config.USERNAME_TOKEN,
                    password: config.PASSWORD_TOKEN
                },
                data: JSON.stringify({
                    token_access: config.WEB_TOKEN,
                    name: user.name.trim(),
                    surname: user.surname.trim(),
                    email: user.email.trim(),
                    password: password
                })
            })
            .then(function(response) {
                if (response.data.code == true) {
                    if (response.data.data_user == true) {
                        localStorage.setItem('user_info', JSON.stringify({
                            id: response.data.id,
                            name: response.data.name,
                            surname: response.data.surname,
                            email: response.data.email,
                            is_employee: response.data.is_employee,
                            is_code_verify: response.data.is_code_verify,
                            is_completed: response.data.is_completed
                        }))
                        setUserAuth(true)
                        localStorage.setItem('isAuth', true)
                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setTimeout(() => {
                            navigate('/account/verifica')
                        }, 1000);
                        setLoading(false)
                    } else {
                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setTimeout(() => {
                            navigate('/login')
                        }, 1000);
                        setLoading(false)
                    }
                } else {
                    toast.error(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setLoading(false)
                }
            })
            .catch(function(error) {
                console.error(error);
                setLoading(false)
            });
        } else {
            toast.error("Compila tutti i campi obbligatori", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false)
        }
    }

    return(
        <>
        <ToastContainer />
        <Helmet>
            <title>Registrati | Takeiteasy Partners</title>
        </Helmet>
        <div className="app-content content">
            <div className="content-overlay"></div>
            <div className="content-wrapper">
                <div className="content-header row">
                </div>
                <div className="content-body">
                    <section className="row flexbox-container">
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            <div className="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
                                <div className="card border-grey border-lighten-3 m-0">
                                    <div className="card-header border-0">
                                        <div className="card-title text-center">
                                            <div className="p-1"><img src={logo} style={{ width: '65%' }} /></div>
                                        </div>
                                    </div>
                                    <div className="card-content">
                                        <p className="card-subtitle line-on-side text-muted text-center font-small-3 mx-2">
                                            <span>Registrati per gestire la tua farmacia</span>
                                        </p>
                                        <div className="card-body pt-0">
                                            <form className="form-horizontal" onSubmit={signupAccount}>
                                                <fieldset className="form-group position-relative has-icon-left">
                                                    <input type="text" className="form-control" name="name" onChange={handleChange} value={user.name} id="name" placeholder="Nome" required />
                                                    <div className="form-control-position">
                                                    <i className="la la-user"></i>
                                                    </div>
                                                </fieldset>
                                                <fieldset className="form-group position-relative has-icon-left">
                                                    <input type="text" className="form-control" name="surname" onChange={handleChange} value={user.surname} id="surname" placeholder="Cognome" required />
                                                    <div className="form-control-position">
                                                    <i className="la la-user"></i>
                                                    </div>
                                                </fieldset>
                                                <fieldset className="form-group position-relative has-icon-left">
                                                    <input type="email" className="form-control" name="email" onChange={handleChange} value={user.email} id="user-name" placeholder="Email" required />
                                                    <div className="form-control-position">
                                                    <i className="la la-envelope"></i>
                                                    </div>
                                                </fieldset>
                                                <fieldset className="form-group position-relative has-icon-left">
                                                    <input type={(showPassword) ? "text" : "password"} className="form-control" name="password" onChange={handleChange} value={user.password} id="user-password" placeholder="Password" required />
                                                    <div className="form-control-position">
                                                    <i style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} className={(showPassword) ? "la la-eye-slash" : "la la-eye"}></i>
                                                    </div>
                                                </fieldset>
                                                <div className="row mb-1">
                                                    <div className="col-4 col-sm-3 col-md-3">
                                                        <fieldset>
                                                            <input type="checkbox" id="remember-me" className="chk-remember" required />
                                                            <label htmlFor="remember-me" className="ml-1"> Acconsento</label>
                                                        </fieldset>
                                                    </div>
                                                    <div className="col-8 col-sm-9 col-md-9">
                                                        <p className="font-small-3">
                                                            Per continuare con la registrazione coferma di aver letto l'informativa sulla <a href="https://www.takeiteasy-app.com/privacy-policy-takeiteasy-partners/" target='_blank'>Privacy Policy</a> e i <a href="https://www.takeiteasy-app.com/termini-e-condizioni-takeiteasy-partners/" target="_blank">Termini e Condiziioni</a> di takeiteasy.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6 col-md-6">
                                                        <Link to="/login" className="btn btn-success btn-lg btn-block">
                                                            <i className="ft-unlock"></i> Accedi
                                                        </Link>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-6">
                                                        <button type="submit" disabled={loading} className="btn btn-info btn-lg btn-block">
                                                            {(loading)
                                                                ?
                                                                <ThreeDots 
                                                                    height="22" 
                                                                    width="46" 
                                                                    radius="9"
                                                                    color="#fff" 
                                                                    ariaLabel="three-dots-loading"
                                                                    wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
                                                                    visible={true}
                                                                />
                                                                :
                                                                <>
                                                                <i className="la la-user"></i> Registrati 
                                                                </>
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
        </div>
        </>
    )
}

export default Signup;