import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet";
import moment, { months } from 'moment';
import 'moment/locale/it';
import Tour from 'reactour';
import { Calendar as CalendarComponent, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
/*import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";*/
import Button from 'react-bootstrap/Button';
import it from "date-fns/locale/it";
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Form from 'react-bootstrap/Form';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import locale from 'antd/es/date-picker/locale/it_IT';
import { Skeleton, Empty, DatePicker, Card, Alert, Modal, Switch, Button as ButtonAntd, Input, Divider, Select, Tooltip } from 'antd';
import { PlusOutlined, CloseOutlined, ClockCircleTwoTone, StopFilled, ExclamationCircleFilled, CopyOutlined } from '@ant-design/icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Errors from '../components/Errors';
import * as config from '../config';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner';
import InputMask from 'react-input-mask';

//registerLocale("it", it)
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { confirm } = Modal;
const { Option } = Select;

const localizer = momentLocalizer(moment)

const steps = [
    {
        selector: '.first-step',
        content: 'This is my first Step',
    },
    {
        selector: '.second-step',
        content: 'This is my second Step',
    }
]

const events = [
    {
        start: 'Wed Feb 01 2023 00:00:00 GMT+0100',
        end: 'Sat Feb 04 2023 00:00:00 GMT+0100',
        title: "Chiuso per ferie",
        allDay: true,
        colorEvento:'red'
    },
    {
        start: new Date(),
        end: new Date(),
        title: "Mario Bianchi"
    }
]

function Calendar() {
    const [isTourOpen, setIsTourOpen] = useState(false)
    const [dateRange, setDateRange] = useState([null, null]);
    const [loadingDaysWork, setLoadingDaysWork] = useState(true)
    const [loadingHours, setLoadingHours] = useState(false)
    const [loadingDeleteDays, setLoadingDeleteDays] = useState(false)
    const [eventsClosed, setEventsClosed] = useState([])
    const [eventsTickets, setEventsTickets] = useState([])
    const [visitsTickets, setVisitsTickets] = useState([])
    const [allTickets, setAllTickets] = useState([])
    const [allVisitsTickets, setAllVisitsTickets] = useState([])
    const [allDatesClosed, setAllDatesClosed] = useState([])
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [showModalEvent, setShowModalEvent] = useState(false)
    const [showModalTicket, setShowModalTicket] = useState(false)
    const [eventSelect, setEventSelect] = useState(null)
    const [ticketSelect, setTicketSelect] = useState(null)
    const [haveError, setHaveError] = useState(false)
    const [showDelete, setShowDelte] = useState(false)
    const [selectMotivation, setSelectMotivation] = useState(null)
    const [motivation, setMotivation] = useState("")
    const [loadingMotivation, setLoadingMotivation] = useState(false)
    const [closeDays, setCloseDays] = useState({
        description: ""
    })
    const [startDateClosed, setStartDateClosed] = useState(null)
    const [endDateClosed, setEndDateClosed] = useState(null)
    const [loadingClosed, setLoadingClosed] = useState(false)
    const [valueLunedi, setValueLunedi] = useState({
        id_day: null,
        day_name: 'Lunedì',
        open_hour: "",
        closed_hour: "",
        second_open_hour: "",
        second_closed_hour: "",
        is_open: 1,
        is_second_hour: false
    });
    const [valueMartedi, setValueMartedi] = useState({
        id_day: null,
        day_name: 'Martedì',
        open_hour: "",
        closed_hour: "",
        second_open_hour: "",
        second_closed_hour: "",
        is_open: 1,
        is_second_hour: false
    });
    const [valueMercoledi, setValueMercoledi] = useState({
        id_day: null,
        day_name: 'Mercoledì',
        open_hour: "",
        closed_hour: "",
        second_open_hour: "",
        second_closed_hour: "",
        is_open: 1,
        is_second_hour: false
    });
    const [valueGiovedi, setValueGiovedi] = useState({
        id_day: null,
        day_name: 'Giovedì',
        open_hour: "",
        closed_hour: "",
        second_open_hour: "",
        second_closed_hour: "",
        is_open: 1,
        is_second_hour: false
    });
    const [valueVenerdi, setValueVenerdi] = useState({
        id_day: null,
        day_name: 'Venerdì',
        open_hour: "",
        closed_hour: "",
        second_open_hour: "",
        second_closed_hour: "",
        is_open: 1,
        is_second_hour: false
    });
    const [valueSabato, setValueSabato] = useState({
        id_day: null,
        day_name: 'Sabato',
        open_hour: "",
        closed_hour: "",
        second_open_hour: "",
        second_closed_hour: "",
        is_open: 1,
        is_second_hour: false
    });
    const [valueDomenica, setValueDomenica] = useState({
        id_day: null,
        day_name: 'Domenica',
        open_hour: "",
        closed_hour: "",
        second_open_hour: "",
        second_closed_hour: "",
        is_open: 1,
        is_second_hour: false
    });
    const [value2, setValue2] = useState(null)

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        const pharmacy = JSON.parse(localStorage.getItem('pharmacy'))
        checkErrors(userInfo, pharmacy)
        getPharmacyDaysWork()
        getPharmacyDaysClosed()
        getTicketsMonth()
        getTicketsVisitsMonth()
    }, [])

    const checkErrors = (userInfo, pharmacy) => {
        if (userInfo !== null && pharmacy !== null) {
            if (userInfo.is_code_verify == "0" || userInfo.is_code_verify == 0 || userInfo.is_completed == "0" || userInfo.is_completed == 0 || pharmacy.is_verificated == "0" || pharmacy.is_verificated == 0) {
                setHaveError(true)
            } else {
                setHaveError(false)
            }
        } else {
            setHaveError(true)
        }
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    const handleSecondOrarioLunedi = () => setValueLunedi({ ...valueLunedi, is_second_hour: true });
    const handleNotSecondOrarioLunedi = () => setValueLunedi({ ...valueLunedi, is_second_hour: false });

    const handleSecondOrarioMartedi = () => setValueMartedi({ ...valueMartedi, is_second_hour: true });
    const handleNotSecondOrarioMartedi = () => setValueMartedi({ ...valueMartedi, is_second_hour: false });

    const handleSecondOrarioMercoledi = () => setValueMercoledi({ ...valueMercoledi, is_second_hour: true });
    const handleNotSecondOrarioMercoledi = () => setValueMercoledi({ ...valueMercoledi, is_second_hour: false });

    const handleSecondOrarioGiovedi = () => setValueGiovedi({ ...valueGiovedi, is_second_hour: true });
    const handleNotSecondOrarioGiovedi = () => setValueGiovedi({ ...valueGiovedi, is_second_hour: false });

    const handleSecondOrarioVenerdi = () => setValueVenerdi({ ...valueVenerdi, is_second_hour: true });
    const handleNotSecondOrarioVenerdi = () => setValueVenerdi({ ...valueVenerdi, is_second_hour: false });
    
    const handleSecondOrarioSabato = () => setValueSabato({ ...valueSabato, is_second_hour: true });
    const handleNotSecondOrarioSabato = () => setValueSabato({ ...valueSabato, is_second_hour: false });
    
    const handleSecondOrarioDomenica = () => setValueDomenica({ ...valueDomenica, is_second_hour: true });
    const handleNotSecondOrarioDomenica = () => setValueDomenica({ ...valueDomenica, is_second_hour: false });

    const getPharmacyDaysWork = () => {
        setLoadingDaysWork(true)
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-pharmacy-days-work`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                setLoadingDaysWork(false)
                if (response.data.days_work.length > 0) {
                    response.data.days_work.forEach(element => {
                        switch (element.day_name) {
                            case 'Lunedì':
                                setValueLunedi({
                                    ...valueLunedi,
                                    id_day: element.id_day,
                                    open_hour: element.open_hour,
                                    second_open_hour: element.second_open_hour,
                                    closed_hour: element.closed_hour,
                                    second_closed_hour: element.second_closed_hour,
                                    is_open: parseInt(element.is_open),
                                    is_second_hour: (element.second_open_hour !== "" && element.second_closed_hour !== "" && element.second_open_hour !== null && element.second_closed_hour !== null) ? true : false
                                })
                                break;
                            case 'Martedì':
                                setValueMartedi({
                                    ...valueMartedi,
                                    id_day: element.id_day,
                                    open_hour: element.open_hour,
                                    second_open_hour: element.second_open_hour,
                                    closed_hour: element.closed_hour,
                                    second_closed_hour: element.second_closed_hour,
                                    is_open: parseInt(element.is_open),
                                    is_second_hour: (element.second_open_hour !== "" && element.second_closed_hour !== "" && element.second_open_hour !== null && element.second_closed_hour !== null) ? true : false
                                })
                                break;
                            case 'Mercoledì':
                                setValueMercoledi({
                                    ...valueMercoledi,
                                    id_day: element.id_day,
                                    open_hour: element.open_hour,
                                    second_open_hour: element.second_open_hour,
                                    closed_hour: element.closed_hour,
                                    second_closed_hour: element.second_closed_hour,
                                    is_open: parseInt(element.is_open),
                                    is_second_hour: (element.second_open_hour !== "" && element.second_closed_hour !== "" && element.second_open_hour !== null && element.second_closed_hour !== null) ? true : false
                                })
                                break;
                            case 'Giovedì':
                                setValueGiovedi({
                                    ...valueGiovedi,
                                    id_day: element.id_day,
                                    open_hour: element.open_hour,
                                    second_open_hour: element.second_open_hour,
                                    closed_hour: element.closed_hour,
                                    second_closed_hour: element.second_closed_hour,
                                    is_open: parseInt(element.is_open),
                                    is_second_hour: (element.second_open_hour !== "" && element.second_closed_hour !== "" && element.second_open_hour !== null && element.second_closed_hour !== null) ? true : false
                                })
                                break;
                            case 'Venerdì':
                                setValueVenerdi({
                                    ...valueVenerdi,
                                    id_day: element.id_day,
                                    open_hour: element.open_hour,
                                    second_open_hour: element.second_open_hour,
                                    closed_hour: element.closed_hour,
                                    second_closed_hour: element.second_closed_hour,
                                    is_open: parseInt(element.is_open),
                                    is_second_hour: (element.second_open_hour !== "" && element.second_closed_hour !== "" && element.second_open_hour !== null && element.second_closed_hour !== null) ? true : false
                                })
                                break;
                            case 'Sabato':
                                setValueSabato({
                                    ...valueSabato,
                                    id_day: element.id_day,
                                    open_hour: element.open_hour,
                                    second_open_hour: element.second_open_hour,
                                    closed_hour: element.closed_hour,
                                    second_closed_hour: element.second_closed_hour,
                                    is_open: parseInt(element.is_open),
                                    is_second_hour: (element.second_open_hour !== "" && element.second_closed_hour !== "" && element.second_open_hour !== null && element.second_closed_hour !== null) ? true : false
                                })
                                break;
                            case 'Domenica':
                                setValueDomenica({
                                    ...valueDomenica,
                                    id_day: element.id_day,
                                    open_hour: element.open_hour,
                                    second_open_hour: element.second_open_hour,
                                    closed_hour: element.closed_hour,
                                    second_closed_hour: element.second_closed_hour,
                                    is_open: parseInt(element.is_open),
                                    is_second_hour: (element.second_open_hour !== "" && element.second_closed_hour !== "" && element.second_open_hour !== null && element.second_closed_hour !== null) ? true : false
                                })
                                break;
                            default:
                                setValueLunedi({
                                    ...valueLunedi,
                                    id_day: element.id_day,
                                    open_hour: element.open_hour,
                                    second_open_hour: element.second_open_hour,
                                    closed_hour: element.closed_hour,
                                    second_closed_hour: element.second_closed_hour,
                                    is_open: parseInt(element.is_open),
                                    is_second_hour: (element.second_open_hour !== "" && element.second_closed_hour !== "" && element.second_open_hour !== null && element.second_closed_hour !== null) ? true : false
                                })
                                break;
                        }
                    });
                }
            } else {
                setLoadingDaysWork(false)
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            setLoadingDaysWork(false)
            console.error(error);
        });
    }

    const getTicketsMonth = (monthSelect) => {
        let startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
        let endOfMonth   = moment().endOf('month').format('YYYY-MM-DD');
        if (monthSelect !== null && monthSelect !== undefined) {
            startOfMonth = moment(monthSelect).startOf('month').format('YYYY-MM-DD');
            endOfMonth   = moment(monthSelect).endOf('month').format('YYYY-MM-DD');
        }
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-tickets-month`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy,
                first_day_month: startOfMonth,
                last_day_month: endOfMonth
            })
        })
        .then(function(response) {
            if (response.data.length > 0) {
                setAllTickets(response.data)
                let eventsTickets = [];
                let count = 0;
                response.data.forEach(element => {
                    count++;
                    eventsTickets.push({
                        start: new Date(`${element.date_slot} ${element.time_slot}`),
                        end:  new Date(`${element.date_slot} ${element.time_slot}`),
                        title: `${element.time_slot.split(':')[0]}:${element.time_slot.split(':')[1]} - ${element.name} ${element.surname}`,
                        is_event_closed: false,
                        is_event_ticket: true,
                        is_visit: false,
                        id_event: element.id,
                        colorEvento: (element.status == "Confermato") ? 'green' : (element.status == "Scaduto") ? 'red' : 'orange'
                    })
                    
                    if (count == response.data.length) {
                        setEventsTickets(eventsTickets)
                    }
                });
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const getTicketsVisitsMonth = (monthSelect) => {
        let startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
        let endOfMonth   = moment().endOf('month').format('YYYY-MM-DD');
        if (monthSelect !== null && monthSelect !== undefined) {
            startOfMonth = moment(monthSelect).startOf('month').format('YYYY-MM-DD');
            endOfMonth   = moment(monthSelect).endOf('month').format('YYYY-MM-DD');
        }
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-visits-tickets-month`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy,
                first_day_month: startOfMonth,
                last_day_month: endOfMonth
            })
        })
        .then(function(response) {
            if (response.data.length > 0) {
                setAllVisitsTickets(response.data)
                let eventsTickets = [];
                let count = 0;
                response.data.forEach(element => {
                    count++;
                    eventsTickets.push({
                        start: new Date(`${element.date_slot} ${element.time_slot}`),
                        end:  new Date(`${element.date_slot} ${element.time_slot}`),
                        title: `Visita - ${element.time_slot.split(':')[0]}:${element.time_slot.split(':')[1]} - ${element.name} ${element.surname}`,
                        is_event_closed: false,
                        is_event_ticket: true,
                        is_visit: true,
                        id_event: element.id,
                        colorEvento: (element.status == "Confermato") ? 'green' : (element.status == "Scaduto") ? 'red' : 'orange'
                    })
                    
                    if (count == response.data.length) {
                        setVisitsTickets(eventsTickets)
                    }
                });
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const selectEvent = (event) => {
        if (event.is_event_closed == true) {
            setEventSelect(event)
            setShowModalEvent(true)
        } else if (event.is_event_ticket == true) {
            if (event.is_visit == true) {
                const elementIndex = allVisitsTickets.findIndex(e => e.id == event.id_event)
                if (elementIndex !== -1) {
                    setTicketSelect(allVisitsTickets[elementIndex])
                    setShowModalTicket(true)
                }
            } else {
                const elementIndex = allTickets.findIndex(e => e.id == event.id_event)
                if (elementIndex !== -1) {
                    setTicketSelect(allTickets[elementIndex])
                    setShowModalTicket(true)
                }
            }
        }
    }

    const changeDateClosed = (e) => {
        if (e !== null) {
            setStartDateClosed(moment(e[0].$d).format('YYYY-MM-DD'))
            setEndDateClosed(moment(e[1].$d).format('YYYY-MM-DD'))
        } else {
            setStartDateClosed(null)
            setEndDateClosed(null)
        }
    }

    const checkAddPharmacyDaysWork = () => {
        let countDay = 0;
        if (valueLunedi.is_open == 1) {
            if (valueLunedi.open_hour !== "" && valueLunedi.closed_hour !== "") {
                if (valueLunedi.is_second_hour) {
                    if (valueLunedi.second_open_hour !== "" && valueLunedi.second_closed_hour !== "") {
                        countDay++;
                    } else {
                        toast.error("Inserisci l'ora di apertura e chiusura del Lunedì", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                } else {
                    countDay++;
                }
            } else {
                toast.error("Inserisci l'ora di apertura e chiusura del Lunedì", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            countDay++;
        }

        if (valueMartedi.is_open == 1) {
            if (valueMartedi.open_hour !== "" && valueMartedi.closed_hour !== "") {
                if (valueMartedi.is_second_hour) {
                    if (valueMartedi.second_open_hour !== "" && valueMartedi.second_closed_hour !== "") {
                        countDay++;
                    } else {
                        toast.error("Inserisci l'ora di apertura e chiusura del Martedì", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                } else {
                    countDay++;
                }
            } else {
                toast.error("Inserisci l'ora di apertura e chiusura del Martedì", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            countDay++;
        }
        
        if (valueMercoledi.is_open == 1) {
            if (valueMercoledi.open_hour !== "" && valueMercoledi.closed_hour !== "") {
                if (valueMercoledi.is_second_hour) {
                    if (valueMercoledi.second_open_hour !== "" && valueMercoledi.second_closed_hour !== "") {
                        countDay++;
                    } else {
                        toast.error("Inserisci l'ora di apertura e chiusura del Mercoledì", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                } else {
                    countDay++;
                }
            } else {
                toast.error("Inserisci l'ora di apertura e chiusura del Mercoledì", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            countDay++;
        }
        
        if (valueGiovedi.is_open == 1) {
            if (valueGiovedi.open_hour !== "" && valueGiovedi.closed_hour !== "") {
                if (valueGiovedi.is_second_hour) {
                    if (valueGiovedi.second_open_hour !== "" && valueGiovedi.second_closed_hour !== "") {
                        countDay++;
                    } else {
                        toast.error("Inserisci l'ora di apertura e chiusura del Giovedì", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                } else {
                    countDay++;
                }
            } else {
                toast.error("Inserisci l'ora di apertura e chiusura del Giovedì", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            countDay++;
        }
        
        if (valueVenerdi.is_open == 1) {
            if (valueVenerdi.open_hour !== "" && valueVenerdi.closed_hour !== "") {
                if (valueVenerdi.is_second_hour) {
                    if (valueVenerdi.second_open_hour !== "" && valueVenerdi.second_closed_hour !== "") {
                        countDay++;
                    } else {
                        toast.error("Inserisci l'ora di apertura e chiusura del Venerdì", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                } else {
                    countDay++;
                }
            } else {
                toast.error("Inserisci l'ora di apertura e chiusura del Venerdì", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            countDay++;
        }
        
        if (valueSabato.is_open == 1) {
            if (valueSabato.open_hour !== "" && valueSabato.closed_hour !== "") {
                if (valueSabato.is_second_hour) {
                    if (valueSabato.second_open_hour !== "" && valueSabato.second_closed_hour !== "") {
                        countDay++;
                    } else {
                        toast.error("Inserisci l'ora di apertura e chiusura del Sabato", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                } else {
                    countDay++;
                }
            } else {
                toast.error("Inserisci l'ora di apertura e chiusura del Sabato", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            countDay++;
        }
        
        if (valueDomenica.is_open == 1) {
            if (valueDomenica.open_hour !== "" && valueDomenica.closed_hour !== "") {
                if (valueDomenica.is_second_hour) {
                    if (valueDomenica.second_open_hour !== "" && valueDomenica.second_closed_hour !== "") {
                        countDay++;
                    } else {
                        toast.error("Inserisci l'ora di apertura e chiusura del Domenica", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                } else {
                    countDay++;
                }
            } else {
                toast.error("Inserisci l'ora di apertura e chiusura del Domenica", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            countDay++;
        }

        if (countDay === 7) {
            addPharmacyDaysWork()
        }
    }

    const addPharmacyDaysWork = () => {
        setLoadingHours(true)
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        let days_work = [];
        days_work.push(valueLunedi);
        days_work.push(valueMartedi);
        days_work.push(valueMercoledi);
        days_work.push(valueGiovedi);
        days_work.push(valueVenerdi);
        days_work.push(valueSabato);
        days_work.push(valueDomenica);
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/add-pharmacy-days-work`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy,
                array_days_work: days_work
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                toast.success(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                getPharmacyDaysWork()
                setLoadingHours(false)
                setShowModal(false)
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setLoadingHours(false)
            }
        })
        .catch(function(error) {
            console.error(error);
            setLoadingHours(false)
        });
    }

    const getDates = (startDate, stopDate) => {
        let dateArray = [];
        let currentDate = moment(startDate);
        const endDate = moment(stopDate);
        while (currentDate <= endDate) {
            dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
            currentDate = moment(currentDate).add(1, 'days');
        }
        return dateArray;
    }

    const getPharmacyDaysClosed = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-pharmacy-days-closed`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                if (response.data.pharmacy_days_closed.length > 0) {
                    let events = [];
                    let completeDates = [];
                    response.data.pharmacy_days_closed.forEach(element => {
                        const allDates = getDates(element.start_date_closed, element.end_date_closed);
                        completeDates = [...completeDates, ...allDates];
                        events.push({
                            start: element.start_date_closed,
                            end: element.end_date_closed,
                            title: (element.description !== "" && element.description !== null) ? element.description : "Chiuso",
                            allDay: true,
                            colorEvento: '#3174ad',
                            is_event_closed: true,
                            is_event_ticket: false,
                            id_event: element.id
                        })
                        setEventsClosed(events)
                        setAllDatesClosed(completeDates)
                    });
                } else {
                    setAllDatesClosed([])
                    setEventsClosed([])
                }
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const saveCloseDays = () => {
        setLoadingClosed(true)
        if (startDateClosed !== null && endDateClosed !== null) {
            const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
            axios({
                method: 'post',
                url: `${config.WEBSITE_URL}/partners/add-pharmacy-days-closed`,
                headers: {
                    'Content-Type': 'application/json'
                },
                auth: {
                    username: config.USERNAME_TOKEN,
                    password: config.PASSWORD_TOKEN
                },
                data: JSON.stringify({
                    token_access: config.WEB_TOKEN,
                    id_pharmacy: pharmacyInfo.id_pharmacy,
                    start_date_closed: startDateClosed,
                    end_date_closed: endDateClosed,
                    description: closeDays.description
                })
            })
            .then(function(response) {
                if (response.data.code == true) {
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setStartDateClosed('')
                    setEndDateClosed('')
                    getPharmacyDaysClosed()
                    setLoadingClosed(false)
                    setShow(false)
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500);
                } else {
                    toast.error(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setLoadingClosed(false)
                }
            })
            .catch(function(error) {
                console.error(error);
                setLoadingClosed(false)
            });
        } else {
            toast.error("Inserisci le date di chiusura", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoadingClosed(false)
        }
    }

    const disabledDate = (current) => {
        // Can not select days before today and today
        if (allDatesClosed.includes(moment(current.$d).format('YYYY-MM-DD'))) {
            return current;
        }
    }

    const deleteCloseDays = (id_event) => {
        confirm({
            title: 'ATTENZIONE',
            icon: <ExclamationCircleFilled />,
            content: 'Sei sicuro di voler eliminare i giorni di chiusura?',
            onOk() {
                deleteCloseDaysAccept(id_event)
            },
            okButtonProps: { danger: true },
            okText: "Elimina",
            cancelText: "Annulla"
        });
    }

    const deleteCloseDaysAccept = (id_event) => {
        setLoadingDeleteDays(true)
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/delete-pharmacy-days-closed`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy,
                id_date_closed: id_event
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                toast.success(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                getPharmacyDaysClosed()
                setLoadingDeleteDays(false)
                setShowModalEvent(false)
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setLoadingDeleteDays(false)
            }
        })
        .catch(function(error) {
            console.error(error);
            setLoadingDeleteDays(false)
        });
    }

    const onSelectMotivationChange = (value) => {
        setSelectMotivation(value)
    }

    const deleteUserTicket = () => {
        setLoadingMotivation(true)
        if (selectMotivation !== null && motivation !== "") {
            if (selectMotivation == "1") {
                axios({
                    method: 'post',
                    url: `${config.WEBSITE_URL}/partners/edit-user-ticket-visit`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    auth: {
                        username: config.USERNAME_TOKEN,
                        password: config.PASSWORD_TOKEN
                    },
                    data: JSON.stringify({
                        token_access: config.WEB_TOKEN,
                        id_ticket: ticketSelect.id,
                        motivation: motivation
                    })
                })
                .then(function(response) {
                    if (response.data.code == true) {
                        if (response.data.result == true) {
                            setLoadingMotivation(false)
                            setShowModalTicket(false)
                            setShowDelte(false)
                            setSelectMotivation(null)
                            setMotivation("")
                            getTicketsVisitsMonth(ticketSelect.date_slot)
                            toast.success(response.data.message, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        } else {
                            setLoadingMotivation(false)
                            toast.error(response.data.message, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    } else {
                        setLoadingMotivation(false)
                        toast.error(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch(function(error) {
                    setLoadingMotivation(false)
                    toast.error("Non è statp possibile eliminare il ticket. Riprova più tardi", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
            } else if (selectMotivation == "2") {
                axios({
                    method: 'post',
                    url: `${config.WEBSITE_URL}/partners/edit-user-ticket-visit-definitive`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    auth: {
                        username: config.USERNAME_TOKEN,
                        password: config.PASSWORD_TOKEN
                    },
                    data: JSON.stringify({
                        token_access: config.WEB_TOKEN,
                        id_ticket: ticketSelect.id,
                        motivation: motivation
                    })
                })
                .then(function(response) {
                    if (response.data.code == true) {
                        if (response.data.result == true) {
                            setLoadingMotivation(false)
                            setShowModalTicket(false)
                            setShowDelte(false)
                            setSelectMotivation(null)
                            setMotivation("")
                            getTicketsVisitsMonth(ticketSelect.date_slot)
                            toast.success(response.data.message, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        } else {
                            setLoadingMotivation(false)
                            toast.error(response.data.message, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    } else {
                        setLoadingMotivation(false)
                        toast.error(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch(function(error) {
                    setLoadingMotivation(false)
                    toast.error("Non è statp possibile eliminare il ticket. Riprova più tardi", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
            }
        } else {
            setLoadingMotivation(false)
            toast.error("Compila tutti i campi obbligatori*", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const copyAllHours = (open, second_open, close, second_close, is_second_hour) => {
        setValueLunedi({ ...valueLunedi, open_hour: open, second_open_hour: second_open, closed_hour: close, second_closed_hour: second_close, is_second_hour: is_second_hour })
        setValueMartedi({ ...valueMartedi, open_hour: open, second_open_hour: second_open, closed_hour: close, second_closed_hour: second_close, is_second_hour: is_second_hour })
        setValueMercoledi({ ...valueMercoledi, open_hour: open, second_open_hour: second_open, closed_hour: close, second_closed_hour: second_close, is_second_hour: is_second_hour })
        setValueGiovedi({ ...valueGiovedi, open_hour: open, second_open_hour: second_open, closed_hour: close, second_closed_hour: second_close, is_second_hour: is_second_hour })
        setValueVenerdi({ ...valueVenerdi, open_hour: open, second_open_hour: second_open, closed_hour: close, second_closed_hour: second_close, is_second_hour: is_second_hour })
        setValueSabato({ ...valueSabato, open_hour: open, second_open_hour: second_open, closed_hour: close, second_closed_hour: second_close, is_second_hour: is_second_hour })
        setValueDomenica({ ...valueDomenica, open_hour: open, second_open_hour: second_open, closed_hour: close, second_closed_hour: second_close, is_second_hour: is_second_hour })
    }

    return(
        <>
        <ToastContainer />
        <Helmet>
            <title>Calendario | Takeiteasy Partners</title>
        </Helmet>
        <Tour
            steps={steps}
            isOpen={isTourOpen}
            onRequestClose={() => setIsTourOpen(false)}
            rounded={15}
        />
        <Navbar />
        <div className="app-content content mb-5">
            <div className="content-overlay"></div>
            <div className="content-wrapper">
                <Errors />
                <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2">
                        <h3 className="content-header-title">Calendario farmacia</h3>
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Gestisci gli orari di apertura e le prenotazioni</h4>
                                </div>
                                <div className="card-content">
                                    {(haveError)
                                        ?
                                        <Empty
                                            description={
                                                <span>
                                                    Risolvi gli errori per poter continuare.
                                                </span>
                                            }
                                        />
                                        :  
                                        <div className="card-body">
                                            <div className='row mb-lg-0 mb-md-0 mb-1'>
                                                <div className='col-lg-2 col-md-4 col-12'>
                                                    <div className="d-flex align-items-center" style={{ marginBottom: 5 }}>
                                                        <div style={{ width: '1.2rem', height: '1.2rem', borderRadius: '0.6rem', backgroundColor: 'green', marginRight: 7 }}></div>
                                                        <span>Ticket Confermato</span>
                                                    </div>
                                                </div>
                                                <div className='col-lg-2 col-md-4 col-12'>
                                                    <div className="d-flex align-items-center" style={{ marginBottom: 5 }}>
                                                        <div style={{ width: '1.2rem', height: '1.2rem', borderRadius: '0.6rem', backgroundColor: 'orange', marginRight: 7 }}></div>
                                                        <span>Ticket Utilizzato</span>
                                                    </div>
                                                </div>
                                                <div className='col-lg-2 col-md-4 col-12'>
                                                    <div className="d-flex align-items-center">
                                                        <div style={{ width: '1.2rem', height: '1.2rem', borderRadius: '0.6rem', backgroundColor: 'red', marginRight: 7 }}></div>
                                                        <span>Ticket Scaduto</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mb-1'>
                                                <div className='col-12 d-flex align-items-center justify-content-end'>
                                                    {(loadingDaysWork)
                                                        ?
                                                        <Skeleton.Button active={true} size='large' className='mr-1' />
                                                        :
                                                        <ButtonAntd className='mr-1 d-flex align-items-center' size='large' type='default' icon={<ClockCircleTwoTone />} onClick={handleShowModal}>
                                                            Orari di lavoro
                                                        </ButtonAntd>
                                                    }
                                                    <ButtonAntd className='d-flex align-items-center' size='large' type='primary' icon={<StopFilled />} onClick={handleShow}>
                                                        Giorni di chiusura
                                                    </ButtonAntd>
                                                </div>
                                            </div>
                                            <CalendarComponent
                                                localizer={localizer}
                                                defaultDate={new Date()}
                                                defaultView="month"
                                                onNavigate={(result) => {getTicketsMonth(result); getTicketsVisitsMonth(result)}}
                                                events={[...eventsTickets, ...visitsTickets, ...eventsClosed]}
                                                style={{ height: "110vh" }}
                                                messages={{ 
                                                    today: "Oggi",
                                                    previous: "Precedente",
                                                    next: "Prossimo",
                                                    month: "Mese",
                                                    week: "Settimana",
                                                    day: "Giorno",
                                                    agenda: "Agenda",
                                                    date: "Data",
                                                    time: "Ora",
                                                    event: "Evento"
                                                }}
                                                onSelectSlot={handleShow}
                                                selectable
                                                views={['month','week','day']}
                                                eventPropGetter={(myEventsList) => {
                                                    const backgroundColor = myEventsList.colorEvento ? myEventsList.colorEvento : '#3174ad';
                                                    return { style: { backgroundColor } }
                                                }}
                                                onSelectEvent={(event) => selectEvent(event)}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        <Modal title="Giorni di chiusura" open={showModalEvent} okButtonProps={{ style: { display: 'none' } }} cancelText="Chiudi" onCancel={() => setShowModalEvent(false)}>
            {(eventSelect !== null)
                &&
                <div>
                    <h5>{eventSelect.title}</h5>
                    <span>Data inizio: <b>{moment(eventSelect.start).format('DD/MM/YYYY')}</b></span><br />
                    <span>Data fine: <b>{moment(eventSelect.end).format('DD/MM/YYYY')}</b></span><br />
                    <ButtonAntd onClick={(loadingDeleteDays) ? null : () => deleteCloseDays(eventSelect.id_event)} type='primary' danger className='mt-1'>
                        {(loadingDeleteDays)
                            ?
                            <ThreeDots height="11" width="23" radius="4.5" color="#fff" ariaLabel="three-dots-loading" wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }} visible={true} />
                            :
                            "Elimina"
                        }
                    </ButtonAntd>
                </div>
            }
        </Modal>
        <Modal title="Dettaglio ticket" open={showModalTicket} okButtonProps={{ style: { display: 'none' } }} cancelText="Chiudi" onCancel={() => {setShowModalTicket(false); setShowDelte(false)}}>
            {(ticketSelect !== null)
                &&
                <div className="row mt-2">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-content">
                                <div className="card-body">
                                    <div className="media d-flex">
                                        <div className="media-body text-left">
                                            <h3>{ticketSelect.name} {ticketSelect.surname}</h3>
                                            {(ticketSelect.is_visit == true)
                                                &&
                                                <>
                                                <span style={{ fontSize: '1rem', fontWeight: '500' }}>
                                                    {ticketSelect.name_visit}
                                                </span><br/>
                                                <span style={{ fontSize: '1rem' }}>
                                                    Durata: <b>{ticketSelect.duration}min</b><br/>Prezzo: <b>{ticketSelect.price}</b>
                                                </span>
                                                </>
                                            }
                                            <div className='d-flex align-items-center'>
                                                <span className='mr-1' style={{ fontSize: '1.2rem', textTransform: 'capitalize' }}>
                                                    {moment(ticketSelect.date_slot).format('ddd DD MMM')} {ticketSelect.time_slot.split(':')[0]}:{ticketSelect.time_slot.split(':')[1]}
                                                </span>
                                                {(ticketSelect.status == "Confermato")
                                                    ?
                                                    <Alert message={ticketSelect.status} type='success' showIcon />
                                                    :
                                                    (ticketSelect.status == "Utilizzato")
                                                        ?
                                                        <Alert message={ticketSelect.status} type='warning' showIcon />
                                                        :
                                                        <Alert message={ticketSelect.status} type='error' showIcon />
                                                }
                                                {(ticketSelect.is_visit == true)
                                                    &&
                                                    <Alert message="Visita" className="ml-1" type='info' />
                                                }
                                            </div>
                                        </div>
                                        <div className="align-self-center">
                                            <div className="avatar mr-1 avatar-lg">
                                                <img src={ticketSelect.avatar} />
                                            </div>
                                        </div>
                                    </div>
                                    {(ticketSelect.is_visit == true && ticketSelect.status == "Confermato")
                                        &&
                                        <>
                                        <Divider orientation="left">Azioni</Divider>
                                        <ButtonAntd type={(showDelete) ? "primary" : "default"} onClick={() => setShowDelte(!showDelete)} danger>Elimina ticket</ButtonAntd>
                                        {(showDelete)
                                            &&
                                            <>
                                            <div className='row mt-1'>
                                                <div className='col-12 mb-2'>
                                                    <label htmlFor='select-motivation'>Seleziona la tipologia di eliminazione*</label>
                                                    <Select
                                                        style={{ width: '100%' }}
                                                        id='select-motivation'
                                                        placeholder="Scegli un'opzione"
                                                        onChange={onSelectMotivationChange}
                                                        allowClear
                                                        value={selectMotivation}
                                                    >
                                                        <Option value="1">Elimina il ticket e blocca lo slot delle {ticketSelect.time_slot.split(':')[0]}:{ticketSelect.time_slot.split(':')[1]}</Option>
                                                        <Option value="2">Elimina il ticket e abilita lo slot delle {ticketSelect.time_slot.split(':')[0]}:{ticketSelect.time_slot.split(':')[1]}</Option>
                                                    </Select>
                                                </div>
                                                <div className='col-12 mb-2'>
                                                    <label htmlFor='motivation'>Descrivi il motivi dell'eliminazione*</label>
                                                    <TextArea showCount maxLength={100} style={{ height: 50 }} name="motivation" placeholder="Inserisci motivazione" value={motivation} onChange={(e) => setMotivation(e.target.value)} />
                                                </div>
                                                <div className='col-12 d-flex justify-content-end mt-2'>
                                                   <ButtonAntd type="primary" onClick={(loadingMotivation) ? null : deleteUserTicket}>
                                                        {(loadingMotivation)
                                                            ?
                                                            <ThreeDots height="11" width="23" radius="4.5" color="#fff" ariaLabel="three-dots-loading" wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }} visible={true} />
                                                            :
                                                            "Salva"
                                                        }
                                                   </ButtonAntd>
                                                </div>
                                            </div>
                                            </>
                                        }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Modal>
        <Modal title="Seleziona i giorni di chiusura" open={show} okText={(loadingClosed) ? <ThreeDots height="11" width="23" radius="4.5" color="#fff" ariaLabel="three-dots-loading" wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }} visible={true} /> : "Salva"} cancelText="Chiudi" onOk={(loadingClosed) ? null : saveCloseDays} onCancel={handleClose}>
            <div className='row mt-1'>
                <div className='col-12'>
                    <label htmlFor='description'>Motivo della chiusura</label>
                    <TextArea showCount maxLength={300} style={{ height: 120 }} name="description" placeholder="Descrivi il motivo della chiusura" value={closeDays.description} onChange={(e) => setCloseDays({ ...closeDays, description: e.target.value })} />
                </div>
                <div className='col-12'>
                    <label htmlFor='date_close'>Giorni chiusura*</label><br />
                    <RangePicker name='date_close' disabledDate={disabledDate} style={{ width: '100%' }} defaultValue={(startDateClosed !== null || endDateClosed !== null) ? [dayjs(moment(startDateClosed).format('DD-MM-YYYY'), 'DD-MM-YYYY'),dayjs(moment(endDateClosed).format('DD-MM-YYYY'), 'DD-MM-YYYY')] : null} format='DD-MM-YYYY' onChange={(e) => changeDateClosed(e)} locale={locale} size='large' />
                </div>
            </div>
        </Modal>
        <Modal title="Orari di lavoro" width={800} open={showModal} okText={(loadingHours) ? <ThreeDots height="11" width="23" radius="4.5" color="#fff" ariaLabel="three-dots-loading" wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }} visible={true} /> : "Salva"} cancelText="Chiudi" onOk={(loadingHours) ? null : checkAddPharmacyDaysWork} onCancel={handleCloseModal}>
            <div className="row mb-2">
                <div className="col-xl-4 col-md-12 col-12 mb-lg-0 mb-md-1 mb-1">
                    <h4 style={{ fontWeight: '500' }}>Lunedì</h4>
                    <Switch defaultChecked={(valueLunedi.is_open == 1) ? true : false } unCheckedChildren="Chiuso" checkedChildren="Aperto" onChange={() => setValueLunedi((valueLunedi.is_open == 0) ? { ...valueLunedi, is_open: 1 } : { ...valueLunedi, is_open: 0 })} />
                </div>
                {(valueLunedi.is_open == 1)
                    &&
                    <div className="col-xl-8 col-md-12 col-12">
                        <div className="d-flex align-items-center">
                            <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueLunedi.open_hour} onChange={(value) => setValueLunedi({ ...valueLunedi, open_hour: value.target.value })} />
                            <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueLunedi.closed_hour} onChange={(value) => setValueLunedi({ ...valueLunedi, closed_hour: value.target.value })} />
                            <div className='ml-2'>
                                {(!valueLunedi.is_second_hour)
                                    ?
                                    <ButtonAntd className='d-flex align-items-center justify-content-center' type='primary' icon={<PlusOutlined />} shape='circle' size='large' onClick={handleSecondOrarioLunedi} />
                                    :
                                    <ButtonAntd className='d-flex align-items-center justify-content-center' danger type='primary' icon={<CloseOutlined />} shape='circle' size='large' onClick={handleNotSecondOrarioLunedi} />
                                }
                                <Tooltip title="Clicca sul pulsante per copiare gli orari su tutti i giorni della settimana" placement='right'>
                                    <ButtonAntd style={{ backgroundColor: 'green' }} className='d-flex align-items-center justify-content-center mt-1' type='primary' icon={<CopyOutlined />} shape='circle' size='large' onClick={() => copyAllHours(valueLunedi.open_hour, valueLunedi.second_open_hour, valueLunedi.closed_hour, valueLunedi.second_closed_hour, valueLunedi.is_second_hour)} />
                                </Tooltip>
                            </div>
                        </div>
                        {(valueLunedi.is_second_hour)
                            &&
                            <div className="d-flex align-items-center mt-1">
                                <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueLunedi.second_open_hour} onChange={(value) => setValueLunedi({ ...valueLunedi, second_open_hour: value.target.value })} />
                                <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueLunedi.second_closed_hour} onChange={(value) => setValueLunedi({ ...valueLunedi, second_closed_hour: value.target.value })} />
                            </div>
                        }
                    </div>
                }
            </div>
            <hr />
            <div className="row my-2">
                <div className="col-xl-4 col-md-12 col-12 mb-lg-0 mb-md-1 mb-1">
                    <h4 style={{ fontWeight: '500' }}>Martedì</h4>
                    <Switch defaultChecked={(valueMartedi.is_open == 1) ? true : false } unCheckedChildren="Chiuso" checkedChildren="Aperto" onChange={() => setValueMartedi((valueMartedi.is_open == 0) ? { ...valueMartedi, is_open: 1 } : { ...valueMartedi, is_open: 0 })} />
                </div>
                {(valueMartedi.is_open == 1)
                    &&
                    <div className="col-xl-8 col-md-12 col-12">
                        <div className="d-flex align-items-center">
                            <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueMartedi.open_hour} onChange={(value) => setValueMartedi({ ...valueMartedi, open_hour: value.target.value })} />
                            <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueMartedi.closed_hour} onChange={(value) => setValueMartedi({ ...valueMartedi, closed_hour: value.target.value })} />
                            <div className='ml-2'>
                                {(!valueMartedi.is_second_hour)
                                    ?
                                    <ButtonAntd className='d-flex align-items-center justify-content-center' type='primary' icon={<PlusOutlined />} shape='circle' size='large' onClick={handleSecondOrarioMartedi} />
                                    :
                                    <ButtonAntd className='d-flex align-items-center justify-content-center' danger type='primary' icon={<CloseOutlined />} shape='circle' size='large' onClick={handleNotSecondOrarioMartedi} />
                                }
                                <Tooltip title="Clicca sul pulsante per copiare gli orari su tutti i giorni della settimana" placement='right'>
                                    <ButtonAntd style={{ backgroundColor: 'green' }} className='d-flex align-items-center justify-content-center mt-1' type='primary' icon={<CopyOutlined />} shape='circle' size='large' onClick={() => copyAllHours(valueMartedi.open_hour, valueMartedi.second_open_hour, valueMartedi.closed_hour, valueMartedi.second_closed_hour, valueMartedi.is_second_hour)} />
                                </Tooltip>
                            </div>
                        </div>
                        {(valueMartedi.is_second_hour)
                            &&
                            <div className="d-flex align-items-center mt-1">
                                <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueMartedi.second_open_hour} onChange={(value) => setValueMartedi({ ...valueMartedi, second_open_hour: value.target.value })} />
                                <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueMartedi.second_closed_hour} onChange={(value) => setValueMartedi({ ...valueMartedi, second_closed_hour: value.target.value })} />
                            </div>
                        }
                    </div>
                }
            </div>
            <hr />
            <div className="row my-2">
                <div className="col-xl-4 col-md-12 col-12 mb-lg-0 mb-md-1 mb-1">
                    <h4 style={{ fontWeight: '500' }}>Mercoledì</h4>
                    <Switch defaultChecked={(valueMercoledi.is_open == 1) ? true : false } unCheckedChildren="Chiuso" checkedChildren="Aperto" onChange={() => setValueMercoledi((valueMercoledi.is_open == 0) ? { ...valueMercoledi, is_open: 1 } : { ...valueMercoledi, is_open: 0 })} />
                </div>
                {(valueMercoledi.is_open == 1)
                    &&
                    <div className="col-xl-8 col-md-12 col-12">
                        <div className="d-flex align-items-center">
                            <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueMercoledi.open_hour} onChange={(value) => setValueMercoledi({ ...valueMercoledi, open_hour: value.target.value })} />
                            <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueMercoledi.closed_hour} onChange={(value) => setValueMercoledi({ ...valueMercoledi, closed_hour: value.target.value })} />
                            <div className='ml-2'>
                                {(!valueMercoledi.is_second_hour)
                                    ?
                                    <ButtonAntd className='d-flex align-items-center justify-content-center' type='primary' icon={<PlusOutlined />} shape='circle' size='large' onClick={handleSecondOrarioMercoledi} />
                                    :
                                    <ButtonAntd className='d-flex align-items-center justify-content-center' danger type='primary' icon={<CloseOutlined />} shape='circle' size='large' onClick={handleNotSecondOrarioMercoledi} />
                                }
                                <Tooltip title="Clicca sul pulsante per copiare gli orari su tutti i giorni della settimana" placement='right'>
                                    <ButtonAntd style={{ backgroundColor: 'green' }} className='d-flex align-items-center justify-content-center mt-1' type='primary' icon={<CopyOutlined />} shape='circle' size='large' onClick={() => copyAllHours(valueMercoledi.open_hour, valueMercoledi.second_open_hour, valueMercoledi.closed_hour, valueMercoledi.second_closed_hour, valueMercoledi.is_second_hour)} />
                                </Tooltip>
                            </div>
                        </div>
                        {(valueMercoledi.is_second_hour)
                            &&
                            <div className="d-flex align-items-center mt-1">
                                <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueMercoledi.second_open_hour} onChange={(value) => setValueMercoledi({ ...valueMercoledi, second_open_hour: value.target.value })} />
                                <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueMercoledi.second_closed_hour} onChange={(value) => setValueMercoledi({ ...valueMercoledi, second_closed_hour: value.target.value })} />
                            </div>
                        }
                    </div>
                }
            </div>
            <hr />
            <div className="row my-2">
                <div className="col-xl-4 col-md-12 col-12 mb-lg-0 mb-md-1 mb-1">
                    <h4 style={{ fontWeight: '500' }}>Giovedì</h4>
                    <Switch defaultChecked={(valueGiovedi.is_open == 1) ? true : false } unCheckedChildren="Chiuso" checkedChildren="Aperto" onChange={() => setValueGiovedi((valueGiovedi.is_open == 0) ? { ...valueGiovedi, is_open: 1 } : { ...valueGiovedi, is_open: 0 })} />
                </div>
                {(valueGiovedi.is_open == 1)
                    &&
                    <div className="col-xl-8 col-md-12 col-12">
                        <div className="d-flex align-items-center">
                            <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueGiovedi.open_hour} onChange={(value) => setValueGiovedi({ ...valueGiovedi, open_hour: value.target.value })} />
                            <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueGiovedi.closed_hour} onChange={(value) => setValueGiovedi({ ...valueGiovedi, closed_hour: value.target.value })} />
                            <div className='ml-2'>
                                {(!valueGiovedi.is_second_hour)
                                    ?
                                    <ButtonAntd className='d-flex align-items-center justify-content-center' type='primary' icon={<PlusOutlined />} shape='circle' size='large' onClick={handleSecondOrarioGiovedi} />
                                    :
                                    <ButtonAntd className='d-flex align-items-center justify-content-center' danger type='primary' icon={<CloseOutlined />} shape='circle' size='large' onClick={handleNotSecondOrarioGiovedi} />
                                }
                                <Tooltip title="Clicca sul pulsante per copiare gli orari su tutti i giorni della settimana" placement='right'>
                                    <ButtonAntd style={{ backgroundColor: 'green' }} className='d-flex align-items-center justify-content-center mt-1' type='primary' icon={<CopyOutlined />} shape='circle' size='large' onClick={() => copyAllHours(valueGiovedi.open_hour, valueGiovedi.second_open_hour, valueGiovedi.closed_hour, valueGiovedi.second_closed_hour, valueGiovedi.is_second_hour)} />
                                </Tooltip>
                            </div>
                        </div>
                        {(valueGiovedi.is_second_hour)
                            &&
                            <div className="d-flex align-items-center mt-1">
                                <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueGiovedi.second_open_hour} onChange={(value) => setValueGiovedi({ ...valueGiovedi, second_open_hour: value.target.value })} />
                                <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueGiovedi.second_closed_hour} onChange={(value) => setValueGiovedi({ ...valueGiovedi, second_closed_hour: value.target.value })} />
                            </div>
                        }
                    </div>
                }
            </div>
            <hr />
            <div className="row my-2">
                <div className="col-xl-4 col-md-12 col-12 mb-lg-0 mb-md-1 mb-1">
                    <h4 style={{ fontWeight: '500' }}>Venerdì</h4>
                    <Switch defaultChecked={(valueVenerdi.is_open == 1) ? true : false } unCheckedChildren="Chiuso" checkedChildren="Aperto" onChange={() => setValueVenerdi((valueVenerdi.is_open == 0) ? { ...valueVenerdi, is_open: 1 } : { ...valueVenerdi, is_open: 0 })} />
                </div>
                {(valueVenerdi.is_open == 1)
                    &&
                    <div className="col-xl-8 col-md-12 col-12">
                        <div className="d-flex align-items-center">
                            <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueVenerdi.open_hour} onChange={(value) => setValueVenerdi({ ...valueVenerdi, open_hour: value.target.value })} />
                            <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueVenerdi.closed_hour} onChange={(value) => setValueVenerdi({ ...valueVenerdi, closed_hour: value.target.value })} />
                            <div className='ml-2'>
                                {(!valueVenerdi.is_second_hour)
                                    ?
                                    <ButtonAntd className='d-flex align-items-center justify-content-center' type='primary' icon={<PlusOutlined />} shape='circle' size='large' onClick={handleSecondOrarioVenerdi} />
                                    :
                                    <ButtonAntd className='d-flex align-items-center justify-content-center' danger type='primary' icon={<CloseOutlined />} shape='circle' size='large' onClick={handleNotSecondOrarioVenerdi} />
                                }
                                <Tooltip title="Clicca sul pulsante per copiare gli orari su tutti i giorni della settimana" placement='right'>
                                    <ButtonAntd style={{ backgroundColor: 'green' }} className='d-flex align-items-center justify-content-center mt-1' type='primary' icon={<CopyOutlined />} shape='circle' size='large' onClick={() => copyAllHours(valueVenerdi.open_hour, valueVenerdi.second_open_hour, valueVenerdi.closed_hour, valueVenerdi.second_closed_hour, valueVenerdi.is_second_hour)} />
                                </Tooltip>
                            </div>
                        </div>
                        {(valueVenerdi.is_second_hour)
                            &&
                            <div className="d-flex align-items-center mt-1">
                                <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueVenerdi.second_open_hour} onChange={(value) => setValueVenerdi({ ...valueVenerdi, second_open_hour: value.target.value })} />
                                <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueVenerdi.second_closed_hour} onChange={(value) => setValueVenerdi({ ...valueVenerdi, second_closed_hour: value.target.value })} />
                            </div>
                        }
                    </div>
                }
            </div>
            <hr />
            <div className="row my-2">
                <div className="col-xl-4 col-md-12 col-12 mb-lg-0 mb-md-1 mb-1">
                    <h4 style={{ fontWeight: '500' }}>Sabato</h4>
                    <Switch defaultChecked={(valueSabato.is_open == 1) ? true : false } unCheckedChildren="Chiuso" checkedChildren="Aperto" onChange={() => setValueSabato((valueSabato.is_open == 0) ? { ...valueSabato, is_open: 1 } : { ...valueSabato, is_open: 0 })} />
                </div>
                {(valueSabato.is_open == 1)
                    &&
                    <div className="col-xl-8 col-md-12 col-12">
                        <div className="d-flex align-items-center">
                            <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueSabato.open_hour} onChange={(value) => setValueSabato({ ...valueSabato, open_hour: value.target.value })} />
                            <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueSabato.closed_hour} onChange={(value) => setValueSabato({ ...valueSabato, closed_hour: value.target.value })} />
                            <div className='ml-2'>
                                {(!valueSabato.is_second_hour)
                                    ?
                                    <ButtonAntd className='d-flex align-items-center justify-content-center' type='primary' icon={<PlusOutlined />} shape='circle' size='large' onClick={handleSecondOrarioSabato} />
                                    :
                                    <ButtonAntd className='d-flex align-items-center justify-content-center' danger type='primary' icon={<CloseOutlined />} shape='circle' size='large' onClick={handleNotSecondOrarioSabato} />
                                }
                                <Tooltip title="Clicca sul pulsante per copiare gli orari su tutti i giorni della settimana" placement='right'>
                                    <ButtonAntd style={{ backgroundColor: 'green' }} className='d-flex align-items-center justify-content-center mt-1' type='primary' icon={<CopyOutlined />} shape='circle' size='large' onClick={() => copyAllHours(valueSabato.open_hour, valueSabato.second_open_hour, valueSabato.closed_hour, valueSabato.second_closed_hour, valueSabato.is_second_hour)} />
                                </Tooltip>
                            </div>
                        </div>
                        {(valueSabato.is_second_hour)
                            &&
                            <div className="d-flex align-items-center mt-1">
                                <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueSabato.second_open_hour} onChange={(value) => setValueSabato({ ...valueSabato, second_open_hour: value.target.value })} />
                                <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueSabato.second_closed_hour} onChange={(value) => setValueSabato({ ...valueSabato, second_closed_hour: value.target.value })} />
                            </div>
                        }
                    </div>
                }
            </div>
            <hr />
            <div className="row mt-2">
                <div className="col-xl-4 col-md-12 col-12 mb-lg-0 mb-md-1 mb-1">
                    <h4 style={{ fontWeight: '500' }}>Domenica</h4>
                    <Switch defaultChecked={(valueDomenica.is_open == 1) ? true : false } unCheckedChildren="Chiuso" checkedChildren="Aperto" onChange={() => setValueDomenica((valueDomenica.is_open == 0) ? { ...valueDomenica, is_open: 1 } : { ...valueDomenica, is_open: 0 })} />
                </div>
                {(valueDomenica.is_open == 1)
                    &&
                    <div className="col-xl-8 col-md-12 col-12">
                        <div className="d-flex align-items-center">
                            <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueDomenica.open_hour} onChange={(value) => setValueDomenica({ ...valueDomenica, open_hour: value.target.value })} />
                            <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueDomenica.closed_hour} onChange={(value) => setValueDomenica({ ...valueDomenica, closed_hour: value.target.value })} />
                            <div className='ml-2'>
                                {(!valueDomenica.is_second_hour)
                                    ?
                                    <ButtonAntd className='d-flex align-items-center justify-content-center' type='primary' icon={<PlusOutlined />} shape='circle' size='large' onClick={handleSecondOrarioDomenica} />
                                    :
                                    <ButtonAntd className='d-flex align-items-center justify-content-center' danger type='primary' icon={<CloseOutlined />} shape='circle' size='large' onClick={handleNotSecondOrarioDomenica} />
                                }
                                <Tooltip title="Clicca sul pulsante per copiare gli orari su tutti i giorni della settimana" placement='right'>
                                    <ButtonAntd style={{ backgroundColor: 'green' }} className='d-flex align-items-center justify-content-center mt-1' type='primary' icon={<CopyOutlined />} shape='circle' size='large' onClick={() => copyAllHours(valueDomenica.open_hour, valueDomenica.second_open_hour, valueDomenica.closed_hour, valueDomenica.second_closed_hour, valueDomenica.is_second_hour)} />
                                </Tooltip>
                            </div>
                        </div>
                        {(valueDomenica.is_second_hour)
                            &&
                            <div className="d-flex align-items-center mt-1">
                                <InputMask className='form-control col-lg-5 col-md-5 col-4' placeholder='Orario di apertura' mask='99:99' value={valueDomenica.second_open_hour} onChange={(value) => setValueDomenica({ ...valueDomenica, second_open_hour: value.target.value })} />
                                <InputMask className='form-control ml-1 col-lg-5 col-md-5 col-4' placeholder='Orario di chiusura' mask='99:99' value={valueDomenica.second_closed_hour} onChange={(value) => setValueDomenica({ ...valueDomenica, second_closed_hour: value.target.value })} />
                            </div>
                        }
                    </div>
                }
            </div>
        </Modal>
        </>
    )
}

export default Calendar;