import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Button from 'react-bootstrap/Button';
import { Result } from 'antd';

function E404Page() {
    
    useEffect(() => {
        document.body.setAttribute("class","vertical-layout vertical-menu bg-cyan bg-lighten-2 1-column blank-page");
        document.body.setAttribute("data-col","1-column");

        return () => {
            document.body.setAttribute("class","vertical-layout vertical-menu 2-columns fixed-navbar");
            document.body.setAttribute("data-col","2-columns");
        };
    }, [])

    return(
        <>
        <Helmet>
            <title>Pagina Non Trovata | Takeiteasy Partners</title>
        </Helmet>
        <div className="app-content content">
            <div className="content-overlay"></div>
            <div className="content-wrapper">
                <div className="content-header row">
                </div>
                <div className="content-body">
                    <section className="flexbox-container">
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            <div className="col-lg-4 col-md-8 col-10 p-0">
                                <Result
                                    status="404"
                                    title={<h2 style={{ fontSize: '5rem' }} className="error-code text-white text-center mb-2">404</h2>}
                                    subTitle={<h3 style={{ fontSize: '1.5rem' }} className="text-uppercase text-white text-center">Pagina Non Trovata!</h3>}
                                />
                                <div className="card-content">
                                    <div className="row py-2 d-flex justify-content-center">
                                        <div className="col-lg-5 col-md-8 col-12 mb-1">
                                            <Link to="/" className='btn btn-success btn-lg btn-block'>
                                                <i className="ft-home"></i> Home
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer bg-transparent">
                                    <div className="row">
                                        <p className="text-white text-center col-12 pb-1">© <span className="year"></span> TAKEITEASY SRL</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
        </div>
        </>
    )
}

export default E404Page;