import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet";
import { Table, Rate, Skeleton, Button as ButtonAntd, Dropdown, Modal, Input, Form } from 'antd';
import * as config from '../config.js';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThreeDots } from 'react-loader-spinner';
import { DownOutlined, EditOutlined, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/it';

const { confirm } = Modal;

function Visits() {
    const navigate = useNavigate()
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loadTable, setLoadTable] = useState(true)
    const [visits, setVisits] = useState([])
    const [loadDelete, setLoadDelete] = useState(false)

    const columns = [
        {
            title: 'Nome visita',
            dataIndex: 'nome',
            key: 'nome',
            render: (text, record, index) => <Link to={`/visita/${record.key}`}>{text}</Link>
        },
        {
            title: 'Descrizione',
            dataIndex: 'descrizione',
            key: 'descrizione'
        },
        {
            title: 'Prezzo',
            dataIndex: 'prezzo',
            key: 'prezzo'
        },
        {
            title: 'Prezzo scontato',
            dataIndex: 'sconto',
            key: 'sconto'
        },
        {
            title: 'Data inizio',
            dataIndex: 'inizio',
            key: 'inizio',
            render: (text, record, index) => (record.is_date == "1" || record.is_date == 1) ? <span style={{ textTransform: 'capitalize' }}>{moment(text).format('ddd DD MMM YYYY')}</span> : null
        },
        {
            title: 'Data fine',
            dataIndex: 'fine',
            key: 'fine',
            render: (text, record, index) => (record.is_date == "1" || record.is_date == 1) ? <span style={{ textTransform: 'capitalize' }}>{moment(text).format('ddd DD MMM YYYY')}</span> : null
        },
        {
            title: 'Durata',
            dataIndex: 'durata',
            key: 'durata',
            render: (text) => `${text}min`
        },
        {
            title: 'Azioni',
            dataIndex: 'azioni',
            key: 'azioni',
            render: (text, record, index) => <Dropdown menu={{ items: [{ label: "Modifica visita", icon: <EditOutlined />, onClick: () => goToEditPage(record.key), key: '1' },{ label: "Elimina visita", icon: <DeleteOutlined />, onClick: () => deletePharmacyVisitRequest(record.key), danger: true, key: '2' }] }}>
                <ButtonAntd>
                    <div className="d-flex align-items-center">
                        Azioni
                        <DownOutlined style={{ marginLeft: 5 }} />
                    </div>
                </ButtonAntd>
            </Dropdown>
        }
    ];

    useEffect(() => {
        checkPharmacyBilling()
        getPharmacyVisits()
    }, [])

    const checkPharmacyBilling = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/check-pharmacy-has-visits`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                if (response.data.has_visits !== true) {
                    navigate('/')
                }
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    }

    const hasSelected = selectedRowKeys.length > 0;

    const getPharmacyVisits = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-pharmacy-visits`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy
            })
        })
        .then(function(response) {
            if (response.data.length > 0) {
                setVisits(response.data)
                setLoadTable(false)
            } else {
                setVisits([])
                setLoadTable(false)
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const deletePharmacyVisitRequest = (uid) => {
        confirm({
            title: 'ATTENZIONE',
            icon: <ExclamationCircleFilled />,
            content: 'Sei sicuro di voler eliminare la visita?',
            onOk() {
                deletePharmacyVisit(uid)
            },
            okButtonProps: { danger: true },
            okText: "Elimina",
            cancelText: "Annulla"
        });
    }

    const deletePharmacyVisit = (uid) => {
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/delete-pharmacy-visit`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_visit: uid
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                getPharmacyVisits()
                toast.success(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const deletePharmacyVisitsRequest = () => {
        confirm({
            title: 'ATTENZIONE',
            icon: <ExclamationCircleFilled />,
            content: 'Sei sicuro di voler eliminare le visite?',
            onOk() {
                deletePharmacyVisits()
            },
            okButtonProps: { danger: true },
            okText: "Elimina",
            cancelText: "Annulla"
        });
    }

    const deletePharmacyVisits = () => {
        setLoadDelete(true)
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/delete-pharmacy-visits`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                ids_visit: selectedRowKeys
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                setSelectedRowKeys([])
                getPharmacyVisits()
                toast.success(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setLoadDelete(false)
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setLoadDelete(false)
            }
        })
        .catch(function(error) {
            console.error(error);
            setLoadDelete(false)
        });
    }

    const goToEditPage = (uid) => {
        navigate(`/visita/${uid}`)
    }

    return(
        <>
        <Helmet>
            <title>Visite | Takeiteasy Partners</title>
        </Helmet>
        <ToastContainer />
        <Navbar />
        <div className="app-content content">
            <div className="content-overlay"></div>
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2">
                        <h3 className="content-header-title">Visite disponibili</h3>
                    </div>
                </div>
                <div className="content-body">
                    <section className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Visite attive nella tua farmacia</h4>
                                    <div className="heading-elements">
                                        <ButtonAntd type='primary' onClick={() => navigate('/nuova/visita')}>
                                            Aggiungi visita
                                        </ButtonAntd>
                                    </div>
                                </div>
                                <div className="card-content">
                                    <div className='px-2'>
                                        <ButtonAntd type="primary" danger disabled={!hasSelected} onClick={(loadDelete) ? null : deletePharmacyVisitsRequest}>
                                            {(loadDelete)
                                                ?
                                                <ThreeDots 
                                                    height="11" 
                                                    width="23" 
                                                    radius="4.5"
                                                    color="#fff" 
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
                                                    visible={true}
                                                />
                                                :
                                                'Elimina'
                                            }
                                        </ButtonAntd>
                                        <span style={{ marginLeft: 8 }}>
                                            {hasSelected ? `${selectedRowKeys.length} visit${(selectedRowKeys.length == 1) ? 'a' : 'e'} selezionat${(selectedRowKeys.length == 1) ? 'a' : 'e'}` : ''}
                                        </span>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            {(loadTable)
                                                ?
                                                <div className="px-2">
                                                    <Skeleton avatar={true} active={true} />
                                                </div>
                                                :
                                                <Table rowSelection={rowSelection} columns={columns} dataSource={visits} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default Visits;