import React, { useState } from "react";
import Cropper from "react-easy-crop";
import { Button } from 'antd';

function ImageCropper({ image, onCropDone, onCropCancel, aspectRatio }) {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedArea, setCroppedArea] = useState(null);

    const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    }

    return (
        <div style={{ height: '23rem' }}>
            <Cropper
                image={image}
                aspect={aspectRatio}
                crop={crop}
                zoom={zoom}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
                style={{
                    containerStyle: {
                        width: "100%",
                        height: "20rem",
                        backgroundColor: "#fff",
                        position: 'relative'
                    },
                }}
            />
            <div className="float-right mt-1">
                <Button type="default" className="mr-1" onClick={onCropCancel}>
                    Annulla
                </Button>
                <Button type="primary" onClick={() => onCropDone(croppedArea)}>
                    Salva
                </Button>
            </div>
        </div>
    );
}

export default ImageCropper;
