import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet";
import * as config from '../config';
import axios from 'axios';
import { Skeleton, Empty, Avatar, Card, Button, Breadcrumb } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Errors from '../components/Errors';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import it from 'react-phone-input-2/lang/it.json';
import { ThreeDots } from 'react-loader-spinner';
import Autocomplete from "react-google-autocomplete";
import { checkVAT, italy } from 'jsvat';

function EditBilling() {
    const navigate = useNavigate()
    const [haveError, setHaveError] = useState(false)
    const [userBill, setUserBill] = useState({
        company: "",
        vat: "",
        pec: "",
        receiver_code: ""
    })
    const [companyAddress, setCompanyAddress] = useState("")
    const [companyCivic, setCompanyCivic] = useState("")
    const [companyCity, setCompanyCity] = useState("")
    const [companyCap, setCompanyCap] = useState("")
    const [companyProvince, setCompanyProvince] = useState("")
    const [companyState, setCompanyState] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [loading, setLoading] = useState(false)
    const [isVatValid, setIsVatValid] = useState(null)
    const [isAddressDiasbled, setIsAddressDisabled] = useState(true)

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        checkErrors(userInfo)
        getUserBill(userInfo)
    }, [])

    const checkErrors = (userInfo) => {
        if (userInfo !== null) {
            if (userInfo.is_code_verify == "0" || userInfo.is_code_verify == 0 || userInfo.is_completed == "0" || userInfo.is_completed == 0) {
                setHaveError(true)
            } else {
                setHaveError(false)
            }
        } else {
            setHaveError(false)
        }
    }

    const getUserBill = (userInfo) => {
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-user-billing`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_admin_user: userInfo.id
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                setUserBill({
                    company: response.data.company,
                    vat: response.data.vat,
                    pec: response.data.pec,
                    receiver_code: response.data.receiver_code
                })
                const validation = checkVAT(`IT${response.data.vat}`, [italy]);
                if (validation.isValid) {
                    setIsVatValid(true)
                } else {
                    setIsVatValid(false)
                }
                setCompanyAddress(response.data.company_address.split(', ')[0])
                setCompanyCivic(response.data.company_address.split(', ')[1])
                setCompanyCity(response.data.company_city)
                setCompanyCap(response.data.company_cap)
                setCompanyProvince(response.data.company_province)
                setCompanyState(response.data.company_state)
                setIsLoading(false)
            } else {
                setIsLoading(false)
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            setIsLoading(false)
            console.error(error);
        });
    }

    const handleChange = (e) => {
        if (e.target.name == "pec") {
            setUserBill({ ...userBill, [e.target.name]: e.target.value.toLowerCase() })
        } else {
            setUserBill({ ...userBill, [e.target.name]: e.target.value })
        }
    }

    const validateVat = (number) => {
        const vatNumber = number.target.value;
        setUserBill({ ...userBill, vat: vatNumber })
        if (vatNumber !== "") {
            const validation = checkVAT(`IT${vatNumber}`, [italy]);
            if (validation.isValid) {
                setIsVatValid(true)
            } else {
                setIsVatValid(false)
            }
        } else {
            setIsVatValid(false)
        }
    }

    const handleAddress = (place) => {
        let count = 0;
        place.address_components.forEach(element => {
            const long_name = element.long_name;
            const short_name = element.short_name;
            count++;

            if (element.types.includes('locality')) {
                setCompanyCity(long_name)
            } else if (element.types.includes('administrative_area_level_2')) {
                setCompanyProvince(short_name)
            } else if (element.types.includes('postal_code')) {
                setCompanyCap(long_name)
            } else if (element.types.includes('street_number')) {
                setCompanyCivic(long_name)
            } else if (element.types.includes('route')) {
                setCompanyAddress(long_name)
            } else if (element.types.includes('country')) {
                setCompanyState(short_name)
            }

            if (count == place.address_components.length) {
                setIsAddressDisabled(false)
            }
        });
    }

    const handleEditBilling = () => {
        setLoading(true)
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        if (userBill.company !== "" && companyAddress !== "" && companyCivic !== "" && companyCity !== "" && companyCap !== "" && companyProvince !== "" && companyState !== "" && userBill.vat !== "") {
            if (userBill.pec == "" && userBill.receiver_code == "") {
                setLoading(false)
                toast.error("Inserisci la PEC o il Codice destinatario per continuare", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (!isVatValid) {
                setLoading(false)
                toast.error("Inserisci una P.IVA valida", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                axios({
                    method: 'post',
                    url: `${config.WEBSITE_URL}/partners/update-user-billing`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    auth: {
                        username: config.USERNAME_TOKEN,
                        password: config.PASSWORD_TOKEN
                    },
                    data: JSON.stringify({
                        token_access: config.WEB_TOKEN,
                        id_admin_user: userInfo.id,
                        company: userBill.company.trim(),
                        company_address: `${companyAddress.trim()}, ${companyCivic.trim()}`,
                        company_city: companyCity.trim(),
                        company_cap: companyCap.trim(),
                        company_province: companyProvince.trim(),
                        company_state: companyState.trim(),
                        vat: userBill.vat.trim(),
                        pec: userBill.pec.trim(),
                        receiver_code: userBill.receiver_code.trim()
                    })
                })
                .then(function(response) {
                    if (response.data.code == true) {
                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setTimeout(() => {
                            navigate('/profilo')
                        }, 1000);
                        setLoading(false)
                    } else {
                        setLoading(false)
                        toast.error(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch(function(error) {
                    setLoading(false)
                    console.error(error);
                });
            }
        } else {
            setLoading(false)
            toast.error("Compila tutti i campi obbligatori", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    return(
        <>
        <ToastContainer />
        <Helmet>
            <title>Modifica Fatturazione | Takeiteasy Partners</title>
        </Helmet>
        <Navbar />
        <div className="app-content content">
            <div className="content-overlay"></div>
            <div className="content-wrapper">
                <Errors />
                <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2">
                        <h3 className="content-header-title">Modifica fatturazione</h3>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/profilo">Profilo</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Modifica fatturazione</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                <div className="content-body">
                    <section className="row">
                        <div className="col-12">
                            <div className="card">
                                {(haveError)
                                    ?
                                    <Empty
                                        description={
                                            <span>
                                                Risolvi gli errori per poter continuare.
                                            </span>
                                        }
                                    />
                                    :
                                    <>
                                    <div className="card-content">
                                        <div className="card-body">
                                            {(isLoading)
                                                ?
                                                <Skeleton active />
                                                :
                                                <form>
                                                    <fieldset>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label htmlFor="company">Ragione sociale*</label>
                                                                    <input type="text" class="form-control" value={userBill.company} onChange={handleChange} name="company" id="company" placeholder='Inserisci la tua Ragione sociale' required />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label htmlFor="company_address">Indirizzo sede legale*</label>
                                                                    <Autocomplete
                                                                        apiKey={config.GOOGLE_MAPS_API}
                                                                        onPlaceSelected={(place) => handleAddress(place)}
                                                                        language='it'
                                                                        options={{ types: ["address"] }}
                                                                        placeholder="Inserisci la tua Sede Legale (Es. Via Torino, 23, Milano)"
                                                                        className="form-control"
                                                                        aria-required={true}
                                                                        id="company_address"
                                                                        defaultValue={(companyAddress !== "" && companyCivic !== "") ? `${companyAddress}, ${companyCivic}` : null}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label htmlFor="company_city">Città*</label>
                                                                    <input type="text" class="form-control" disabled={isAddressDiasbled} onChange={(city) => setCompanyCity(city.target.value)} value={companyCity} name="company_city" id="company_city" placeholder='Inserisci la Città' required />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label htmlFor="company_cap">CAP*</label>
                                                                    <input type="text" class="form-control" disabled={isAddressDiasbled} onChange={(cap) => setCompanyCap(cap.target.value)} value={companyCap} name="company_cap" id="company_cap" placeholder='Inserisci il CAP' required />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label htmlFor="company_province">Provincia*</label>
                                                                    <input type="text" class="form-control" disabled={isAddressDiasbled} onChange={(province) => setCompanyProvince(province.target.value)} value={companyProvince} name="company_province" id="company_province" placeholder='Inserisci la Provincia' required />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label htmlFor="vat">P.IVA* (Senza la sigla IT)</label>
                                                                    <div style={{ position: 'relative' }}>
                                                                        <input type="text" class="form-control" value={userBill.vat} onChange={(vat) => validateVat(vat)} name="vat" placeholder='Inserisci la P.IVA' required />
                                                                        {(isVatValid == true)
                                                                            ?
                                                                            <div style={{ position: 'absolute', right: 10, top: 10.5, zIndex: 2 }}>
                                                                                <i style={{ fontSize: '1.5rem' }} className='ft-check-circle text-success'></i>
                                                                            </div>
                                                                            :
                                                                            (isVatValid == false)
                                                                                ?
                                                                                <div style={{ position: 'absolute', right: 10, top: 10.5, zIndex: 2 }}>
                                                                                    <i style={{ fontSize: '1.5rem' }} className='ft-alert-octagon text-danger'></i>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label htmlFor="pec">PEC</label>
                                                                    <input type="email" class="form-control" value={userBill.pec} onChange={handleChange} name="pec" id="pec" placeholder='Inserisci la tua PEC' />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label htmlFor="receiver_code">Codice destinatario (Per fatturazione elettronica)</label>
                                                                    <input type="text" class="form-control" value={userBill.receiver_code} onChange={handleChange} name="receiver_code" id="receiver_code" placeholder='Inserisci il Codice destinatario' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    <fieldset>
                                                        <div style={{ marginTop: 24 }} className="float-right">
                                                            <Button type="primary" onClick={(loading) ? null : handleEditBilling}>
                                                                {(loading)
                                                                    ?
                                                                    <ThreeDots 
                                                                        height="11" 
                                                                        width="23" 
                                                                        radius="4.5"
                                                                        color="#fff" 
                                                                        ariaLabel="three-dots-loading"
                                                                        wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
                                                                        visible={true}
                                                                    />
                                                                    :
                                                                    'Salva'
                                                                }
                                                            </Button>
                                                        </div>
                                                    </fieldset>
                                                </form>
                                            }
                                        </div>
                                    </div>
                                    </>
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default EditBilling;