import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet";
import moment from 'moment';
import 'moment/locale/it';
import Tour from 'reactour';
import { Skeleton, Empty, Input, Result, Divider, Modal, Alert, Button as ButtonAntd, Spin, Tooltip } from 'antd';
import { QrcodeOutlined, CheckCircleOutlined, ReloadOutlined, NotificationTwoTone, NodeIndexOutlined } from '@ant-design/icons';
import QrReader from 'react-web-qr-reader';
import * as config from '../config';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThreeDots } from 'react-loader-spinner';
//import Countdown from 'react-countdown-simple';
import Timer from '@amplication/react-compound-timer';
import ProgressCountdown from '../components/ProgressCountdown';
import OneSignal from 'react-onesignal';
import { messaging } from '../firebase';
import { getToken } from 'firebase/messaging';

const steps = [
    {
        selector: '.first-step',
        content: 'This is my first Step',
    },
    {
        selector: '.second-step',
        content: 'This is my second Step',
    }
]

function Tickets() {
    const [isTomorrow, setIsTomorrow] = useState(false)
    const [isTomorrowVisite, setIsTomorrowVisite] = useState(false)
    const [isTourOpen, setIsTourOpen] = useState(false)
    const [isActiveVisite, setIsActiveVisite] = useState(false)
    const [showConvalida, setShowConvalida] = useState(false)
    const [showQrReader, setShowQrReader] = useState(false)
    const [ticketsToday, setTicketsToday] = useState([])
    const [loadTicketsToday, setLoadTicketsToday] = useState(true)
    const [ticketsTomorrow, setTicketsTomorrow] = useState([])
    const [loadTicketsTomorrow, setLoadTicketsTomorrow] = useState(true)
    const [ticketsVisitToday, setTicketsVisitToday] = useState([])
    const [loadTicketsVisitToday, setLoadTicketsVisitToday] = useState(true)
    const [ticketsVisitTomorrow, setTicketsVisitTomorrow] = useState([])
    const [loadTicketsVisitTomorrow, setLoadTicketsVisitTomorrow] = useState(true)
    const [ticketsOpened, setTicketsOpened] = useState([])
    const [loadTicketsOpened, setLoadTicketsOpened] = useState(true)
    const [ticketCode, setTicketCode] = useState('')
    const [loadTicketValidation, setLoadTicketValidation] = useState(false)
    const [showReader, setShowReader] = useState(false)
    const [loadReader, setLoadReader] = useState(true)
    const [showModalTicket, setShowModalTicket] = useState(false)
    const [ticketSelect, setTicketSelect] = useState(null)
    const [initialized, setInitialized] = useState(false)
    const [notInitialized, setNotInitialized] = useState(false)

    useEffect(() => {
        checkPharmacyBilling()
        initializeWebToken()
        getTicketsToday()
        getTicketsTomorrow()
        getTicketsVisitToday()
        getTicketsVisitTomorrow()
        getTicketsTodayOpened()
        setInterval(() => {
            getTicketsTodayOpened()
        }, 6000);
    }, [])

    const checkPharmacyBilling = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/check-pharmacy-has-visits`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                setIsActiveVisite(response.data.has_visits)
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const initializeWebToken = async () => {
        const check_permission = Notification.permission
        if (check_permission !== 'granted') {
            if (check_permission === 'denied') {
                setNotInitialized(true)
            } else {
                setInitialized(true)
            }
            const permission = await Notification.requestPermission()
            if (permission === 'granted') {
                const token = await getToken(messaging, { vapidKey: config.WEB_PUSH_TOKEN })
                saveWebPushToken(token)
                setInitialized(false)
                setNotInitialized(false)
            } else {
                setNotInitialized(true)
            }
        } else {
            const token = await getToken(messaging, { vapidKey: config.WEB_PUSH_TOKEN })
            saveWebPushToken(token)
        }
    }

    const saveWebPushToken = (web_token) => {
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/add-pharmacy-notification`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_admin_user: userInfo.id,
                token_push: web_token
            })
        })
        .then(function(response) {
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const getTicketsToday = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-tickets-today-tomorrow`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy,
                today_date: moment(new Date()).format('YYYY-MM-DD')
            })
        })
        .then(function(response) {
            if (response.data.length > 0) {
                setTicketsToday(response.data)
                setLoadTicketsToday(false)
            } else {
                setTicketsToday([])
                setLoadTicketsToday(false)
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const getTicketsTomorrow = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-tickets-today-tomorrow`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy,
                today_date: moment(new Date()).add(1,'day').format('YYYY-MM-DD')
            })
        })
        .then(function(response) {
            if (response.data.length > 0) {
                setTicketsTomorrow(response.data)
                setLoadTicketsTomorrow(false)
            } else {
                setTicketsTomorrow([])
                setLoadTicketsTomorrow(false)
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const reloadTickets = () => {
        setLoadTicketsToday(true)
        setLoadTicketsTomorrow(true)
        getTicketsToday()
        getTicketsTomorrow()
    }

    const getTicketsVisitToday = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-visits-tickets-today-tomorrow`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy,
                today_date: moment(new Date()).format('YYYY-MM-DD')
            })
        })
        .then(function(response) {
            if (response.data.length > 0) {
                setTicketsVisitToday(response.data)
                setLoadTicketsVisitToday(false)
            } else {
                setTicketsVisitToday([])
                setLoadTicketsVisitToday(false)
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const getTicketsVisitTomorrow = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-visits-tickets-today-tomorrow`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy,
                today_date: moment(new Date()).add(1,'day').format('YYYY-MM-DD')
            })
        })
        .then(function(response) {
            if (response.data.length > 0) {
                setTicketsVisitTomorrow(response.data)
                setLoadTicketsVisitTomorrow(false)
            } else {
                setTicketsVisitTomorrow([])
                setLoadTicketsVisitTomorrow(false)
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const reloadVisitTickets = () => {
        setLoadTicketsVisitToday(true)
        setLoadTicketsVisitTomorrow(true)
        getTicketsVisitToday()
        getTicketsVisitTomorrow()
    }

    const getTicketsTodayOpened = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-tickets-today-open`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy,
                today_date: moment(new Date()).format('YYYY-MM-DD')
            })
        })
        .then(function(response) {
            if (response.data.length > 0) {
                setTicketsOpened(response.data)
                setLoadTicketsOpened(false)
            } else {
                setTicketsOpened([])
                setLoadTicketsOpened(false)
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const validateTicketCodeInput = () => {
        if (ticketCode !== '') {
            setLoadTicketValidation(true)
            const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
            axios({
                method: 'post',
                url: `${config.WEBSITE_URL}/partners/validate-ticket-code`,
                headers: {
                    'Content-Type': 'application/json'
                },
                auth: {
                    username: config.USERNAME_TOKEN,
                    password: config.PASSWORD_TOKEN
                },
                data: JSON.stringify({
                    token_access: config.WEB_TOKEN,
                    id_pharmacy: pharmacyInfo.id_pharmacy,
                    ticket_code: ticketCode,
                    date_time_now: moment(new Date()).format('YYYY-MM-DD HH:mm')
                })
            })
            .then(function(response) {
                if (response.data.code == true) {
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setLoadTicketValidation(false)
                    setTicketSelect(response.data.ticket_data)
                    setShowConvalida(false)
                    setShowModalTicket(true)
                    setTicketCode('')
                } else {
                    toast.error(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setLoadTicketValidation(false)
                }
            })
            .catch(function(error) {
                console.error(error);
            });
        } else {
            toast.error("Inserisci un codice per convalidarlo", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const handleScanQrCode = (result) => {
        setLoadReader(true)
        const ticketCode = result.data.split('##TKYCODE##')[1]
        if (ticketCode !== undefined && ticketCode !== null && ticketCode !== '') {
            const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
            axios({
                method: 'post',
                url: `${config.WEBSITE_URL}/partners/validate-ticket-code`,
                headers: {
                    'Content-Type': 'application/json'
                },
                auth: {
                    username: config.USERNAME_TOKEN,
                    password: config.PASSWORD_TOKEN
                },
                data: JSON.stringify({
                    token_access: config.WEB_TOKEN,
                    id_pharmacy: pharmacyInfo.id_pharmacy,
                    ticket_code: ticketCode,
                    date_time_now: moment(new Date()).format('YYYY-MM-DD HH:mm')
                })
            })
            .then(function(response) {
                if (response.data.code == true) {
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setLoadReader(false)
                    setTicketSelect(response.data.ticket_data)
                    setShowQrReader(false)
                    setShowModalTicket(true)
                    setTicketCode('')
                } else {
                    toast.error(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setLoadReader(false)
                }
            })
            .catch(function(error) {
                console.error(error);
            });
        } else {
            toast.error("Il QrCode non è corretto", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setTimeout(() => {
                setLoadReader(false)
            }, 2000);
        }
    }
      
    const renderer = ({ days, hours, minutes, seconds }) => {
        return(
            <div className='d-flex justify-content-center align-items-center'>
                <Alert message={(minutes.toString().length == 1) ? `0${minutes}` : minutes} type="error" />
                <span style={{ marginLeft: 4, marginRight: 4 }}>:</span>
                <Alert message={(seconds.toString().length == 1) ? `0${seconds}` : seconds} type="error" />
            </div>
        )
    }

    const openDisplayTickets = () => {
        window.open('/display/tickets', '_blank');
    }

    return(
        <>
        <Helmet>
            <title>Ticket | Takeiteasy Partners</title>
        </Helmet>
        <ToastContainer />
        <Tour
            steps={steps}
            isOpen={isTourOpen}
            onRequestClose={() => setIsTourOpen(false)}
            rounded={15}
        />
        <Navbar />
        <div className="app-content content mb-5">
            <div className="content-overlay"></div>
            <div className="content-wrapper">
                {(notInitialized)
                    &&
                    <Alert
                        message="Notifiche non attive"
                        description="Offri un'esperienza migliore ai tuoi clienti. Abilita le notifiche per ricevere un messagio quando ogni cliente entra in farmacia. Per abilitarle vai su Impostazioni del tuo borwser e su Privacy e sicurezza."
                        type="warning"
                        showIcon
                        className="mb-1"
                    />
                }
                <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2">
                        <h3 className="content-header-title">Ticket prenotati</h3>
                        <div className='d-flex align-items-center mt-1'>
                            <ButtonAntd className='d-flex align-items-center justify-content-center' type='primary' icon={<QrcodeOutlined />} size='large' onClick={() => setShowConvalida(true)}>
                                <span style={{ fontWeight: '500' }}>Convalida il ticket</span>
                            </ButtonAntd>
                            <ButtonAntd className='d-flex align-items-center justify-content-center ml-1' type='default' icon={<NodeIndexOutlined />} size='large' onClick={openDisplayTickets}>
                                <span style={{ fontWeight: '500' }}>Apri lista d'attesa</span>
                            </ButtonAntd>
                        </div>
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className={`${(isActiveVisite) ? 'col-xl-4 col-md-4' : 'col-xl-6 col-md-6'} col-12`}>
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 mt-3 mb-1">
                                    <div className="card">
                                        <div className="card-header" style={{ borderRadius: '0.45rem' }}>
                                            <h4 className="text-uppercase">Ticket entrati in farmacia</h4>
                                            <p className='mb-0 pb-0'>Questi clienti sono in attesa di essere serviti</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {(loadTicketsOpened)
                                    ?
                                    <div className={`${(isActiveVisite) ? 'col-xl-12' : 'col-xl-12'} col-md-12 col-12`}>
                                        <div className="card">
                                            <div className="card-content">
                                                <div className="card-body">
                                                    <Skeleton loading={true} active avatar></Skeleton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    (ticketsOpened.length > 0)
                                        ?
                                        ticketsOpened.map((item, index) => {
                                            const now = moment(new Date()); //todays date
                                            const end = moment(item.date_time_open); // another date
                                            const duration = moment.duration(now.diff(end));
                                            const milliseconds = duration.asMilliseconds();
                                            const minutesCount = 600000 - milliseconds;

                                            return(
                                                <div key={index} className={`${(isActiveVisite) ? 'col-xl-12' : 'col-xl-12'} col-md-12 col-12`}>
                                                    <div className="card first-step">
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <div className="media d-flex">
                                                                    <div className="media-body text-left">
                                                                        <h3 className="info">{item.name} {item.surname}</h3>
                                                                        {(item.is_visit == true)
                                                                            &&
                                                                            <>
                                                                            <span style={{ fontSize: '1rem', fontWeight: '500' }}>
                                                                                {item.name_visit}
                                                                            </span><br/>
                                                                            <span style={{ fontSize: '1rem' }}>
                                                                                Durata: <b>{item.duration}min</b><br/>Prezzo: <b>{item.price}</b>
                                                                            </span>
                                                                            </>
                                                                        }
                                                                        <div className='d-flex align-items-center'>
                                                                            <span className='mr-1' style={{ fontSize: '1.2rem' }}>
                                                                                {item.time_slot.split(':')[0]}:{item.time_slot.split(':')[1]}
                                                                            </span>
                                                                            {(item.status == "Confermato")
                                                                                ?
                                                                                <Alert message={item.status} type='success' showIcon />
                                                                                :
                                                                                <Alert message={item.status} type='error' showIcon />
                                                                            }
                                                                            {(item.is_visit == true)
                                                                                &&
                                                                                <Alert className="ml-1" message='Visita' type='info' />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="align-self-center">
                                                                        <div className="avatar mr-1 avatar-lg">
                                                                            <img src={item.avatar} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='mt-1 mb-0'>
                                                                    <ProgressCountdown millisecondsStart={minutesCount} />
                                                                </div>
                                                                <div className="text-right">
                                                                    <Timer initialTime={minutesCount} direction='backward'>
                                                                        <span style={{ fontSize: '1.1rem', fontWeight: '500' }}><Timer.Minutes formatValue={(value) => (value < 10 ? `0${value}` : value)} />:<Timer.Seconds formatValue={(value) => (value < 10 ? `0${value}` : value)} /></span>
                                                                    </Timer>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className={`${(isActiveVisite) ? 'col-xl-12' : 'col-xl-12'} col-md-12 col-12`}>
                                            <div className="card">
                                                <div className="card-content">
                                                    <div className="card-body">
                                                        <Empty description={<span style={{ fontSize: '1rem' }}>Non sono presenti ticket</span>} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className={`${(isActiveVisite) ? 'col-xl-4 col-md-4' : 'col-xl-6 col-md-6'} col-12`}>
                            <section id="minimal-statistics">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 mt-3 mb-1">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center justify-content-between">
                                                <h4 className="card-title">Ticket</h4>
                                                <Tooltip title="Aggiorna">
                                                    <ButtonAntd type="default" shape="circle" className='d-flex align-items-center justify-content-center' onClick={reloadTickets} icon={<ReloadOutlined />} />
                                                </Tooltip>
                                            </div>
                                            <div className="card-content">
                                                <div className="card-body">
                                                    <p>Seleziona una delle due date per vedere i ticket prenotati dai tuoi clienti</p>
                                                    <div className='row'>
                                                        <div className='col-lg-6 col-md-12 col-12 mb-lg-0 mb-1'>
                                                            <ButtonAntd block={true} type={(isTomorrow) ? 'default' : 'primary'} size='large' onClick={() => setIsTomorrow(false)}>
                                                                Oggi {moment(Date.now()).format('DD MMM')}
                                                            </ButtonAntd>
                                                        </div>
                                                        <div className='col-lg-6 col-md-12 col-12'>
                                                            <ButtonAntd block={true} type={(!isTomorrow) ? 'default' : 'primary'} size='large' onClick={() => setIsTomorrow(true)}>
                                                                Domani {moment(Date.now()).add(1, 'day').format('DD MMM')}
                                                            </ButtonAntd>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {(!isTomorrow)
                                    ?
                                    <div className="row">
                                        {(loadTicketsToday)
                                            ?
                                            <div className={`${(isActiveVisite) ? 'col-xl-12' : 'col-xl-12'} col-md-12 col-12`}>
                                                <div className="card">
                                                    <div className="card-content">
                                                        <div className="card-body">
                                                            <Skeleton loading={true} active avatar></Skeleton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            (ticketsToday.length > 0)
                                                ?
                                                ticketsToday.map((item, index) => {
                                                    return(
                                                        <div key={index} className={`${(isActiveVisite) ? 'col-xl-12' : 'col-xl-12'} col-md-12 col-12`}>
                                                            <div className="card">
                                                                <div className="card-content">
                                                                    <div className="card-body">
                                                                        <div className="media d-flex">
                                                                            <div className="media-body text-left">
                                                                                <h3>{item.name} {item.surname}</h3>
                                                                                <div className='d-flex align-items-center'>
                                                                                    <span className='mr-1' style={{ fontSize: '1.2rem' }}>
                                                                                        {item.time_slot.split(':')[0]}:{item.time_slot.split(':')[1]}
                                                                                    </span>
                                                                                    {(item.status == "Confermato")
                                                                                        ?
                                                                                        <Alert message={item.status} type='success' showIcon />
                                                                                        :
                                                                                        (item.status == "Utilizzato")
                                                                                            ?
                                                                                            <Alert message={item.status} type='warning' showIcon />
                                                                                            :
                                                                                            <Alert message={item.status} type='error' showIcon />
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="align-self-center">
                                                                                <div className="avatar mr-1 avatar-lg">
                                                                                    <img src={item.avatar} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div className={`${(isActiveVisite) ? 'col-xl-12' : 'col-xl-12'} col-md-12 col-12`}>
                                                    <div className="card">
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <Empty description={<span style={{ fontSize: '1rem' }}>Non sono presenti ticket</span>} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                    :
                                    <div className="row">
                                        {(loadTicketsTomorrow)
                                            ?
                                            <div className={`${(isActiveVisite) ? 'col-xl-12' : 'col-xl-12'} col-md-12 col-12`}>
                                                <div className="card">
                                                    <div className="card-content">
                                                        <div className="card-body">
                                                            <Skeleton loading={true} active avatar></Skeleton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            (ticketsTomorrow.length > 0)
                                                ?
                                                ticketsTomorrow.map((item, index) => {
                                                    return(
                                                        <div key={index} className={`${(isActiveVisite) ? 'col-xl-12' : 'col-xl-12'} col-md-12 col-12`}>
                                                            <div className="card">
                                                                <div className="card-content">
                                                                    <div className="card-body">
                                                                        <div className="media d-flex">
                                                                            <div className="media-body text-left">
                                                                                <h3>{item.name} {item.surname}</h3>
                                                                                <div className='d-flex align-items-center'>
                                                                                    <span className='mr-1' style={{ fontSize: '1.2rem' }}>
                                                                                        {item.time_slot.split(':')[0]}:{item.time_slot.split(':')[1]}
                                                                                    </span>
                                                                                    {(item.status == "Confermato")
                                                                                        ?
                                                                                        <Alert message={item.status} type='success' showIcon />
                                                                                        :
                                                                                        (item.status == "Utilizzato")
                                                                                            ?
                                                                                            <Alert message={item.status} type='warning' showIcon />
                                                                                            :
                                                                                            <Alert message={item.status} type='error' showIcon />
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="align-self-center">
                                                                                <div className="avatar mr-1 avatar-lg">
                                                                                    <img src={item.avatar} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div className={`${(isActiveVisite) ? 'col-xl-12' : 'col-xl-12'} col-md-12 col-12`}>
                                                    <div className="card">
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <Empty description={<span style={{ fontSize: '1rem' }}>Non sono presenti ticket</span>} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                }
                            </section>
                        </div>
                        {(isActiveVisite)
                            &&
                            <div className={`col-xl-4 col-md-4 col-12`}>
                                <section id="minimal-statistics">
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12 mt-3 mb-1">
                                            <div className="card">
                                                <div className="card-header d-flex align-items-center justify-content-between">
                                                    <h4 className="card-title">Ticket visita</h4>
                                                    <Tooltip title="Aggiorna">
                                                        <ButtonAntd type="default" shape="circle" className='d-flex align-items-center justify-content-center' onClick={reloadVisitTickets} icon={<ReloadOutlined />} />
                                                    </Tooltip>
                                                </div>
                                                <div className="card-content">
                                                    <div className="card-body">
                                                        <p>Seleziona una delle due date per vedere i ticket visita prenotati dai tuoi clienti</p>
                                                        <div className='row'>
                                                            <div className='col-lg-6 col-md-12 col-12 mb-lg-0 mb-1'>
                                                                <ButtonAntd block={true} type={(isTomorrowVisite) ? 'default' : 'primary'} size='large' onClick={() => setIsTomorrowVisite(false)}>
                                                                    Oggi {moment(Date.now()).format('DD MMM')}
                                                                </ButtonAntd>
                                                            </div>
                                                            <div className='col-lg-6 col-md-12 col-12'>
                                                                <ButtonAntd block={true} type={(!isTomorrowVisite) ? 'default' : 'primary'} size='large' onClick={() => setIsTomorrowVisite(true)}>
                                                                    Domani {moment(Date.now()).add(1, 'day').format('DD MMM')}
                                                                </ButtonAntd>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {(!isTomorrowVisite)
                                        ?
                                        <div className="row">
                                            {(loadTicketsVisitToday)
                                                ?
                                                <div className={`${(isActiveVisite) ? 'col-xl-12' : 'col-xl-6'} col-md-12 col-12`}>
                                                    <div className="card">
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <Skeleton loading={true} active avatar></Skeleton>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                (ticketsVisitToday.length > 0)
                                                    ?
                                                    ticketsVisitToday.map((item, index) => {
                                                        return(
                                                            <div key={index} className={`${(isActiveVisite) ? 'col-xl-12' : 'col-xl-6'} col-md-12 col-12`}>
                                                                <div className="card">
                                                                    <div className="card-content">
                                                                        <div className="card-body">
                                                                            <div className="media d-flex">
                                                                                <div className="media-body text-left">
                                                                                    <h3>{item.name} {item.surname}</h3>
                                                                                    <span style={{ fontSize: '1rem', fontWeight: '500' }}>
                                                                                        {item.name_visit}
                                                                                    </span><br/>
                                                                                    <span style={{ fontSize: '1rem' }}>
                                                                                        Durata: <b>{item.duration}min</b><br/>Prezzo: <b>{item.price}</b>
                                                                                    </span>
                                                                                    <div className='d-flex align-items-center'>
                                                                                        <span className='mr-1' style={{ fontSize: '1.2rem' }}>
                                                                                            {item.time_slot.split(':')[0]}:{item.time_slot.split(':')[1]}
                                                                                        </span>
                                                                                        {(item.status == "Confermato")
                                                                                            ?
                                                                                            <Alert message={item.status} type='success' showIcon />
                                                                                            :
                                                                                            (item.status == "Utilizzato")
                                                                                                ?
                                                                                                <Alert message={item.status} type='warning' showIcon />
                                                                                                :
                                                                                                <Alert message={item.status} type='error' showIcon />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="align-self-center">
                                                                                    <div className="avatar mr-1 avatar-lg">
                                                                                        <img src={item.avatar} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <div className={`${(isActiveVisite) ? 'col-xl-12' : 'col-xl-6'} col-md-12 col-12`}>
                                                        <div className="card">
                                                            <div className="card-content">
                                                                <div className="card-body">
                                                                    <Empty description={<span style={{ fontSize: '1rem' }}>Non sono presenti ticket</span>} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                        :
                                        <div className="row">
                                            {(loadTicketsVisitTomorrow)
                                                ?
                                                <div className={`${(isActiveVisite) ? 'col-xl-12' : 'col-xl-6'} col-md-12 col-12`}>
                                                    <div className="card">
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <Skeleton loading={true} active avatar></Skeleton>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                (ticketsVisitTomorrow.length > 0)
                                                    ?
                                                    ticketsVisitTomorrow.map((item, index) => {
                                                        return(
                                                            <div key={index} className={`${(isActiveVisite) ? 'col-xl-12' : 'col-xl-6'} col-md-12 col-12`}>
                                                                <div className="card">
                                                                    <div className="card-content">
                                                                        <div className="card-body">
                                                                            <div className="media d-flex">
                                                                                <div className="media-body text-left">
                                                                                    <h3>{item.name} {item.surname}</h3>
                                                                                    <div className='d-flex align-items-center'>
                                                                                        <span className='mr-1' style={{ fontSize: '1.2rem' }}>
                                                                                            {item.time_slot.split(':')[0]}:{item.time_slot.split(':')[1]}
                                                                                        </span>
                                                                                        {(item.status == "Confermato")
                                                                                            ?
                                                                                            <Alert message={item.status} type='success' showIcon />
                                                                                            :
                                                                                            (item.status == "Utilizzato")
                                                                                                ?
                                                                                                <Alert message={item.status} type='warning' showIcon />
                                                                                                :
                                                                                                <Alert message={item.status} type='error' showIcon />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="align-self-center">
                                                                                    <div className="avatar mr-1 avatar-lg">
                                                                                        <img src={item.avatar} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <div className={`${(isActiveVisite) ? 'col-xl-12' : 'col-xl-6'} col-md-12 col-12`}>
                                                        <div className="card">
                                                            <div className="card-content">
                                                                <div className="card-body">
                                                                    <Empty description={<span style={{ fontSize: '1rem' }}>Non sono presenti ticket</span>} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    }
                                </section>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        <Modal title="Convalida il ticket del cliente" open={showConvalida} onOk={validateTicketCodeInput} okText={(loadTicketValidation) ? <ThreeDots height="11" width="23" radius="4.5" color="#fff" ariaLabel="three-dots-loading" wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }} visible={true} /> : "Convalida"} cancelText="Chiudi" onCancel={() => { setShowConvalida(false); setTicketCode('') }}>
            <div className='d-flex justify-content-center py-1'>
                <ButtonAntd size='large' type='default' icon={<QrcodeOutlined />} className="d-flex justify-content-center align-items-center" onClick={() => { setShowConvalida(false); setShowReader(true); setShowQrReader(true) }}>
                    <span style={{ fontWeight: '500' }}>Leggi il QrCode</span>
                </ButtonAntd>
            </div>
            <Divider />
            <Input className='mb-1' size="large" placeholder="Inserisci il codice del ticket" value={ticketCode} onChange={(code) => setTicketCode(code.target.value.toUpperCase().trim())} prefix={<CheckCircleOutlined />} />
        </Modal>
        <Modal title="Leggi il QrCode del cliente" open={showQrReader} okButtonProps={{ style: { display: 'none' } }} cancelText="Chiudi" onCancel={() => { setShowReader(false); setShowQrReader(false); setShowConvalida(true) }}>
            <div className="d-flex justify-content-center" style={{ position: 'relative' }}>
                {loadReader && (
                    <div className="d-flex justify-content-center align-items-center qr-loader">
                        <Spin />
                    </div>
                )}
                {showReader && (
                    <QrReader
                        facingMode='environment'
                        delay={(loadReader) ? false : 1500}
                        style={{ height: '100%', width: '100%' }}
                        onScan={handleScanQrCode}
                        onLoad={() => setLoadReader(false)}
                    />
                )}
            </div>
        </Modal>
        <Modal title="Dettaglio ticket" open={showModalTicket} okButtonProps={{ style: { display: 'none' } }} cancelText="Chiudi" onCancel={() => setShowModalTicket(false)}>
            {(ticketSelect !== null)
                &&
                <div className="row mt-2">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-content">
                                <div className="card-body">
                                    <div className="media d-flex">
                                        <div className="media-body text-left">
                                            <h3>{ticketSelect.name} {ticketSelect.surname}</h3>
                                            {(ticketSelect.is_visit == true)
                                                &&
                                                <>
                                                <span style={{ fontSize: '1rem', fontWeight: '500' }}>
                                                    {ticketSelect.name_visit}
                                                </span><br/>
                                                <span style={{ fontSize: '1rem' }}>
                                                    Durata: <b>{ticketSelect.duration}min</b><br/>Prezzo: <b>{ticketSelect.price}</b>
                                                </span>
                                                </>
                                            }
                                            <div className='d-flex align-items-center'>
                                                <span className='mr-1' style={{ fontSize: '1.2rem', textTransform: 'capitalize' }}>
                                                    {ticketSelect.time_slot.split(':')[0]}:{ticketSelect.time_slot.split(':')[1]}
                                                </span>
                                                {(ticketSelect.status == "Confermato")
                                                    ?
                                                    <Alert message={ticketSelect.status} type='success' showIcon />
                                                    :
                                                    (ticketSelect.status == "Utilizzato")
                                                        ?
                                                        <Alert message={ticketSelect.status} type='warning' showIcon />
                                                        :
                                                        <Alert message={ticketSelect.status} type='error' showIcon />
                                                }
                                                {(ticketSelect.is_visit == true)
                                                    &&
                                                    <Alert message="Visita" className="ml-1" type='info' />
                                                }
                                            </div>
                                        </div>
                                        <div className="align-self-center">
                                            <div className="avatar mr-1 avatar-lg">
                                                <img src={ticketSelect.avatar} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Modal>
        <Modal
            title={null}
            centered
            open={initialized}
            onOk={null}
            onCancel={() => setInitialized(false)}
            footer={null}
        >
            <Result
                icon={<NotificationTwoTone />}
                status='info'
                title='Abilita le notifiche'
                subTitle="Non far attendere i tuoi clienti. Abilita le notifiche per ricevere un messaggio quando ogni cliente entra in farmacia."
            />
        </Modal>
        </>
    )
}

export default Tickets;