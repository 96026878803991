import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet";
import { Table, Alert, Skeleton, Button as ButtonAntd, Avatar, Card, Badge, Empty, Modal, Result, Dropdown } from 'antd';
import * as config from '../config.js';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Errors from '../components/Errors';
import { ThreeDots } from 'react-loader-spinner';
import { LoadingOutlined, CloseCircleFilled, CheckCircleFilled, ShoppingCartOutlined, DownOutlined, EditOutlined, DeleteOutlined, ExclamationCircleFilled, UpOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/it';

const { Meta } = Card;
const { confirm } = Modal;

function Flyer() {
    const navigate = useNavigate()
    const { result, id_pharmacy, id_plan, id_session } = useParams()
    const [haveError, setHaveError] = useState(false)
    const [productsRemaning, setProductsRemaning] = useState(0)
    const [totalProducts, setTotalProducts] = useState(0)
    const [pharmacyProducts, setPharmacyProducts] = useState(0)
    const [loadPlans, setLoadPlans] = useState(true)
    const [plans, setPlans] = useState([])
    const [customerId, setCustomerId] = useState(null)
    const [disablePayment, setDisablePayment] = useState(true)
    const [loadResponse1, setLoadResponse1] = useState(false)
    const [loadResponse2, setLoadResponse2] = useState(false)
    const [loadResponse3, setLoadResponse3] = useState(false)
    const [loadModal, setLoadModal] = useState(false)
    const [loadResult, setLoadResult] = useState(true)
    const [messageTitle, setMessageTitle] = useState("Stiamo elaborando il pagamento")
    const [messageDesc, setMessageDesc] = useState("Non chiudere questa pagina")
    const [hasError, setHasError] = useState(false)
    const [pageError, setPageError] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loadTable, setLoadTable] = useState(true)
    const [products, setProducts] = useState([])
    const [loadDelete, setLoadDelete] = useState(false)

    const columns = [
        {
            title: 'Nome prodotto',
            dataIndex: 'nome',
            key: 'nome',
            render: (text, record, index) => <Link to={`/prodotto/${record.key}`}>{text}</Link>
        },
        {
            title: 'Prezzo',
            dataIndex: 'prezzo',
            key: 'prezzo'
        },
        {
            title: 'Prezzo scontato',
            dataIndex: 'sconto',
            key: 'sconto'
        },
        {
            title: 'Data inizio',
            dataIndex: 'inizio',
            key: 'inizio',
            render: (text, record, index) => (record.is_date == "1" || record.is_date == 1) ? <span style={{ textTransform: 'capitalize' }}>{moment(text).format('ddd DD MMM YYYY')}</span> : null
        },
        {
            title: 'Data fine',
            dataIndex: 'fine',
            key: 'fine',
            render: (text, record, index) => (record.is_date == "1" || record.is_date == 1) ? <span style={{ textTransform: 'capitalize' }}>{moment(text).format('ddd DD MMM YYYY')}</span> : null
        },
        {
            title: 'Azioni',
            dataIndex: 'azioni',
            key: 'azioni',
            render: (text, record, index) => <Dropdown menu={{ items: [{ label: "Modifica prodotto", icon: <EditOutlined />, onClick: () => goToEditPage(record.key), key: '1' },{ label: "Elimina prodotto", icon: <DeleteOutlined />, onClick: () => deletePharmacyProductRequest(record.key), danger: true, key: '2' }] }}>
                <ButtonAntd>
                    <div className="d-flex align-items-center">
                        Azioni
                        <DownOutlined style={{ marginLeft: 5 }} />
                    </div>
                </ButtonAntd>
            </Dropdown>
        }
    ];

    const notAbleColumns = [
        {
            title: 'Nome prodotto',
            dataIndex: 'nome',
            key: 'nome'
        },
        {
            title: 'Prezzo',
            dataIndex: 'prezzo',
            key: 'prezzo'
        },
        {
            title: 'Prezzo scontato',
            dataIndex: 'sconto',
            key: 'sconto'
        },
        {
            title: 'Data inizio',
            dataIndex: 'inizio',
            key: 'inizio',
            render: (text, record, index) => (record.is_date == "1" || record.is_date == 1) ? <span style={{ textTransform: 'capitalize' }}>{moment(text).format('ddd DD MMM YYYY')}</span> : null
        },
        {
            title: 'Data fine',
            dataIndex: 'fine',
            key: 'fine',
            render: (text, record, index) => (record.is_date == "1" || record.is_date == 1) ? <span style={{ textTransform: 'capitalize' }}>{moment(text).format('ddd DD MMM YYYY')}</span> : null
        }
    ];

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        checkErrors(userInfo)
        isPaymentComplete()
        getProductsRemaning()
        getPharmacyProducts()
        getAllPlans()
        getPharmacyCustomerId()
    }, [])

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    }

    const hasSelected = selectedRowKeys.length > 0;

    const getPharmacyProducts = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-pharmacy-products`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy
            })
        })
        .then(function(response) {
            if (response.data.length > 0) {
                setProducts(response.data)
                setLoadTable(false)
            } else {
                setProducts([])
                setLoadTable(false)
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const isPaymentComplete = () => {
        if (result === "checkout") {
            setLoadModal(true)
            completePaymentProducts()
        }
    }

    const deletePharmacyProductRequest = (uid) => {
        confirm({
            title: 'ATTENZIONE',
            icon: <ExclamationCircleFilled />,
            content: 'Sei sicuro di voler eliminare il prodotto?',
            onOk() {
                deletePharmacyProduct(uid)
            },
            okButtonProps: { danger: true },
            okText: "Elimina",
            cancelText: "Annulla"
        });
    }

    const deletePharmacyProduct = (uid) => {
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/delete-pharmacy-product`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_product: uid
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                getProductsRemaning()
                getPharmacyProducts()
                toast.success(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const deletePharmacyProductsRequest = () => {
        confirm({
            title: 'ATTENZIONE',
            icon: <ExclamationCircleFilled />,
            content: 'Sei sicuro di voler eliminare i prodotti?',
            onOk() {
                deletePharmacyProducts()
            },
            okButtonProps: { danger: true },
            okText: "Elimina",
            cancelText: "Annulla"
        });
    }

    const deletePharmacyProducts = () => {
        setLoadDelete(true)
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/delete-pharmacy-products`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                ids_product: selectedRowKeys
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                setSelectedRowKeys([])
                getProductsRemaning()
                getPharmacyProducts()
                toast.success(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setLoadDelete(false)
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setLoadDelete(false)
            }
        })
        .catch(function(error) {
            console.error(error);
            setLoadDelete(false)
        });
    }

    const goToEditPage = (uid) => {
        navigate(`/prodotto/${uid}`)
    }

    const completePaymentProducts = () => {
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/add-pharmacy-extra-products`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_plan: id_plan,
                id_pharmacy: id_pharmacy,
                id_session: id_session
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                getProductsRemaning()
                setMessageTitle(response.data.title)
                setMessageDesc(response.data.message)
                setHasError(response.data.has_error)
                setPageError(response.data.page_error)
                setLoadResult(false)
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            setMessageTitle("Errore!")
            setMessageDesc("Abbiamo riscontrato problemi ad inserire il tuo acquisto sulla piattaforma. Ricarica la pagina o contatta l'assistenza.")
            setHasError(true)
            setLoadResult(false)
            reloadFunction()
            console.error(error);
        });
    }

    const reloadFunction = () => {
        setTimeout(() => {
            setLoadResult(true)
            setTimeout(() => {
                completePaymentProducts()
            }, 1000);
        }, 1500);
    }

    const checkErrors = (userInfo) => {
        if (userInfo !== null) {
            if (userInfo.is_code_verify == "0" || userInfo.is_code_verify == 0 || userInfo.is_completed == "0" || userInfo.is_completed == 0) {
                setHaveError(true)
            } else {
                setHaveError(false)
            }
        } else {
            setHaveError(false)
        }
    }

    const getProductsRemaning = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-pharmacy-remaning-products`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                setProductsRemaning(parseInt(response.data.remaning_products))
                setTotalProducts(parseInt(response.data.total_products))
                setPharmacyProducts(parseInt(response.data.pharmacy_products))
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const getPharmacyCustomerId = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-payment-customer-id`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                setDisablePayment(false)
                setCustomerId(response.data.customer_id)
            } else {
                setDisablePayment(true)
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const getAllPlans = () => {
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-all-products-extra-plans`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN
            })
        })
        .then(function(response) {
            if (response.data.length > 0) {
                setPlans(response.data)
                setLoadPlans(false)
            } else {
                setPlans([])
                setLoadPlans(false)
            }
        })
        .catch(function(error) {
            setLoadPlans(false)
            console.error(error);
        });
    }

    const goToCheckoutProduct = (price_id, plan_id, index) => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        if (index == 1) {
            setLoadResponse1(true)
        } else if (index == 2) {
            setLoadResponse2(true)
        } else if (index == 3) {
            setLoadResponse3(true)
        }
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/checkout-extra-product`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                plan: plan_id,
                id_pharmacy: pharmacyInfo.id_pharmacy,
                plan_id: price_id,
                customer: customerId
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                if (index == 1) {
                    setLoadResponse1(false)
                } else if (index == 2) {
                    setLoadResponse2(false)
                } else if (index == 3) {
                    setLoadResponse3(false)
                }
                window.location.href = response.data.message;
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                if (index == 1) {
                    setLoadResponse1(false)
                } else if (index == 2) {
                    setLoadResponse2(false)
                } else if (index == 3) {
                    setLoadResponse3(false)
                }
            }
        })
        .catch(function(error) {
            if (index == 1) {
                setLoadResponse1(false)
            } else if (index == 2) {
                setLoadResponse2(false)
            } else if (index == 3) {
                setLoadResponse3(false)
            }
            console.error(error);
        });
    }

    const closeModal = () => {
        navigate('/promozioni')
        setLoadModal(false)
    }

    return(
        <>
        <Helmet>
            <title>Promozioni | Takeiteasy Partners</title>
        </Helmet>
        <ToastContainer />
        <Navbar />
        <div className="app-content content">
            <div className="content-overlay"></div>
            <div className="content-wrapper">
                <Errors />
                <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2">
                        <h3 className="content-header-title">Promozioni farmacia</h3>
                    </div>
                </div>
                <div className="content-body">
                    <section className="row">
                        <div className="col-12">
                            <div className="card">
                                {(haveError)
                                    ?
                                    <Empty
                                        description={
                                            <span>
                                                Risolvi gli errori per poter continuare.
                                            </span>
                                        }
                                    />
                                    :
                                    <>
                                    <div className="card-header">
                                        <h4 className="card-title mb-1">Gestisci prodotti in promozione</h4>
                                        <span>Prodotti disponibili: <Badge count={`${productsRemaning} / ${totalProducts}`} showZero color="#faad14" /></span>
                                        <div className="heading-elements">
                                            <ButtonAntd type='primary' disabled={(productsRemaning == 0) ? true : (pharmacyProducts > totalProducts) ? true : false} onClick={() => navigate('/aggiungi/prodotto')}>
                                                Aggiungi prodotto
                                            </ButtonAntd>
                                        </div>
                                    </div>
                                    <div className="card-content">
                                        {(pharmacyProducts > totalProducts)
                                            &&
                                            <div className="px-2 pb-1">
                                                <Alert
                                                    message="Errore"
                                                    description={`Attualmente i tuoi prodotti non sono visibili. Elimina ${pharmacyProducts - totalProducts} prodotti per continuare ad utilizzare la sezione promozioni.`}
                                                    type="error"
                                                    showIcon
                                                />
                                            </div>
                                        }
                                        <div className='px-2'>
                                            <ButtonAntd type="primary" danger disabled={!hasSelected} onClick={(loadDelete) ? null : deletePharmacyProductsRequest}>
                                                {(loadDelete)
                                                    ?
                                                    <ThreeDots 
                                                        height="11" 
                                                        width="23" 
                                                        radius="4.5"
                                                        color="#fff" 
                                                        ariaLabel="three-dots-loading"
                                                        wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
                                                        visible={true}
                                                    />
                                                    :
                                                    'Elimina'
                                                }
                                            </ButtonAntd>
                                            <span style={{ marginLeft: 8 }}>
                                                {hasSelected ? `${selectedRowKeys.length} prodott${(selectedRowKeys.length == 1) ? 'o' : 'i'} selezionat${(selectedRowKeys.length == 1) ? 'o' : 'i'}` : ''}
                                            </span>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                {(loadTable)
                                                    ?
                                                    <div className="px-2">
                                                        <Skeleton avatar={true} active={true} />
                                                    </div>
                                                    :
                                                    <Table rowSelection={rowSelection} columns={(pharmacyProducts > totalProducts) ? notAbleColumns : columns} dataSource={products} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                }
                            </div>
                        </div>
                    </section>
                    {(!haveError)
                        &&
                        <>
                        <section className="row">
                            <div className="col-12">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="card-body cleartfix">
                                            <div class="media align-items-stretch">
                                                <div class="align-self-center">
                                                    <i class="icon-bar-chart warning font-large-2 mr-2"></i>
                                                </div>
                                                <div class="media-body">
                                                    <h4>Aumenta le tue vendite</h4>
                                                    <span>Acquista un pacchetto per aumentare il numero di prodotti e massimizza le vendite della tua farmacia.</span>
                                                </div>
                                                <div class="align-self-center">
                                                    <h1 className="d-flex align-items-baseline justify-content-center" style={{ fontWeight: '500' }}>+35% <h5 style={{ marginLeft: 6 }}>vendite</h5></h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Aggiungi nuovi prodotti</h4>
                                    </div>
                                    <div className="card-content">
                                        <div className="card-body">
                                            <div className="row">
                                                {(loadPlans)
                                                    ?
                                                    <div className="col-lg-4 col-md-6 col-12">
                                                        <Skeleton avatar={true} active={true} />
                                                    </div>
                                                    :
                                                    plans.map((item, index) => {
                                                        const currentIndex = index + 1;
                                                        return (
                                                            <div key={index} className="col-lg-4 col-md-6 col-12">
                                                                <Card
                                                                    actions={[
                                                                        <span style={{ fontSize: '0.7rem', fontWeight: '400', color: '#1677ff' }}>
                                                                            N. PRODOTTI<br />
                                                                            <span style={{ fontSize: '2rem', fontWeight: '600' }}>{item.extra_products}</span>
                                                                        </span>,
                                                                        <span style={{ fontSize: '0.7rem', fontWeight: '400', color: '#1677ff' }}>
                                                                            PREZZO (IVA Esclusa)<br />
                                                                            {(item.popular == "1" || item.popular == 1)
                                                                                ?
                                                                                <div className="d-flex align-items-baseline justify-content-center">
                                                                                    <span style={{ fontSize: '1.2rem', fontWeight: '500', color: 'grey' }}><s>€{item.price_plan}</s></span>
                                                                                    <span style={{ fontSize: '2rem', fontWeight: '600' }}>€{item.discount_price_plan}</span>
                                                                                </div>
                                                                                :
                                                                                <span style={{ fontSize: '2rem', fontWeight: '600' }}>€{item.price_plan}</span>
                                                                            }
                                                                        </span>,
                                                                        <div style={{ minHeight: '5rem' }} className="d-flex align-items-center justify-content-center">
                                                                            <ButtonAntd disabled={disablePayment} type='primary' block onClick={(loadResponse1 || loadResponse2 || loadResponse3) ? null : () => goToCheckoutProduct(item.stripe_price_id, item.id, currentIndex)} className="d-flex align-items-center justify-content-center mx-1" size='large' icon={<ShoppingCartOutlined />}>
                                                                                {(currentIndex == 1)
                                                                                    ?
                                                                                    (loadResponse1)
                                                                                        ?
                                                                                        <ThreeDots 
                                                                                            height="11" 
                                                                                            width="23" 
                                                                                            radius="4.5"
                                                                                            color="#fff" 
                                                                                            ariaLabel="three-dots-loading"
                                                                                            wrapperStyle={{ justifyContent: 'center', alignItems: 'center', marginLeft: 10 }}
                                                                                            visible={true}
                                                                                        />
                                                                                        :
                                                                                        'Acquista'
                                                                                    :
                                                                                    (currentIndex == 2)
                                                                                        ?
                                                                                        (loadResponse2)
                                                                                            ?
                                                                                            <ThreeDots 
                                                                                                height="11" 
                                                                                                width="23" 
                                                                                                radius="4.5"
                                                                                                color="#fff" 
                                                                                                ariaLabel="three-dots-loading"
                                                                                                wrapperStyle={{ justifyContent: 'center', alignItems: 'center', marginLeft: 10 }}
                                                                                                visible={true}
                                                                                            />
                                                                                            :
                                                                                            'Acquista'
                                                                                        :
                                                                                        (currentIndex == 3)
                                                                                            ?
                                                                                            (loadResponse3)
                                                                                                ?
                                                                                                <ThreeDots 
                                                                                                    height="11" 
                                                                                                    width="23" 
                                                                                                    radius="4.5"
                                                                                                    color="#fff" 
                                                                                                    ariaLabel="three-dots-loading"
                                                                                                    wrapperStyle={{ justifyContent: 'center', alignItems: 'center', marginLeft: 10 }}
                                                                                                    visible={true}
                                                                                                />
                                                                                                :
                                                                                                'Acquista'
                                                                                            :
                                                                                            null
                                                                                }
                                                                            </ButtonAntd>
                                                                        </div>
                                                                    ]}
                                                                >
                                                                    <Meta
                                                                        avatar={(item.popular == "1" || item.popular == 1) ? <Badge count={`-${item.discount}%`} style={{ width: '3.5rem', height: '3.5rem', borderRadius: '1.75rem', backgroundColor: 'red', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1rem', fontWeight: '600' }} /> : null}
                                                                        title={item.name_plan}
                                                                        description={item.description_plan}
                                                                    />
                                                                </Card>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        </>
                    }
                </div>
            </div>
        </div>
        <Modal
            title={null}
            centered
            open={loadModal}
            onOk={null}
            onCancel={null}
            footer={null}
        >
            <Result
                icon={(loadResult) ? <LoadingOutlined /> : (hasError) ? <CloseCircleFilled /> : <CheckCircleFilled />}
                status={(loadResult) ? "info" : (hasError) ? "error" : "success"}
                title={messageTitle}
                subTitle={messageDesc}
                extra={
                    (!loadResult)
                        ?
                        (hasError)
                            ?
                            (pageError)
                                ?
                                <ButtonAntd type="primary" onClick={closeModal}>
                                    Chiudi
                                </ButtonAntd>
                                :
                                <>
                                <ButtonAntd type="default">
                                    Contatta l'Assistenza
                                </ButtonAntd>
                                <ButtonAntd type="primary" onClick={closeModal}>
                                    Chiudi
                                </ButtonAntd>
                                </>
                            :
                            <ButtonAntd type="primary" onClick={closeModal}>
                                Chiudi
                            </ButtonAntd>
                        :
                        null
                }
            />
        </Modal>
        <Footer />
        </>
    )
}

export default Flyer;