import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet";
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Empty, Skeleton, Alert, Breadcrumb, Segmented, Badge } from 'antd';
import * as config from '../config';
import axios from 'axios';
import Errors from '../components/Errors';
import { ThreeDots } from 'react-loader-spinner';

function ActivatePharmacy() {
    const { id_pharmacy } = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [loadResponse1, setLoadResponse1] = useState(false)
    const [loadResponse2, setLoadResponse2] = useState(false)
    const [loadResponse3, setLoadResponse3] = useState(false)
    const [haveError, setHaveError] = useState(false)
    const [pharmacyBilling, setPharmacyBilling] = useState(null)
    const [isError, setIsError] = useState(false)
    const [message, setMessage] = useState("")
    const [plans, setPlans] = useState([])
    const [loadPlan, setLoadPlan] = useState(true)
    const [typePlan, setTypePlan] = useState('Semestrale')

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        checkErrors(userInfo)
        getPharmacy(userInfo)
        getAllPlans()
    }, [])

    const checkErrors = (userInfo) => {
        if (userInfo !== null) {
            if (userInfo.is_code_verify == "0" || userInfo.is_code_verify == 0 || userInfo.is_completed == "0" || userInfo.is_completed == 0) {
                setHaveError(true)
            } else {
                setHaveError(false)
            }
        } else {
            setHaveError(false)
        }
    }

    const getAllPlans = () => {
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-all-plans`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN
            })
        })
        .then(function(response) {
            if (response.data.length > 0) {
                setPlans(response.data)
                setLoadPlan(false)
            } else {
                setPlans([])
                setLoadPlan(false)
            }
        })
        .catch(function(error) {
            setIsLoading(false)
            console.error(error);
        });
    }

    const getPharmacy = (userInfo) => {
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-user-pharmacy-billing`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_admin_user: userInfo.id,
                id_pharmacy: id_pharmacy
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                setPharmacyBilling(response.data)
                setIsLoading(false)
                setIsError(false)
            } else {
                setIsLoading(false)
                setMessage(response.data.message)
                setIsError(true)
            }
        })
        .catch(function(error) {
            setIsLoading(false)
            console.error(error);
        });
    }

    const activatePharmacy = (price_id, plan_id, index) => {
        if (index == 1) {
            setLoadResponse1(true)
        } else if (index == 2) {
            setLoadResponse2(true)
        } else if (index == 3) {
            setLoadResponse3(true)
        }
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/activate-pharmacy-subscription`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                plan: plan_id,
                id_pharmacy: id_pharmacy,
                email: pharmacyBilling.email,
                plan_id: price_id
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                if (index == 1) {
                    setLoadResponse1(false)
                } else if (index == 2) {
                    setLoadResponse2(false)
                } else if (index == 3) {
                    setLoadResponse3(false)
                }
                window.location.href = response.data.message;
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                if (index == 1) {
                    setLoadResponse1(false)
                } else if (index == 2) {
                    setLoadResponse2(false)
                } else if (index == 3) {
                    setLoadResponse3(false)
                }
            }
        })
        .catch(function(error) {
            if (index == 1) {
                setLoadResponse1(false)
            } else if (index == 2) {
                setLoadResponse2(false)
            } else if (index == 3) {
                setLoadResponse3(false)
            }
            console.error(error);
        });
    }

    return(
        <>
        <ToastContainer />
        <Helmet>
            <title>Attiva Farmacia | Takeiteasy Partners</title>
        </Helmet>
        <Navbar />
        <div class="app-content content">
            <div class="content-overlay"></div>
            <div class="content-wrapper">
                <Errors />
                <div class="content-header row">
                    <div class="content-header-left col-md-6 col-12 mb-2">
                        <h3 class="content-header-title">Attiva farmacia</h3>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/farmacie">Farmacie</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Attiva farmacia</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                <div class="content-body">
                    {(!isLoading && !isError)
                        &&
                        <>
                        <div className="d-flex justify-content-center">
                            <Segmented onChange={(value) => setTypePlan(value)} value={typePlan} size="large" options={[{ label: "Semestrale", value: "Semestrale" },{ label: `Annuale -10%`, value: "Annuale" }]} />
                        </div>
                        <div className="container">
                            <div class="row mt-2">
                                {(loadPlan)
                                    ?
                                    <Skeleton active={true} />
                                    :
                                    plans.map((item, index) => {
                                        const currentIndex = index + 1;
                                        const feautures = item.feautures.split('-');
                                        const sconto = parseInt(item.monthly_price) / parseInt(item.annual_discount);
                                        const mensile_annuale = (parseInt(item.monthly_price) - sconto).toFixed(0);
                                        return(
                                            <div key={index} class="col-xl-4 col-md-6 col-12">

                                                <div class="card profile-card-with-cover">
                                                    <div class="card-content card-deck text-center">
                                                        <div class="card box-shadow">
                                                            {(item.popular == "1" || item.popular == 1)
                                                                ?
                                                                <Badge.Ribbon text={<span style={{ color: '#000' }}>Consigliato</span>} color="yellow">
                                                                    <div class={`card-header ${(item.popular == "1" || item.popular == 1) && "bg-info"}`} style={{ backgroundColor: (item.popular == "0" || item.popular == 0) ? '#f5f7ff' : null }}>
                                                                        <h3 class={`my-0 font-weight-bold ${(item.popular == "1" || item.popular == 1) && "text-white"}`}>{item.name_plan}</h3>
                                                                    </div>
                                                                </Badge.Ribbon>
                                                                :
                                                                <div class={`card-header ${(item.popular == "1" || item.popular == 1) && "bg-info"}`} style={{ backgroundColor: (item.popular == "0" || item.popular == 0) ? '#f5f7ff' : null }}>
                                                                    <h3 class={`my-0 font-weight-bold ${(item.popular == "1" || item.popular == 1) && "text-white"}`}>{item.name_plan}</h3>
                                                                </div>
                                                            }
                                                            <div class="card-body">
                                                                {(typePlan == "Semestrale")
                                                                    ?
                                                                    <h1 style={{ fontWeight: '600', fontSize: '2.5rem' }} class="pricing-card-title">€{item.semesterly_price}<small style={{ fontSize: '1rem' }} class="text-muted">/ ogni 6 mesi</small></h1>
                                                                    :
                                                                    <h1 style={{ fontWeight: '600', fontSize: '2.5rem' }} class="pricing-card-title">€{item.annual_price}<small style={{ fontSize: '1rem' }} class="text-muted">/ all'anno</small></h1>
                                                                }
                                                                {(typePlan == "Semestrale")
                                                                    ?
                                                                    <h3 class="pricing-card-title">€{item.monthly_price}<small style={{ fontSize: '1rem' }} class="text-muted">/ al mese</small></h3>
                                                                    :
                                                                    <h3 class="pricing-card-title">€{mensile_annuale}<small style={{ fontSize: '1rem' }} class="text-muted">/ al mese</small></h3>
                                                                }
                                                                <span style={{ textDecoration: 'underline' }}>{item.description_plan}</span>
                                                                <ul class="list-unstyled mt-2 mb-2">
                                                                    {feautures.map((item, index) => {
                                                                        if (item.includes('(NON)')) {
                                                                            return(
                                                                                <li key={index}><s>{item.replace('(NON)','')}</s></li>
                                                                            )
                                                                        } else if (item.includes('(NON CLI)')) {
                                                                            return(
                                                                                <li key={index}>Lista clienti <s>{item.replace('(NON CLI)','')}</s></li>
                                                                            )
                                                                        } else {
                                                                            return(
                                                                                <li key={index}>{item}</li>
                                                                            )
                                                                        }
                                                                    })}
                                                                </ul>
                                                                {(typePlan == "Semestrale")
                                                                    ?
                                                                    <button onClick={(loadResponse1 || loadResponse2 || loadResponse3) ? null : () => activatePharmacy(item.stripe_plan_id_6, item.id, currentIndex)} type="button" class="btn btn-lg btn-block btn-info">
                                                                        {(currentIndex == 1)
                                                                            ?
                                                                            (loadResponse1)
                                                                                ?
                                                                                <ThreeDots 
                                                                                    height="22" 
                                                                                    width="46" 
                                                                                    radius="9"
                                                                                    color="#fff" 
                                                                                    ariaLabel="three-dots-loading"
                                                                                    wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
                                                                                    visible={true}
                                                                                />
                                                                                :
                                                                                'Inizia periodo di prova'
                                                                            :
                                                                            (currentIndex == 2)
                                                                                ?
                                                                                (loadResponse2)
                                                                                    ?
                                                                                    <ThreeDots 
                                                                                        height="22" 
                                                                                        width="46" 
                                                                                        radius="9"
                                                                                        color="#fff" 
                                                                                        ariaLabel="three-dots-loading"
                                                                                        wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
                                                                                        visible={true}
                                                                                    />
                                                                                    :
                                                                                    'Inizia periodo di prova'
                                                                                :
                                                                                (currentIndex == 3)
                                                                                    ?
                                                                                    (loadResponse3)
                                                                                        ?
                                                                                        <ThreeDots 
                                                                                            height="22" 
                                                                                            width="46" 
                                                                                            radius="9"
                                                                                            color="#fff" 
                                                                                            ariaLabel="three-dots-loading"
                                                                                            wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
                                                                                            visible={true}
                                                                                        />
                                                                                        :
                                                                                        'Inizia periodo di prova'
                                                                                    :
                                                                                    'Inizia periodo di prova'
                                                                        }
                                                                    </button>
                                                                    :
                                                                    <button onClick={(loadResponse1 || loadResponse2 || loadResponse3) ? null : () => activatePharmacy(item.stripe_plan_id_12, item.id, currentIndex)} type="button" class="btn btn-lg btn-block btn-info">
                                                                        {(currentIndex == 1)
                                                                            ?
                                                                            (loadResponse1)
                                                                                ?
                                                                                <ThreeDots 
                                                                                    height="22" 
                                                                                    width="46" 
                                                                                    radius="9"
                                                                                    color="#fff" 
                                                                                    ariaLabel="three-dots-loading"
                                                                                    wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
                                                                                    visible={true}
                                                                                />
                                                                                :
                                                                                'Inizia periodo di prova'
                                                                            :
                                                                            (currentIndex == 2)
                                                                                ?
                                                                                (loadResponse2)
                                                                                    ?
                                                                                    <ThreeDots 
                                                                                        height="22" 
                                                                                        width="46" 
                                                                                        radius="9"
                                                                                        color="#fff" 
                                                                                        ariaLabel="three-dots-loading"
                                                                                        wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
                                                                                        visible={true}
                                                                                    />
                                                                                    :
                                                                                    'Inizia periodo di prova'
                                                                                :
                                                                                (currentIndex == 3)
                                                                                    ?
                                                                                    (loadResponse3)
                                                                                        ?
                                                                                        <ThreeDots 
                                                                                            height="22" 
                                                                                            width="46" 
                                                                                            radius="9"
                                                                                            color="#fff" 
                                                                                            ariaLabel="three-dots-loading"
                                                                                            wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
                                                                                            visible={true}
                                                                                        />
                                                                                        :
                                                                                        'Inizia periodo di prova'
                                                                                    :
                                                                                    'Inizia periodo di prova'
                                                                        }
                                                                    </button>
                                                                }
                                                                <Badge style={{ marginTop: 5 }} status="success" text="2 mesi di prova gratuita" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        </>
                    }
                    {(haveError)
                        ?
                        <section class="input-groups" id="input-groups">
                            <div class="row match-height">
                                <div class="col-12">
                                    <div class="card">
                                        <Empty
                                            description={
                                                <span>
                                                    Risolvi gli errori per poter continuare.
                                                </span>
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                        :
                        (isLoading)
                            ?
                            <section class="input-groups" id="input-groups">
                                <div class="row match-height">
                                    <div class="col-12">
                                        <div class="card">
                                            <div class="card-body">
                                                <Skeleton active />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            :
                            (isError)
                                ?
                                <section class="input-groups" id="input-groups">
                                    <div class="row match-height">
                                        <div class="col-12">
                                            <div class="card">
                                                <div class="card-body">
                                                    <Empty
                                                        description={
                                                            <span>
                                                                {message}
                                                            </span>
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                :
                                null
                    }
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default ActivatePharmacy;