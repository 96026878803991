import React from "react";
import { Button } from 'antd';
import Resizer from "react-image-file-resizer";

function FileInput({ onImageSelected, inputRef, typeImage }) {

    const handleOnChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            //typeImage 0 per il Logo
            if (typeImage == 0) {
                Resizer.imageFileResizer(
                    event.target.files[0],
                    650,
                    650,
                    "JPEG",
                    90,
                    0,
                    (uri) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(uri);
                        reader.onload = function (e) {
                            onImageSelected(reader.result);
                        };
                    },
                    "file"
                );
            } else {
                Resizer.imageFileResizer(
                    event.target.files[0],
                    960,
                    540,
                    "JPEG",
                    90,
                    0,
                    (uri) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(uri);
                        reader.onload = function (e) {
                            onImageSelected(reader.result);
                        };
                    },
                    "file"
                );
            }
        }
    }

    const onChooseImg = () => {
        inputRef.current.click();
    }

    return (
        <div>
            <input
                type="file"
                accept="image/*"
                ref={inputRef}
                onChange={handleOnChange}
                style={{ display: "none" }}
            />

            <Button type="primary" onClick={onChooseImg}>
                Carica immagine
            </Button>
        </div>
    );
}

export default FileInput;