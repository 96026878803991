import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Empty, Skeleton, Breadcrumb, Button, Modal, Avatar } from 'antd';
import * as config from '../config';
import axios from 'axios';
import Errors from '../components/Errors';
import { ThreeDots } from 'react-loader-spinner';
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
import FileInput from '../components/FileInput';
import ImageCropper from '../components/ImageCropper';

Geocode.setApiKey(config.GOOGLE_MAPS_API);
Geocode.setLanguage("it");

function AddPharmacy() {
    const navigate = useNavigate()
    const inputLogoRef = useRef()
    const inputBannerRef = useRef()
    const [isLoading, setIsLoading] = useState(true)
    const [loading, setLoading] = useState(false)
    const [haveError, setHaveError] = useState(false)
    const [pharmacy, setPharmacy] = useState({
        name_pharmacy: ""
    })
    const [message, setMessage] = useState("")
    const [pharmacyAddress, setPharmacyAddress] = useState("")
    const [pharmacyCivic, setPharmacyCivic] = useState("")
    const [pharmacyCity, setPharmacyCity] = useState("")
    const [pharmacyCap, setPharmacyCap] = useState("")
    const [pharmacyProvince, setPharmacyProvince] = useState("")
    const [pharmacyState, setPharmacyState] = useState("")
    const [pharmacyLatitude, setPharmacyLatitude] = useState(0)
    const [pharmacyLongitude, setPharmacyLongitude] = useState(0)
    const [isAddressDiasbledPharmacy, setIsAddressDisabledPharmacy] = useState(true)
    const [isModalOpenBanner, setIsModalOpenBanner] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [image, setImage] = useState("");
    const [currentPage, setCurrentPage] = useState("choose-img");
    const [imageBanner, setImageBanner] = useState("");
    const [currentPageBanner, setCurrentPageBanner] = useState("choose-img");
    const [imgUpload, setImgUpload] = useState(config.LOGO_DEFAULT)
    const [imgUploadBanner, setImgUploadBanner] = useState(config.BANNER_1)
    const [showAnteprima, setShowAnteprima] = useState(false)

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        checkErrors(userInfo)
    }, [])

    const checkErrors = (userInfo) => {
        if (userInfo !== null) {
            if (userInfo.is_code_verify == "0" || userInfo.is_code_verify == 0 || userInfo.is_completed == "0" || userInfo.is_completed == 0) {
                setHaveError(true)
            } else {
                setHaveError(false)
            }
        } else {
            setHaveError(false)
        }
    }

    const onImageSelected = (selectedImg) => {
        setImage(selectedImg);
        setIsModalOpen(true)
    }
    
    const onCropDone = (imgCroppedArea) => {
        const canvasEle = document.createElement("canvas");
        canvasEle.width = imgCroppedArea.width;
        canvasEle.height = imgCroppedArea.height;
    
        const context = canvasEle.getContext("2d");
    
        let imageObj1 = new Image();
        imageObj1.src = image;
        imageObj1.onload = function () {
            context.drawImage(
                imageObj1,
                imgCroppedArea.x,
                imgCroppedArea.y,
                imgCroppedArea.width,
                imgCroppedArea.height,
                0,
                0,
                imgCroppedArea.width,
                imgCroppedArea.height
            );
        
            const dataURL = canvasEle.toDataURL("image/jpeg");
        
            setImgUpload(dataURL);
            setCurrentPage("img-cropped");
            setIsModalOpen(false)
        }
    }

    const onCropCancel = () => {
        setCurrentPage("choose-img");
        setImgUpload(config.LOGO_DEFAULT);
        setIsModalOpen(false)
    }

    const onImageSelectedBanner = (selectedImg) => {
        setImageBanner(selectedImg);
        setIsModalOpenBanner(true)
    }
    
    const onCropDoneBanner = (imgCroppedAreaBanner) => {
        const canvasEle = document.createElement("canvas");
        canvasEle.width = imgCroppedAreaBanner.width;
        canvasEle.height = imgCroppedAreaBanner.height;
    
        const context = canvasEle.getContext("2d");
    
        let imageObj1 = new Image();
        imageObj1.src = imageBanner;
        imageObj1.onload = function () {
            context.drawImage(
                imageObj1,
                imgCroppedAreaBanner.x,
                imgCroppedAreaBanner.y,
                imgCroppedAreaBanner.width,
                imgCroppedAreaBanner.height,
                0,
                0,
                imgCroppedAreaBanner.width,
                imgCroppedAreaBanner.height
            );
        
            const dataURL = canvasEle.toDataURL("image/jpeg");
        
            setImgUploadBanner(dataURL);
            setCurrentPageBanner("img-cropped");
            setIsModalOpenBanner(false)
        }
    }

    const onCropCancelBanner = () => {
        setCurrentPageBanner("choose-img");
        //setImgUploadBanner(config.BANNER_1);
        setIsModalOpenBanner(false)
    }

    const addPharmacy = () => {
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        setLoading(true)
        if (pharmacy.name_pharmacy !== "" && pharmacyAddress !== "" && pharmacyCivic !== "" && pharmacyCity !== "" && pharmacyCap !== "" && pharmacyProvince !== "" && pharmacyState !== "" && pharmacyLatitude !== 0 && pharmacyLongitude !== 0 && imgUpload !== "" && imgUploadBanner !== "") {
            axios({
                method: 'post',
                url: `${config.WEBSITE_URL}/partners/add-pharmacy`,
                headers: {
                    'Content-Type': 'application/json'
                },
                auth: {
                    username: config.USERNAME_TOKEN,
                    password: config.PASSWORD_TOKEN
                },
                data: JSON.stringify({
                    token_access: config.WEB_TOKEN,
                    id_admin_user: userInfo.id,
                    name_pharmacy: pharmacy.name_pharmacy.trim(),
                    address: `${pharmacyAddress.trim()}, ${pharmacyCivic.trim()}`,
                    city: pharmacyCity.trim(),
                    cap: pharmacyCap.trim(),
                    province: pharmacyProvince.trim(),
                    state: pharmacyState,
                    latitude: pharmacyLatitude,
                    longitude: pharmacyLongitude,
                    logo: imgUpload,
                    banner: imgUploadBanner
                })
            })
            .then(function(response) {
                if (response.data.code == true) {
                    let pharmaciesInfo = JSON.parse(localStorage.getItem('pharmacies_info'))
                    pharmaciesInfo.push({ id_pharmacy: response.data.id_pharmacy, name_pharmacy: pharmacy.name_pharmacy.trim(), is_active: "0", is_verificated: "0" })
                    localStorage.setItem('pharmacies_info', JSON.stringify(pharmaciesInfo))
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(() => {
                        navigate('/farmacie')
                    }, 1000);
                    setLoading(false)
                } else {
                    toast.error(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setLoading(false)
                }
            })
            .catch(function(error) {
                console.error(error);
                setLoading(false)
            });
        } else {
            toast.error("Compila tutti i campi obbligatori", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false)
        }
    }

    const handleAddressPharmacy = (place) => {
        let count = 0;
        place.address_components.forEach(element => {
            const long_name = element.long_name;
            const short_name = element.short_name;
            count++;

            if (element.types.includes('locality')) {
                setPharmacyCity(long_name)
            } else if (element.types.includes('administrative_area_level_2')) {
                setPharmacyProvince(short_name)
            } else if (element.types.includes('postal_code')) {
                setPharmacyCap(long_name)
            } else if (element.types.includes('street_number')) {
                setPharmacyCivic(long_name)
            } else if (element.types.includes('route')) {
                setPharmacyAddress(long_name)
            } else if (element.types.includes('country')) {
                setPharmacyState(short_name)
            }

            if (count == place.address_components.length) {
                Geocode.fromAddress(place.formatted_address).then(
                    (response) => {
                        const { lat, lng } = response.results[0].geometry.location;
                        setPharmacyLatitude(lat)
                        setPharmacyLongitude(lng)
                    },
                    (error) => {
                        console.error(error);
                    }
                );
                setIsAddressDisabledPharmacy(false)
            }
        });
    }

    return(
        <>
        <ToastContainer />
        <Helmet>
            <title>Aggiungi Farmacia | Takeiteasy Partners</title>
        </Helmet>
        <Navbar />
        <div class="app-content content">
            <div class="content-overlay"></div>
            <div class="content-wrapper">
                <Errors />
                <div class="content-header row">
                    <div class="content-header-left col-md-6 col-12 mb-2">
                        <h3 class="content-header-title">Aggiungi farmacia</h3>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/farmacie">Farmacie</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Aggiungi farmacia</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                <div class="content-body">
                    <section class="input-groups" id="input-groups">
                        <div class="row match-height">
                            <div class="col-12">
                                <div class="card">
                                    {(haveError)
                                        ?
                                        <Empty
                                            description={
                                                <span>
                                                    Risolvi gli errori per poter continuare.
                                                </span>
                                            }
                                        />
                                        :
                                        <>
                                        <div class="card-header">
                                            <h4 class="card-title">Inserisci i dati della tua farmacia</h4>
                                        </div>
                                        <div class="card-body">
                                            <form>
                                                <fieldset>
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label htmlFor="name_pharmacy">Nome farmacia*</label>
                                                                <input type="text" class="form-control" value={pharmacy.name_pharmacy} onChange={(name_pharmacy) => setPharmacy({ ...pharmacy, name_pharmacy: name_pharmacy.target.value })} name="name_pharmacy" id="name_pharmacy" placeholder='Inserisci il nome della Farmacia (Es. Farmacia Dott. Rossi)' required />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label htmlFor="address">Indirizzo farmacia*</label>
                                                                <Autocomplete
                                                                    apiKey={config.GOOGLE_MAPS_API}
                                                                    onPlaceSelected={(place) => handleAddressPharmacy(place)}
                                                                    language='it'
                                                                    options={{ types: ["address"] }}
                                                                    placeholder="Inserisci l'indirizzo della tua Farmacia (Es. Via Torino, 23, Milano)"
                                                                    className="form-control"
                                                                    aria-required={true}
                                                                    id="address"
                                                                    defaultValue={(pharmacyAddress !== "" && pharmacyCivic !== "") ? `${pharmacyAddress}, ${pharmacyCivic}` : null}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label htmlFor="city">Città farmacia*</label>
                                                                <input type="text" class="form-control" disabled={isAddressDiasbledPharmacy} value={pharmacyCity} onChange={(city) => setPharmacyCity(city.target.value)} name="city" id="city" placeholder="Inserisci la città della Farmacia" required />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label htmlFor="cap">CAP*</label>
                                                                <input type="text" class="form-control" disabled={isAddressDiasbledPharmacy} value={pharmacyCap} onChange={(cap) => setPharmacyCap(cap.target.value)} name="cap" id="cap" placeholder="Inserisci il CAP della Farmacia" required />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label htmlFor="province">Provincia*</label>
                                                                <input type="text" class="form-control" disabled={isAddressDiasbledPharmacy} value={pharmacyProvince} onChange={(province) => setPharmacyProvince(province.target.value)} name="province" id="province" placeholder="Inserisci la provincia della Farmacia" required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                                <fieldset>
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label htmlFor="logo">Logo della farmacia*</label><br />
                                                                <p>Seleziona un'opzione o carica la tua immagine</p>
                                                                <div className="row">
                                                                    <div className='col-lg-4 col-md-12 col-12 mb-1'>
                                                                        <button onClick={(imgUpload === config.LOGO_DEFAULT) ? null : () => {setCurrentPage("choose-img"); setImage(""); setImgUpload(config.LOGO_DEFAULT);}} type="button" className={(imgUpload === config.LOGO_DEFAULT) ? "button-select-image mr-1" : "button-not-select-image mr-1"}>
                                                                            <i className={(imgUpload === config.LOGO_DEFAULT) ? "icon-check icon-img-select" : "icon-check icon-img-not-select"}></i>
                                                                            <Avatar size={100} src={config.LOGO_DEFAULT} />
                                                                        </button>
                                                                    </div>
                                                                    <div className='col-lg-4 col-md-12 col-12 mb-1'>
                                                                        <div className={(imgUpload === config.LOGO_DEFAULT) ? "button-not-select-image" : "button-select-image"}>
                                                                            <i className={(imgUpload === config.LOGO_DEFAULT) ? "icon-check icon-img-not-select" : "icon-check icon-img-select"}></i>
                                                                            {currentPage === "choose-img"
                                                                                ? 
                                                                                (
                                                                                    <FileInput setImage={setImage} typeImage={0} onImageSelected={onImageSelected} inputRef={inputLogoRef} />
                                                                                ) : (
                                                                                    <div>
                                                                                        <Avatar size={100} src={imgUpload} />
                                                                                        <Button className='ml-1' type='primary' danger onClick={() => {setCurrentPage("choose-img"); setImage(""); setImgUpload(config.LOGO_DEFAULT);}}>
                                                                                            <i className='ft-trash-2'></i>
                                                                                        </Button>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label htmlFor="banner">Sfondo della farmacia*</label>
                                                                <p>Seleziona un'opzione o carica la tua immagine</p>
                                                                <div className='row'>
                                                                    <div className="col-lg-4 col-md-12 col-12 mb-1">
                                                                        <button type="button" onClick={() => {setCurrentPageBanner("choose-img"); setImageBanner(""); setImgUploadBanner(config.BANNER_1);}} className={(imgUploadBanner == config.BANNER_1) ? "button-select-image" : "button-not-select-image"}>
                                                                            <div className="icon-container">
                                                                                <i className={(imgUploadBanner == config.BANNER_1) ? "icon-check icon-color-select" : "icon-check icon-non-color"}></i>
                                                                            </div>
                                                                            <img style={{ width: 170, height: 140, borderRadius: '0.5rem', objectFit: 'cover' }} src={config.BANNER_1} />
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-12 col-12 mb-1">
                                                                        <button type="button" onClick={() => {setCurrentPageBanner("choose-img"); setImageBanner(""); setImgUploadBanner(config.BANNER_2);}} className={(imgUploadBanner == config.BANNER_2) ? "button-select-image" : "button-not-select-image"}>
                                                                            <div className="icon-container">
                                                                                <i className={(imgUploadBanner == config.BANNER_2) ? "icon-check icon-color-select" : "icon-check icon-non-color"}></i>
                                                                            </div>
                                                                            <img style={{ width: 170, height: 140, borderRadius: '0.5rem', objectFit: 'cover' }} src={config.BANNER_2} />
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-12 col-12 mb-1">
                                                                        <button type="button" onClick={() => {setCurrentPageBanner("choose-img"); setImageBanner(""); setImgUploadBanner(config.BANNER_3);}} className={(imgUploadBanner == config.BANNER_3) ? "button-select-image" : "button-not-select-image"}>
                                                                            <div className="icon-container">
                                                                                <i className={(imgUploadBanner == config.BANNER_3) ? "icon-check icon-color-select" : "icon-check icon-non-color"}></i>
                                                                            </div>
                                                                            <img style={{ width: 170, height: 140, borderRadius: '0.5rem', objectFit: 'cover' }} src={config.BANNER_3} />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-lg-4 col-md-12 col-12 mb-1">
                                                                        <button type="button" onClick={() => {setCurrentPageBanner("choose-img"); setImageBanner(""); setImgUploadBanner(config.BANNER_4);}} className={(imgUploadBanner == config.BANNER_4) ? "button-select-image" : "button-not-select-image"}>
                                                                            <div className="icon-container">
                                                                                <i className={(imgUploadBanner == config.BANNER_4) ? "icon-check icon-color-select" : "icon-check icon-non-color"}></i>
                                                                            </div>
                                                                            <img style={{ width: 170, height: 140, borderRadius: '0.5rem', objectFit: 'cover' }} src={config.BANNER_4} />
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-12 col-12 mb-1">
                                                                        <button type="button" onClick={() => {setCurrentPageBanner("choose-img"); setImageBanner(""); setImgUploadBanner(config.BANNER_5);}} className={(imgUploadBanner == config.BANNER_5) ? "button-select-image" : "button-not-select-image"}>
                                                                            <div className="icon-container">
                                                                                <i className={(imgUploadBanner == config.BANNER_5) ? "icon-check icon-color-select" : "icon-check icon-non-color"}></i>
                                                                            </div>
                                                                            <img style={{ width: 170, height: 140, borderRadius: '0.5rem', objectFit: 'cover' }} src={config.BANNER_5} />
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-12 col-12">
                                                                        <div className={(imgUploadBanner !== config.BANNER_1 && imgUploadBanner !== config.BANNER_2 && imgUploadBanner !== config.BANNER_3 && imgUploadBanner !== config.BANNER_4 && imgUploadBanner !== config.BANNER_5) ? "button-select-image" : "button-not-select-image"}>
                                                                            <div className="icon-container">
                                                                                <i className={(imgUploadBanner !== config.BANNER_1 && imgUploadBanner !== config.BANNER_2 && imgUploadBanner !== config.BANNER_3 && imgUploadBanner !== config.BANNER_4 && imgUploadBanner !== config.BANNER_5) ? "icon-check icon-color-select" : "icon-check icon-non-color"}></i>
                                                                            </div>
                                                                            {currentPageBanner === "choose-img"
                                                                                ? 
                                                                                (
                                                                                    <FileInput setImage={setImageBanner} typeImage={1} onImageSelected={onImageSelectedBanner} inputRef={inputBannerRef} />
                                                                                ) : (
                                                                                    <>
                                                                                        <img style={{ width: 170, height: 140, borderRadius: '0.5rem', objectFit: 'cover' }} src={imgUploadBanner} />
                                                                                        <Button className='delete-btn-image-select' type='primary' danger onClick={() => {setCurrentPageBanner("choose-img"); setImageBanner(""); setImgUploadBanner(config.BANNER_1);}}>
                                                                                            <i className='ft-trash-2'></i>
                                                                                        </Button>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                                <Modal title="Modifica immagine" onCancel={onCropCancel} open={isModalOpen} okButtonProps={{ style: { display: 'none' } }} cancelButtonProps={{ style: { display: 'none' } }}>
                                                    <ImageCropper
                                                        image={image}
                                                        onCropDone={onCropDone}
                                                        onCropCancel={onCropCancel}
                                                        aspectRatio={1 / 1}
                                                    />
                                                </Modal>
                                                <Modal title="Modifica immagine" onCancel={onCropCancelBanner} open={isModalOpenBanner} okButtonProps={{ style: { display: 'none' } }} cancelButtonProps={{ style: { display: 'none' } }}>
                                                    <ImageCropper
                                                        image={imageBanner}
                                                        onCropDone={onCropDoneBanner}
                                                        onCropCancel={onCropCancelBanner}
                                                        aspectRatio={16 / 9}
                                                    />
                                                </Modal>
                                            </form>
                                            <div style={{ marginTop: 24 }} className="float-right">
                                                <Button style={{ margin: '0 8px 0 0', }} ghost icon={<i style={{ marginRight: '0.3rem' }} className='icon-eye'></i>} type="primary" onClick={() => setShowAnteprima(true)}>
                                                    Anteprima
                                                </Button>
                                                <Button type="primary" onClick={(loading) ? null : addPharmacy}>
                                                    {(loading)
                                                        ?
                                                        <ThreeDots 
                                                            height="11" 
                                                            width="23" 
                                                            radius="4.5"
                                                            color="#fff" 
                                                            ariaLabel="three-dots-loading"
                                                            wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
                                                            visible={true}
                                                        />
                                                        :
                                                        'Salva'
                                                    }
                                                </Button>
                                            </div>
                                        </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <Modal title="Anteprima della tua farmacia all'interno dell'app" bodyStyle={{ backgroundColor: '#f5f7ff', padding: '1.2rem', borderRadius: '0.8rem' }} open={showAnteprima} okText="Chiudi" cancelButtonProps={{ style: { display: 'none' } }} onCancel={() => setShowAnteprima(false)} onOk={() => setShowAnteprima(false)}>
            <div className="d-flex justify-content-center py-3">
                <div className='pharmacy-container'>
                    <div style={{ position: 'relative' }}>
                        <div style={{ position: 'absolute', bottom: 5, left: 5, zIndex: 2 }}>
                            <Avatar size={50} src={imgUpload} />
                        </div>
                        <img src={imgUploadBanner} style={{ width: '20rem', height: '6.5rem', borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem', objectFit: 'cover', objectPosition: 'top center', backgroundColor: '#fdf0e0' }} />   
                    </div>
                    <div style={{ padding: 10 }}>
                        <label className='mb-0' style={{ fontWeight: '700' }}>{(pharmacy !== null) ? pharmacy.name_pharmacy : null}</label><br />
                        <span style={{ fontSize: 11 }}>Apreta oggi dalle 08:00 alle 13:00</span>
                    </div>                   
                </div>
            </div>
        </Modal>
        <Footer />
        </>
    )
}

export default AddPharmacy;