import React, { useEffect, useState, useContext } from 'react';
import useCountDown from 'react-countdown-hook';
import { Progress } from 'antd';

function ProgressCountdown({ millisecondsStart }) {
    const [timeLeft, { start, pause, resume, reset }] = useCountDown(millisecondsStart, 1000);

    useEffect(() => {
        start();
    }, []);

    const getPercentage = (milliseconds) => {
        const percentage = (milliseconds * 100) / 600000;
        return percentage;
    }

    return(
        <Progress percent={getPercentage(timeLeft)} strokeColor={(getPercentage(timeLeft) <= 10) ? 'red' : null} status="active" showInfo={false} />
    )
}

export default ProgressCountdown;