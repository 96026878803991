import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet";
import { DownOutlined, UserOutlined, MailOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Table, Rate, Skeleton, Button as ButtonAntd, Dropdown, Modal, Input, Form } from 'antd';
import * as config from '../config.js';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThreeDots } from 'react-loader-spinner';

const { confirm } = Modal;

const columns = [
    {
        title: '',
        dataIndex: 'avatar',
        key: 'avatar',
        render: (text) => <div className="align-self-center">
            <div className="avatar avatar-md">
                <img src={text} />
            </div>
        </div>
    },
    {
        title: 'Nome e Cognome',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        render: (text) => <a href={`mailto:${text}`}>{text}</a>
    },
    {
        title: 'Telefono',
        dataIndex: 'phone',
        key: 'phone',
        render: (text) => <a href={`tel:${text}`}>{text}</a>
    },
    {
        title: 'N. Ticket',
        dataIndex: 'tickets',
        key: 'tickets',
    },
    {
        title: 'Recensione',
        dataIndex: 'review',
        key: 'review',
        render: (text) => <Rate disabled allowHalf defaultValue={parseFloat(text)} />
    }
];

const menuItems = [
    {
        label: 'Scarica contatti (.xlsx)',
        key: '1'
    }
];

function Contacts() {
    const [form] = Form.useForm();
    const [loadPage, setLoadPage] = useState(true)
    const [allClients, setAllClients] = useState([])
    const [showClientModal, setShowClientModal] = useState(false)
    const [loadClient, setLoadClient] = useState(false)
    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [email, setEmail] = useState('')
    const [isSearch, setIsSearch] = useState(false)
    const [search, setSearch] = useState('')
    const [searchClients, setSearchClients] = useState([])

    useEffect(() => {
        getAllPharmacyContacts()
    }, [])

    const getAllPharmacyContacts = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-all-pharmacy-clients`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy
            })
        })
        .then(function(response) {
            if (response.data.length > 0) {
                setAllClients(response.data)
                setLoadPage(false)
            } else {
                setLoadPage(false)
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const searchPharmacyClients = () => {
        if (search !== '') {
            const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
            axios({
                method: 'post',
                url: `${config.WEBSITE_URL}/partners/search-pharmacy-clients`,
                headers: {
                    'Content-Type': 'application/json'
                },
                auth: {
                    username: config.USERNAME_TOKEN,
                    password: config.PASSWORD_TOKEN
                },
                data: JSON.stringify({
                    token_access: config.WEB_TOKEN,
                    id_pharmacy: pharmacyInfo.id_pharmacy,
                    search_input: search
                })
            })
            .then(function(response) {
                if (response.data.length > 0) {
                    setSearchClients(response.data)
                    setIsSearch(true)
                } else {
                    setSearchClients([])
                    setIsSearch(true)
                }
            })
            .catch(function(error) {
                console.error(error);
            });
        } else {
            toast.error("Inserisci il nome, cognome, email o telefono del cliente", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const addNewPharmacyClient = () => {
        if (name !== '' && surname !== '' && email !== '') {
            const validateEmail = handleValidateEmail(email)
            if (validateEmail == true) {
                const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
                setLoadClient(true)
                axios({
                    method: 'post',
                    url: `${config.WEBSITE_URL}/partners/add-user-pharmacy`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    auth: {
                        username: config.USERNAME_TOKEN,
                        password: config.PASSWORD_TOKEN
                    },
                    data: JSON.stringify({
                        token_access: config.WEB_TOKEN,
                        name: name,
                        surname: surname,
                        email: email,
                        id_pharmacy: pharmacyInfo.id_pharmacy
                    })
                })
                .then(function(response) {
                    if (response.data.code == true) {
                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setShowClientModal(false)
                        setName('')
                        setSurname('')
                        setEmail('')
                        setLoadClient(false)
                    } else {
                        toast.error(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setLoadClient(false)
                    }
                })
                .catch(function(error) {
                    console.error(error);
                });
            } else {
                toast.error("L'email inserita non è corretta", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            toast.error("Compila tutti i campi obbligatori", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const handleValidateEmail = (text) => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(text) === false) {
            return false;
        } else {
            return true;
        }
    }

    const capitalizeFirstLowercaseRest = str => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const azzeraRicercaRequest = () => {
        confirm({
            title: 'ATTENZIONE',
            icon: <ExclamationCircleFilled />,
            content: 'Sei sicuro di voler svuotare la ricerca?',
            onOk() {
                azzeraRicerca()
            },
            okButtonProps: { danger: true },
            okText: "Svuota",
            cancelText: "Annulla"
        });
    }

    const azzeraRicerca = () => {
        setIsSearch(false)
        setSearchClients([])
        setSearch('')
    }

    const formSubmitSearch = (e) => {
        e.preventDefault()
        searchPharmacyClients()
    }

    return(
        <>
        <Helmet>
            <title>Contatti Clienti | Takeiteasy Partners</title>
        </Helmet>
        <ToastContainer />
        <Navbar />
        <div className="app-content content">
            <div className="content-overlay"></div>
            <div className="content-wrapper">
                <div className="content-header row">
                </div>
                <div>
                    <div className="content-body">
                        <div className="content-overlay"></div>
                        <section className="row">
                            <div className="col-lg-5 col-md-12 col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="bug-list-search">
                                            <div className="bug-list-search-content">
                                                <form onSubmit={formSubmitSearch}>
                                                    <div className="position-relative">
                                                        <input type="search" value={search} onChange={(search) => setSearch(search.target.value)} id="search-contacts" className="form-control" placeholder="Cerca contatti per nome, cognome, email e telfono" />
                                                        <div className="form-control-position" style={{ top: 4, right: 2 }}>
                                                            <ButtonAntd onClick={searchPharmacyClients} type='default' shape='circle' className='d-flex align-items-center justify-content-center'>
                                                                <i className="la la-search text-size-base text-muted la-rotate-270"></i>
                                                            </ButtonAntd>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="row all-contacts">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-head">
                                        <div className="card-header">
                                            <h4 className="card-title">I tuoi clienti</h4>
                                            <div className="heading-elements mt-0">
                                                {(isSearch)
                                                    &&
                                                    <ButtonAntd type='primary' danger className="mr-1" onClick={azzeraRicercaRequest}>
                                                        Svuota ricerca
                                                    </ButtonAntd>
                                                }
                                                <ButtonAntd type='primary' onClick={() => setShowClientModal(true)}>
                                                    Aggiungi cliente
                                                </ButtonAntd>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-content">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                {(loadPage)
                                                    ?
                                                    <Skeleton active />
                                                    :
                                                    <Table columns={columns} dataSource={(isSearch) ? searchClients : allClients} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        <Modal title="Aggiungi un cliente" open={showClientModal} onOk={addNewPharmacyClient} okText={(loadClient) ? <ThreeDots height="11" width="23" radius="4.5" color="#fff" ariaLabel="three-dots-loading" wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }} visible={true} /> : "Aggiungi"} cancelText="Chiudi" onCancel={() => { setShowClientModal(false); setName(''); setSurname(''); setEmail('') }}>
            <Form
                form={form}
                layout="vertical"
                className='mt-1'
            >
                <Form.Item label="Nome cliente" required tooltip="Campo obbligatorio">
                    <Input size="large" placeholder="Inserisci il nome del cliente" value={name} onChange={(name) => setName(capitalizeFirstLowercaseRest(name.target.value.trim()))} prefix={<UserOutlined />} />
                </Form.Item>
                <Form.Item label="Cognome cliente" required tooltip="Campo obbligatorio">
                    <Input size="large" placeholder="Inserisci il cognome del cliente" value={surname} onChange={(surname) => setSurname(capitalizeFirstLowercaseRest(surname.target.value.trim()))} prefix={<UserOutlined />} />
                </Form.Item>
                <Form.Item label="Email cliente" required tooltip="Campo obbligatorio">
                    <Input size="large" placeholder="Inserisci l'email del cliente" value={email} onChange={(email) => setEmail(email.target.value.toLocaleLowerCase().trim())} prefix={<MailOutlined />} />
                </Form.Item>
            </Form>
        </Modal>
        </>
    )
}

export default Contacts;