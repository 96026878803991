import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { ThreeDots } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../assets/images/logo-header-takeiteasy.png';
import * as config from '../config';
import axios from 'axios';

function ForgotPassword() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState({
        email: ""
    })

    useEffect(() => {
        document.body.setAttribute("class","vertical-layout vertical-menu bg-cyan bg-lighten-2 1-column blank-page");
        document.body.setAttribute("data-col","1-column");

        return () => {
            document.body.setAttribute("class","vertical-layout vertical-menu 2-columns fixed-navbar");
            document.body.setAttribute("data-col","2-columns");
        };
    }, [])

    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value.toLowerCase() })
    }

    const forgotPasswordAccount = (event) => {
        event.preventDefault();
        setLoading(true)
        if (user.email !== "") {
            axios({
                method: 'post',
                url: `${config.WEBSITE_URL}/partners/forgot-password`,
                headers: {
                    'Content-Type': 'application/json'
                },
                auth: {
                    username: config.USERNAME_TOKEN,
                    password: config.PASSWORD_TOKEN
                },
                data: JSON.stringify({
                    token_access: config.WEB_TOKEN,
                    email: user.email.trim()
                })
            })
            .then(function(response) {
                if (response.data.code == true) {
                    console.log(response.data);
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setLoading(false)
                } else {
                    toast.error(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setLoading(false)
                }
            })
            .catch(function(error) {
                console.error(error);
                setLoading(false)
            });
        } else {
            toast.error("Compila tutti i campi obbligatori", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoading(false)
        }
    }

    return(
        <>
        <ToastContainer />
        <Helmet>
            <title>Recupera Password | Takeiteasy Partners</title>
        </Helmet>
        <div className="app-content content">
            <div className="content-overlay"></div>
            <div className="content-wrapper">
                <div className="content-header row">
                </div>
                <div className="content-body">
                    <section className="row flexbox-container">
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            <div className="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
                                <div className="card border-grey border-lighten-3 m-0">
                                    <div className="card-header border-0">
                                        <div className="card-title text-center">
                                            <div className="p-1"><img src={logo} style={{ width: '65%' }} /></div>
                                        </div>
                                    </div>
                                    <div className="card-content">
                                        <p className="card-subtitle line-on-side text-muted text-center font-small-3 mx-2">
                                            <span>Recupera la password per il tuo account Takeiteasy</span>
                                        </p>
                                        <div className="card-body pt-0">
                                            <form className="form-horizontal" onSubmit={forgotPasswordAccount}>
                                                <fieldset className="form-group position-relative has-icon-left">
                                                    <input type="email" onChange={handleChange} value={user.email} className="form-control" name="email" id="email" placeholder="Email" required />
                                                    <div className="form-control-position">
                                                        <i className="la la-user"></i>
                                                    </div>
                                                </fieldset>
                                                <button type="submit" disabled={loading} className="btn btn-info btn-lg btn-block">
                                                    {(loading)
                                                        ?
                                                        <ThreeDots 
                                                            height="22" 
                                                            width="46" 
                                                            radius="9"
                                                            color="#fff" 
                                                            ariaLabel="three-dots-loading"
                                                            wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
                                                            visible={true}
                                                        />
                                                        :
                                                        <>
                                                        <i className="ft-unlock"></i> Recupera password 
                                                        </>
                                                    }
                                                </button>
                                            </form>
                                        </div>
                                        <div className="card-footer border-0">
                                            <p className="float-sm-left text-center">
                                                <Link to="/login" className="card-link">
                                                    Login
                                                </Link>
                                            </p>
                                            <p className="float-sm-right text-center">
                                                Nuovo su Takeiteasy? <Link to="/signup" className="card-link">Crea un account</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        </>
    )
}

export default ForgotPassword;