import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line, Pie } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import * as config from '../config.js';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment, { months } from 'moment';
import 'moment/locale/it';
import { Rate, Skeleton, Empty, Progress, Tooltip as TooltipAntd, Button as ButtonAntd, Modal } from 'antd';
import { ReloadOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { ThreeDots } from 'react-loader-spinner';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const { confirm } = Modal;

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Andamento recensioni',
        },
    },
};

const labels = ['Gennaio','Febbraio','Marzo','Aprile','Maggio','Giugno','Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre'];

function Dashboard() {
    const [countClients, setCountClients] = useState(0)
    const [countTickets, setCountTickets] = useState(0)
    const [reviewPharmacy, setReviewPharmacy] = useState(0)
    const [countReview, setCountReview] = useState(0)
    const [loadClients, setLoadCients] = useState(true)
    const [bestClients, setBetsClients] = useState([])
    const [loadTicketToday, setLoadTicketToday] = useState(true)
    const [ticketToday, setTicketToday] = useState(null)
    const [loadTicketTomorrow, setLoadTicketTomorrow] = useState(true)
    const [ticketTomorrow, setTicketTomorrow] = useState(null)
    const [listReviews, setListReviews] = useState([])
    const [showNewPrivacy, setShowNewPrivacy] = useState(false)
    const [informationPrivacy, setInformationPrivacy] = useState(null)
    const [typePrivacy, setTypePrivacy] = useState(null)
    const [loadingPrivacy, setLoadingPrivacy] = useState(false)

    useEffect(() => {
        checkUserPolicy()
        getCountPharmacyClients()
        getTicketsMonthCount()
        getPharmacyReview()
        getBestPharmacyClients()
        getTicketsAviableToday()
        getTicketsAviableTomorrow()
        getReviewYear()
    }, [])

    const checkUserPolicy = () => {
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/check-user-privacy`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_user: userInfo.id
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                if (response.data.new_policy == true) {
                    setInformationPrivacy({
                        title: response.data.title,
                        message: response.data.message
                    })
                    setTypePrivacy(response.data.type)
                    setShowNewPrivacy(true)
                }
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const handleClosePolicy = () => {
        setShowNewPrivacy(false)
        setInformationPrivacy(null)
        setTypePrivacy(null)
        setLoadingPrivacy(false)
    }

    const updateUserPolicy = () => {
        setLoadingPrivacy(true)
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/update-user-policy`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_user: userInfo.id
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                setLoadingPrivacy(false)
                setShowNewPrivacy(false)
                setInformationPrivacy(null)
                setTypePrivacy(null)
                toast.success(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                setLoadingPrivacy(false)
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            setLoadingPrivacy(false)
            toast.error("Non è stato possibile accettare l'informativa. Riprova più tardi", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    const getReviewYear = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-reviews-year`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                setListReviews(response.data.list_reviews)
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const getCountPharmacyClients = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-count-pharmacy-clients`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                setCountClients(response.data.num_clients)
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const getTicketsMonthCount = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
        const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD');
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-tickets-month-count`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy,
                first_day_month: startOfMonth,
                last_day_month: endOfMonth
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                setCountTickets(response.data.number_tickets)
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const getPharmacyReview = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-pharmacy-review`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                setReviewPharmacy(response.data.review)
                setCountReview(response.data.count_review)
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const getBestPharmacyClients = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-best-pharmacy-clients`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy
            })
        })
        .then(function(response) {
            if (response.data.length > 0) {
                setBetsClients(response.data)
                setLoadCients(false)
            } else {
                setLoadCients(false)
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const getTicketsAviableToday = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-tickets-aviable`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy,
                day_name: capitalizeFirstLowercaseRest(moment(new Date()).format('dddd')),
                today_date: moment(new Date()).format('YYYY-MM-DD')
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                setTicketToday(response.data)
                setLoadTicketToday(false)
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const getTicketsAviableTomorrow = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-tickets-aviable`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy,
                day_name: capitalizeFirstLowercaseRest(moment(new Date()).add(1, 'day').format('dddd')),
                today_date: moment(new Date()).add(1, 'day').format('YYYY-MM-DD')
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                setTicketTomorrow(response.data)
                setLoadTicketTomorrow(false)
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    const capitalizeFirstLowercaseRest = str => {
        return (
            str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
        );
    }

    const calculatePercentage = (allTickets, numTickets) => {
        const percentage = (parseInt(numTickets) * 100) / allTickets;
        return percentage;
    }

    const refreshTicketsAviable = () => {
        setLoadTicketToday(true)
        setLoadTicketTomorrow(true)
        getTicketsAviableToday()
        getTicketsAviableTomorrow()
    }

    return(
        <>
        <Helmet>
            <title>Dashboard | Takeiteasy Partners</title>
        </Helmet>
        <ToastContainer />
        <Navbar />
        <div className="app-content content">
            <div className="content-overlay"></div>
            <div className="content-wrapper">
                <div className="content-header row">
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-xl-6 col-12">
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <div className="card pull-up">
                                        <div className="card-content">
                                            <div className="card-body">
                                                <div className="media d-flex">
                                                    <div className="media-body text-left">
                                                        <h6 className="text-muted">Clienti totali</h6>
                                                        <h3>{countClients}</h3>
                                                    </div>
                                                    <div className="align-self-center">
                                                        <i className="la la-users info font-large-2 float-right"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <div className="card pull-up">
                                        <div className="card-content">
                                            <div className="card-body">
                                                <div className="media d-flex">
                                                    <div className="media-body text-left">
                                                        <h6 className="text-muted">Ticket ultimo mese</h6>
                                                        <h3>{countTickets}</h3>
                                                    </div>
                                                    <div className="align-self-center">
                                                        <i className="la la-ticket success font-large-2 float-right"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <div className="card">
                                        <div className="card-header d-flex align-items-center justify-content-between">
                                            <h4 className="card-title">Ticket disponibili</h4>
                                            <TooltipAntd title="Aggiorna">
                                                <ButtonAntd type="default" shape="circle" className='d-flex align-items-center justify-content-center' onClick={refreshTicketsAviable} icon={<ReloadOutlined />} />
                                            </TooltipAntd>
                                        </div>
                                        <div className="card-content collapse show">
                                            <div className="card-body pt-0">
                                                {(loadTicketToday)
                                                    ?
                                                    <Skeleton.Input active={true} block={true} className="mb-1" />
                                                    :
                                                    <>
                                                    <p className="pt-1">Oggi <span className="float-right"><span className="text-bold-600">{ticketToday.all_tickets - parseInt(ticketToday.booked_tickets)}</span>/{ticketToday.all_tickets}</span></p>
                                                    <Progress percent={calculatePercentage(ticketToday.all_tickets, ticketToday.booked_tickets)} strokeColor={(calculatePercentage(ticketToday.all_tickets, ticketToday.booked_tickets) >= 90) ? 'red' : 'green'} status="active" showInfo={false} />
                                                    </>
                                                }
                                                {(loadTicketTomorrow)
                                                    ?
                                                    <Skeleton.Input active={true} block={true} />
                                                    :
                                                    <>
                                                    <p className="pt-1">Domani <span className="float-right"><span className="text-bold-600">{ticketTomorrow.all_tickets - parseInt(ticketTomorrow.booked_tickets)}</span>/{ticketTomorrow.all_tickets}</span></p>
                                                    <Progress strokeColor={(calculatePercentage(ticketTomorrow.all_tickets, ticketTomorrow.booked_tickets) >= 90) ? 'red' : null} percent={calculatePercentage(ticketTomorrow.all_tickets, ticketTomorrow.booked_tickets)} status="active" showInfo={false} />
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex align-items-center">
                                        <h4 className="card-title mr-1">Recensioni</h4>
                                        <div class="badge badge-pill badge-glow badge-primary">
                                            <i class="font-medium-4 icon-line-height la la-star"></i>
                                            <span className="font-medium-1 text-white">{reviewPharmacy}</span>
                                        </div>
                                    </div>
                                    <p>Basato sulla media di {countReview} recensioni dei clienti</p>
                                </div>
                                <div className="card-content collapse show">
                                    <div className="card-body pt-0">
                                        <Line options={options} data={{
                                            labels,
                                            datasets: [
                                                {
                                                    label: 'Media recensioni',
                                                    data: listReviews,
                                                    borderColor: 'rgb(53, 162, 235)',
                                                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                                }
                                            ],
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div id="recent-sales" className="col-12 col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">I tuoi migliori clienti</h4>
                                    <div className="heading-elements">
                                        <ul className="list-inline mb-0">
                                            <li>
                                                <Link to="/contatti" className="btn btn-sm btn-danger box-shadow-2 round btn-min-width pull-right">Vedi tutti</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="card-content mt-1">
                                    {(loadClients)
                                        ?
                                        <div className='p-2'>
                                            <Skeleton avatar active={true} />
                                        </div>
                                        :
                                        (bestClients.length > 0)
                                            ?
                                            <div className="table-responsive">
                                                <table id="recent-orders" className="table table-hover table-xl mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="border-top-0"></th>
                                                            <th className="border-top-0">Nome e Cognome</th>
                                                            <th className="border-top-0">Email</th>
                                                            <th className="border-top-0">Telefono</th>
                                                            <th className="border-top-0">N. Ticket</th>
                                                            <th className="border-top-0">Recensione</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {bestClients.map((item, index) => {
                                                            return(
                                                                <tr key={index}>
                                                                    <td className="text-truncate">
                                                                        <div className="align-self-center">
                                                                            <div className="avatar avatar-md">
                                                                                <img src={item.avatar} />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-truncate">{item.name}</td>
                                                                    <td className="text-truncate">
                                                                        <a href={`mailto:${item.email}`}>{item.email}</a>
                                                                    </td>
                                                                    <td className="text-truncate">
                                                                        <a href={`tel:${item.phone}`}>{item.phone}</a>
                                                                    </td>
                                                                    <td className="text-truncate">{item.tickets}</td>
                                                                    <td>
                                                                        <Rate allowHalf disabled defaultValue={parseFloat(item.review)} />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            :
                                            <Empty className='pb-2' description={<span>Non sono presenti clienti</span>} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        <Modal title={(informationPrivacy !== null) ? informationPrivacy.title : ""} open={showNewPrivacy} okText={(loadingPrivacy) ? <ThreeDots height="11" width="23" radius="4.5" color="#fff" ariaLabel="three-dots-loading" wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }} visible={true} /> : "Accetta"} cancelText="Chiudi" onOk={(loadingPrivacy) ? null : updateUserPolicy} onCancel={handleClosePolicy}>
            <div className='row mt-1'>
                <div className='col-12'>
                    {(informationPrivacy !== null)
                        &&
                        <p>{informationPrivacy.message}</p>
                    }
                </div>
                <div className='col-12'>
                    {(typePrivacy !== null)
                        ?
                        (typePrivacy == "both")
                            ?
                            <p className="font-small-3">
                                Per continuare con la registrazione coferma di aver letto l'informativa sulla <a href="https://www.takeiteasy-app.com/privacy-policy-takeiteasy-partners/" target='_blank'>Privacy Policy</a> e i <a href="https://www.takeiteasy-app.com/termini-e-condizioni-takeiteasy-partners/" target="_blank">Termini e Condiziioni</a> di takeiteasy.
                            </p>
                            :
                            (typePrivacy == "privacy")
                                ?
                                <p className="font-small-3">
                                    Per continuare con la registrazione coferma di aver letto l'informativa sulla <a href="https://www.takeiteasy-app.com/privacy-policy-takeiteasy-partners/" target='_blank'>Privacy Policy</a> di takeiteasy.
                                </p>
                                :
                                (typePrivacy == "termini")
                                    ?
                                    <p className="font-small-3">
                                        Per continuare con la registrazione coferma di aver letto i <a href="https://www.takeiteasy-app.com/termini-e-condizioni-takeiteasy-partners/" target="_blank">Termini e Condiziioni</a> di takeiteasy.
                                    </p>
                                    :
                                    null
                        :
                        null
                    }
                </div>
            </div>
        </Modal>
        </>
    )
}

export default Dashboard;