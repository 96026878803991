import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import moment from 'moment';
import 'moment/locale/it';
import { Skeleton, Empty, Input, Result, Divider, Modal, Alert, Button as ButtonAntd, Spin, Tooltip } from 'antd';
import * as config from '../config';
import axios from 'axios';
import Timer from '@amplication/react-compound-timer';
import ProgressCountdown from '../components/ProgressCountdown';
import './DisplayTickets.css';
import logo from '../assets/images/logo-header-takeiteasy.png';

function DisplayTickets() {
    const [ticketsOpened, setTicketsOpened] = useState([])
    const [loadTicketsOpened, setLoadTicketsOpened] = useState(true)

    useEffect(() => {
        getTicketsTodayOpened()
        setInterval(() => {
            getTicketsTodayOpened()
        }, 6000);
    }, [])

    const getTicketsTodayOpened = () => {
        const pharmacyInfo = JSON.parse(localStorage.getItem('pharmacy'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-tickets-today-open`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_pharmacy: pharmacyInfo.id_pharmacy,
                today_date: moment(new Date()).format('YYYY-MM-DD')
            })
        })
        .then(function(response) {
            if (response.data.length > 0) {
                setTicketsOpened(response.data)
                setLoadTicketsOpened(false)
            } else {
                setTicketsOpened([])
                setLoadTicketsOpened(false)
            }
        })
        .catch(function(error) {
            console.error(error);
        });
    }

    return(
        <>
        <Helmet>
            <title>Display Ticket | Takeiteasy Partners</title>
            <link rel="stylesheet" type="text/css" href="assets/css/custom-body.css"></link>
        </Helmet>
        <div className="app-content app-content-edit content">
            <div className="content-overlay"></div>
            <div className="content-wrapper pt-0">
                <div className="content-body">
                    <div className="row">
                        <div className='col-xl-3 col-md-2 col-12'></div>
                        <div className="col-xl-6 col-md-8 col-12">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 mt-1 mb-1">
                                    <div className="card">
                                        <div className="card-header pt-1" style={{ borderRadius: '0.45rem' }}>
                                            <div className='d-flex justify-content-center'>
                                                <img className="img-logo-display" src={logo} />
                                            </div>
                                            <h4 className="text-uppercase text-center"><b>I seguenti ticket possono entrare in farmacia</b></h4>
                                            <p className='mb-0 pb-0 text-center'>Mostra il QrCode del ticket al farmacista</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {(loadTicketsOpened)
                                    ?
                                    <div className='col-xl-12 col-md-12 col-12'>
                                        <div className="card">
                                            <div className="card-content">
                                                <div className="card-body">
                                                    <Skeleton loading={true} active avatar></Skeleton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    (ticketsOpened.length > 0)
                                        ?
                                        ticketsOpened.map((item, index) => {
                                            const now = moment(new Date()); //todays date
                                            const end = moment(item.date_time_open); // another date
                                            const duration = moment.duration(now.diff(end));
                                            const milliseconds = duration.asMilliseconds();
                                            const minutesCount = 600000 - milliseconds;

                                            return(
                                                <div key={index} className='col-xl-12 col-md-12 col-12'>
                                                    <div className="card first-step">
                                                        <div className="card-content">
                                                            <div className="card-body">
                                                                <div className="media d-flex">
                                                                    <div className="media-body">
                                                                        <div className='px-2' style={{ backgroundColor: "#f5f5f5", borderRadius: 10, paddingTop: 10, paddingBottom: 10 }}>
                                                                            <h2 className="info text-center mb-0">{(item.is_visit == true) ? "TICKET VISITA" : "TICKET"}: <b>{item.ticket_code}</b></h2>
                                                                        </div>
                                                                        {(item.is_visit == true)
                                                                            &&
                                                                            <div className='px-2 mt-1' style={{ backgroundColor: "#f5f5f5", borderRadius: 10, paddingTop: 10, paddingBottom: 10 }}>
                                                                                <h3 className="text-center mb-0"><b>{item.name_visit}</b></h3>
                                                                            </div>
                                                                        }
                                                                        <div className='row mt-1'>
                                                                            <div className='col-6 d-flex align-items-center'>
                                                                                <span className='mr-1' style={{ fontSize: '1.5rem' }}>
                                                                                    <b>{item.time_slot.split(':')[0]}:{item.time_slot.split(':')[1]}</b>
                                                                                </span>
                                                                                {(item.status == "Confermato")
                                                                                    ?
                                                                                    <Alert message={item.status} type='success' showIcon />
                                                                                    :
                                                                                    <Alert message={item.status} type='error' showIcon />
                                                                                }
                                                                            </div>
                                                                            <div className='col-6'>
                                                                                <div className='mt-0 mb-0'>
                                                                                    <ProgressCountdown millisecondsStart={minutesCount} />
                                                                                </div>
                                                                                <div className="text-right">
                                                                                    <Timer initialTime={minutesCount} direction='backward'>
                                                                                        <span style={{ fontSize: '1.1rem', fontWeight: '500' }}><Timer.Minutes formatValue={(value) => (value < 10 ? `0${value}` : value)} />:<Timer.Seconds formatValue={(value) => (value < 10 ? `0${value}` : value)} /></span>
                                                                                    </Timer>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className='col-xl-12 col-md-12 col-12'>
                                            <div className="card">
                                                <div className="card-content">
                                                    <div className="card-body">
                                                        <Empty description={<span style={{ fontSize: '1rem' }}>Non sono presenti ticket</span>} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className='col-xl-3 col-md-2 col-12'></div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default DisplayTickets;