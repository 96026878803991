import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet";
import { ThreeDots } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Wizard, useWizard } from 'react-use-wizard';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import it from 'react-phone-input-2/lang/it.json';
import { Button, message, Steps, theme, Avatar, Modal, Image as ImageAntd, Tour, Skeleton, Empty } from 'antd';
import ImgCrop from 'antd-img-crop';
import FileInput from '../components/FileInput';
import ImageCropper from '../components/ImageCropper';
import * as config from '../config';
import Autocomplete from "react-google-autocomplete";
import { checkVAT, italy } from 'jsvat';
import Geocode from "react-geocode";
import axios from 'axios';
import Errors from '../components/Errors';

Geocode.setApiKey(config.GOOGLE_MAPS_API);
Geocode.setLanguage("it");

function CompleteProfile() {
    const navigate = useNavigate()
    const inputLogoRef = useRef()
    const inputBannerRef = useRef()
    const firstStep = useRef()
    const secondStep = useRef()
    const [loading, setLoading] = useState(false)
    const [isTourOpen, setIsTourOpen] = useState(false)
    const [userData, setUserData] = useState({
        phone: "",
        company: "",
        vat: "",
        pec: "",
        receiver_code: "",
        name_pharmacy: ""
    })
    const [companyAddress, setCompanyAddress] = useState("")
    const [companyCivic, setCompanyCivic] = useState("")
    const [companyCity, setCompanyCity] = useState("")
    const [companyCap, setCompanyCap] = useState("")
    const [companyProvince, setCompanyProvince] = useState("")
    const [companyState, setCompanyState] = useState("")
    const [pharmacyAddress, setPharmacyAddress] = useState("")
    const [pharmacyCivic, setPharmacyCivic] = useState("")
    const [pharmacyCity, setPharmacyCity] = useState("")
    const [pharmacyCap, setPharmacyCap] = useState("")
    const [pharmacyProvince, setPharmacyProvince] = useState("")
    const [pharmacyState, setPharmacyState] = useState("")
    const [pharmacyLatitude, setPharmacyLatitude] = useState(0)
    const [pharmacyLongitude, setPharmacyLongitude] = useState(0)
    const { token } = theme.useToken();
    const [current, setCurrent] = useState(0);
    const [image, setImage] = useState("");
    const [currentPage, setCurrentPage] = useState("choose-img");
    const [imgAfterCrop, setImgAfterCrop] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imageBanner, setImageBanner] = useState("");
    const [currentPageBanner, setCurrentPageBanner] = useState("choose-img");
    const [imgAfterCropBanner, setImgAfterCropBanner] = useState("");
    const [isModalOpenBanner, setIsModalOpenBanner] = useState(false);
    const [isLogoDegault, setIsLogoDefault] = useState(true)
    const [bannerDesign, setBannerDesign] = useState(config.BANNER_1)
    const [showAnteprima, setShowAnteprima] = useState(false)
    const [isAddressDiasbled, setIsAddressDisabled] = useState(true)
    const [isAddressDiasbledPharmacy, setIsAddressDisabledPharmacy] = useState(true)
    const [isVatValid, setIsVatValid] = useState(null)
    const [haveError, setHaveError] = useState(false)
    const [isCompleted, setIsCompleted] = useState(false)

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        if (userInfo !== null) {
            if (userInfo.is_code_verify == "0" || userInfo.is_code_verify == 0) {
                setHaveError(true)
            } else if (userInfo.is_completed == "1" || userInfo.is_completed == 1) {
                setIsCompleted(true)
            } else {
                setHaveError(false)
                setIsCompleted(false)
            }
        } else {
            setHaveError(false)
            setIsCompleted(false)
        }
    }, [])

    const stepsTour = [
        {
            title: 'Inserisci i tuoi dati aziendali',
            description: 'Compila tutti i campi.',
            target: () => firstStep.current,
        },
        {
            title: 'Inserisci i tuoi dati aziendali',
            description: 'Compila tutti i campi.',
            target: () => secondStep.current,
        },
    ];

    const next = () => {
        setCurrent(current + 1);
    }

    const prev = () => {
        setCurrent(current - 1);
    }

    const onImageSelected = (selectedImg) => {
        setImage(selectedImg);
        setIsModalOpen(true)
    }
    
    const onCropDone = (imgCroppedArea) => {
        const canvasEle = document.createElement("canvas");
        canvasEle.width = imgCroppedArea.width;
        canvasEle.height = imgCroppedArea.height;
    
        const context = canvasEle.getContext("2d");
    
        let imageObj1 = new Image();
        imageObj1.src = image;
        imageObj1.onload = function () {
            context.drawImage(
                imageObj1,
                imgCroppedArea.x,
                imgCroppedArea.y,
                imgCroppedArea.width,
                imgCroppedArea.height,
                0,
                0,
                imgCroppedArea.width,
                imgCroppedArea.height
            );
        
            const dataURL = canvasEle.toDataURL("image/jpeg");
            console.log(dataURL);
        
            setImgAfterCrop(dataURL);
            setCurrentPage("img-cropped");
            setIsModalOpen(false)
            setIsLogoDefault(false)
        }
    }

    const onCropCancel = () => {
        setCurrentPage("choose-img");
        setImage("");
        setIsModalOpen(false)
    }

    const onImageSelectedBanner = (selectedImg) => {
        setImageBanner(selectedImg);
        setIsModalOpenBanner(true)
    }
    
    const onCropDoneBanner = (imgCroppedAreaBanner) => {
        const canvasEle = document.createElement("canvas");
        canvasEle.width = imgCroppedAreaBanner.width;
        canvasEle.height = imgCroppedAreaBanner.height;
    
        const context = canvasEle.getContext("2d");
    
        let imageObj1 = new Image();
        imageObj1.src = imageBanner;
        imageObj1.onload = function () {
            context.drawImage(
                imageObj1,
                imgCroppedAreaBanner.x,
                imgCroppedAreaBanner.y,
                imgCroppedAreaBanner.width,
                imgCroppedAreaBanner.height,
                0,
                0,
                imgCroppedAreaBanner.width,
                imgCroppedAreaBanner.height
            );
        
            const dataURL = canvasEle.toDataURL("image/jpeg");
        
            setImgAfterCropBanner(dataURL);
            setCurrentPageBanner("img-cropped");
            setIsModalOpenBanner(false)
            setBannerDesign(dataURL)
        }
    }

    const onCropCancelBanner = () => {
        setCurrentPageBanner("choose-img");
        setImageBanner("");
        setIsModalOpenBanner(false)
    }
    
    const steps = [
        {
          title: 'Dati aziendali'
        },
        {
          title: 'Dati farmacia'
        },
        {
          title: 'Grafica farmacia'
        },
    ]

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title
    }))

    const contentStyle = {
        color: token.colorTextTertiary,
        backgroundColor: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: `1px dashed ${token.colorBorder}`,
        padding: 20,
        marginTop: 16,
    }

    const handleChange = (e) => {
        if (e.target.name == "pec") {
            setUserData({ ...userData, [e.target.name]: e.target.value.toLowerCase() })
        } else {
            setUserData({ ...userData, [e.target.name]: e.target.value })
        }
    }

    const handleAddress = (place) => {
        let count = 0;
        place.address_components.forEach(element => {
            const long_name = element.long_name;
            const short_name = element.short_name;
            count++;

            if (element.types.includes('locality')) {
                setCompanyCity(long_name)
            } else if (element.types.includes('administrative_area_level_2')) {
                setCompanyProvince(short_name)
            } else if (element.types.includes('postal_code')) {
                setCompanyCap(long_name)
            } else if (element.types.includes('street_number')) {
                setCompanyCivic(long_name)
            } else if (element.types.includes('route')) {
                setCompanyAddress(long_name)
            } else if (element.types.includes('country')) {
                setCompanyState(short_name)
            }

            if (count == place.address_components.length) {
                setIsAddressDisabled(false)
            }
        });
    }

    const handleAddressPharmacy = (place) => {
        let count = 0;
        place.address_components.forEach(element => {
            const long_name = element.long_name;
            const short_name = element.short_name;
            count++;

            if (element.types.includes('locality')) {
                setPharmacyCity(long_name)
            } else if (element.types.includes('administrative_area_level_2')) {
                setPharmacyProvince(short_name)
            } else if (element.types.includes('postal_code')) {
                setPharmacyCap(long_name)
            } else if (element.types.includes('street_number')) {
                setPharmacyCivic(long_name)
            } else if (element.types.includes('route')) {
                setPharmacyAddress(long_name)
            } else if (element.types.includes('country')) {
                setPharmacyState(short_name)
            }

            if (count == place.address_components.length) {
                Geocode.fromAddress(place.formatted_address).then(
                    (response) => {
                        const { lat, lng } = response.results[0].geometry.location;
                        setPharmacyLatitude(lat)
                        setPharmacyLongitude(lng)
                    },
                    (error) => {
                        console.error(error);
                    }
                );
                setIsAddressDisabledPharmacy(false)
            }
        });
    }

    const validateVat = (number) => {
        const vatNumber = number.target.value;
        setUserData({ ...userData, vat: vatNumber })
        if (vatNumber !== "") {
            const validation = checkVAT(`IT${vatNumber}`, [italy]);
            if (validation.isValid) {
                setIsVatValid(true)
            } else {
                setIsVatValid(false)
            }
        } else {
            setIsVatValid(false)
        }
    }

    const controlFirstStep = () => {
        if (userData.phone !== "" && userData.company !== "" && companyAddress !== "" && companyCivic !== "" && companyCity !== "" && companyCap !== "" && companyProvince !== "" && userData.vat !== "") {
            if (isVatValid !== null && isVatValid !== false) {
                if (userData.pec == "" && userData.receiver_code == "") {
                    toast.error("Inserisci la PEC o il codice destinatario per continuare", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    next()
                }
            } else {
                toast.error("Inserisci una P.IVA valida per continuare", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            toast.error("Compila tutti i campi obbligatori per poter continuare", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const controlSecondStep = () => {
        if (userData.name_pharmacy !== "" && pharmacyAddress !== "" && pharmacyCivic !== "" && pharmacyCity !== "" && pharmacyCap !== "" && pharmacyProvince !== "" && pharmacyLatitude !== 0 && pharmacyLongitude!== 0) {
            next()
        } else {
            toast.error("Compila tutti i campi obbligatori per poter continuare", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const handleCompleteProfile = () => {
        setLoading(true)
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/complete-profile`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id: userInfo.id,
                phone: userData.phone,
                company: userData.company.trim(),
                company_address: `${companyAddress}, ${companyCivic}`,
                company_city: companyCity,
                company_cap: companyCap,
                company_province: companyProvince,
                company_state: companyState,
                vat: userData.vat.trim(),
                pec: userData.pec.trim(),
                receiver_code: userData.receiver_code.trim(),
                name_pharmacy: userData.name_pharmacy.trim(),
                logo: (isLogoDegault) ? config.LOGO_DEFAULT : imgAfterCrop,
                banner: bannerDesign,
                address: `${pharmacyAddress}, ${pharmacyCivic}`,
                city: pharmacyCity,
                cap: pharmacyCap,
                province: pharmacyProvince,
                state: pharmacyState,
                longitude: pharmacyLongitude,
                latitude: pharmacyLatitude
            })
        })
        .then(function(response) {
            if (response.data.code == true) {
                let pharmaciesUser = [];
                pharmaciesUser.push({ id_pharmacy: response.data.id_pharmacy, name_pharmacy: userData.name_pharmacy.trim(), is_active: "0", is_verificated: "0" })
                localStorage.setItem('pharmacies_info', JSON.stringify(pharmaciesUser))
                const newUserInfo = { ...userInfo, is_completed: "1" };
                localStorage.setItem('user_info', JSON.stringify(newUserInfo))
                localStorage.setItem('pharmacy', JSON.stringify({ id_pharmacy: response.data.id_pharmacy, name_pharmacy: userData.name_pharmacy.trim(), is_active: "0", is_verificated: "0" }))
                toast.success(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(() => {
                    navigate('/farmacie')
                }, 1000);
                setLoading(false)
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setLoading(false)
            }
        })
        .catch(function(error) {
            console.error(error);
            setLoading(false)
        });
    }

    const Step3 = () => {
        return (
            <>
                <fieldset>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="logo">Logo della farmacia*</label><br />
                                <p>Seleziona un'opzione o carica la tua immagine</p>
                                <div className="row">
                                    <div className='col-lg-4 col-md-12 col-12 mb-1'>
                                        <button onClick={(isLogoDegault) ? null : () => {setCurrentPage("choose-img"); setImage(""); setIsLogoDefault(true);}} type="button" className={(isLogoDegault) ? "button-select-image mr-1" : "button-not-select-image mr-1"}>
                                            <i className={(isLogoDegault) ? "icon-check icon-img-select" : "icon-check icon-img-not-select"}></i>
                                            <Avatar size={100} src={config.LOGO_DEFAULT} />
                                        </button>
                                    </div>
                                    <div className='col-lg-4 col-md-12 col-12 mb-1'>
                                        <div className={(isLogoDegault) ? "button-not-select-image" : "button-select-image"}>
                                            <i className={(isLogoDegault) ? "icon-check icon-img-not-select" : "icon-check icon-img-select"}></i>
                                            {currentPage === "choose-img"
                                                ? 
                                                (
                                                    <FileInput setImage={setImage} typeImage={0} onImageSelected={onImageSelected} inputRef={inputLogoRef} />
                                                ) : (
                                                    <div>
                                                        <Avatar size={100} src={imgAfterCrop} />
                                                        <Button className='ml-1' type='primary' danger onClick={() => {setCurrentPage("choose-img"); setImage(""); setIsLogoDefault(true);}}>
                                                            <i className='ft-trash-2'></i>
                                                        </Button>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="banner">Sfondo della farmacia*</label>
                                <p>Seleziona un'opzione o carica la tua immagine</p>
                                <div className='row'>
                                    <div className="col-lg-4 col-md-12 col-12 mb-1">
                                        <button type="button" onClick={() => {setCurrentPageBanner("choose-img"); setImageBanner(""); setBannerDesign(config.BANNER_1);}} className={(bannerDesign == config.BANNER_1) ? "button-select-image" : "button-not-select-image"}>
                                            <div className="icon-container">
                                                <i className={(bannerDesign == config.BANNER_1) ? "icon-check icon-color-select" : "icon-check icon-non-color"}></i>
                                            </div>
                                            <img style={{ width: 170, height: 140, borderRadius: '0.5rem', objectFit: 'cover' }} src={config.BANNER_1} />
                                        </button>
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-12 mb-1">
                                        <button type="button" onClick={() => {setCurrentPageBanner("choose-img"); setImageBanner(""); setBannerDesign(config.BANNER_2);}} className={(bannerDesign == config.BANNER_2) ? "button-select-image" : "button-not-select-image"}>
                                            <div className="icon-container">
                                                <i className={(bannerDesign == config.BANNER_2) ? "icon-check icon-color-select" : "icon-check icon-non-color"}></i>
                                            </div>
                                            <img style={{ width: 170, height: 140, borderRadius: '0.5rem', objectFit: 'cover' }} src={config.BANNER_2} />
                                        </button>
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-12 mb-1">
                                        <button type="button" onClick={() => {setCurrentPageBanner("choose-img"); setImageBanner(""); setBannerDesign(config.BANNER_3);}} className={(bannerDesign == config.BANNER_3) ? "button-select-image" : "button-not-select-image"}>
                                            <div className="icon-container">
                                                <i className={(bannerDesign == config.BANNER_3) ? "icon-check icon-color-select" : "icon-check icon-non-color"}></i>
                                            </div>
                                            <img style={{ width: 170, height: 140, borderRadius: '0.5rem', objectFit: 'cover' }} src={config.BANNER_3} />
                                        </button>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-lg-4 col-md-12 col-12 mb-1">
                                        <button type="button" onClick={() => {setCurrentPageBanner("choose-img"); setImageBanner(""); setBannerDesign(config.BANNER_4);}} className={(bannerDesign == config.BANNER_4) ? "button-select-image" : "button-not-select-image"}>
                                            <div className="icon-container">
                                                <i className={(bannerDesign == config.BANNER_4) ? "icon-check icon-color-select" : "icon-check icon-non-color"}></i>
                                            </div>
                                            <img style={{ width: 170, height: 140, borderRadius: '0.5rem', objectFit: 'cover' }} src={config.BANNER_4} />
                                        </button>
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-12 mb-1">
                                        <button type="button" onClick={() => {setCurrentPageBanner("choose-img"); setImageBanner(""); setBannerDesign(config.BANNER_5);}} className={(bannerDesign == config.BANNER_5) ? "button-select-image" : "button-not-select-image"}>
                                            <div className="icon-container">
                                                <i className={(bannerDesign == config.BANNER_5) ? "icon-check icon-color-select" : "icon-check icon-non-color"}></i>
                                            </div>
                                            <img style={{ width: 170, height: 140, borderRadius: '0.5rem', objectFit: 'cover' }} src={config.BANNER_5} />
                                        </button>
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-12 mb-1">
                                        <button type="button" onClick={() => {setCurrentPageBanner("choose-img"); setImageBanner(""); setBannerDesign(config.BANNER_11);}} className={(bannerDesign == config.BANNER_11) ? "button-select-image" : "button-not-select-image"}>
                                            <div className="icon-container">
                                                <i className={(bannerDesign == config.BANNER_11) ? "icon-check icon-color-select" : "icon-check icon-non-color"}></i>
                                            </div>
                                            <img style={{ width: 170, height: 140, borderRadius: '0.5rem', objectFit: 'cover' }} src={config.BANNER_11} />
                                        </button>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-lg-4 col-md-12 col-12 mb-1">
                                        <button type="button" onClick={() => {setCurrentPageBanner("choose-img"); setImageBanner(""); setBannerDesign(config.BANNER_6);}} className={(bannerDesign == config.BANNER_6) ? "button-select-image" : "button-not-select-image"}>
                                            <div className="icon-container">
                                                <i className={(bannerDesign == config.BANNER_6) ? "icon-check icon-color-select" : "icon-check icon-non-color"}></i>
                                            </div>
                                            <img style={{ width: 170, height: 140, borderRadius: '0.5rem', objectFit: 'cover' }} src={config.BANNER_6} />
                                        </button>
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-12 mb-1">
                                        <button type="button" onClick={() => {setCurrentPageBanner("choose-img"); setImageBanner(""); setBannerDesign(config.BANNER_7);}} className={(bannerDesign == config.BANNER_7) ? "button-select-image" : "button-not-select-image"}>
                                            <div className="icon-container">
                                                <i className={(bannerDesign == config.BANNER_7) ? "icon-check icon-color-select" : "icon-check icon-non-color"}></i>
                                            </div>
                                            <img style={{ width: 170, height: 140, borderRadius: '0.5rem', objectFit: 'cover' }} src={config.BANNER_7} />
                                        </button>
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-12 mb-1">
                                        <button type="button" onClick={() => {setCurrentPageBanner("choose-img"); setImageBanner(""); setBannerDesign(config.BANNER_8);}} className={(bannerDesign == config.BANNER_8) ? "button-select-image" : "button-not-select-image"}>
                                            <div className="icon-container">
                                                <i className={(bannerDesign == config.BANNER_8) ? "icon-check icon-color-select" : "icon-check icon-non-color"}></i>
                                            </div>
                                            <img style={{ width: 170, height: 140, borderRadius: '0.5rem', objectFit: 'cover' }} src={config.BANNER_8} />
                                        </button>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-lg-4 col-md-12 col-12 mb-1">
                                        <button type="button" onClick={() => {setCurrentPageBanner("choose-img"); setImageBanner(""); setBannerDesign(config.BANNER_9);}} className={(bannerDesign == config.BANNER_9) ? "button-select-image" : "button-not-select-image"}>
                                            <div className="icon-container">
                                                <i className={(bannerDesign == config.BANNER_9) ? "icon-check icon-color-select" : "icon-check icon-non-color"}></i>
                                            </div>
                                            <img style={{ width: 170, height: 140, borderRadius: '0.5rem', objectFit: 'cover' }} src={config.BANNER_9} />
                                        </button>
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-12 mb-1">
                                        <button type="button" onClick={() => {setCurrentPageBanner("choose-img"); setImageBanner(""); setBannerDesign(config.BANNER_10);}} className={(bannerDesign == config.BANNER_10) ? "button-select-image" : "button-not-select-image"}>
                                            <div className="icon-container">
                                                <i className={(bannerDesign == config.BANNER_10) ? "icon-check icon-color-select" : "icon-check icon-non-color"}></i>
                                            </div>
                                            <img style={{ width: 170, height: 140, borderRadius: '0.5rem', objectFit: 'cover' }} src={config.BANNER_10} />
                                        </button>
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-12">
                                        <div className={(bannerDesign !== config.BANNER_1 && bannerDesign !== config.BANNER_2 && bannerDesign !== config.BANNER_3 && bannerDesign !== config.BANNER_4 && bannerDesign !== config.BANNER_5 && bannerDesign !== config.BANNER_6 && bannerDesign !== config.BANNER_7 && bannerDesign !== config.BANNER_8 && bannerDesign !== config.BANNER_9 && bannerDesign !== config.BANNER_10 && bannerDesign !== config.BANNER_11) ? "button-select-image" : "button-not-select-image"}>
                                            <div className="icon-container">
                                                <i className={(bannerDesign !== config.BANNER_1 && bannerDesign !== config.BANNER_2 && bannerDesign !== config.BANNER_3 && bannerDesign !== config.BANNER_4 && bannerDesign !== config.BANNER_5 && bannerDesign !== config.BANNER_6 && bannerDesign !== config.BANNER_7 && bannerDesign !== config.BANNER_8 && bannerDesign !== config.BANNER_9 && bannerDesign !== config.BANNER_10 && bannerDesign !== config.BANNER_11) ? "icon-check icon-color-select" : "icon-check icon-non-color"}></i>
                                            </div>
                                            {currentPageBanner === "choose-img"
                                                ? 
                                                (
                                                    <FileInput setImage={setImageBanner} typeImage={1} onImageSelected={onImageSelectedBanner} inputRef={inputBannerRef} />
                                                ) : (
                                                    <>
                                                        <img style={{ width: 170, height: 140, borderRadius: '0.5rem', objectFit: 'cover' }} src={bannerDesign} />
                                                        <Button className='delete-btn-image-select' type='primary' danger onClick={() => {setCurrentPageBanner("choose-img"); setImageBanner(""); setBannerDesign(config.BANNER_1);}}>
                                                            <i className='ft-trash-2'></i>
                                                        </Button>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <Modal title="Modifica immagine" onCancel={onCropCancel} open={isModalOpen} okButtonProps={{ style: { display: 'none' } }} cancelButtonProps={{ style: { display: 'none' } }}>
                    <ImageCropper
                        image={image}
                        onCropDone={onCropDone}
                        onCropCancel={onCropCancel}
                        aspectRatio={1 / 1}
                    />
                </Modal>
                <Modal title="Modifica immagine" onCancel={onCropCancelBanner} open={isModalOpenBanner} okButtonProps={{ style: { display: 'none' } }} cancelButtonProps={{ style: { display: 'none' } }}>
                    <ImageCropper
                        image={imageBanner}
                        onCropDone={onCropDoneBanner}
                        onCropCancel={onCropCancelBanner}
                        aspectRatio={16 / 9}
                    />
                </Modal>
            </>
        );
    }

    return(
        <>
        <ToastContainer />
        <Helmet>
            <title>Completa Profilo | Takeiteasy Partners</title>
        </Helmet>
        <Navbar />
        <Tour open={isTourOpen} onClose={() => setIsTourOpen(false)} steps={stepsTour} />
        <div className="app-content content">
            <div className="content-overlay"></div>
            <div className="content-wrapper">
                <Errors />
                <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2">
                        <h3 className="content-header-title">Completa il tuo profilo</h3>
                    </div>
                </div>
                <div className="content-body">
                    <div className="content-overlay"></div>
                    <section className="row">
                        <div className="col-lg-12 col-md-12 col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Configura il tuo account e la tua farmacia</h4>
                                </div>
                                <div className="card-body">
                                    {(haveError)
                                        ?
                                        <Skeleton active />
                                        :
                                        (isCompleted)
                                            ?
                                            <Empty
                                                description={
                                                    <span>
                                                        Hai già completato il tuo profilo.
                                                    </span>
                                                }
                                            />
                                            :
                                            <>
                                            <Steps current={current} items={items} />
                                            <div style={contentStyle}>
                                                <form>
                                                    {(current === 0)
                                                        ?
                                                        <fieldset ref={firstStep}>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor='phone'>Numero di telefono*</label>
                                                                        <PhoneInput
                                                                            placeholder='Insrisci il tuo Numero di telefono'
                                                                            country={'it'}
                                                                            localization={it}
                                                                            regions={'europe'}
                                                                            inputclassName="form-control"
                                                                            inputStyle={{ width: '100%' }}
                                                                            disableSearchIcon={true}
                                                                            searchPlaceholder="Cerca"
                                                                            inputProps={{
                                                                                name: 'phone',
                                                                                required: true,
                                                                                id: 'phone'
                                                                            }}
                                                                            countryCodeEditable={false}
                                                                            enableSearch={true}
                                                                            value={userData.phone}
                                                                            onChange={(phone) => setUserData({ ...userData, phone: phone })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="company">Ragione sociale*</label>
                                                                        <input type="text" className="form-control" value={userData.company} onChange={handleChange} name="company" id="company" placeholder='Inserisci la tua Ragione sociale' required />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="company_address">Indirizzo sede legale*</label>
                                                                        <Autocomplete
                                                                            apiKey={config.GOOGLE_MAPS_API}
                                                                            onPlaceSelected={(place) => handleAddress(place)}
                                                                            language='it'
                                                                            options={{ types: ["address"] }}
                                                                            placeholder="Inserisci la tua Sede Legale (Es. Via Torino, 23, Milano)"
                                                                            className="form-control"
                                                                            aria-required={true}
                                                                            id="company_address"
                                                                            defaultValue={(companyAddress !== "" && companyCivic !== "") ? `${companyAddress}, ${companyCivic}` : null}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="company_city">Città*</label>
                                                                        <input type="text" className="form-control" disabled={isAddressDiasbled} onChange={(city) => setCompanyCity(city.target.value)} value={companyCity} name="company_city" id="company_city" placeholder='Inserisci la Città' required />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="company_cap">CAP*</label>
                                                                        <input type="text" className="form-control" disabled={isAddressDiasbled} onChange={(cap) => setCompanyCap(cap.target.value)} value={companyCap} name="company_cap" id="company_cap" placeholder='Inserisci il CAP' required />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="company_province">Provincia*</label>
                                                                        <input type="text" className="form-control" disabled={isAddressDiasbled} onChange={(province) => setCompanyProvince(province.target.value)} value={companyProvince} name="company_province" id="company_province" placeholder='Inserisci la Provincia' required />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="vat">P.IVA* (Senza la sigla IT)</label>
                                                                        <div style={{ position: 'relative' }}>
                                                                            <input type="text" className="form-control" value={userData.vat} onChange={(vat) => validateVat(vat)} name="vat" placeholder='Inserisci la P.IVA' required />
                                                                            {(isVatValid == true)
                                                                                ?
                                                                                <div style={{ position: 'absolute', right: 10, top: 10.5, zIndex: 2 }}>
                                                                                    <i style={{ fontSize: '1.5rem' }} className='ft-check-circle text-success'></i>
                                                                                </div>
                                                                                :
                                                                                (isVatValid == false)
                                                                                    ?
                                                                                    <div style={{ position: 'absolute', right: 10, top: 10.5, zIndex: 2 }}>
                                                                                        <i style={{ fontSize: '1.5rem' }} className='ft-alert-octagon text-danger'></i>
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="pec">Pec</label>
                                                                        <input type="email" className="form-control" value={userData.pec} onChange={handleChange} name="pec" id="pec" placeholder='Inserisci la Pec' />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="receiver_code">Codice destinatario (Per fatturazione elettronica)</label>
                                                                        <input type="text" className="form-control" value={userData.receiver_code} onChange={handleChange} name="receiver_code" id="receiver_code" placeholder='Inserisci il Codice destinatario' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                        :
                                                        (current === 1)
                                                            ?
                                                            <fieldset ref={secondStep}>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="name_pharmacy">Nome farmacia*</label>
                                                                            <input type="text" className="form-control" value={userData.name_pharmacy} onChange={handleChange} name="name_pharmacy" id="name_pharmacy" placeholder='Inserisci il nome della Farmacia (Es. Farmacia Dott. Rossi)' required />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="address">Indirizzo farmacia*</label>
                                                                            <Autocomplete
                                                                                apiKey={config.GOOGLE_MAPS_API}
                                                                                onPlaceSelected={(place) => handleAddressPharmacy(place)}
                                                                                language='it'
                                                                                options={{ types: ["address"] }}
                                                                                placeholder="Inserisci l'indirizzo della tua Farmacia (Es. Via Torino, 23, Milano)"
                                                                                className="form-control"
                                                                                aria-required={true}
                                                                                id="address"
                                                                                defaultValue={(pharmacyAddress !== "" && pharmacyCivic !== "") ? `${pharmacyAddress}, ${pharmacyCivic}` : null}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="city">Città farmacia*</label>
                                                                            <input type="text" disabled={isAddressDiasbledPharmacy} value={pharmacyCity} onChange={(city) => setPharmacyCity(city.target.value)} className="form-control" name="city" id="city" placeholder="Inserisci la città della Farmacia" required />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="cap">CAP*</label>
                                                                            <input type="text" disabled={isAddressDiasbledPharmacy} value={pharmacyCap} onChange={(cap) => setPharmacyCap(cap.target.value)} className="form-control" name="cap" id="cap" placeholder="Inserisci il CAP della Farmacia" required />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="province">Provincia*</label>
                                                                            <input type="text" disabled={isAddressDiasbledPharmacy} value={pharmacyProvince} onChange={(province) => setPharmacyProvince(province.target.value)} className="form-control" name="province" id="province" placeholder="Inserisci la provincia della Farmacia" required />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                            :
                                                            (current === 2)
                                                            ?
                                                            <Step3 />
                                                            :
                                                            null
                                                    }
                                                </form>
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: 24,
                                                }}
                                                className="float-right"
                                            >
                                                {current > 0 && (
                                                    <Button
                                                        style={{ margin: '0 8px 0 0', }}
                                                        onClick={() => prev()}
                                                    >
                                                        Indietro
                                                    </Button>
                                                )}
                                                {current === 2 && (
                                                    <Button style={{ margin: '0 8px 0 0', }} ghost icon={<i style={{ marginRight: '0.3rem' }} className='icon-eye'></i>} type="primary" onClick={() => setShowAnteprima(true)}>
                                                        Anteprima
                                                    </Button>
                                                )}
                                                {current === 0 && (
                                                    <Button type="primary" onClick={() => controlFirstStep()}>
                                                        Prossimo
                                                    </Button>
                                                )}
                                                {current === 1 && (
                                                    <Button type="primary" onClick={() => controlSecondStep()}>
                                                        Prossimo
                                                    </Button>
                                                )}
                                                {current === steps.length - 1 && (
                                                    <Button type="primary" onClick={(loading) ? null : handleCompleteProfile}>
                                                        {(loading)
                                                            ?
                                                            <ThreeDots 
                                                                height="11" 
                                                                width="23" 
                                                                radius="4.5"
                                                                color="#fff" 
                                                                ariaLabel="three-dots-loading"
                                                                wrapperStyle={{ justifyContent: 'center', alignItems: 'center' }}
                                                                visible={true}
                                                            />
                                                            :
                                                            'Salva'
                                                        }
                                                    </Button>
                                                )}
                                            </div>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <Modal title="Anteprima della tua farmacia all'interno dell'app" bodyStyle={{ backgroundColor: '#f5f7ff', padding: '1.2rem', borderRadius: '0.8rem' }} open={showAnteprima} okText="Chiudi" cancelButtonProps={{ style: { display: 'none' } }} onCancel={() => setShowAnteprima(false)} onOk={() => setShowAnteprima(false)}>
            <div className="d-flex justify-content-center py-3">
                <div className='pharmacy-container'>
                    <div style={{ position: 'relative' }}>
                        <div style={{ position: 'absolute', bottom: 5, left: 5, zIndex: 2 }}>
                            <Avatar size={50} src={(isLogoDegault) ? config.LOGO_DEFAULT : imgAfterCrop} />
                        </div>
                        <img src={bannerDesign} style={{ width: '20rem', height: '6.5rem', borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem', objectFit: 'cover', objectPosition: 'top center', backgroundColor: '#fdf0e0' }} />   
                    </div>
                    <div style={{ padding: 10 }}>
                        <label className='mb-0' style={{ fontWeight: '700' }}>{userData.name_pharmacy}</label><br />
                        <span style={{ fontSize: 11 }}>Apreta oggi dalle 08:00 alle 13:00</span>
                    </div>                   
                </div>
            </div>
        </Modal>
        <Footer />
        </>
    )
}

export default CompleteProfile;