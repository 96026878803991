import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet";
import * as config from '../config';
import axios from 'axios';
import { Skeleton, Empty, Avatar, Card, Tag } from 'antd';
import { EditOutlined, CheckOutlined } from '@ant-design/icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Errors from '../components/Errors';

const { Meta } = Card;

function Pharmacies() {
    const navigate = useNavigate()
    const [isLoad, setIsLoad] = useState(false)
    const [pharmacies, setPharmacies] = useState([])
    const [haveError, setHaveError] = useState(false)

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('user_info'))
        checkErrors(userInfo)
        getPharmacies(userInfo)
    }, [])

    const checkErrors = (userInfo) => {
        if (userInfo !== null) {
            if (userInfo.is_code_verify == "0" || userInfo.is_code_verify == 0 || userInfo.is_completed == "0" || userInfo.is_completed == 0) {
                setHaveError(true)
            } else {
                setHaveError(false)
            }
        } else {
            setHaveError(false)
        }
    }

    const getPharmacies = (userInfo) => {
        axios({
            method: 'post',
            url: `${config.WEBSITE_URL}/partners/get-pharmacies`,
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: config.USERNAME_TOKEN,
                password: config.PASSWORD_TOKEN
            },
            data: JSON.stringify({
                token_access: config.WEB_TOKEN,
                id_admin_user: userInfo.id
            })
        })
        .then(function(response) {
            if (response.data.code !== false) {
                if (response.data.length > 0) {
                    setPharmacies(response.data)
                    setIsLoad(true)
                } else {
                    setIsLoad(true)
                }
            } else {
                setIsLoad(true)
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(function(error) {
            setIsLoad(true)
            console.error(error);
        });
    }

    return(
        <>
        <ToastContainer />
        <Helmet>
            <title>Farmacie | Takeiteasy Partners</title>
        </Helmet>
        <Navbar />
        <div className="app-content content">
            <div className="content-overlay"></div>
            <div className="content-wrapper">
                <Errors />
                <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2">
                        <h3 className="content-header-title">Farmacie</h3>
                    </div>
                </div>
                <div className="content-body">
                    <section className="row">
                        <div className="col-12">
                            <div className="card">
                                {(haveError)
                                    ?
                                    <Empty
                                        description={
                                            <span>
                                                Risolvi gli errori per poter continuare.
                                            </span>
                                        }
                                    />
                                    :
                                    <>
                                    <div className="card-header">
                                        <h4 className="card-title">Le tue farmacie</h4>
                                        <a className="heading-elements-toggle"><i className="la la-ellipsis-h font-medium-3"></i></a>
                                        <div className="heading-elements">
                                            <Link to="/aggiungi/farmacia" className="btn btn-primary btn-sm">
                                                <i className="ft-plus white"></i> Nuova farmacia
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="card-content">
                                        <div className="card-body">
                                            {(isLoad)
                                                ?
                                                (pharmacies.length > 0)
                                                    ?
                                                    <div className='row px-1'>
                                                        {pharmacies.map((item, index) => {
                                                            return(
                                                                <div className='col-lg-3 col-md-6 col-12'>
                                                                    <Card
                                                                        cover={
                                                                            <img
                                                                                src={item.banner}
                                                                            />
                                                                        }
                                                                        actions={
                                                                            (item.is_verificated == "0" || item.is_verificated == 0)
                                                                            ?
                                                                            [
                                                                                <div onClick={() => navigate(`/verifica/farmacia/${item.id}`)} className="d-flex align-items-center justify-content-center">
                                                                                    <CheckOutlined label='Verifica' key="check" />
                                                                                    <span style={{ marginLeft: 6 }}>Verifica</span>
                                                                                </div>,
                                                                                <div onClick={() => navigate(`/modifica/farmacia/${item.id}`)} className="d-flex align-items-center justify-content-center">
                                                                                    <EditOutlined label='Modifica' key="edit" />
                                                                                    <span style={{ marginLeft: 6 }}>Modifica</span>
                                                                                </div>
                                                                            ]
                                                                            :
                                                                            (item.is_active == "0" || item.is_active == 0)
                                                                            ?
                                                                            [
                                                                                <div onClick={() => navigate(`/attiva/farmacia/${item.id}`)} className="d-flex align-items-center justify-content-center">
                                                                                    <CheckOutlined label='Attiva' key="check" />
                                                                                    <span style={{ marginLeft: 6 }}>Attiva</span>
                                                                                </div>,
                                                                                <div onClick={() => navigate(`/modifica/farmacia/${item.id}`)} className="d-flex align-items-center justify-content-center">
                                                                                    <EditOutlined label='Modifica' key="edit" />
                                                                                    <span style={{ marginLeft: 6 }}>Modifica</span>
                                                                                </div>
                                                                            ]
                                                                            :
                                                                            [
                                                                                <div onClick={() => navigate(`/modifica/farmacia/${item.id}`)} className="d-flex align-items-center justify-content-center">
                                                                                    <EditOutlined label='Modifica' key="edit" />
                                                                                    <span style={{ marginLeft: 6 }}>Modifica</span>
                                                                                </div>
                                                                            ]
                                                                        }
                                                                    >
                                                                        <Meta
                                                                            avatar={<Avatar size={45} src={item.logo} />}
                                                                            title={item.name_pharmacy}
                                                                            description={
                                                                                <>
                                                                                <span>{item.address}, {item.city}, {item.cap}, {item.province}</span><br />
                                                                                <div className='d-flex mt-1'>
                                                                                    {(item.is_verificated == "0" || item.is_verificated == 0)
                                                                                        ?
                                                                                        <Tag color='volcano'>
                                                                                            NON VERIFICATA
                                                                                        </Tag>
                                                                                        :
                                                                                        <Tag color='green'>
                                                                                            VERIFICATA
                                                                                        </Tag>
                                                                                    }
                                                                                    {(item.is_active == "0" || item.is_active == 0)
                                                                                        ?
                                                                                        <Tag color='volcano'>
                                                                                            NON ATTIVA
                                                                                        </Tag>
                                                                                        :
                                                                                        <Tag color='green'>
                                                                                            ATTIVA
                                                                                        </Tag>
                                                                                    }
                                                                                </div>
                                                                                </>
                                                                            }
                                                                        />
                                                                    </Card>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    :
                                                    <Empty
                                                        description={
                                                            <span>
                                                                Non hai aggiunto alcuna Farmacia.
                                                            </span>
                                                        }
                                                    />
                                                :
                                                <div className='row px-1'>
                                                    <Card
                                                        className='col-lg-3 col-md-6 col-12px-0'
                                                        actions={[
                                                            <CheckOutlined key="check" />,
                                                            <EditOutlined key="edit" />
                                                        ]}
                                                    >
                                                        <Skeleton loading={true} avatar active>
                                                            <Meta
                                                                avatar={<Avatar size={45} src={config.LOGO_DEFAULT} />}
                                                                title="Card title"
                                                                description="This is the description"
                                                            />
                                                        </Skeleton>
                                                    </Card>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    </>
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default Pharmacies;