import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet";
import { Result, Button as ButtonAntd, Avatar, Card, Badge } from 'antd';
import * as config from '../config.js';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThreeDots } from 'react-loader-spinner';
import { LoadingOutlined, CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';

function RestoreComplete() {
    const navigate = useNavigate()

    return(
        <>
        <Helmet>
            <title>Ripristino Completato | Takeiteasy Partners</title>
        </Helmet>
        <ToastContainer />
        <Navbar />
        <div className="app-content content">
            <div className="content-overlay"></div>
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2">
                        <h3 className="content-header-title">Ripristino completato</h3>
                    </div>
                </div>
                <div className="content-body">
                    <section className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <Result
                                            icon={<CheckCircleFilled />}
                                            status={"success"}
                                            title={"Farmacia ripristinata"}
                                            subTitle={"L'abbonamento della tua farmacia è di nuovo attivo. Ora sei visibile ai tuoi clienti"}
                                            extra={
                                                <ButtonAntd type="primary" onClick={() => navigate('/')}>
                                                    Vai alla Dashboard
                                                </ButtonAntd>
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default RestoreComplete;